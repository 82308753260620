
import {
  UnilevelMember,
  UnilevelTeam,
  doUpdateLeaderData,
  initializeTeam,
  searchUser,
  setLeaderUsername,
} from "@/store/Unilevel/UnilevelInterfaces";
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import { RanksCard } from "../components";
import { CarrerSummaryCard, Team } from "../components/unilevel";
import { RankStatus } from "@/store/Auth/AuthInterfaces";

export default defineComponent({
  name: "unilevel",
  components: {
    RanksCard,
    CarrerSummaryCard,
    Team,
  },
  data() {
    return {
      loaded: false,
      loadingTeam: true,
      nextRank: {},
    };
  },
  computed: {
    ...mapGetters("unilevel", {
      getTeam: "getTeam",
      currentLeader: "getCurrentLeader",
    }),
    UnilevelTeam(): UnilevelTeam {
      return this.getTeam(this.currentLeader);
    },
    leader(): UnilevelMember | undefined {
      if (
        this.UnilevelTeam == undefined &&
        this.currentLeader == "@@USER_NOT_FOUND@@"
      ) {
        return new UnilevelMember(
          "@@USER_NOT_FOUND@@",
          "@@USER_NOT_FOUND@@",
          "@@USER_NOT_FOUND@@",
          "@@USER_NOT_FOUND@@",
          "@@USER_NOT_FOUND@@",
          new Map<string, RankStatus>()
        );
      }
      return this.UnilevelTeam?.leader
        ? { ...this.UnilevelTeam?.leader }
        : undefined;
    },
  },
  methods: {
    async initializeTeam() {
      await this.$store.dispatch("unilevel/" + initializeTeam).then(() => {
        this.loaded = true;
        this.loadingTeam = false;
      });
    },
    changeTeamLeader(newLeaderUsername: string) {
      // console.log(newLeaderUsername);
      if (newLeaderUsername != undefined) {
        this.loadingTeam = true;
        this.$store
          .dispatch("unilevel/" + setLeaderUsername, newLeaderUsername)
          .then(() => {
            this.loadingTeam = false;
          });
      }
    },
    searchUser(username: string) {
      if (username != undefined) {
        this.loadingTeam = true;
        this.$store.dispatch("unilevel/" + searchUser, username).finally(() => {
          this.loadingTeam = false;
        });
      }
    },
    reloadUser(username: string) {
      this.loadingTeam = true;
      this.$store
        .dispatch("unilevel/" + doUpdateLeaderData, username)
        .finally(() => {
          this.loadingTeam = false;
        });
    },
    setNextRank(rank: Record<string, never>) {
      this.nextRank = rank;
    },
  },
  async created() {
    if (this.currentLeader == "" || this.currentLeader == undefined) {
      await this.initializeTeam();
    } else {
      this.loaded = true;
      this.loadingTeam = false;
    }
  },
});
