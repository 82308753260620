
import { defineComponent } from "vue";
import { images } from "@/mixins/images";

export default defineComponent({
  name: "image-modal",
  props: {
    modalId: {
      type: String,
      default: "image-modal",
    },
    image: {
      type: Object as () => {
        src: string;
        alt: string;
      },
      required: true,
    },
    closeButtonClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: "",
      id: "",
    };
  },
  created() {
    this.id = this.modalId;
    this.getImage(this.image.src).then((r) => {
      this.imgSrc = r;
    });
  },
  watch: {
    "image.src": function (newValue) {
      this.getImage(newValue.src).then((r) => {
        this.imgSrc = r;
      });
    },
  },
  setup() {
    const getImage = images.methods.getImage;
    return {
      getImage,
    };
  },
});
