
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { doGetActiveEvents } from "@/store/Events/EventsInterfaces";
import NewEventForm from "@/components/events/admin/NewEventForm.vue";

export default defineComponent({
  name: "BuyTicketsNewEvent",
  components: {
    NewEventForm,
  },
  computed: {
    ...mapGetters("events", { events: "getActiveEvents" }),
  },
  methods: {
    getEvents() {
      this.$store.dispatch("events/" + doGetActiveEvents);
    },
  },
  async mounted() {
    await this.getEvents();
  },
});
