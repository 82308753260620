import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdEditor = _resolveComponent("MdEditor")!

  return (_openBlock(), _createBlock(_component_MdEditor, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    language: "en-US",
    toolbars: _ctx.defaultConfig,
    "editor-id": _ctx.id
  }, null, 8, ["modelValue", "toolbars", "editor-id"]))
}