
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import {
  BinaryOptionsClass,
  ForexClass,
  FastStartCall,
  BusinessPlan,
  WakeupCall,
  MarketkingClass,
  Mentorship,
  LlamadaTestimoniosCall,
  FreeClass,
  CryptospaceClass,
  SportsTradingClass,
  GTRWoman,
  AdvancedMentorship,
  FromHumanToHero as FHH,
  MindsetCall,
} from "../../store/Classes/ClassesInterfases";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ClassImageModal from "./ClassImageModal.vue";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(objectSupport);

export default defineComponent({
  name: "ClassModal",
  components: { VueDatePicker, ClassImageModal },
  props: {
    header: { type: String },
    classId: { required: true, type: String },
    classType: { type: String },
    classTitle: { type: String },
    scheduleType: { type: String },
    classHour: { type: String },
    classDay: { type: String },
    classActive: { type: [Boolean, String] },
    classLink: { type: String },
    toggle: {},
    getSchedule: {},
    edit: Boolean,
  },
  data() {
    return {
      schedule: this.scheduleType ?? "",
      type: this.classType ?? "blank",
      title: this.classTitle ?? "",
      professor: this.classProfessor ?? "",
      hour: this.classHour ?? "",
      day: this.classDay ?? "",
      active: this.classActive ?? true,
      link: this.classLink ?? "",
      hours: [] as any,
      date: new Date(),
      weekdays: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      },
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      classList: {
        academy: {
          BinaryOptionsClass: new BinaryOptionsClass({}),
          ForexClass: new ForexClass({}),
          MarketkingClass: new MarketkingClass({}),
          CryptospaceClass: new CryptospaceClass({}),
          SportsTradingClass: new SportsTradingClass({}),
          blank: {
            title: "blank",
          },
        },
        gtrSystem: {
          FastStartCall: new FastStartCall({}),
          BusinessPlan: new BusinessPlan({}),
          WakeupCall: new WakeupCall({}),
          Mentorship: new Mentorship({}),
          LlamadaTestimoniosCall: new LlamadaTestimoniosCall({}),
          FreeClass: new FreeClass({}),
          GTRWoman: new GTRWoman({}),
          AdvancedMentorship: new AdvancedMentorship({}),
          FHH: new FHH({}),
          MindsetCall: new MindsetCall({}),
          blank: {
            title: "blank",
          },
        },
        mmiColombia: {
          blank: {
            title: "blank",
          },
        },
      },
      image: "",
    };
  },
  methods: {
    closeModal() {
      if (this.modal) this.modal.hide();
    },
    openModal() {
      if (this.modal) this.modal.show();
    },
    getImage() {
      const defaultImage = "default-card.png";
      if (this.type == "blank") return "";
      if (
        this.classList[this.scheduleType ?? ""][this.type].imageUrl &&
        this.classList[this.scheduleType ?? ""][this.type].imageUrl.startsWith(
          "data:image"
        )
      ) {
        return this.classList[this.scheduleType ?? ""][this.type].imageUrl;
      } else {
        try {
          return require(`../../assets/img/classes/${
            this.classList[this.scheduleType ?? ""][this.type].imageUrl
          }`);
        } catch {
          return require(`../../assets/img/classes/${defaultImage}`);
        }
      }
    },
    getImageUrl() {
      const defaultImage = "default-card.png";
      if (this.type == "blank") return "";
      if (
        this.classList[this.scheduleType ?? ""][this.type].imageUrl &&
        this.classList[this.scheduleType ?? ""][this.type].imageUrl.startsWith(
          "data:image"
        )
      ) {
        return this.classList[this.scheduleType ?? ""][this.type].imageUrl;
      } else {
        try {
          return this.classList[this.scheduleType ?? ""][this.type].imageUrl;
        } catch {
          return defaultImage;
        }
      }
    },
    saveClass() {
      const data = {
        scheduleType: this.schedule,
        classType: this.type,
        hour: this.hour,
        weekDay: this.day,
        active: this.active == "true" || this.active == true,
        link: this.link,
        title:
          this.title ??
          this.classList[this.scheduleType ?? ""][this.type].title,
        imageUrl:
          this.image.search("http") >= 0 ? this.image : this.getImageUrl(),
      };

      // console.log(
      //   this.image.search("http") >= 0 ? this.image : this.getImageUrl()
      // );

      this.$store.dispatch("classes/doCreateClass", data).then(() => {
        this.$store.dispatch("classes/doGetSchedules");
        this.type = "";
        this.title = "";
        this.hour = "";
        this.day = "";
        this.active = true;
        this.link = "";
        this.modal.hide();
      });
    },
    dateFormatting(date) {
      const weekday = date.getDay() - 1;
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      return `${Object.values(this.weekdays)[weekday]} ${day} ${
        this.months[month]
      }, ${year}`;
    },
    updateImage(url) {
      this.image = url;
    },
  },
  computed: {
    modal() {
      return this.$refs.modal ? new Modal(this.$refs.modal) : null;
    },
  },
  watch: {
    type() {
      if (this.type == "blank") {
        this.title = "";
        this.link = "";
        this.image = "";
      } else if (this.type != "") {
        this.title = this.classList[this.scheduleType ?? ""][this.type].title;
        this.link = this.classList[this.scheduleType ?? ""][this.type].link;
        this.image = this.getImage();
      }
    },
  },
  mounted() {
    const hours: Array<string> = [];
    for (let hour = 0; hour < 24; hour++) {
      hours.push(dayjs({ hour }).format("hh:mm A"));
      hours.push(
        dayjs({
          hour,
          minute: 30,
        }).format("hh:mm A")
      );
    }
    this.hours = [...hours];
    if (this.type != "") {
      this.link = this.classList[this.scheduleType ?? ""][this.type].link;

      if (this.type == "blank") this.title = "";
      else
        this.title = this.classList[this.scheduleType ?? ""][this.type].title;

      this.image = this.getImage();
      this.active = "true";
    }
    this.classList = {
      academy: {
        BinaryOptionsClass: new BinaryOptionsClass({}),
        ForexClass: new ForexClass({}),
        MarketkingClass: new MarketkingClass({}),
        CryptospaceClass: new CryptospaceClass({}),
        SportsTradingClass: new SportsTradingClass({}),
        blank: {
          title: "blank",
        },
      },
      gtrSystem: {
        FastStartCall: new FastStartCall({}),
        BusinessPlan: new BusinessPlan({}),
        WakeupCall: new WakeupCall({}),
        Mentorship: new Mentorship({}),
        LlamadaTestimoniosCall: new LlamadaTestimoniosCall({}),
        FreeClass: new FreeClass({}),
        GTRWoman: new GTRWoman({}),
        AdvancedMentorship: new AdvancedMentorship({}),
        FHH: new FHH({}),
        MindsetCall: new MindsetCall({}),
        blank: {
          title: "blank",
        },
      },
      mmiColombia: {
        blank: {
          title: "blank",
        },
      },
    };
  },
  unmounted() {
    if (this.modal) this.modal.hide();
  },
});
