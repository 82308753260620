import { VideoData } from "functions/src/MMI/MMIInterfaces";
import { ActionContext } from "vuex";
import * as fb from "../../firebase";
// import router from "../../router";

import {
  Chapters,
  CLEAR_CHAPTER_WATCHED_VIDEOS_LISTENER,
  MMIState,
  MMIUser,
  SET_CHAPTER_PLAYLIST,
  SET_CHAPTER_WATCHED_VIDEOS,
  SET_CHAPTER_WATCHED_VIDEOS_LISTENER,
  SET_USER_STRUCTURE,
} from "./MMIInterfaces";

const initialState: MMIState = {
  colombia: {
    users: new Map<string, MMIUser>(),
    videos: [],
    watchedVideos: [],
  },
};

const state: MMIState = { ...initialState };

const snapshotListeners = {
  colombia: {
    watchedVideos: () => {
      return;
    },
  },
};

const mutations = {
  SET_USER_STRUCTURE(
    state: MMIState,
    structure: { chapter: Chapters; user: MMIUser }
  ) {
    state[structure.chapter].users.set(
      structure.user._usernamePK,
      structure.user
    );
  },
  SET_CHAPTER_PLAYLIST(
    state: MMIState,
    structure: { chapter: Chapters; videos: Array<VideoData> }
  ) {
    state[structure.chapter].videos = structure.videos;
  },
  SET_CHAPTER_WATCHED_VIDEOS(
    state: MMIState,
    structure: { chapter: Chapters; videos: Array<string> }
  ) {
    state[structure.chapter].watchedVideos = structure.videos;
  },
  SET_CHAPTER_WATCHED_VIDEOS_LISTENER(
    state: MMIState,
    structure: { chapter: Chapters; listener: () => void }
  ) {
    snapshotListeners[structure.chapter].watchedVideos = structure.listener;
  },
  CLEAR_CHAPTER_WATCHED_VIDEOS_LISTENER(
    state: MMIState,
    structure: { chapter: Chapters }
  ) {
    snapshotListeners[structure.chapter].watchedVideos();
    snapshotListeners[structure.chapter].watchedVideos = () => {
      return;
    };
  },
};

const actions = {
  async doGetUserStructure(
    context: ActionContext<MMIState, any>,
    request: { chapter: Chapters; username: string }
  ) {
    const getUserStructure = fb.func.httpsCallable("MMI-getUserStructure");
    const structure: MMIUser = await getUserStructure({
      chapter: request.chapter,
      username: request.username,
    }).then((result) => {
      if (result.data.OPCODE == "SUCCESS") {
        return result.data.data;
      } else {
        if (result.data.OPCODE == "ERROR") {
          const e = new Error(result.data);
          e.name = result.data.error.name;
          throw e;
        } else {
          throw new Error("Unknown error");
        }
      }
    });
    structure.rankIconClass = context.rootGetters["ranks/rankIconClass"](
      structure.currentRankId
    );
    structure.users.forEach((user) => {
      user.rankIconClass = context.rootGetters["ranks/rankIconClass"](
        user.currentRankId
      );
    });
    context.commit(SET_USER_STRUCTURE, {
      chapter: request.chapter,
      user: structure,
    });
  },
  async doGetChapterVideosPlaylist(
    context: ActionContext<MMIState, any>,
    chapter: Chapters
  ) {
    const getChapterVideos = fb.func.httpsCallable(
      "MMI-getChapterVideosPlaylist"
    );
    const playlist: Array<VideoData> = await getChapterVideos({
      chapter: chapter,
    }).then((result) => {
      if (result.data.OPCODE == "SUCCESS") {
        return result.data.items as Array<VideoData>;
      } else {
        if (result.data.OPCODE == "ERROR") {
          const e = new Error(result.data);
          e.name = result.data.error.name;
          throw e;
        } else {
          throw new Error("Unknown error");
        }
      }
    });
    context.commit(SET_CHAPTER_PLAYLIST, {
      chapter: chapter,
      videos: playlist,
    });
  },
  async doGetWatchedVideos(
    context: ActionContext<MMIState, any>,
    chapter: Chapters
  ) {
    const username = await context.rootGetters["auth/getUsernamePK"];
    const _structureRef = fb.fbfs.collection(`mmi/${chapter}/structure/`);
    const listener = _structureRef.doc(username).onSnapshot((ss) => {
      let watechedVideos = [];
      if (ss.exists && ss.data()?.watchedVideos) {
        watechedVideos = ss.data()?.watchedVideos;
      }
      context.commit(SET_CHAPTER_WATCHED_VIDEOS, {
        chapter: chapter,
        videos: watechedVideos,
      });
    });
    context.commit(SET_CHAPTER_WATCHED_VIDEOS_LISTENER, {
      chapter: chapter,
      listener: listener,
    });
  },
  async doClearWatchedVideosListener(
    context: ActionContext<MMIState, any>,
    chapter: Chapters
  ) {
    context.commit(CLEAR_CHAPTER_WATCHED_VIDEOS_LISTENER, {
      chapter: chapter,
    });
  },
  addWatchedVideo(
    context: ActionContext<MMIState, any>,
    request: { chapter: Chapters; videoId: string }
  ) {
    const username = context.rootGetters["auth/getUsernamePK"];
    const _structureRef = fb.fbfs.collection(
      `mmi/${request.chapter}/structure/`
    );
    const _watchedVideosRef = _structureRef.doc(username);
    const watchedVideosArray = [
      ...context.state[request.chapter].watchedVideos,
    ];
    watchedVideosArray.push(request.videoId);
    _watchedVideosRef.set(
      { watchedVideos: watchedVideosArray },
      { merge: true }
    );
  },
  removeWatchedVideo(
    context: ActionContext<MMIState, any>,
    request: {
      chapter: Chapters;
      videoId: string;
    }
  ) {
    const username = context.rootGetters["auth/getUsernamePK"];
    const _structureRef = fb.fbfs.collection(
      `mmi/${request.chapter}/structure/`
    );
    const _watchedVideosRef = _structureRef.doc(username);
    const watchedVideosArray = [
      ...context.state[request.chapter].watchedVideos,
    ];
    const filteredWatchedVideosArray = watchedVideosArray.filter((value) => {
      return value != request.videoId;
    });
    _watchedVideosRef.set(
      { watchedVideos: filteredWatchedVideosArray },
      { merge: true }
    );
  },
};
const getters = {
  getUserStructure(state: MMIState) {
    return (chapter: Chapters, username: string): MMIUser | undefined => {
      if (state[chapter] && state[chapter].users.size > 0) {
        const structure = state[chapter].users.get(username);
        return structure;
      }
      return undefined;
    };
  },
  getChapterVideosPlaylist(state: MMIState) {
    return (chapter: Chapters): Array<VideoData> => {
      if (state[chapter] && state[chapter].videos?.length > 0) {
        return state[chapter].videos;
      }
      return [];
    };
  },
  getWatchedVideos(state: MMIState) {
    return (chapter: Chapters): Array<string> => {
      if (state[chapter] && state[chapter].watchedVideos?.length > 0) {
        return state[chapter].watchedVideos;
      }
      return [];
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
