
import { AccountSummary } from "@/store/Account/AccountInterface";
import { UnilevelPoints } from "functions/src/Unilevel/UnilevelInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "unilevel-card",
  computed: {
    ...mapGetters("account", ["getAccountSummary"]),
    ...mapGetters("ranks", ["rankById"]),
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    points(): UnilevelPoints {
      return this.accountSummary.unilevelData.points;
    },
    totalPoints(): string {
      return this.points.valid.total.toLocaleString();
    },
    leftPoints(): string {
      return this.points.valid.left.toLocaleString();
    },
    rightPoints(): string {
      return this.points.valid.right.toLocaleString();
    },
    totalPercentage(): string {
      return (
        (this.points.valid.total /
          this.accountSummary.unilevelData.nextRankRequiredPoints) *
        100
      ).toFixed(1);
    },
    leftPercentage(): string {
      return (
        (this.points.valid.left /
          this.accountSummary.unilevelData.nextRankRequiredPoints) *
        100
      ).toFixed(1);
    },
    rightPercentage(): string {
      return (
        (this.points.valid.right /
          this.accountSummary.unilevelData.nextRankRequiredPoints) *
        100
      ).toFixed(1);
    },
    nextPointsRank(): string {
      return this.rankById(this.accountSummary.unilevelData.nextPointsRank)
        ?.name;
    },
  },
  methods: {
    isBelowTeamMinimum(points: number): boolean {
      return (
        points > 0 &&
        this.accountSummary.unilevelData.nextRankRequiredPoints -
          this.accountSummary.unilevelData.nextRankMaxTeamPoints >
          points
      );
    },
    isOverTeamMax(points: number): boolean {
      return this.accountSummary.unilevelData.nextRankMaxTeamPoints < points;
    },
    warningIconClass(validPoints: number, totalPoints: number): string {
      return this.isBelowTeamMinimum(validPoints)
        ? "fas  fa-exclamation text-danger"
        : this.isOverTeamMax(totalPoints)
        ? "fas fa-exclamation-triangle text-warning"
        : "d-none";
    },
  },
});
