import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-image" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/login" }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "logo-gtr fade-in-image",
          src: require('@/assets/img/logo-gtr-white.png')
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}