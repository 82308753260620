
import {
  clearIncomeData,
  doGetIncomeData,
  IncomeDataRequest,
} from "@/store/Queries/QueriesInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default defineComponent({
  name: "income-report",
  data() {
    return {
      fromDate: "",
      toDate: "",
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters("queries", ["getIncomeReport"]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    async getData() {
      if (this.getIncomeReport && this.getIncomeReport.length > 0) {
        await this.$store.dispatch("queries/" + clearIncomeData);
      }
      const request: IncomeDataRequest = {
        fromDate: new Date(this.fromDate + " 00:00:00"),
        toDate: dayjs(new Date(this.toDate + " 00:00:00"))
          .add(1, "day")
          .toDate(),
      };
      this.loadingData = true;
      await this.$store.dispatch("queries/" + doGetIncomeData, request);
      this.loadingData = false;
    },
  },
});
