import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import {
  SubscriptionPackage,
  UserPackageInfo,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";

interface PackageInfo {
  currentPackage: SubscriptionPackage;
  isPackageActive: boolean;
  isLicenseActive: boolean;
}

export async function _determineUserPackageAndStatus(
  username: string
): Promise<PackageInfo> {
  const getUserPackageStatus = httpsCallable(
    functions,
    "Subscriptions-GetUserPackageStatus"
  );
  const userPackageInfo: UserPackageInfo = await getUserPackageStatus({
    username,
  })
    .then((result) => {
      return result.data as UserPackageInfo;
    })
    .catch((error) => {
      console.error("Error getting user package status: ", error);
      throw error;
    });
  return {
    currentPackage: userPackageInfo.package.packageId,
    isPackageActive: userPackageInfo.package.isActive,
    isLicenseActive: userPackageInfo.license.isActive,
  };
}
