
import { images } from "@/mixins/images";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import {
  BinaryTreeNodeUser,
  UserSubStatus,
} from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "binary-node",
  data() {
    return {
      maxLevelsToDisplay: 4,
      profilePic: "",
      random: Math.floor(Math.random() * 1000) + 1,
    };
  },
  props: {
    username: { type: String, default: "" },
    level: { type: Number, default: 1 },
  },
  emits: ["changeTreeRoot"],
  computed: {
    ...mapGetters("ranks", ["rankById"]),
    ...mapGetters("userNetwork", ["getTreeForUser"]),
    UserAcademyRemainingDays(): number {
      return this.SubscriptionRemainingDays("trading-academy");
    },
    UserAcademyStatusClass() {
      if (this.UserAcademyRemainingDays <= 0) return "user-inactive";
      else if (this.UserAcademyRemainingDays < 5) return "user-danger";
      else if (this.UserAcademyRemainingDays < 10) return "user-warning";
      else return "user-active";
    },
    UserDistributorRemainingDays(): number {
      return this.SubscriptionRemainingDays("distributor-license");
    },
    UserDistributorStatusClass() {
      if (this.UserDistributorRemainingDays <= 0) return "distributor-inactive";
      else if (this.UserDistributorRemainingDays < 10)
        return "distributor-danger";
      else if (this.UserDistributorRemainingDays < 20)
        return "distributor-warning";
      else return "distributor-active";
    },
    UserRank(): Rank {
      return this.rankById(this.node.rank);
    },
    node(): BinaryTreeNodeUser {
      const n = this.getTreeForUser(this.username) as BinaryTreeNodeUser;
      if (n.pictureUrl != undefined && n.pictureUrl != "") {
        this.updateProfilePicture(n.pictureUrl);
      }
      return this.getTreeForUser(this.username) as BinaryTreeNodeUser;
    },
    isVIP(): boolean {
      if (this.node == undefined || this.node?.subscriptions == undefined)
        return false;
      const isVIP = this.node.subscriptions.some((s) => {
        return s.subscriptionId == "mmi-colombia";
      });
      return isVIP;
    },
  },
  methods: {
    SubscriptionRemainingDays(subscriptionId: string): number {
      if (this.node != undefined) {
        const subs = this.node.subscriptions || new Array<UserSubStatus>();
        const academySubs = subs.find((s) => {
          return s.subscriptionId == subscriptionId;
        });
        if (
          academySubs == undefined ||
          academySubs.remainingDays == undefined ||
          academySubs.remainingDays <= 0
        ) {
          return 0;
        } else {
          return academySubs.remainingDays;
        }
      }
      return 0;
    },
    changeRootUserFunc(newUsername: string) {
      this.$emit("changeTreeRoot", newUsername);
    },
    updateProfilePicture(url: string) {
      this.getProfilePicture(url).then((response) => {
        this.profilePic = response;
      });
    },
    nodeId(): string {
      return this.node.username.replace(/[^a-zA-Z0-9]/g, "") + this.random;
    },
    // }
  },
  setup() {
    const getProfilePicture = images.methods.getProfilePicture;
    return {
      getProfilePicture,
    };
  },
});
