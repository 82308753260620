
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import * as bs from "bootstrap";
import { Event } from "../../../../store/Events/EventsInterfaces";
import { UnilevelMember } from "functions/src/Unilevel/UnilevelInterfaces";
import firebase from "firebase/compat/app";
import TeamMember from "@/components/unilevel/TeamMember.vue";

export default defineComponent({
  name: "set-tickets",
  components: {
    TeamMember,
  },
  data() {
    return {
      users: [],
      event: null as Event | null,
      user: null as string | null,
      ticketOption: null as any,
      ticketOptionValue: null as string | null,
      quantity: 1 as number,
      opened: false as boolean,
      ticketsAdded: {} as object,
      usersNotAdded: [] as Array<string>,
      ticketsNotAdded: [] as Array<string>,
      popover: null as any,
      uploadingCSV: false,
      loading: false,
      selected: null as any,
      selectedError: "",
      ticketAnalytics: {},
    };
  },
  computed: {
    ...mapGetters("events", ["getActiveEvents", "getEvent"]),
    usersFiltered() {
      const regex = new RegExp(this.user ?? "");
      return this.users.filter((ele: any) =>
        ele.userPK.match(regex)
      ) as Array<any>;
    },
    ticketTypes() {
      const types = {};
      Object.values(this.ticketsAdded).map((options: any) => {
        Object.values(options).map(({ option, quantity }: any) => {
          if (!types[option.id]) types[option.id] = 0;
          types[option.id] += quantity;
        });
      });

      const typesArray = Object.entries(types);
      typesArray.push([
        "Total",
        Object.values(types).reduce((a: any, b: any) => a + b),
      ]);

      return typesArray;
    },
  },
  methods: {
    async saveChanges() {
      this.loading = true;
      await Promise.allSettled(
        Object.entries(this.ticketsAdded).map(
          async ([username, options]: any) => {
            let ticketOptions = Object.values(options);
            ticketOptions = ticketOptions.map(({ option, quantity }: any) => ({
              id: option.id,
              quantity,
              total: option.price.value * quantity,
            }));

            await this.$store.dispatch("events/buyTickets", {
              eventId: this.event?.id,
              eventName: this.event?.title,
              paymentOption: null,
              ticketOptions,
              username,
              assigned: true,
            });
          }
        )
      );

      this.user = null;
      this.ticketOption = null;
      this.ticketOptionValue = null;
      this.quantity = 1;
      this.opened = false;
      this.ticketsAdded = {};
      this.usersNotAdded = [];
      this.ticketsNotAdded = [];
      this.uploadingCSV = false;
      this.selected = null;
      this.selectedError = "";

      this.loading = false;

      this.getAllTicketsByTypes(this.event);

      alert("Los tickes se han asignado a los usuarios.");
    },
    parseCsv(evt) {
      this.ticketsAdded = {};
      this.uploadingCSV = true;
      const input = evt.target.files[0];
      const reader = new FileReader();
      evt.target.value = "";

      reader.onload = (e) => {
        const content: string = e.target?.result as string;
        const [_headers, ...items] = content.split("\r\n");
        const headers = _headers.split(",");

        items.map((col) => {
          const [user, ...cols] = col.split(",");

          cols.map((_quantity: string, index) => {
            const quantity: number = +_quantity;
            const ind = index + 1;
            if (
              (this.event?.ticket_options?.findIndex(
                (ele: any) => ele.id == headers[ind]
              ) as number) < 0
            ) {
              if (
                this.ticketsNotAdded.findIndex((ele) => ele == headers[ind]) < 0
              )
                this.ticketsNotAdded.push(headers[ind]);
              return;
            }
            if (quantity == 0) {
              if (
                this.usersNotAdded.findIndex((ele) => ele == user) < 0 &&
                quantity > 0
              )
                this.usersNotAdded.push(user);
              return;
            }

            if (!this.ticketsAdded[user]) this.ticketsAdded[user] = {};
            this.ticketsAdded[user][headers[ind]] = {
              quantity,
              option: this.event?.ticket_options?.find(
                (ele) => ele.id == headers[ind]
              ),
            };
          });
        });
      };

      if (input) reader.readAsText(input);
      setTimeout(() => {
        this.uploadingCSV = false;
      }, 1000);
    },
    btnPopover() {
      if (!this.event?.ticket_options) return;
      const options = this.event?.ticket_options
        .map(
          (option) => `<li>${option.name}: <strong>${option.id}</strong></li>`
        )
        .join("");
      const headers = this.event?.ticket_options
        .slice(0, 3)
        .map((option) => `<th>${option.id}</th>`)
        .join("");

      if (!this.popover)
        this.popover = new bs.Popover(this.$refs.popover, {
          customClass: "popover-csv",
          container: "body",
          placement: "top",
          title: "¿Comó crear el archivo CSV?",
          content: `<p>Antes de continuar te sugiero descargar el archivo CSV de ejemplo para facilitar el llenado.</p>
          <p>En la tabla cada columna hace referencia a una parte importante para el que el programa pueda asignar de manera automatica los tickes. Por ello es importante que nombres los titulos de la cabecera de manera correcta. En primer lugar se encuentra el nombre del usuario, la columna debe llevar el nombre <strong>"user"</strong>. De la segunda en adelante debes colocar el nombre identificador del tipo de boleto. Para ello aquí puedes ver una lista con los nombres correctos que debes utilizar (el correcto es el que esta en negrita). Hacía abajo en la columna debería ir incluyendo el usuario y debajo de los tickes la cantidad de ese ticket que vas a agregar.</p>
          <ul>
            ${options}
          </ul>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>user</th>
                ${headers}
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>user_1</td>
                ${this.event?.ticket_options
                  .slice(0, 3)
                  .map(() => `<td>${Math.floor(Math.random() * 5)}</td>`)
                  .join("")}
                <td>...</td>
              </tr>
              <tr>
                <td>user_2</td>
                ${this.event?.ticket_options
                  .slice(0, 3)
                  .map(() => `<td>${Math.floor(Math.random() * 5)}</td>`)
                  .join("")}
                <td>...</td>
              </tr>
              <tr>
                <td colspan="5" class="text-center">...</td>
              </tr>
            </tbody>
          </table>

          `,
          html: true,
          sanitize: false,
        });
      this.popover.toggle();
    },
    removeItem(user, optionId) {
      delete this.ticketsAdded[user][optionId];
      if (Object.keys(this.ticketsAdded[user]).length == 0)
        delete this.ticketsAdded[user];
    },
    addTickets() {
      if (!this.user) return;
      if (!this.ticketsAdded[this.user]) this.ticketsAdded[this.user] = {};
      this.ticketsAdded[this.user][this.ticketOption.id] = {
        quantity: this.quantity,
        option: this.ticketOption,
      };
      this.quantity = 1;
      this.ticketOption = null;
      this.ticketOptionValue = "";
    },
    setOptionTicket(evt) {
      const ticketOption = this.event?.ticket_options?.find(
        (ele) => ele.id == evt.target.value
      );
      this.ticketOption = ticketOption;
      this.ticketOptionValue = evt.target.value;
    },
    goToEvent() {
      this.$router.push({
        name: "buy-tickets-admin-edit-event",
        params: { id: this.event?.id },
      });
    },
    openDropdown(evt) {
      if (evt.type === "focus") this.opened = true;
      else if (evt.type === "blur" && evt.target.value.length == 0)
        this.opened = false;
    },
    setUser(user) {
      this.user = user.userPK;
      this.opened = false;
    },
    goNewEvent() {
      this.$router.push({ name: "buy-tickets-admin-new-event" });
    },
    async getEvents() {
      this.$store.dispatch("events/doGetActiveEvents").then(async (events) => {
        const event = await this.$store.dispatch(
          "events/getEventById",
          events[0].id
        );
        this.getAllTicketsByTypes(event);
        this.event = event;
      });
    },
    searchUser(evt) {
      evt.preventDefault();
      const searchUserFunction = firebase
        .functions()
        .httpsCallable("Tickets-SearchUser");

      searchUserFunction({ username: this.user })
        .then((res) => res.data)
        .then((res) => {
          if (res.OPCODE == "SUCCESS") {
            this.selected = res.data as UnilevelMember;
            if (this.selectedError.length > 0) this.selectedError = "";
          } else if (res.OPCODE == "ERROR") {
            this.selectedError = res.message;
            if (this.selected) this.selected = null;
          }
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Error al llamar la función de búsqueda de usuarios: " +
              error.message
          );
          // this.searching = false;
        });
      // const user = [...this.unilevelTeam.rightTeam, ...this.unilevelTeam.leftTeam].filter(ele => ele.leader.username.search(evt.target.value) >= 0)
      // this.team = user
    },
    async getAllTicketsByTypes(event) {
      const tickets = await this.$store.dispatch(
        "tickets/getAllTicketsByTypes",
        {
          eventId: event?.id,
        }
      );
      this.ticketAnalytics = tickets;
    },
  },
  async mounted() {
    await this.getEvents();
  },
  unmounted() {
    if (this.popover) this.popover.hide();
  },
});
