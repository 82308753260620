import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_points_history = _resolveComponent("points-history")!
  const _component_expiring_points = _resolveComponent("expiring-points")!
  const _component_rank_requalification = _resolveComponent("rank-requalification")!
  const _component_gtr_lifestyle_bonus = _resolveComponent("gtr-lifestyle-bonus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_points_history, {
        "points-history": _ctx.pointsHistory,
        class: "col-12 col-xl-6 mb-2"
      }, null, 8, ["points-history"]),
      _createVNode(_component_expiring_points, {
        "expiring-points": _ctx.expiringPoints,
        loaded: _ctx.loaded,
        class: "col-12 col-xl-6 mb-2"
      }, null, 8, ["expiring-points", "loaded"]),
      (_ctx.hasGtrLifeBonus)
        ? (_openBlock(), _createBlock(_component_rank_requalification, {
            key: 0,
            "rank-requalification-records": _ctx.rankRequalification,
            "current-rank-priority": _ctx.currentRankPriority,
            class: "col-12 col-xl-6 mb-2"
          }, null, 8, ["rank-requalification-records", "current-rank-priority"]))
        : _createCommentVNode("", true),
      (_ctx.hasGtrLifeBonus)
        ? (_openBlock(), _createBlock(_component_gtr_lifestyle_bonus, {
            key: 1,
            "bonus-list": _ctx.gtrLifeBonusList,
            class: "col-12 col-xl-6 mb-2"
          }, null, 8, ["bonus-list"]))
        : _createCommentVNode("", true)
    ])
  ]))
}