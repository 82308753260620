
import { errorModal, informationModal } from "@/components/modals";
import {
  EmailValidationResponse,
  validationTools,
} from "@/mixins/validationTools";
import router from "@/router";
import * as bs from "bootstrap";
import { defineComponent } from "vue";
import { createConfetti } from "@/assets/js/confettiKit/confettiConstructor";

export default defineComponent({
  name: "Login",
  components: {
    errorModal,
    informationModal,
  },
  data() {
    let userPass: string[] | null = null;
    if (process.env.NODE_ENV == "development")
      userPass = process.env.VUE_APP_USER_PASS
        ? process.env.VUE_APP_USER_PASS.split("|")
        : null;

    return {
      timer: undefined as NodeJS.Timeout | undefined,
      loadingPW: false,
      loading: false,
      verifyEmail: true,
      showPassword: false,
      capsLockOn: false,
      loginForm: {
        userId: userPass ? userPass[0] : "",
        email: "",
        password: userPass ? userPass[1] : "",
      },
      userEmail: "",
      resetPasswordModal: {} as any,
      emailValidation: new EmailValidationResponse(),
      informationModal: {} as any,
      errorModal: {} as any,
      errorTitle: "",
      errorMessage: "",
      informationTitle: "",
      informationMessage: "",
    };
  },
  methods: {
    confetti() {
      createConfetti();
    },
    login() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", {
          userIdentification: this.loginForm.userId,
          password: this.loginForm.password,
        })
        .then((response) => {
          if (response) {
            router.push({ name: "home" });
          }
        })
        .catch((error) => {
          this.errorTitle = "Error";
          if (typeof error === "string") {
            this.errorMessage = error;
          } else if (error.code != undefined) {
            switch (error.code) {
              case "auth/user-not-found":
              case "USER_NOT_FOUND":
              case "EMAIL_NOT_FOUND":
                this.errorMessage =
                  "<p class='text-center'><i class='fas fa-user-slash fa-4x'></i></p>" +
                  "<p>El 'Correo Electrónico' o 'Nombre Usuario' proporcionado <strong>NO existe.</strong> Favor de verificarlo.</p>";
                break;
              case "INVALID_EMAIL":
                this.errorMessage =
                  "<p class='text-center'><i class='far fa-times-circle fa-4x'></i></p>" +
                  "<h3>El correo electrónico no es válido.</h3>";
                break;
              case "auth/wrong-password":
                this.errorMessage =
                  "<p class='text-center'><i class='far fa-times-circle fa-4x'></i></p>" +
                  "<p>El usuario o el password son <strong>incorrectos.</strong></p>";
                break;
              case "auth/too-many-requests":
                this.errorMessage =
                  "<p class='text-center'><i class='fas fa-user-clock fa-4x'></i></p>" +
                  "<p>El acceso a esta cuenta ha sido <strong>bloqueado temporalmente</strong> por seguridad, " +
                  "debido a que ha superado el número máximo de intentos de inicio de sesión.</p>" +
                  "<p>Por favor, pruebe de nuevo más tarde o <strong>realice un cambio de contraseña</strong> " +
                  "para <strong>desbloquearla inmediatamente.</strong></p>";
                break;
              case "auth/user-disabled":
                this.errorMessage =
                  "<p class='text-center'><i class='fas fa-user-lock fa-4x'></i></p>" +
                  "<p>El usuario está <strong>deshabilitado</strong>.</p>" +
                  "<p>Favor de contactar al departamento de cumplimiento al correo <a href='mailto:compliance@gtr.academy'>compliance@gtr.academy</a>.</p>";
                break;
              default:
                console.error(error);
                this.errorMessage = "Error al hacer el login.";
                break;
            }
          } else {
            this.errorMessage = JSON.stringify(error);
          }
          this.errorModal.show();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateEmailInput() {
      if (this.timer !== undefined) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.emailValidation = this.validateEmail(this.userEmail);
        clearTimeout(this.timer as NodeJS.Timeout);
        this.timer = undefined;
      }, 500);
    },
    showResetPasswordModal() {
      this.resetPasswordModal.show();
    },
    resetPassword(userEmail: string) {
      this.loadingPW = true;
      this.$store
        .dispatch("auth/sendPasswordResetLink", userEmail)
        .then((response) => {
          this.informationTitle = "Liga de restablecimiento enviada";
          this.informationMessage = response.message;
          this.informationModal.show();
          this.resetPasswordModal.hide();
        })
        .catch((error) => {
          this.errorTitle = "Error al generar la liga de restablecimiento";
          if (error.data.OPCODE === "ERROR") {
            if (error.data.code !== undefined) {
              switch (error.data.code) {
                case "USER_NOT_FOUND":
                case "EMAIL_NOT_FOUND":
                  this.errorMessage =
                    "<p class='text-center'><i class='fas fa-user-slash fa-4x'></i></p>" +
                    "<p>El correo electrónico proporcionado <strong>NO existe.</strong> Favor de verificarlo.</p>";
                  break;
                case "INVALID_EMAIL":
                  this.errorMessage =
                    "<p class='text-center'><i class='far fa-times-circle fa-4x'></i></p>" +
                    "<h3>El correo electrónico no es válido.</h3>";
                  break;
                default:
                  this.errorMessage = error.data.message;
                  break;
              }
            } else {
              this.errorMessage = error.data.message;
            }
            console.error(error.data.errorDetail);
          } else {
            this.errorMessage = "Error: " + JSON.stringify(error.message);
            console.error(error.data.message);
          }
          this.errorModal.show();
        })
        .finally(() => {
          this.loadingPW = false;
        });
    },
  },
  async mounted() {
    await this.$store.dispatch("auth/logout");

    const resetPswModal = this.$refs.resetPasswordModal as HTMLElement;
    this.resetPasswordModal = bs.Modal.getOrCreateInstance(resetPswModal);
    resetPswModal?.addEventListener("hidden.bs.modal", () => {
      this.userEmail = "";
      this.emailValidation = new EmailValidationResponse();
    });

    const username: HTMLInputElement = this.$refs.username as HTMLInputElement;
    const psw: HTMLInputElement = this.$refs.password as HTMLInputElement;
    username.addEventListener("keyup", (event) => {
      this.capsLockOn = event.getModifierState("CapsLock");
    });
    psw.addEventListener("keyup", (event) => {
      this.capsLockOn = event.getModifierState("CapsLock");
    });

    const infModalElement = document.getElementById("information-modal");
    this.informationModal = bs.Modal.getOrCreateInstance(infModalElement);
    infModalElement?.addEventListener("hidden.bs.modal", () => {
      this.informationTitle = "";
      this.informationMessage = "";
    });

    const errorModalElement = document.getElementById("error-modal");
    this.errorModal = bs.Modal.getOrCreateInstance(errorModalElement);
    errorModalElement?.addEventListener("hidden.bs.modal", () => {
      this.errorTitle = "";
      this.errorMessage = "";
    });

    // RELOAD ONLY WHEN IS IN DEVELOPMENT MODE.
    // AND SET BY DEFAULT USERNAME AND PASSWORD.
    if (process.env.NODE_ENV == "development" && process.env.VUE_APP_AUTOLOGIN)
      this.login();
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    return {
      validateEmail,
    };
  },
});
