
import "dayjs/locale/es";
import { mapGetters } from "vuex";
import { ComponentPublicInstance, defineComponent } from "vue";
import {
  OptionEvent,
  Event,
  AdminEventData,
} from "../../../store/Events/EventsInterfaces";
import _ from "lodash";
import VueDatePicker from "@vuepic/vue-datepicker";
import TicketPaymentOptions from "./ticket-options/TicketPaymentOptions.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import PaymentOptionModal from "@/components/events/admin/ticket-options/PaymentOptionModal.vue";
import MarkdownEditor from "@/components/markdown/MarkdownEditor.vue";
import { images } from "@/mixins/images";
import dayjs from "dayjs";

export default defineComponent({
  name: "event-form",
  emits: ["getData"],
  props: [
    "event",
    "buttonClass",
    "buttonStyle",
    "paymentOptions",
    "eventLoading",
  ],
  data() {
    return {
      eventId: "" as string,
      optionSelected: [] as OptionEvent[],
      currentEvent: null as null | Event,
      currentData: {} as AdminEventData,
      eventData: {} as AdminEventData,
      textareaOpacity: 0.65 as number,
      src: null as string | null,
      currencies: {
        MXN: "Pesos méxicanos (MXN)",
        USD: "Dolares (USD)",
        BTC: "Bitcoins (BTC)",
      },
      loading: false as boolean,
      image: null as any,
      logo: null as any,
    };
  },
  watch: {
    event: function (event) {
      this.eventId = event.id;
      this.processData();
    },
  },
  components: {
    VueDatePicker,
    TicketPaymentOptions,
    PaymentOptionModal,
    MarkdownEditor,
  },
  computed: {
    ...mapGetters("events", ["getEvent", "getTicketPaymentOptions"]),
    ticket_options() {
      return this.currentEvent?.ticket_options ?? [];
    },
    editing() {
      return Object.entries(this.currentData).length > 0;
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD MMMM, YYYY • hh:mm a");
    },
    scrolled(evt) {
      const element = evt.target;
      const max = 40;
      let scroll = element.scrollTop / max;
      const top = 0.65;

      if (scroll >= top) scroll = top;
      if (scroll <= 0) scroll = 0;

      scroll = Math.abs(scroll - top);

      this.textareaOpacity = scroll;
    },
    modifyValue(evt) {
      const {
        target: { name, value },
      } = evt;

      const _value =
        value &&
        ((typeof value == "string" && value.length > 0) ||
          (typeof value == "object" && value.constructor.name == "Date"))
          ? value
          : null;

      this.currentData = {
        ...this.currentData,
        [name]: _value,
      };
    },
    modifyDateStart(value) {
      this.modifyValue({ target: { name: "eventDateStart", value } });
    },
    modifyDateEnd(value) {
      this.modifyValue({ target: { name: "eventDateEnd", value } });
    },
    modifyDescription(value) {
      this.modifyValue({ target: { name: "details", value } });
    },
    saveData() {
      try {
        this.loading = true;
        this.validateRequiredData();
        this.currentData.eventId = this.event.id;
        this.$store
          .dispatch("events/updateEvent", this.currentData)
          .then(() => {
            this.eventData = {
              ...this.eventData,
              ...this.currentData,
            };

            const parent = this?.$parent as ComponentPublicInstance<{
              getData: () => void;
            }>;

            this.resetData();
            parent?.getData();
            this.loading = false;
          });
      } catch (error: unknown) {
        this.loading = false;
        if (error instanceof Error) {
          alert(error.message);
        }
      }
    },
    resetData() {
      this.currentData = {} as AdminEventData;
    },
    removePaymentOption(option) {
      const remove = confirm(
        `Estas seguro de eliminar la opción de pago "${option.name}"?`
      );
      // console.log("remove", remove);

      const parent: any = this?.$parent;

      if (remove)
        this.$store
          .dispatch("events/deletePaymentOption", {
            event_id: this.eventId,
            option_id: option.id,
          })
          .then(() => {
            // console.log(parent);
            parent?.getData();
          })
          .catch((err) => console.error(err));
    },
    async processData() {
      let dateStart = this.event?.date?.start._seconds * 1000;
      dateStart = parseInt(dateStart?.toString() ?? "");

      let dateEnd: number | null =
        (this.event?.date?.end?._seconds ?? 0) * 1000;
      dateEnd = dateEnd > 0 ? parseInt(dateEnd?.toString() ?? "") : null;

      this.eventData = _.cloneDeep({
        ...this.event,
        title: this.event?.title ?? "",
        organizer: this.event?.organizer ?? "",
        email: this.event?.contact?.email ?? "",
        phone: this.event?.contact?.phone ?? "",
        details: this.event?.details ?? "",
        eventDateStart: new Date(dateStart),
        eventDateEnd: dateEnd ? new Date(dateEnd) : "",

        // LOCATION INFO
        location_type: this.event?.location?.type ?? "",
        addr_name: this.event?.location?.address?.name ?? "",
        addr_address_1: this.event?.location?.address?.address_1 ?? "",
        addr_address_2: this.event?.location?.address?.address_2 ?? "",
        addr_city: this.event?.location?.address?.city ?? "",
        addr_country: this.event?.location?.address?.country ?? "",
        addr_state: this.event?.location?.address?.state ?? "",
        addr_zip: this.event?.location?.address?.zip ?? "",

        online_url: this.event?.location?.online_url ?? "",

        accept_bitcoin:
          this.event?.accept_bitcoin === true
            ? "accept_bitcoin"
            : "not_accept_bitcoin",
      });

      // console.log(
      //   "hello world",
      //   this.eventData.payment_options,
      //   this.event.payment_options
      // );
      if (this.eventData.image && typeof this.eventData.image == "string")
        this.image = await this.getImageSrc(this.eventData.image);
      if (this.eventData.logo && typeof this.eventData.logo == "string")
        this.logo = await this.getImageSrc(this.eventData.logo);
    },
    async fileSelected(evt) {
      const [file] = evt.target.files;
      if (file) {
        const image = {
          src: URL.createObjectURL(file),
          base64: await this.getBase64(file),
          mimeType: file.type,
        };
        this.currentData = {
          ...this.currentData,
          image,
        };
        (this.$parent as any).updateEvent(this.currentData);
      }
    },
    async logoSelected(evt) {
      const [file] = evt.target.files;
      if (file) {
        const logo = {
          src: URL.createObjectURL(file),
          base64: await this.getBase64(file),
          mimeType: file.type,
        };
        this.currentData = {
          ...this.currentData,
          logo,
        };
        (this.$parent as any).updateEvent(this.currentData);
      }
    },
    getBase64(file) {
      return new Promise<string | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          return resolve(
            typeof reader.result == "string" ? reader.result : null
          );
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
          return reject(error);
        };
      });
    },
    arrayBufferToBase64(buffer) {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    deleteCover() {
      if (this.currentData.image && typeof this.currentData.image == "object") {
        delete this.currentData.image;
        if (this.eventData.image && typeof this.eventData.image == "object")
          delete this.eventData.image;

        (this.$refs.image as any).value = "";
        return;
      }

      this.$store
        .dispatch("events/deleteImage", {
          eventId: this.event.id,
          type: "image",
        })
        .then((res) => {
          if (res.data == true) {
            this.eventData = {
              ...this.eventData,
              image: undefined,
            };

            this.currentData = {
              ...this.currentData,
              image: undefined,
            };
          }
        });
    },
    deleteLogo() {
      if (this.currentData.logo && typeof this.currentData.logo == "object") {
        delete this.currentData.logo;
        if (this.eventData.logo && typeof this.eventData.logo == "object")
          delete this.eventData.logo;

        (this.$refs.logo as any).value = "";
        return;
      }

      this.$store
        .dispatch("events/deleteImage", {
          eventId: this.event.id,
          type: "logo",
        })
        .then((res) => {
          // console.log(res);
          if (res.data == true) {
            this.eventData = {
              ...this.eventData,
              logo: undefined,
            };

            this.currentData = {
              ...this.currentData,
              logo: undefined,
            };
          }
        });
    },
    validateRequiredData() {
      const data = {
        ...this.eventData,
        ...this.currentData,
      };

      if (!data.title) {
        (this.$refs.title as any).focus();
        throw new Error("Un titulo es requerido para poder continuar.");
      }

      if (!data?.location_type) {
        throw new Error(
          "Es necesario que selecciones un tipo de ubicación para poder continuar."
        );
      }

      if (!data.eventDateStart) {
        throw new Error("Selecciona una fecha de inicio para poder continuar.");
      }

      if (data.email && !this.validateEmail(data.email)) {
        (this.$refs.email as any).focus();
        throw new Error(
          "El formato de email no esta correcto. (name@domain.com)"
        );
      }
    },
    validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    async getImageSrc(path) {
      return images.methods.getImage(path, "");
    },
  },
  mounted() {
    this.processData();
    this.eventId = this.event.id;
  },
});
