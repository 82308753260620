
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "my-team-breadcrumbs",
  computed: {
    ...mapGetters("myTeam", {
      isLoading: "getIsLoading",
      teamBreadcrumbs: "getBreadcrumbs",
    }),
  },
  methods: {
    showUserTeam(username: string) {
      this.$store.dispatch("myTeam/doGetTeamForUser", {
        username: username,
        forceUpdate: false,
      });
    },
  },
});
