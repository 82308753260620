
import { defineComponent } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: "payouts-list",
  data() {
    return {
      selectedPayout: "",
      selectedYear: dayjs().tz("America/Mexico_city", true).year().toString(), // Initialize with current year
      selectedMonth: (dayjs().tz("America/Mexico_city", true).month() + 1)
        .toString()
        .padStart(2, "0"), // Initialize with current month
      years: this.generateYears(),
    };
  },
  props: {
    payoutsList: {
      required: true,
      type: Object as () => Array<string>,
    },
  },
  emits: ["payoutSelected", "yearMonthSelected"],
  computed: {
    selectedYearMonth() {
      // Combine the selected year and month into a YYYY-MM format
      return `${this.selectedYear}-${this.selectedMonth}`;
    },
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      const years = Array<string>();
      for (let year = currentYear; year >= 2021; year--) {
        years.push(year.toString());
      }
      return years;
    },
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    payoutSelected(id: string) {
      this.selectedPayout = id;
      this.$emit("payoutSelected", id);
    },
  },
  watch: {
    // Emit the selectedYearMonth value whenever the selected year or month changes
    selectedYear() {
      this.$emit("yearMonthSelected", this.selectedYearMonth);
    },
    selectedMonth() {
      this.$emit("yearMonthSelected", this.selectedYearMonth);
    },
    payoutsList: function (newValue) {
      if (newValue.length > 0) {
        this.payoutSelected(newValue[0]);
      }
    },
  },
});
