
import { defineComponent } from "vue";
import { mapState } from "vuex";
import EventCard from "@/components/events/EventCard.vue";
import { doGetArchivedEvents } from "@/store/Events/EventsInterfaces";

export default defineComponent({
  name: "BuyTicketsArchivedAdmin",
  components: {
    EventCard,
  },
  computed: {
    ...mapState("events", {
      events: "archivedEvents",
      loading: "archivedEventsLoading",
      loaded: "archivedEventsLoaded",
    }),
  },
  methods: {
    getEvents() {
      this.$store.dispatch(`events/${doGetArchivedEvents}`);
    },
    goListEvents() {
      this.$router.push({
        name: "buy-tickets-admin",
      });
    },
  },
  mounted() {
    this.getEvents();
  },
});
