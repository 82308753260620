
import dayjs from "dayjs";
import { defineComponent } from "vue";
import ClassCard from "../schedule/ClassCard.vue";
import ClassEditModal from "./ClassEditModal.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "SchedulesConfiguration",
  components: { ClassCard, ClassEditModal },
  data() {
    return {
      scheduleObject: {},
      isModalVisible: false,
      modalKey: "",
      modalDay: "",
      modalIndex: 0,
      modalInfo: {},
      mode: "",
      program: "trading-academy",
    };
  },
  computed: {
    ...mapGetters("classes", ["getClasses"]),
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      const week = dayjs().format("WW");
      const year = dayjs().format("YYYY");

      this.$store
        .dispatch("classes/doGetSchedules", {
          year: year,
          week: week,
          program: this.program,
        })
        .catch((error) => {
          alert(error);
        });
    },
    showModal(
      key: string,
      day: string,
      index: number,
      info: object,
      mode: string
    ) {
      //console.log(key, day, index);
      this.modalKey = key;
      this.modalDay = day;
      this.modalIndex = index;
      this.modalInfo = info;
      this.isModalVisible = true;
      this.mode = mode;

      const menu = document.getElementById("sidebarMenu") as HTMLElement;
      menu.classList.add("navbar-modal-active");
    },
    closeModal() {
      this.isModalVisible = false;
      this.modalKey = "";
      this.modalDay = "";
      this.modalIndex = 0;
      this.modalInfo = {};
      const menu = document.getElementById("sidebarMenu") as HTMLElement;
      menu.classList.remove("navbar-modal-active");
    },
    addSchedule() {
      const info = {};
      this.showModal("", "", 0, info, "add");
    },
    cloneSchedule() {
      this.$store.dispatch("loading", true);
      const week = dayjs().format("WW");
      const year = dayjs().format("YYYY");
      this.$store
        .dispatch("classes/cloneClasses", {
          week: week,
          year: year,
        })
        .then((response) => {
          alert(response.message);
          this.$store
            .dispatch("classes/doGetSchedules", { year: year, week: week })
            .catch((error) => {
              alert(error);
            });
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
  },
});
