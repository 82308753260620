
import "dayjs/locale/es";
import { ComponentPublicInstance, defineComponent } from "vue";
import { OptionEvent } from "@/store/Events/EventsInterfaces";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import dayjs from "dayjs";

export default defineComponent({
  name: "ticket-option-form",
  props: ["option", "options", "id", "event"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      currentData: {} as OptionEvent,
      eventData: {} as OptionEvent,
      textareaOpacity: 0.65 as number,
      ticketSales: {
        immediately: "Inmediatamente",
        never: "Nunca",
        after_starts: "Después de comenzar",
        before_starts: "Antes de comenzar",
        after_ends: "Después de finalizar",
        before_ends: "Antes de finalizar",
        fix_date: "Fecha y hora fija",
      },
      units: {
        minutes: "Minuto(s)",
        hours: "Hora(s)",
        days: "Día(s)",
        weeks: "Semana(s)",
        months: "Mes(es)",
      },
      currencies: {
        MXN: "Pesos méxicanos (MXN)",
        USD: "Dolares (USD)",
        BTC: "Bitcoins (BTC)",
      },
      is_pack: "is_not_pack" as string,
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date)
        .locale("es-mx")
        .format("DD \\d\\e MMMM \\d\\e YYYY • hh:mm a");
    },
    resetData() {
      this.currentData = {} as OptionEvent;
      this.is_pack = this.option.pack ? "is_pack" : "is_not_pack";
    },
    saveData() {
      const parent: any = this.$parent?.$parent?.$parent;
      this.$store
        .dispatch("events/createTicketOption", {
          data: { ...this.currentData, id: this.eventData.id },
          event_id: parent.event.id,
        })
        .then(() => {
          const modal = this.$parent as ComponentPublicInstance<{
            dismiss: () => void;
          }>;
          const parent = this.$parent?.$parent?.$parent
            ?.$parent as ComponentPublicInstance<{
            getData: () => void;
          }>;

          if (modal) modal.dismiss();
          if (parent) parent.getData();
          this.resetData();
        });
    },
    modifyValue(evt) {
      const {
        target: { name, value: $value },
      } = evt;
      let value = $value;
      if (value === "is_pack") value = true;
      if (value === "is_not_pack") value = false;

      if (name == "is_pack") this.is_pack = value ? "is_pack" : "is_not_pack";

      this.currentData = {
        ...this.currentData,
        [name]: value.length > 0 || typeof value == "boolean" ? value : " ",
      };
    },
    modifyDateStart(value) {
      this.modifyValue({
        target: { name: "ticket_sales_starts_value", value },
      });
    },
    modifyDateEnd(value) {
      this.modifyValue({ target: { name: "ticket_sales_ends_value", value } });
    },
    scrolled(evt) {
      const element = evt.target;
      const max = 40;
      let scroll = element.scrollTop / max;
      const top = 0.65;

      if (scroll >= top) scroll = top;
      if (scroll <= 0) scroll = 0;

      scroll = Math.abs(scroll - top);

      this.textareaOpacity = scroll;
    },
  },
  computed: {
    editing() {
      return Object.entries(this.currentData).length > 0;
    },
    ticketSalesEndsValue() {
      const ticket_sales_starts_value =
        this.eventData.ticket_sales_starts_value ||
        this.currentData.ticket_sales_starts_value;
      if (
        typeof ticket_sales_starts_value == "number" ||
        typeof ticket_sales_starts_value == "undefined"
      )
        return new Date();

      return new Date(parseInt(ticket_sales_starts_value.toString()));
    },
    ticketSalesStartsValue() {
      const ticket_sales_ends_value =
        this.eventData.ticket_sales_ends_value ||
        this.currentData.ticket_sales_ends_value;
      return typeof ticket_sales_ends_value == "number"
        ? new Date()
        : this.eventData.ticket_sales_ends_value ||
            this.currentData.ticket_sales_ends_value;
    },
    allOptions() {
      return this.options.length > 0
        ? this.options.filter((ele) => !ele.pack)
        : [];
    },
  },
  watch: {
    editing() {
      this.$emit("modifying", this.editing);
    },
  },
  mounted() {
    this.eventData = {
      ...this.option,
      id: this.option.id,
      price_value: this.option.price.value,
      price_currency: this.option.price.currency,
      tickets_per_order_maximum_quantity:
        this.option.tickets_per_order?.maximum_quantity ?? null,
      tickets_per_order_minimum_quantity:
        this.option.tickets_per_order?.minimum_quantity,
      ticket_sales_starts_type:
        this.option.ticket_sales_start?.type ?? "immediately",
      ticket_sales_starts_unit: this.option.ticket_sales_start?.unit ?? null,
      ticket_sales_starts_value: this.option.ticket_sales_start?.value ?? null,
      ticket_sales_starts_hide: this.option.ticket_sales_start?.hide ?? false,
      ticket_sales_ends_type: this.option.ticket_sales_ends?.type ?? null,
      ticket_sales_ends_unit: this.option.ticket_sales_ends?.unit ?? null,
      ticket_sales_ends_value: this.option.ticket_sales_ends?.value ?? null,
      ticket_sales_ends_hide: this.option.ticket_sales_ends?.hide ?? false,
      pack_option: this.option.pack?.option_id?.id ?? null,
      pack_quantity: this.option.pack?.quantity ?? 0,
    };

    this.is_pack = this.option.pack ? "is_pack" : "is_not_pack";
  },
});
