
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import {
  EmailValidationResponse,
  validationTools,
} from "@/mixins/validationTools";

export default defineComponent({
  name: "change-user-email",
  props: {
    username: { type: String, required: true },
    email: { type: String, required: true },
  },
  emits: ["emailUpdated", "error"],
  data() {
    return {
      timer: undefined as NodeJS.Timeout | undefined,
      newEmail: "",
      update: false,
      saving: false,
      emailValidation: new EmailValidationResponse(),
    };
  },
  computed: {
    canChangeEmail(): boolean {
      return (
        this.emailValidation.canChange &&
        this.newEmail.length > 0 &&
        this.newEmail !== this.email
      );
    },
  },
  methods: {
    save() {
      this.saving = true;
      firebase
        .functions()
        .httpsCallable("Admin-ChangeEmail")({
          username: this.username.toLowerCase(),
          email: this.newEmail.toLowerCase(),
        })
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.$emit("emailUpdated", this.newEmail);
            this.newEmail = "";
          } else {
            this.$emit(
              "error",
              "Error al cambiar el correo electrónico",
              response.data.error.message
            );
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    validateEmailInput() {
      if (this.timer !== undefined) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        //console.log(this.newEmail);
        if (this.newEmail !== this.email) {
          this.emailValidation = this.validateEmail(this.newEmail);
        } else {
          this.emailValidation.hasWarning = false;
          this.emailValidation.canChange = false;
          this.emailValidation.hasError = true;
          this.emailValidation.userMessage =
            "El correo electrónico no puede ser el mismo";
        }
        clearTimeout(this.timer as NodeJS.Timeout);
        this.timer = undefined;
      }, 500);
    },
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    return {
      validateEmail,
    };
  },
});
