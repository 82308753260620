
import { defineComponent } from "vue";

export default defineComponent({
  name: "bootstrap-breakpoints",
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    widthPercentage(): number {
      const breaks = [0, 576, 768, 992, 1200, 1400];
      let b = 1;
      const wwidth = this.windowWidth;
      if (wwidth >= 576) {
        b = 2;
      }
      if (wwidth >= 768) {
        b = 3;
      }
      if (wwidth >= 992) {
        b = 4;
      }
      if (wwidth >= 1200) {
        b = 5;
      }
      if (wwidth >= 1400) {
        return 0;
      }
      return Math.floor(
        ((wwidth - breaks[b - 1]) / (breaks[b] - breaks[b - 1])) * 100
      );
    },
  },
  methods: {
    onWidthChange() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.onWidthChange);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWidthChange);
  },
});
