
import {
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import {
  QuoteSubscriptionRequest,
  SubscriptionPromotion,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { Ref, defineComponent, ref } from "vue";
import { mapGetters } from "vuex";
import * as bs from "bootstrap";
import { quoteSubscription } from "@/store/Subscriptions/SubscriptionsConsts";
import { CheckoutModal } from "../../modals";

export default defineComponent({
  name: "create-quote",
  props: {
    username: { type: String, required: true },
    email: { type: String, required: true },
    subscriptions: {
      type: Object as () => Map<string, number>,
      required: true,
    },
  },
  emits: ["quoteCreated", "error"],
  components: {
    CheckoutModal,
  },
  data() {
    return {
      update: false,
      isQuoting: false,
      quote: {} as Quote<QuoteRequestType>,
      modalObj: {} as any,
      activating: false,
    };
  },
  computed: {
    ...mapGetters("subs", {
      subs: "getSubscriptionsConfig",
      promotions: "getSubscriptionsPromotions",
    }),
    ...mapGetters("sys", ["isOffline"]),
    isNew(): boolean {
      return this.subscriptions.get("trading-academy") == undefined;
    },
    isVIP(): boolean {
      return (this.subscriptions.get("mmi-colombia") ?? 0) > 0;
    },
  },
  methods: {
    canCreateQuote(subscriptionId: subscriptionType): boolean {
      switch (subscriptionId) {
        case "gtr-vip":
          return this.isNew;
        case "trading-academy":
          return (this.subscriptions.get("trading-academy") ?? 0) < 3;
        case "mmi-colombia":
          return !this.isVIP && !this.isNew;
        case "distributor-license":
          return !this.isNew;
        default:
          return false;
      }
    },
    clearQuote() {
      this.quote = {} as Quote<QuoteRequestType>;
    },
    createQuote(forceUpdate = false) {
      if (
        this.selectedSubscription &&
        this.canCreateQuote(this.selectedSubscription.subscriptionId)
      ) {
        const p = this.selectedSubscription;
        const request: QuoteSubscriptionRequest = {
          username: this.username,
          email: this.email,
          duration: p.duration,
          durationUnit: p.durationUnit,
          isDirectAffiliate: false,
          subscriptionId: p.subscriptionId,
          promotionId: p._id,
          forceUpdate: forceUpdate,
        };
        if (!this.isOffline) {
          this.isQuoting = true;
          this.$store
            .dispatch("subs/" + quoteSubscription, request)
            .then((q) => {
              if (q.OPCODE == "ERROR") {
                alert(q.message);
              } else {
                this.quote = q;

                if (!this.modalObj._isShown) {
                  this.modalObj.show();
                }
              }
              this.isQuoting = false;
            });
        }
      }
    },
  },
  mounted() {
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
  },
  setup() {
    const selectedSubscription: Ref<SubscriptionPromotion | null> = ref(null);

    const selectSubscription = (subscription: SubscriptionPromotion) => {
      selectedSubscription.value = subscription;
    };

    return {
      selectedSubscription,
      selectSubscription,
    };
  },
});
