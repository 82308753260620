
import { defineComponent } from "vue";
import WeekSchedule from "../components/schedule/WeekSchedule.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "academy",
  components: { WeekSchedule },
  computed: {
    ...mapGetters("subs", ["isUserActive"]),
  },
});
