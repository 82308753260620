
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import * as bs from "bootstrap";

import {
  ClassData as ServerClassObject,
  scheduleType,
} from "functions/src/Classes/ClassesInterfaces";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import {
  BinaryOptionsClass,
  ForexClass,
  FastStartCall,
  BusinessPlan,
  WakeupCall,
  MarketkingClass,
  Mentorship,
  LlamadaTestimoniosCall,
  FreeClass,
  CryptospaceClass,
  SportsTradingClass,
  GTRWoman,
  AdvancedMentorship,
  FromHumanToHero as FHH,
  MindsetCall,
  ClassData,
} from "../../store/Classes/ClassesInterfases";
import ClassImageModal from "./ClassImageModal.vue";
import ConfirmDeleteClassModal from "./ConfirmDeleteClassModal.vue";

export default defineComponent({
  name: "class-form-modal",
  components: { ClassImageModal, ConfirmDeleteClassModal },
  props: {
    scheduleType: {
      type: String as () => scheduleType,
      required: true,
    },
    classInfo: {
      type: Object as () => ServerClassObject,
      required: true,
    },
  },
  emits: ["delete", "close"],
  data() {
    return {
      devHelpersHidden: !(
        process.env.VUE_APP_DEV_TESTING_HELPERS_VISIBLE === "true"
      ),
      confirmDeleteModal: {} as any,
      saving: false,
      saved: false,
      editedData: {} as ServerClassObject,
      originalData: {} as ServerClassObject,
      classTemplate: "",
      classTimeStructure: {
        hour: "8",
        minutes: "00",
        am_pm: "am",
      },
      allow: {
        gtrLite: false,
        gtrBasic: false,
      },
      weekdays: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      },
      minutes: ["00", "30"],
      am_pm: ["am", "pm"],
      classList: {
        academy: {} as { [key: string]: ClassData },
        gtrSystem: {} as { [key: string]: ClassData },
        mmiColombia: {} as { [key: string]: ClassData },
      },
    };
  },
  computed: {
    ...mapGetters("ranks", ["allRanks"]),
    ranksListNames() {
      const ranks: Map<string, Rank> = this.allRanks();
      // Map to name and id
      return Array.from(ranks.values()).map((rank) => {
        return { name: rank.name, id: rank._id, iconClass: rank.iconClass };
      });
    },
    mode(): "new" | "edit" {
      return this.editedData?.id ? "edit" : "new";
    },
    editMode(): boolean {
      return this.mode == "edit";
    },
    title(): string {
      return this.mode == "edit" ? "Editar Clase" : "Nueva Clase";
    },
    classTimeStructureString(): string {
      return `${this.classTimeStructure.hour}:${this.classTimeStructure.minutes}${this.classTimeStructure.am_pm}`;
    },
    imageDimensionsString(): string {
      return this.editedData.scheduleType == "mmi-colombia"
        ? "1024 x 576px"
        : "1024 x 484px";
    },
    isClassEdited(): boolean {
      return (
        JSON.stringify(this.editedData) !== JSON.stringify(this.originalData)
      );
    },
    imageHeight(): string {
      return this.editedData.scheduleType == "mmi-colombia" ? "484" : "576";
    },
    imageAspectRatio(): string {
      return this.editedData.scheduleType == "mmi-colombia"
        ? "16/9"
        : "256/121";
    },
  },
  methods: {
    setClassType(type: string) {
      this.editedData.classType = type;
    },
    cancelEdit() {
      this.clearData();
      this.$emit("close");
    },
    undoChanges() {
      this.editedData = { ...this.originalData };
      this.allow = {
        gtrLite:
          this.originalData.availableForPackage?.includes("gtr-lite") || false,
        gtrBasic:
          this.originalData.availableForPackage?.includes("gtr-basic") || false,
      };
      this.classTimeStructure = {
        hour: this.originalData.time.split(":")[0],
        minutes: this.originalData.time.split(":")[1].substring(0, 2),
        am_pm: this.originalData.time.split(":")[1].substring(2),
      };
    },
    async saveClass() {
      this.saving = true;
      // Call the store action to save the class doUpdateClass
      try {
        const response = await this.$store.dispatch("classes/doUpdateClass", {
          newClassData: this.editedData,
          oldClassData: this.originalData,
        });

        if (response) {
          this.editedData.id = response;
          this.originalData = { ...this.editedData };
          this.saved = true;
          setTimeout(() => {
            this.saved = false;
          }, 2000);
        }
      } catch (error) {
        alert("Error al guardar la clase. \n" + JSON.stringify(error));
      } finally {
        this.saving = false;
      }
    },
    async deleteClass() {
      this.confirmDeleteModal.hide();
      this.saving = true;
      // Call the store action to delete the class doDeleteClass
      try {
        const response = await this.$store.dispatch("classes/doDeleteClass", {
          classInfo: this.originalData,
        });
        if (response) {
          this.clearData();
          this.$emit("close");
          alert("Clase eliminada correctamente");
        }
      } catch (error: any) {
        alert("Error al eliminar la clase. \n" + JSON.stringify(error));
      } finally {
        this.saving = false;
      }
    },
    confirmDeleteClass() {
      this.confirmDeleteModal.show();
    },
    applyClassTemplate(template: string) {
      if (template === "") {
        this.editedData.title = "";
        this.editedData.link = "";
        this.editedData.imageUrl = "";
        this.editedData.classType = "";
      } else {
        this.editedData = {
          ...this.editedData,
          ...this.classList[this.scheduleType][template],
        };
      }
    },
    updateClassTime() {
      this.editedData.time = this.classTimeStructureString;
    },
    updateAllowList(allow: { gtrLite: boolean; gtrBasic: boolean }) {
      if (!this.editedData.availableForPackage) {
        this.editedData.availableForPackage = [];
      }

      if (allow.gtrLite) {
        if (!this.editedData.availableForPackage.includes("gtr-lite")) {
          this.editedData.availableForPackage.push("gtr-lite");
        }
      } else {
        this.editedData.availableForPackage =
          this.editedData.availableForPackage.filter(
            (pkg) => pkg !== "gtr-lite"
          );
      }
      if (allow.gtrBasic) {
        if (!this.editedData.availableForPackage.includes("gtr-basic")) {
          this.editedData.availableForPackage.push("gtr-basic");
        }
        if (!this.editedData.availableForPackage.includes("gtr-vip")) {
          this.editedData.availableForPackage.push("gtr-vip");
        }
      } else {
        this.editedData.availableForPackage =
          this.editedData.availableForPackage.filter(
            (pkg) => pkg !== "gtr-basic" && pkg !== "gtr-vip"
          );
      }
    },
    getImage() {
      if (this.classTemplate == "" && !this.editedData.imageUrl) return;
      if (
        (this.editedData.imageUrl &&
          this.editedData.imageUrl.startsWith("data:image")) ||
        this.editedData.imageUrl?.search("http") >= 0
      ) {
        return this.editedData.imageUrl;
      } else {
        const defaultImage = "default-card.png";
        try {
          return require(`../../assets/img/classes/${this.editedData.imageUrl}`);
        } catch {
          return require(`../../assets/img/classes/${defaultImage}`);
        }
      }
    },
    updateImage(url: string) {
      this.editedData.imageUrl = url;
    },
    setImage(imageUrl: string) {
      this.editedData.imageUrl = imageUrl;
    },
    resetData() {
      this.editedData = { ...this.originalData };
    },
    clearData() {
      this.editedData.title = "";
      this.editedData.link = "";
      this.editedData.imageUrl = "";
      this.editedData.classType = "";
      this.editedData.active = false;
      this.editedData.requiredRank = "";
      this.editedData.availableForPackage = [];
      this.editedData.time = "";
      this.allow = {
        gtrLite: false,
        gtrBasic: false,
      };
      this.classTimeStructure = {
        hour: "8",
        minutes: "00",
        am_pm: "am",
      };
    },
  },
  watch: {
    classTemplate: {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.applyClassTemplate(val);
        }
      },
      immediate: true,
    },
    classInfo: {
      handler(val) {
        this.originalData = { ...val };
        this.editedData = { ...val };
        this.classTemplate = "";
        this.editedData.scheduleType = this.scheduleType;
        if (val && Object.keys(val).length > 0) {
          this.classTemplate = "";
          this.classTimeStructure = val.time
            ? {
                hour: val.time.split(":")[0],
                minutes: val.time.split(":")[1].substring(0, 2),
                am_pm: val.time.split(":")[1].substring(2),
              }
            : {
                hour: "8",
                minutes: "00",
                am_pm: "am",
              };
          this.allow = {
            gtrLite: val.availableForPackage?.includes("gtr-lite") || false,
            gtrBasic: val.availableForPackage?.includes("gtr-basic") || false,
          };
        } else if (val && Object.keys(val).length == 0) {
          this.editedData.time = this.classTimeStructureString;
          this.editedData.scheduleType = this.scheduleType;
          this.editedData.availableForPackage = [];
          this.editedData.active = false;
        }
      },
      immediate: true,
      deep: true,
    },
    classTimeStructure: {
      handler() {
        this.updateClassTime();
      },
      deep: true,
      immediate: true,
    },
    allow: {
      handler() {
        this.updateAllowList(this.allow);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.classList = {
      academy: {
        // blank: new GenericClass({ title: "" }),
        BinaryOptionsClass: new BinaryOptionsClass({}),
        ForexClass: new ForexClass({}),
        MarketkingClass: new MarketkingClass({}),
        CryptospaceClass: new CryptospaceClass({}),
        SportsTradingClass: new SportsTradingClass({}),
      },
      gtrSystem: {
        // blank: new GenericClass({}),
        FastStartCall: new FastStartCall({}),
        BusinessPlan: new BusinessPlan({}),
        WakeupCall: new WakeupCall({}),
        Mentorship: new Mentorship({}),
        LlamadaTestimoniosCall: new LlamadaTestimoniosCall({}),
        FreeClass: new FreeClass({}),
        GTRWoman: new GTRWoman({}),
        AdvancedMentorship: new AdvancedMentorship({}),
        FHH: new FHH({}),
        MindsetCall: new MindsetCall({}),
      },
      mmiColombia: {
        // blank: new GenericClass({}),
      },
    };
  },
  mounted() {
    this.confirmDeleteModal = new bs.Modal(
      document.getElementById(
        "confirmDeleteClassModal_onEditClass"
      ) as HTMLElement,
      {
        backdrop: false,
      }
    );
  },
});
