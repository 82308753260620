import { CommitInterface } from "..";
import * as fb from "../../firebase";
import {
  EventsState,
  EventIdContext,
  AdminEventData,
  ICreatTicketOption,
} from "./EventsInterfaces";
import firebase from "firebase/compat/app";
import { Event } from "./EventsInterfaces";

const initialState: EventsState = {
  eventsLoaded: false,
  eventsLoading: false,
  events: [],
  archivedEvents: [],
  archivedEventsLoaded: false,
  archivedEventsLoading: false,
  currentEvent: null,
  ticketOptions: {},
};

const state = initialState;

const mutations = {
  SET_LOADING(state: EventsState, loading: boolean) {
    state.eventsLoading = loading;
  },
  SET_EVENTS_LOADED(state: EventsState, loaded: boolean) {
    state.eventsLoaded = loaded;
  },
  SET_EVENTS(state: EventsState, events: Event[]) {
    state.events = events;
    state.eventsLoaded = true;
    state.eventsLoading = false;
  },
  SET_CURRENT_EVENT_LOADING(state: EventsState, loading: boolean) {
    state.currentEvent = {
      data: null,
      error: null,
      loading,
    };
  },
  SET_CURRENT_EVENT(state: EventsState, event: Event) {
    state.currentEvent = {
      data: event,
      error: null,
      loading: false,
    };
  },
  SET_ARCHIVED_EVENTS_LOADING(state: EventsState, loading: boolean) {
    state.archivedEventsLoading = loading;
  },
  SET_ARCHIVED_EVENTS_LOADED(state: EventsState, loaded: boolean) {
    state.archivedEventsLoaded = loaded;
  },
  SET_ARCHIVED_EVENTS(state: EventsState, events: Event[]) {
    state.archivedEvents = events;
    state.archivedEventsLoaded = true;
    state.archivedEventsLoading = false;
  },
  // TODO: remove next reducers when getting all data on a single request
  SET_EVENT_TICKET_OPTIONS(state: EventsState, context: any) {
    const events = state.events.map((event) => {
      if (event.id != context.event_id) return event;
      event.ticket_options = context.options;
      return event;
    });
    state.events = events;
  },
  SET_EVENT_TICKET_PAYMENT_OPTIONS(state: EventsState, context: any) {
    const events = state.events.map((event) => {
      if (event.id != context.event_id) return event;
      event.payment_options = context.payment_options;
      return event;
    });
    state.events = events;
  },
};

const actions = {
  async doGetActiveEvents(
    { commit }: CommitInterface<EventsState>,
    request?: { collections?: string[] }
  ) {
    const getActiveEvent = firebase
      .functions()
      .httpsCallable("Events-GetActiveEvent");

    commit("SET_LOADING", true);
    const { data } = await getActiveEvent(request);
    commit("SET_EVENTS", data);
    return data;
  },
  async doGetArchivedEvents(
    { commit }: CommitInterface<EventsState>,
    request?: { collections?: string[] }
  ) {
    const getArchivedEvent = firebase
      .functions()
      .httpsCallable("Events-GetArchivedEvent");

    commit("SET_ARCHIVED_EVENTS_LOADING", true);
    const { data } = await getArchivedEvent(request);
    commit("SET_ARCHIVED_EVENTS", data);
  },
  async doGetEventTicketOptions(
    { commit }: CommitInterface<any>,
    { event_id }: EventIdContext
  ) {
    const _events = fb.fbfs
      .collection("events")
      .doc(event_id)
      .collection("ticket_options");

    const _options = await _events.get();
    let options = await Promise.allSettled(
      _options.docs.map(async (event) => {
        const data = {
          id: event.id,
          ...event.data(),
        } as any;

        if (data?.pack) {
          const optionRef = await data?.pack?.option_id.get();
          data.pack.option = { id: optionRef.id, ...optionRef.data() };
        }

        return data;
      })
    );
    options = options
      .filter((ele) => ele.status === "fulfilled")
      .map((ele: any) => ele.value);

    commit("SET_EVENT_TICKET_OPTIONS", { event_id, options });
  },
  async doGetEventTicketPaymentOptions(
    { commit }: CommitInterface<any>,
    { event_id }: EventIdContext
  ) {
    const _events = fb.fbfs
      .collection("events")
      .doc(event_id)
      .collection("payment_options");

    const _payment_options = await _events.get();
    const payment_options = _payment_options.docs.map((event) => ({
      id: event.id,
      ...event.data(),
    }));

    commit("SET_EVENT_TICKET_PAYMENT_OPTIONS", {
      event_id,
      payment_options,
    });
  },
  async doHoldTickets(_, data) {
    const _holdTickets = firebase
      .functions()
      .httpsCallable("Tickets-HoldTickets");
    return _holdTickets(data);
  },
  getEventById(_, eventId: string) {
    const _getEventById = firebase
      .functions()
      .httpsCallable("Events-GetEventById");
    return _getEventById(eventId).then((event) => {
      return event.data;
    });
  },
  updateEvent(_, evt: AdminEventData) {
    const _creatEvent = firebase.functions().httpsCallable("Events-CreatEvent");
    return _creatEvent({ evt });
  },
  archiveEvent(_, { eventId }: AdminEventData) {
    const archiveEvent = firebase
      .functions()
      .httpsCallable("Events-ArchiveEvent");
    return archiveEvent({ eventId });
  },
  unarchiveEvent(_, { eventId }: AdminEventData) {
    const archiveEvent = firebase
      .functions()
      .httpsCallable("Events-ArchiveEvent");
    return archiveEvent({ eventId, unarchive: true });
  },
  createEvent(_, evt: AdminEventData) {
    const _creatEvent = firebase.functions().httpsCallable("Events-CreatEvent");
    return _creatEvent({ evt });
  },
  createPaymentOption(_, { eventId, data }: ICreatTicketOption) {
    const _createPaymentOption = firebase
      .functions()
      .httpsCallable("Events-CreatePaymentOption");
    return _createPaymentOption({ eventId, data });
  },
  deletePaymentOption(_, { event_id, option_id }) {
    return fb.fbfs
      .collection("events")
      .doc(event_id)
      .collection("payment_options")
      .doc(option_id)
      .delete();
  },
  createTicketOption(_, { event_id, data }) {
    const _createTicketOption = firebase
      .functions()
      .httpsCallable("Tickets-CreatTicketOption");
    return _createTicketOption({ event_id, data });
  },
  deleteTicketOption(_, { event_id, ticket_id }) {
    const _createTicketOption = firebase
      .functions()
      .httpsCallable("Tickets-DeleteTicketOption");
    return _createTicketOption({ event_id, ticket_id });
  },
  async getTicketOption(_, { path }) {
    const ref = await fb.fbfs.doc(path).get();
    return ref.data();
  },
  async buyTickets(_, data) {
    const _buyTickets = firebase
      .functions()
      .httpsCallable("Tickets-BuyTickets");
    return _buyTickets(data);
  },
  deleteImage(_, { eventId, type }) {
    const _createPaymentOption = firebase
      .functions()
      .httpsCallable("Events-DeleteImage");
    return _createPaymentOption({ eventId, type });
  },
};

const getters = {
  getActiveEvents(state: EventsState) {
    return state.events;
  },
  getArchivedEvents(state: EventsState) {
    return state.archivedEvents;
  },
  getEvent(state: EventsState) {
    return (event_id) => {
      return state.events.find((ele) => ele.id == event_id) ?? null;
    };
  },
  getTicketPaymentOptions(state: EventsState) {
    return (event_id) => {
      const event = state.events.find((ele) => ele.id == event_id) ?? null;
      if (!event) return null;
      return event?.payment_options ?? [];
    };
  },
  //   getAcademySchedule(state: ClassesState): Map<string, TimeWeekDefinition> {
  //     return state.academy;
  //   },
  //   getGtrSystemSchedule(state: ClassesState): Map<string, TimeWeekDefinition> {
  //     return state.gtrSystem;
  //   },
  //   getSchedule(state: ClassesState) {
  //     return (scheduleId: string): Map<string, TimeWeekDefinition> => {
  //       switch (scheduleId) {
  //         case "trading-academy":
  //           return state.academy;
  //         case "gtr-system":
  //           return state.gtrSystem;
  //         default:
  //           return state.academy;
  //       }
  //     };
  //   },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
