
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import {
  QuoteRequest,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";

export default defineComponent({
  name: "gtr-basic",
  data() {
    return {
      QuoteRequest: {} as QuoteRequest<QuoteRequestType>,
    };
  },
  props: {
    subscriptionName: { type: String, required: false },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    promotionId: { type: String, required: false },
    promotionType: {
      type: Object as () => "upgrade" | "promotion",
      required: false,
    },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["clearQuote", "requestQuote"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", [
      "getSubscriptionConfig",
      "durationUnitLocalized",
      "getUserSubscription",
      "getSubscriptionsUpgrade",
      "getUserCurrentPackage",
    ]),
    ...mapGetters("sys", ["isOffline"]),
    isLoaded(): boolean {
      const loaded =
        this.SubscriptionConfig != undefined &&
        (this.promotionId === undefined || this.PromotionConfig != undefined);
      return loaded;
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    PromotionConfig(): SubscriptionInfo {
      return this.getSubscriptionsUpgrade(this.promotionId);
    },
    CanUpgrade(): boolean {
      const upgradeDays = this.PromotionConfig?.upgradeWindowDays
        ? this.PromotionConfig.upgradeWindowDays
        : 0;
      const daysLeft = this.UserStatus.daysLeft || 0;
      const duration = this.getSubscriptionConfig(
        this.CurrentUserSubscription
      ).duration;
      return duration - upgradeDays <= daysLeft;
    },
    SubscriptionDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.SubscriptionConfig?.duration,
        this.SubscriptionConfig?.durationUnit
      );
    },
    CurrentUserSubscription(): subscriptionType {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return "gtr-lite";
        case "gtr-basic":
        case "gtr-vip":
        default:
          return "trading-academy";
      }
    },
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.CurrentUserSubscription);
    },
    UserBasicSubscription(): UserSubscription {
      return this.getUserSubscription("trading-academy");
    },
    UserBasicDistributionLicense(): UserSubscription {
      return this.getUserSubscription("distributor-license");
    },
    DaysLeft(): number {
      return this.UserStatus.daysLeft || 0;
    },
    LicenseDays(): number {
      if (this.UserBasicSubscription.isFirstTime) {
        return this.SubscriptionConfig?.initialLicenseDays || 0;
      }
      return this.UserBasicDistributionLicense.daysLeft &&
        this.UserBasicDistributionLicense.daysLeft > 0
        ? this.UserBasicDistributionLicense.daysLeft
        : 0;
    },
  },
  methods: {
    subscriptionQuote() {
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == this.getUsernamePK &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          promotionId: this.CanUpgrade ? this.promotionId : undefined,
          promotionType: this.CanUpgrade ? this.promotionType : undefined,
          duration:
            this.promotionId && this.CanUpgrade
              ? this.DaysLeft + 1 // DaysLeft are rounded down, so we add 1 to get the correct value
              : this.SubscriptionConfig.duration,
          durationUnit:
            this.promotionId && this.CanUpgrade
              ? this.PromotionConfig.durationUnit
              : this.SubscriptionConfig.durationUnit,
          username: this.getUsernamePK,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
  },
});
