
import {
  CLEAR_USER_COMMISSIONS,
  doGetCommissions,
  unsubscribeListener,
} from "@/store/Commissions/CommissionsInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  CommissionsDetail,
  CommissionsSummary,
} from "../components/commissions";

export default defineComponent({
  name: "Commissions",
  components: { CommissionsDetail, CommissionsSummary },
  data() {
    return {
      loading: true,
      unwatch: () => {
        return;
      },
    };
  },
  computed: {
    ...mapGetters("commissions", {
      commissions: "getCommissions",
      summary: "getSummary",
    }),
  },
  methods: {
    async requestCommissions() {
      this.loading = true;
      this.$store.dispatch("commissions/" + doGetCommissions).then(() => {
        this.loading = false;
      });
    },
  },
  async created() {
    if (this.commissions.length > 0) {
      this.$store.commit("commissions/" + CLEAR_USER_COMMISSIONS);
    }
    this.requestCommissions();
    this.unwatch = this.$store.watch(
      (state) => state.commissions.summary,
      () => {
        return;
      },
      { deep: true }
    );
  },
  beforeUnmount() {
    this.unwatch();
    this.$store.dispatch("commissions/" + unsubscribeListener);
  },
});
