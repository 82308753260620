
import { defineComponent } from "vue";
import { images } from "@/mixins/images";

export default defineComponent({
  name: "event-ticket-resume",
  props: ["optionSelected", "event", "minify", "disabled"],
  data() {
    return {
      image: null as string | null,
    };
  },
  computed: {
    grand_total() {
      return this.optionSelected.reduce((a, b) => a + b.total, 0);
    },
    format_currency() {
      return (price: any = { value: 0 }, total = null) =>
        `${this.event.currency} ` +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: this.event.currency,
        }).format(total != null ? total : price.value);
    },
  },
  methods: {
    async getImage() {
      this.image = await images.methods.getImage(this.event.image);
    },
  },
  mounted() {
    if (this.event.image) this.getImage();
  },
});
