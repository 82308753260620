import { ActionContext } from "vuex";
import * as fb from "../../firebase";

import {
  ADD_USER_COMMISSION,
  Commission,
  DELETE_USER_COMMISSION,
  SetListenerRequest,
  SET_LISTENER,
  UPDATE_USER_COMMISSION,
  CommissionsState,
  CommissionsSummary,
  UPDATE_USER_COMMISSION_SUMMARY,
  CLEAR_LISTENER,
  CommissionTypeConfig,
  CommissionStatusConfig,
  UPDATE_USER_TO_BE_PROCESSED,
  UPDATE_USER_TOTALS_WITH_FUTURE_COMMISSIONS,
  CLEAR_USER_COMMISSIONS,
} from "./CommissionsInterfaces";
import {
  CommissionStatusMap,
  CommissionTypesMap,
} from "./CommissionsLocalData";

const initialState: CommissionsState = {
  commissionsTypes: CommissionTypesMap,
  commissionsStatuses: CommissionStatusMap,
  commissions: [],
  summary: {
    _total: 0,
    _pending: 0,
    total: 0,
    pending: 0,
    paid: 0,
    futureAmount: 0,
    totalUpdatedDate: undefined,
    pendingUpdatedDate: undefined,
    paidUpdatedDate: undefined,
    toBeProcessed: undefined,
    toBeProcessedUpdatedDate: undefined,
  },
};
const snapshotListeners = {
  commissions: () => {
    return;
  },
  summary: () => {
    return;
  },
  toBeProcessed: () => {
    return;
  },
  futureCommissions: () => {
    return;
  },
};

const state: CommissionsState = initialState;

const mutations = {
  UPDATE_USER_COMMISSION_SUMMARY(
    state: CommissionsState,
    summary: CommissionsSummary
  ) {
    state.summary.paid = summary.paid;
    state.summary.paidUpdatedDate = summary.paidUpdatedDate;
    state.summary.pending = summary.pending;
    state.summary._pending = summary.pending;
    state.summary.pendingUpdatedDate = summary.pendingUpdatedDate;
    state.summary.total = summary.total;
    state.summary._total = summary.total;
    state.summary.totalUpdatedDate = summary.totalUpdatedDate;
  },
  UPDATE_USER_TO_BE_PROCESSED(
    state: CommissionsState,
    {
      toBeProcessedAmount,
      toBeProcessedUpdatedDate,
    }: {
      toBeProcessedAmount: number;
      toBeProcessedUpdatedDate: Date;
    }
  ) {
    state.summary.toBeProcessed = toBeProcessedAmount;
    state.summary.toBeProcessedUpdatedDate = toBeProcessedUpdatedDate;
  },
  UPDATE_USER_TOTALS_WITH_FUTURE_COMMISSIONS(
    state: CommissionsState,
    { futureAmount }: { futureAmount: number }
  ) {
    state.summary.futureAmount = futureAmount;
  },
  ADD_USER_COMMISSION(state: CommissionsState, commission: Commission) {
    // Find the index where the new commission should be inserted
    const index = state.commissions.findIndex(
      (item: any) => item._id < commission._id
    );
    // If no such index is found, push the commission to the end of the array
    if (index === -1) {
      state.commissions.push(commission);
    } else {
      // Otherwise, insert the commission at the correct index to maintain order
      state.commissions.splice(index, 0, commission);
    }
  },
  DELETE_USER_COMMISSION(state: CommissionsState, docId: string) {
    const dateId = new Date(docId).getTime();
    state.commissions = [
      ...state.commissions.filter((item) => item._id != dateId),
    ];
  },
  UPDATE_USER_COMMISSION(state: CommissionsState, commission: Commission) {
    const index = state.commissions.findIndex(
      (item: any) => item._id === commission._id
    );
    if (index !== -1) {
      state.commissions.splice(index, 1, commission);
    }
  },
  CLEAR_USER_COMMISSIONS() {
    if (state) {
      state.summary._total = 0;
      state.summary.total = 0;
      state.summary._pending = 0;
      state.summary.pending = 0;
      state.summary.paid = 0;
      state.summary.toBeProcessed = 0;
      state.summary.totalUpdatedDate = undefined;
      state.summary.pendingUpdatedDate = undefined;
      state.summary.paidUpdatedDate = undefined;
      state.summary.toBeProcessedUpdatedDate = undefined;
      state.commissions.splice(0, state.commissions.length);
    }
  },
  SET_LISTENER(state: CommissionsState, request: SetListenerRequest) {
    if (request.listenerId == undefined) {
      snapshotListeners.commissions = request.commissions;
      snapshotListeners.summary = request.summary;
      snapshotListeners.toBeProcessed = request.toBeProcessed;
    } else if (request.listenerId == "commissions") {
      snapshotListeners.commissions = request.commissions;
    } else if (request.listenerId == "summary") {
      snapshotListeners.summary = request.summary;
    } else if (request.listenerId == "toBeProcessed") {
      snapshotListeners.toBeProcessed = request.toBeProcessed;
    } else if (request.listenerId == "futureCommissions") {
      snapshotListeners.futureCommissions = request.futureCommissions;
    }
  },
  CLEAR_LISTENER(
    state: CommissionsState,
    listenerId?:
      | "commissions"
      | "summary"
      | "toBeProcessed"
      | "futureCommissions"
  ) {
    if (listenerId == undefined) {
      snapshotListeners.commissions();
      snapshotListeners.commissions = function () {
        return;
      };
      snapshotListeners.summary();
      snapshotListeners.summary = function () {
        return;
      };
      snapshotListeners.toBeProcessed();
      snapshotListeners.toBeProcessed = function () {
        return;
      };
      snapshotListeners.futureCommissions();
      snapshotListeners.futureCommissions = function () {
        return;
      };
    } else {
      snapshotListeners[listenerId]();
      snapshotListeners[listenerId] = function () {
        return;
      };
    }
  },
};

const actions = {
  async doGetCommissions(context: ActionContext<CommissionsState, any>) {
    const username = await context.rootGetters["auth/getUsernamePK"];
    const _userCommissions = fb.fbfs.collection(
      "users/" + username + "/commissions/"
    );
    context.commit(CLEAR_USER_COMMISSIONS);
    const _summaryRef = _userCommissions.where("_id", "==", 0);
    const summaryListener = _summaryRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        if (doc.data()) {
          const com = doc.data();
          //Commissions summary
          const summary = com as CommissionsSummary;
          summary.totalUpdatedDate = com.totalUpdatedDate.toDate() || undefined;
          summary.pendingUpdatedDate =
            com.pendingUpdatedDate.toDate() || undefined;
          summary.paidUpdatedDate = com.paidUpdateDate?.toDate() || undefined;
          context.commit(UPDATE_USER_COMMISSION_SUMMARY, summary);
        }
      });
    });

    const _detailRef = _userCommissions
      .orderBy("_id", "desc")
      .where("_id", "<=", new Date().getTime());

    const l = _detailRef.limit(100).onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach(async (change) => {
        const doc = change.doc;
        if (doc.data()) {
          const com = doc.data();
          const commission = com as Commission;
          if (com._id != 0) {
            if (change.type == "added") {
              context.commit(ADD_USER_COMMISSION, commission);
            } else if (change.type == "modified") {
              context.commit(UPDATE_USER_COMMISSION, commission);
            } else if (change.type == "removed") {
              context.commit(DELETE_USER_COMMISSION, doc.id);
            }
          }
        }
      });
    });
    // get the sum of the toBeProcessed commissions
    const pendingListener = _detailRef
      .where("status", "==", "pending")
      .onSnapshot((querySnapshot) => {
        const toBeProcessedAmount = querySnapshot.docs.reduce(
          (acc, doc) => acc + doc.data().amount,
          0
        );
        const toBeProcessedUpdatedDate = new Date();
        context.commit(UPDATE_USER_TO_BE_PROCESSED, {
          toBeProcessedAmount,
          toBeProcessedUpdatedDate,
        });
      });
    // get the sum of the future commissions to subtract from the total
    const futureListener = _userCommissions
      .where("_id", ">", new Date().getTime())
      .onSnapshot((querySnapshot) => {
        const futureAmount = querySnapshot.docs.reduce((acc, doc) => {
          return doc.data()._id > new Date().getTime()
            ? acc + doc.data().amount
            : acc;
        }, 0);
        context.commit(UPDATE_USER_TOTALS_WITH_FUTURE_COMMISSIONS, {
          futureAmount,
        });
      });
    context.commit(SET_LISTENER, {
      commissions: l,
      summary: summaryListener,
      toBeProcessed: pendingListener,
      futureCommissions: futureListener,
    });
  },
  unsubscribeListener(context: ActionContext<CommissionsState, any>) {
    context.commit(CLEAR_LISTENER);
  },
};
const getters = {
  getCommissions(state: CommissionsState): Array<Commission> {
    return state ? state.commissions : new Array<Commission>();
  },
  getSummary(state: CommissionsState): CommissionsSummary | undefined {
    const summary = { ...state.summary };
    summary.total = summary ? summary._total - summary.futureAmount : 0;
    summary.pending = summary ? summary._pending - summary.futureAmount : 0;
    return summary;
  },
  getCommissionTypeConfig(state: CommissionsState) {
    return (type: string): CommissionTypeConfig | undefined => {
      return state.commissionsTypes.get(type);
    };
  },
  getCommissionStatusConfig(state: CommissionsState) {
    return (status: string): CommissionStatusConfig | undefined => {
      return state.commissionsStatuses.get(status);
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
