
import { defineComponent } from "vue";
import Modal from "./modal.vue";

export default defineComponent({
  name: "loader-spinner",
  props: ["show"],
  components: { Modal },
  computed: {
    is_show() {
      return this.show ?? false;
    },
  },
});
