
import { defineComponent } from "vue";
import { images } from "@/mixins/images";

export default defineComponent({
  name: "image-card",
  props: {
    fromBucket: { required: false, type: Boolean, default: false },
    topImage: { required: false, type: Boolean, default: false },
    cardHeader: { required: false, type: String },
    cardTitle: { required: false, type: String },
    imgSrc: { required: true, type: String },
    imgAlt: { required: false, type: String },
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  created() {
    const defaultImage = "GTR-No-Picture.png";
    if (this.fromBucket) {
      const url = "" + this.imgSrc;
      this.getImage(url).then((r) => {
        this.imgUrl = r;
      });
    } else {
      try {
        this.imgUrl = require(`../assets/img/${this.imgSrc}`);
      } catch {
        this.imgUrl = require(`../assets/img/${defaultImage}`);
      }
    }
  },
  setup() {
    const getImage = images.methods.getImage;
    return {
      getImage,
    };
  },
});
