// import { SubscriptionInfo } from "functions/src/Subscriptions/SubscriptionsInterfaces";
// import { UserSubscription } from "functions/src/Users/UsersInterfaces";

import {
  SubscriptionPackage,
  SubscriptionPromotion,
  SubscriptionUpgrade,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";

//Actions
export const doGetUserSubscriptions = "doGetUserSubscriptions";
export const doGetSubscriptionsConfig = "doGetSubscriptionsConfig";
export const updateUserSubscription = "updateUserSubscription";
export const quoteSubscription = "quoteSubscription";

//Mutations
export const SET_SUBS_CONFIG = "SET_SUBS_CONFIG";
export const UNSET_SUBS_CONFIG = "UNSET_SUBS_CONFIG";
export const SET_USER_SUBS = "SET_USER_SUBS";
export const UNSET_USER_SUBS = "UNSET_USER_SUBS";
export const CLEAR_USER_SUBS = "CLEAR_USER_SUBS";
export const SET_PROMOTIONS_CONFIG = "SET_PROMOTIONS_CONFIG";
export const UNSET_PROMOTIONS_CONFIG = "UNSET_PROMOTIONS_CONFIG";
export const SET_USER_CURRENT_PACKAGE = "SET_USER_CURRENT_PACKAGE";
export const SET_USER_ACTIVE = "SET_USER_ACTIVE";
export const SET_IS_USER_DISTRIBUTOR = "SET_IS_USER_DISTRIBUTOR";
export const SET_UPGRADES_CONFIG = "SET_UPGRADES_CONFIG";
export const UNSET_UPGRADES_CONFIG = "UNSET_UPGRADES_CONFIG";

export interface SubscriptionsState {
  UserSubscriptions: Array<[string, UserSubscription]>;
  SubscriptionsConfiguration: Array<any>;
  SubscriptionsPromotions: Array<[string, SubscriptionPromotion]>;
  SubscriptionsUpgrades: Array<[string, SubscriptionUpgrade]>;
  UserPackage: SubscriptionPackage;
  isUserActive: boolean;
  isUserDistributor: boolean;
}
