
import { defineComponent } from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default defineComponent({
  name: "qr-code-generate",
  components: { VueQrcode },
  data() {
    return {
      options: {
        maskPattern: 7,
        width: 500,
        scale: 10,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
        margin: 0,
      },
    };
  },
  computed: {
    data(): string {
      let token = this.$route.params.token as string;
      token = atob(token).split("").reverse().join("");
      token = atob(token);
      return token;
    },
  },
});
