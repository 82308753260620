
import { defineComponent } from "vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { mapGetters } from "vuex";

import { DataResponse } from "functions/src/responseTypes";
import UserSimpleView from "@/components/UserSimpleView.vue";
import { UserProfile } from "@/store/Auth/AuthInterfaces";

export default defineComponent({
  name: "transfer-gtr-points",
  components: { UserSimpleView },
  data() {
    return {
      searchUserValue: "",
      user: undefined as undefined | UserProfile,
      amountString: "",
      concept: "",
      error: "",
      invalidAmountError: "",
      searching: false,
      processing: false,
      processResponse: false,
      processError: false,
      processMessage: "No se puede",
      conceptTouched: false,
      amountTouched: false,
    };
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
  },
  emits: ["points-transferred"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("subs", ["getUserSubscription"]),
    isActive() {
      const academy = this.getUserSubscription("trading-academy");
      return academy?.isActive || false;
    },
    totalString(): string {
      const num = this.total || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    amountLocalString(): string {
      const num = this.amount || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    amount(): number {
      return Number.parseInt(this.amountString);
    },
    invalidAmount(): boolean {
      const lessThanZero = this.amount <= 0;
      const greaterThanTotal = this.amount > this.total;
      const isNotANumber = isNaN(this.amount);
      const isUndefined = this.amount === undefined;

      return (
        this.amountTouched &&
        (lessThanZero || greaterThanTotal || isNotANumber || isUndefined)
      );
    },
    conceptError() {
      // The concept field has an error if it's empty and has been touched
      return this.conceptTouched && !this.concept;
    },
    canTransfer() {
      return (
        this.total > 0 &&
        this.user !== undefined &&
        this.amount > 0 &&
        this.total > this.amount &&
        this.concept !== ""
      );
    },
  },
  methods: {
    async searchUser() {
      this.searching = true;
      this.conceptTouched = false;
      this.error = "";
      this.user = undefined;

      const searchUserFunction = httpsCallable(
        functions,
        "Users-SearchAllUsers"
      );
      const response: DataResponse<UserProfile> = await searchUserFunction({
        username: this.searchUserValue,
      })
        .then((result) => result.data as DataResponse<UserProfile>)
        .finally(() => {
          this.searching = false;
        });

      if (response.OPCODE === "SUCCESS") {
        this.user = response.data as UserProfile;
        return;
      } else if (response.OPCODE === "ERROR") {
        this.error = response.message || "Error desconocido";
        // alert(response.message);
      } else {
        alert("Error desconocido");
        console.error(response);
      }
      this.user = undefined;
    },
    transferPoints() {
      this.processing = true;
      this.processMessage = "";
      this.processResponse = false;
      this.processError = false;

      const transferPoints = httpsCallable(
        functions,
        "GtrPoints-TransferGtrPoints"
      );
      transferPoints({
        points: this.amount,
        concept: this.concept,
        receiver: this.user?._usernamePK,
        sender: this.getUsernamePK,
      })
        .then((result) => {
          const response = result.data as DataResponse<undefined>;
          if (response.OPCODE === "SUCCESS") {
            this.processError = false;
            this.processMessage =
              `<div>Se han transferido exitosamente</div>` +
              `<div class='fs-5 py-2 text-dark fw-bold'>G$ ${this.amountLocalString}</div>` +
              `<div>al usuario:</div>` +
              `<div class='text-dark'>${this.user?.nickname} (${this.user?.username})<div>`;
            return;
          } else if (response.OPCODE === "ERROR") {
            this.processError = true;
            switch (response.message) {
              case "UNAUTHORIZED_USER":
                this.processMessage =
                  "No está autorizado para transferir GTR Points";
                break;
              case "USER_NOT_FOUND":
                this.processMessage = "Usuario no encontrado";
                break;
              case "USER_IS_NOT_ACTIVE":
                this.processMessage =
                  "Usuario no está activo. Para poder transferir GTR Points, el usuario debe estar activo";
                break;
              case "INSUFFICIENT_POINTS":
                this.processMessage =
                  "GTR Points insuficientes para transferir";
                break;
              default:
                this.processMessage = "Error desconocido";
                console.error(response);
            }
          } else {
            this.processError = true;
            this.processMessage = "Error desconocido";
            console.error(response);
          }
        })
        .finally(() => {
          this.processing = false;
          this.processResponse = true;
        });
      this.error = "";
      return;
    },
    newTransfer() {
      this.amountTouched = false;
      this.conceptTouched = false;
      this.processResponse = false;
      this.processError = false;
      this.processMessage = "";
      this.amountString = "";
      this.concept = "";
      return;
    },
    validateInteger(event) {
      const value = event.target.value;
      // Allow only digits; remove non-digit characters
      const sanitizedValue = value.replace(/\D/g, "");
      // Update the model only if the input is an integer
      if (sanitizedValue !== this.amountString) {
        this.amountString = sanitizedValue;
      }
    },
    touchConcept() {
      // Mark the concept field as touched when it loses focus
      this.conceptTouched = true;
    },
    touchAmount() {
      // Mark the amount field as touched when it loses focus
      this.amountTouched = true;
      this.setAmountError();
    },
    setAmountError() {
      if (this.amountTouched && this.invalidAmount) {
        if (
          this.amount <= 0 ||
          isNaN(this.amount) ||
          this.amount === undefined
        ) {
          this.invalidAmountError = "La cantidad debe ser mayor a G$ 0";
        } else if (this.amount > this.total) {
          this.invalidAmountError =
            "La cantidad debe ser menor o igual a G$ " + this.totalString;
        }
      } else {
        this.invalidAmountError = ""; // Clear the error message when the amount becomes valid
      }
    },
    close() {
      this.amountTouched = false;
      this.conceptTouched = false;
      this.user = undefined;
      this.amountString = "";
      this.concept = "";
      this.searchUserValue = "";
      this.processResponse = false;
      this.processing = false;
      return;
    },
  },
});
