
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  QuoteRequest,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import { SubscriptionPackage } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

export default defineComponent({
  name: "subscription-status-card",
  data() {
    return {
      QuoteRequest: {} as QuoteRequest<QuoteRequestType>,
      editedEndDate: new Date().toISOString().substring(0, 10),
      manualDateUpdate:
        process.env.VUE_APP_MANUAL_SUBSCRIPTION_DATE_UPDATE === "true",
    };
  },
  props: {
    cardType: {
      type: Object as () => "license" | "package",
      required: true,
    },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    packageId: {
      type: String as () => SubscriptionPackage,
      required: true,
    },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["isActive", "clearQuote", "requestQuote"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail", "getUserPicture"]),
    ...mapGetters("subs", [
      "getSubscriptionConfig",
      "getUserSubscription",
      "durationUnitLocalized",
      "isUserActive",
    ]),
    ...mapGetters("sys", ["isOffline"]),
    isLoaded(): boolean {
      const loaded =
        this.SubscriptionConfig != undefined && this.UserStatus != undefined;
      return loaded;
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    SubscriptionDisplayName(): string {
      if (this.cardType == "license") {
        return this.getSubscriptionConfig(this.subscriptionId).displayName;
      }
      switch (this.packageId) {
        case "gtr-basic":
          return "GTR Básico";
        case "gtr-lite":
          return this.getSubscriptionConfig(this.packageId).displayName;
        case "gtr-vip":
          return this.getSubscriptionConfig(this.packageId).displayName;
        default:
          return "Suscripción";
      }
    },
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.subscriptionId);
    },
    PeriodsStep(): number {
      return this.SubscriptionConfig?.duration || 0;
    },
    DaysLeft(): number {
      // Calculated at subs store
      return this.UserStatus.daysLeft || 0;
    },
    daysLeftClass(): string {
      if (this.DaysLeft <= 5) {
        return "bg-danger";
      } else if (this.DaysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    isActive(): boolean {
      const status =
        this.UserStatus.endDate != undefined &&
        this.UserStatus.startDate != undefined &&
        this.UserStatus.endDate > new Date();
      this.$emit("isActive", this.subscriptionId, status);
      return status;
    },
    Total(): string {
      const total = this.SubscriptionConfig.cost;
      return total.toLocaleString();
    },
    SubscriptionDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.SubscriptionConfig.duration,
        this.SubscriptionConfig?.durationUnit
      );
    },
    ExpirationDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.DaysLeft,
        this.SubscriptionConfig?.durationUnit
      );
    },
    CanRenewSubscription(): boolean {
      if (this.subscriptionId == "distributor-license") {
        return this.isUserActive;
      } else {
        //Update days at function
        return this.DaysLeft < 3;
      }
    },
    btnClass(): string {
      switch (this.packageId) {
        case "gtr-basic":
          return "btn-danger";
        case "gtr-lite":
          return "btn-info";
        case "gtr-vip":
          return "btn-yellow";
        default:
          return "btn-danger";
      }
    },
  },
  methods: {
    async updateEndDate() {
      const newEndDate = new Date(this.editedEndDate);
      const updateSubscriptionEndDate = httpsCallable(
        functions,
        "Subscriptions-updateSubscriptionEndDate"
      );
      await updateSubscriptionEndDate({
        username: this.getUsernamePK,
        subscriptionId: this.subscriptionId,
        endDate: newEndDate,
      })
        .then(() => {
          alert("Fecha de vencimiento actualizada");
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Error al actualizar la fecha de vencimiento: " + error.message
          );
        });
    },
    subscriptionQuote() {
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == this.getUsernamePK &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          duration: this.SubscriptionConfig.duration,
          durationUnit: this.SubscriptionConfig.durationUnit,
          username: this.getUsernamePK,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
  },
  mounted() {
    this.editedEndDate = (this.UserStatus.endDate as Date)
      ?.toISOString()
      .substring(0, 10);
  },
});
