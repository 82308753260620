import { ActionContext } from "vuex";
import * as fb from "../../firebase";
import {
  Leaderboard,
  LeaderboardEntry,
  LeaderboardState,
} from "./LeaderboardInterfaces";
import { images } from "@/mixins/images";

const initialState: LeaderboardState = {
  leaderBoards: new Array<Leaderboard>(),
};

const state: LeaderboardState = { ...initialState };

const mutations = {
  setLeaderboard(state: LeaderboardState, leaderBoard: Leaderboard[]) {
    state.leaderBoards = [...leaderBoard];
  },
};
const actions = {
  async doGetLeaderboard({ commit }: ActionContext<LeaderboardState, any>) {
    const leaderBoard = await fb.fbfs
      .collection("leaderboards")
      .where("active", "==", true)
      .orderBy("priority")
      .get();
    const leaderBoardList: Leaderboard[] = [];
    await Promise.all(
      leaderBoard.docs.map(async (doc: any) => {
        let leaderboardEntries: LeaderboardEntry[] = [];
        const l = doc.data() as Leaderboard;
        const excludedUsers = l.excludedUsers || [];

        const listCollection = doc.ref
          .collection("leaderboardEntries")
          .orderBy("position", "asc")
          .limit(10 + excludedUsers.length);
        const entries = await listCollection.get();

        await Promise.all(
          entries.docs.map(async (doc: any) => {
            const entry: LeaderboardEntry = {
              ...(doc.data() as LeaderboardEntry),
              username: doc.data()._usernamePK,
              profilePicture: await images.methods.getProfilePicture(
                doc.data().profilePicture
              ),
            };
            leaderboardEntries.push(entry);
          })
        );

        // Remove the excluded users
        leaderboardEntries = leaderboardEntries.filter((entry) => {
          return !excludedUsers.includes(entry.username);
        });

        // Resort the list, because promises are not guaranteed to resolve in order
        leaderboardEntries = leaderboardEntries.sort((a, b) => {
          return a.position - b.position;
        });

        // Fix position, in case there are excluded users
        leaderboardEntries = leaderboardEntries.map((entry, index) => {
          entry.position = index + 1;
          return entry;
        });

        // Trim the list to 10
        leaderboardEntries = leaderboardEntries.slice(0, 10);

        const defaultHeaderImg = "/img/leaderboard/tabla_firmas_header.png";
        const defaultBackgroundImg =
          "/img/leaderboard/tabla_firmas_background.jpg";
        const leaderboardObject: Leaderboard = {
          ...doc.data(),
          backgroundImage: await images.methods.getImage(
            doc.data().backgroundImage,
            defaultBackgroundImg
          ),
          headerImage: await images.methods.getImage(
            doc.data().headerImage,
            defaultHeaderImg
          ),
          leaderboardEntries: leaderboardEntries,
          dateUpdated: doc.data().dateUpdated.toDate(),
        };
        leaderBoardList.push(leaderboardObject);
      })
    );
    commit("setLeaderboard", leaderBoardList);
  },
};
const getters = {
  getLeaderboards: (state: LeaderboardState) => {
    return state.leaderBoards;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
