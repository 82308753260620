
import { images } from "@/mixins/images";
import dayjs from "dayjs";
import { MMIUser } from "@/store/MMI/MMIInterfaces";
import { defineComponent } from "vue";

export default defineComponent({
  name: "mmi-current-user-card",
  props: {
    userData: {
      type: Object as () => MMIUser,
      required: true,
    },
  },
  data() {
    return {
      profilePictureUrl: "",
    };
  },
  computed: {
    userInitialDate(): string {
      const d: Date = (this.userData.initialDate as any)._seconds
        ? new Date((this.userData.initialDate as any)._seconds * 1000)
        : this.userData.initialDate;
      return dayjs(d).format("YYYY/MM/DD HH:mm:ss z");
    },
  },
  created() {
    this.getProfilePicture(this.userData.profilePicture).then((response) => {
      this.profilePictureUrl = response;
    });
  },
  setup() {
    const getProfilePicture = images.methods.getProfilePicture;
    return {
      getProfilePicture,
    };
  },
  watch: {
    userData: {
      handler(newValue: MMIUser) {
        this.profilePictureUrl = "";
        this.getProfilePicture(newValue.profilePicture).then((response) => {
          this.profilePictureUrl = response;
        });
      },
      deep: true,
    },
  },
});
