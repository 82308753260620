
import { defineComponent } from "vue";
import router from "@/router";
import {
  PasswordValidationResponse,
  validationTools,
} from "@/mixins/validationTools";

export default defineComponent({
  name: "ResetPassword",
  data() {
    return {
      capsLockOn: false,
      showPassword: false,
      loading: false,
      passwordValidation: new PasswordValidationResponse(),
      validPassword: true,
      validPasswordConfirm: true,
      loginForm: {
        password: "",
        passwordConfirm: "",
      },
    };
  },
  methods: {
    invalidInputs() {
      return (
        !this.loginForm.passwordConfirm ||
        !this.loginForm.password ||
        this.loginForm.passwordConfirm != this.loginForm.password
      );
    },
    resetPassword() {
      this.loading = true;
      const _token = this.$route.params.token;
      this.$store
        .dispatch("auth/resetPassword", {
          token: _token,
          pw: this.loginForm.password,
        })
        .then((response) => {
          if (response.OPCODE == "SUCCESS") {
            this.loading = false;
            alert(response.message);
            router.push({ name: "login" });
          } else {
            this.loading = false;
            alert(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          alert(error.message);
        });
    },
    validatePasswords() {
      this.validatePasswordInput();
      this.validatePasswordConfirm();
    },
    validatePasswordInput() {
      this.passwordValidation = this.validatePassword(this.loginForm.password);
      this.validPassword = !this.passwordValidation.hasError;
    },
    validatePasswordConfirm() {
      if (
        this.loginForm.passwordConfirm.length > 0 &&
        this.loginForm.passwordConfirm != this.loginForm.password
      ) {
        this.validPasswordConfirm = false;
        return;
      }
      this.validPasswordConfirm = true;
    },
  },
  mounted() {
    const inputs: HTMLInputElement[] = [];
    inputs.push(this.$refs.password as HTMLInputElement);
    inputs.push(this.$refs.confirmPassword as HTMLInputElement);

    inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener("keyup", (event) => {
        this.capsLockOn = event.getModifierState("CapsLock");
      });
    });
  },
  setup() {
    const validatePassword = validationTools.methods.validPassword;
    return {
      validatePassword,
    };
  },
});
