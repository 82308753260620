
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { images } from "@/mixins/images";
import { UserProfile } from "@/store/Auth/AuthInterfaces";

export default defineComponent({
  name: "user-simple-view",
  props: {
    user: { type: Object as () => UserProfile, required: false },
  },
  data() {
    return {
      profilePictureUrl: "",
    };
  },
  computed: {
    ...mapGetters("ranks", ["rankName", "rankIconClass"]),
    profilePicture(): string {
      this.getProfilePicture();
      return this.profilePictureUrl;
    },
  },
  methods: {
    async getProfilePicture(): Promise<void> {
      const url = await images.methods
        .getProfilePicture(this.user?.pictureUrl || "")
        .then((url: string) => {
          return url;
        });
      this.profilePictureUrl = url;
    },
  },
});
