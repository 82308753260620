import {
  CommissionStatusConfig,
  CommissionTypeConfig,
} from "./CommissionsInterfaces";

export const CommissionTypesMap = new Map<string, CommissionTypeConfig>();

//#region Commission Types
CommissionTypesMap.set("direct", {
  code: "direct",
  icon: "fas fa-fw fa-hand-holding-usd text-cyan",
  label: "Venta Directa",
});
CommissionTypesMap.set("binary", {
  code: "binary",
  icon: "fas fa-share-alt fa-fw fa-rotate-90 text-purple",
  label: "Binario",
});
CommissionTypesMap.set("carrer", {
  code: "carrer",
  icon: "fas fa-fw fa-award text-red",
  label: "Carrera",
});
CommissionTypesMap.set("cancel", {
  code: "cancel",
  icon: "fas fa-fw fa-ban text-danger",
  label: "Cancelación",
});
CommissionTypesMap.set("loan", {
  code: "loan",
  icon: "fas fa-fw fa-file-invoice-dollar text-green",
  label: "Préstamo",
});
CommissionTypesMap.set("loanPayment", {
  code: "loanPayment",
  icon: "fas fa-fw fa-donate text-teal",
  label: "Pago de Préstamo",
});
CommissionTypesMap.set("scholarship", {
  code: "scholarship",
  icon: "fas fa-fw fa-piggy-bank text-pink",
  label: "Beca",
});
CommissionTypesMap.set("scholarshipCredit", {
  code: "scholarshipCredit",
  icon: "fas fa-fw fa-user text-orange",
  label: "Crédito de Beca",
});
CommissionTypesMap.set("prize", {
  code: "prize",
  icon: "fas fa-fw fa-trophy text-yellow",
  label: "Premio",
});
CommissionTypesMap.set("penalty", {
  code: "penalty",
  icon: "fas fa-fw fa-times-circle text-danger",
  label: "Multa",
});
CommissionTypesMap.set("novolume", {
  code: "novolume",
  icon: "far fa-fw fa-handshake text-muted",
  label: "Sin Volumen",
});
CommissionTypesMap.set("mmi-colombia", {
  code: "mmi-colombia",
  icon: "fa fa-fw fas fa-fire fa-fw text-orange",
  label: "MMI: Colombia",
});
CommissionTypesMap.set("gtrCar", {
  code: "gtrCar",
  icon: "fas fa-fw fa-car text-red",
  label: "Bono Carro",
});
CommissionTypesMap.set("gtrDep", {
  code: "gtrDep",
  icon: "fas fa-fw fa-building text-red",
  label: "Bono Depa",
});
CommissionTypesMap.set("gtrLife", {
  code: "gtrLife",
  icon: "fas fa-fw fa-passport text-red",
  label: "Bono Vida GTR",
});
CommissionTypesMap.set("gtrLifeBonus[INFLUENCER]", {
  code: "gtrLifeBonus[INFLUENCER]",
  icon: "far fa-fw fa-thumbs-up text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("gtrLifeBonus[FINANCIAL-INF]", {
  code: "gtrLifeBonus[FINANCIAL-INF]",
  icon: "fas fa-fw fa-comments-dollar text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("gtrLifeBonus[SIX-FIGURES]", {
  code: "gtrLifeBonus[SIX-FIGURES]",
  icon: "far fa-fw fa-gem text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("gtrLifeBonus[GLOBAL-AMBASSADOR]", {
  code: "gtrLifeBonus[GLOBAL-AMBASSADOR]",
  icon: "fas fa-fw fa-globe text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("gtrLifeBonus[GLOBAL-CROWN]", {
  code: "gtrLifeBonus[GLOBAL-CROWN]",
  icon: "fas fa-fw fa-crown text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("gtrLifeBonus[LEYEND]", {
  code: "gtrLifeBonus[LEYEND]",
  icon: "fas fa-fw fa-space-shuttle text-red",
  label: "Bono GTR Life",
});
CommissionTypesMap.set("tickets", {
  code: "tickets",
  icon: "fas fa-fw fa-ticket-alt text-yellow",
  label: "Boleto(s)",
});
CommissionTypesMap.set("enrollerUpline", {
  code: "enrollerUpline",
  icon: "fas fa-fw fa-angle-double-up text-cyan",
  label: "GTR Lite Downline",
});
//#endregion

export const CommissionStatusMap = new Map<string, CommissionStatusConfig>();

//#region Commission Status
CommissionStatusMap.set("pending", {
  code: "pending",
  icon: "far fa-fw fa-clock",
  label: "Pendiente",
  colorClass: "text-warning",
  amountClass: "muted",
});

CommissionStatusMap.set("paid", {
  code: "paid",
  icon: "fab fa-fw fa-bitcoin text-yellow",
  label: "Pagada",
  colorClass: "text-success",
  amountClass: "text-dark fw-bold",
});

CommissionStatusMap.set("processing", {
  code: "processing",
  icon: "fas fa-fw fa-cogs",
  label: "En Proceso",
  colorClass: "text-cyan",
  amountClass: "",
});

CommissionStatusMap.set("lost", {
  code: "lost",
  icon: "far fa-fw fa-frown",
  label: "Perdida",
  colorClass: "muted",
  amountClass: "text-decoration-line-through",
});
//#endregion
