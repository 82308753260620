
import { defineComponent } from "vue";
import * as bs from "bootstrap";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { mapGetters } from "vuex";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "commissions-summary",
  props: {
    summary: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("sys", ["getSystemConfig"]),
    cols(): number {
      return parseFloat(this.toBeProcessed) != 0 ? 4 : 3;
    },
    total(): string {
      const num = this.summary?.total || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    pending(): string {
      const num = this.summary?.pending || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    paid(): string {
      const num = this.summary?.paid || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    toBeProcessed(): string {
      const num = this.summary?.toBeProcessed || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    totalDate(): string {
      return this.summary?.totalUpdatedDate
        ? this.formatDateTimezone(this.summary.totalUpdatedDate)
        : "-";
    },
    pendingDate(): string {
      return this.summary?.pendingUpdatedDate
        ? this.formatDateTimezone(this.summary.pendingUpdatedDate)
        : "-";
    },
    paidDate(): string {
      return this.summary?.paidUpdatedDate
        ? this.formatDateTimezone(this.summary.paidUpdatedDate)
        : "-";
    },
    toBeProcessedDate(): string {
      return this.summary?.toBeProcessedUpdatedDate
        ? this.formatDateTimezone(this.summary.paidUpdatedDate)
        : "-";
    },
    minimumAmountMessage(): string {
      return `
        <div class="text-center">
          El monto mínimo para retiro es de:<br />
          <strong>G$ ${this.toTwoDecimals(
            this.getSystemConfig.displayedMinimumPaymentAmount
          )}</strong>
        </div>
      `;
    },
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
  },
  mounted() {
    new bs.Popover(this.$refs.minimumAmountPopup);
  },
});
