
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import {
  QuoteRequest,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";

export default defineComponent({
  name: "gtr-lite",
  data() {
    return {
      QuoteRequest: {} as QuoteRequest<QuoteRequestType>,
    };
  },
  props: {
    subscriptionName: { type: String, required: false },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["clearQuote", "requestQuote"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", ["getSubscriptionConfig", "durationUnitLocalized"]),
    ...mapGetters("sys", ["isOffline"]),
    isLoaded(): boolean {
      const loaded = this.SubscriptionConfig != undefined;
      return loaded;
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    SubscriptionDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.SubscriptionConfig?.duration,
        this.SubscriptionConfig?.durationUnit
      );
    },
  },
  methods: {
    subscriptionQuote() {
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == this.getUsernamePK &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          duration: this.SubscriptionConfig.duration,
          durationUnit: this.SubscriptionConfig.durationUnit,
          username: this.getUsernamePK,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
  },
});
