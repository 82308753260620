
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EventForm from "@/components/events/admin/EventForm.vue";
import EventTicketOptions from "@/components/events/admin/EventTicketOptions.vue";
import { Event } from "@/store/Events/EventsInterfaces";

export default defineComponent({
  name: "BuyTicketsNewEvent",
  data() {
    return {
      eventLoading: true,
      currentEvent: null as Event | null,
    };
  },
  components: {
    EventForm,
    EventTicketOptions,
  },
  computed: {
    ...mapGetters("events", ["getEvent"]),
  },
  methods: {
    updateEvent(event) {
      this.currentEvent = {
        ...this.currentEvent,
        ...event,
      };
    },
    async getData() {
      this.eventLoading = true;
      const event = await this.$store.dispatch(
        "events/getEventById",
        this.$route.params.id
      );
      this.currentEvent = event;
      this.eventLoading = false;
    },
    savePaymentOptions(payment_options, modal, component) {
      const save = this.$store.dispatch("events/createPaymentOption", {
        eventId: this.currentEvent?.id,
        data: payment_options,
      });

      save
        .then(() => {
          this.getData();
          component.resetData();
          if (modal) {
            const element = document.querySelector(
              `#${modal.uniqueId} .btn-close`
            );
            if (element instanceof HTMLElement) element.click();
          }
        })
        .catch((err) => console.error(err));
    },
  },
  async mounted() {
    await this.getData();
  },
});
