
import { AccountSummary } from "@/store/Account/AccountInterface";
import {
  initializeTree,
  searchUser,
  setRootUsername,
} from "@/store/UserNetwork/UserNetworkInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import BinaryNode from "../components/BinaryNode.vue";

export default defineComponent({
  components: { BinaryNode },
  name: "binary-network",
  data() {
    return {
      leftLink: "",
      rightLink: "",
      searchUsernameString: "",
      loading: true,
      invalidUser: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUserProfile"]),
    ...mapGetters("userNetwork", {
      rootUserNode: "getRootUserNode",
    }),
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("account", ["getAccountSummary"]),
    loggedUserEnroller(): string {
      return this.getUserProfile.enroller;
    },
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    linkBase(): string {
      const hrefArray = window.location.href.split("/");
      return hrefArray[0] + "//" + hrefArray[2] + "/registro/";
    },
    totalRightPoints(): number {
      return (
        this.rootUserNode?.points.right.pending +
          this.rootUserNode?.points.right.paid || 0
      );
    },
    totalLeftPoints(): number {
      return (
        this.rootUserNode?.points.left.pending +
          this.rootUserNode?.points.left.paid || 0
      );
    },
    totalPoints(): number {
      return this.totalRightPoints + this.totalLeftPoints || 0;
    },
    binarySide(): string {
      return this.accountSummary?.binarySide
        ? this.accountSummary?.binarySide
        : "";
    },
    innerLegUnlocked(): boolean {
      if (this.accountSummary?.innerLegUnlocked == undefined) {
        return true;
      }
      return this.accountSummary?.innerLegUnlocked
        ? this.accountSummary?.innerLegUnlocked
        : false;
    },
  },
  methods: {
    async initializeTree() {
      this.loading = true;
      await this.$store
        .dispatch("userNetwork/" + initializeTree)
        .finally(() => {
          this.loading = false;
        });
    },
    async changeTreeRoot(newUsername: string) {
      if (newUsername != undefined) {
        this.loading = true;
        await this.$store
          .dispatch("userNetwork/" + setRootUsername, newUsername)
          .catch((error) => {
            if (
              error.name &&
              (error.name == "USER_NOT_FOUND" ||
                error.name == "USER_NOT_AUTHORIZED")
            ) {
              this.invalidUser = true;
            } else {
              alert(error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    copyToClipboard(side: string) {
      const el = document.getElementById(
        "referralUrl_" + side
      ) as HTMLInputElement;
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
    sideUnlocked(btnSide: "right" | "left"): boolean {
      if (
        btnSide == "left" &&
        this.binarySide == "right" &&
        this.innerLegUnlocked == false
      ) {
        return false;
      } else if (
        btnSide == "right" &&
        this.binarySide == "left" &&
        this.innerLegUnlocked == false
      ) {
        return false;
      }
      return true;
    },
    async searchUser() {
      this.loading = true;
      await this.$store
        .dispatch(
          "userNetwork/" + searchUser,
          this.searchUsernameString.toLowerCase()
        )
        .catch((error) => {
          if (
            error.name &&
            (error.name == "USER_NOT_FOUND" ||
              error.name == "USER_NOT_AUTHORIZED")
          ) {
            this.invalidUser = true;
          } else {
            alert(error);
          }
        });
      this.loading = false;
    },
  },
  async mounted() {
    if (this.rootUserNode == "" || this.rootUserNode == undefined) {
      await this.initializeTree();
    } else {
      this.loading = false;
    }
    this.leftLink = this.linkBase + this.rootUserNode.username + "/izq";
    this.rightLink = this.linkBase + this.rootUserNode.username + "/der";
  },
  watch: {
    searchUsernameString() {
      this.invalidUser = false;
    },
  },
});
