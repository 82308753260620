
import { defineComponent } from "vue";
import { PoweredBy } from "./";
import { mapState, mapGetters } from "vuex";
import { MenuItemElements } from "@/store/System/SystemConfigInterfaces";
import * as bs from "bootstrap";

export default defineComponent({
  name: "dashboard-sidebar",
  components: { PoweredBy },
  inject: {
    menus: { from: "menus-definition" },
  },
  data() {
    return {
      menuDiv: {} as any,
    };
  },
  computed: {
    ...mapState("sys", ["UserMenu"]),
    ...mapGetters("auth", ["getUserPermissions"]),
    ...mapGetters("auth", ["getUserRole"]),
    ...mapGetters("subs", ["getUserSubscription"]),
    menu(): Array<MenuItemElements> {
      const menu = this.UserMenu.filter((item: any) =>
        this.getUserPermissions.includes(item.Key)
      );
      return menu;
    },
  },
  methods: {
    hideMenu() {
      this.menuDiv.hide();
    },
  },
  mounted() {
    const menuDiv = document.getElementById("main-menu");
    this.menuDiv = new bs.Collapse(menuDiv, {
      toggle: false,
    });
  },
});
