// Actions
export const doGetGtrPoints = "doGetGtrPoints";
export const unsubscribeListener = "unsubscribeListener";
export const getSummary = "getSummary";

// Mutations
export const UPDATE_USER_GTR_POINTS_SUMMARY = "UPDATE_USER_GTR_POINTS_SUMMARY";
export const ADD_USER_GTR_POINTS = "ADD_USER_GTR_POINTS";
export const UPDATE_USER_GTR_POINTS = "UPDATE_USER_GTR_POINTS";
export const DELETE_USER_GTR_POINTS = "DELETE_USER_GTR_POINTS";
export const CLEAR_USER_GTR_POINTS = "CLEAR_USER_GTR_POINTS";
export const SET_LISTENER = "SET_LISTENER";
export const CLEAR_LISTENER = "CLEAR_LISTENER";

export interface GtrPointsTypeConfig {
  code: string;
  label: string;
  icon: string;
}

export interface GtrPointsState {
  GtrPointsTypes: Map<string, GtrPointsTypeConfig>;
  gtrPointsRecords: Array<GtrPointsRecord>;
  summary: GtrPointsSummary;
}

export interface GtrPointsRecord {
  _id: number;
  _usernamePK: string;
  amount: number;
  date?: Date;
  source: string;
  type: "earned" | "expended";
}

export interface GtrPointsSummary {
  _id: 0;
  _usernamePK: string;
  total: number;
  totalUpdatedDate: Date | undefined;
  earned: number;
  earnedUpdatedDate: Date | undefined;
  expended: number;
  expendedUpdatedDate: Date | undefined;
}

export class GtrPointsSummary {
  constructor() {
    this._id = 0;
    this.total = 0;
    this.totalUpdatedDate = undefined;
    this.earned = 0;
    this.earnedUpdatedDate = undefined;
    this.expended = 0;
    this.expendedUpdatedDate = undefined;
  }
}

export interface SetListenerRequest {
  listenerId: "gtrPoints" | "summary" | undefined;
  gtrPoints(): void;
  summary(): void;
}
