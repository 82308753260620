import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-md mb-4" }
const _hoisted_2 = { class: "row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-4 mb-4 justify-content-center" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gtr_points_summary = _resolveComponent("gtr-points-summary")!
  const _component_gtr_points_detail = _resolveComponent("gtr-points-detail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_gtr_points_summary, { summary: _ctx.summary }, null, 8, ["summary"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_gtr_points_detail, { gtrpoints: _ctx.gtrPoints }, null, 8, ["gtrpoints"])
    ])
  ]))
}