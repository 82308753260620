// export const  = "";
// Actions

import {
  Quote as FunctionsQuote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";

// export const action = "action";
export const doGetPayments = "doGetPayments";
export const doGetQuotes = "doGetQuotes";
export const unsubscribeListener = "unsubscribeListener";

// Mutations
// export const MUTATION_EXAMPLE = "MUTATION_EXAMPLE";
export const ADD_USER_PAYMENT = "ADD_USER_PAYMENT";
export const UPDATE_USER_PAYMENT = "UPDATE_USER_PAYMENT";
export const DELETE_USER_PAYMENT = "DELETE_USER_PAYMENT";
export const CLEAR_USER_PAYMENTS = "CLEAR_USER_PAYMENTS";

export const ADD_USER_QUOTE = "ADD_USER_QUOTE";
export const UPDATE_USER_QUOTE = "UPDATE_USER_QUOTE";
export const DELETE_USER_QUOTE = "DELETE_USER_QUOTE";
export const CLEAR_USER_QUOTES = "CLEAR_USER_QUOTES";

export const SET_LISTENER = "SET_LISTENER";
export const CLEAR_LISTENER = "CLEAR_LISTENER";

export interface PaymentsState {
  payments: Array<Payment>;
  quotes: Array<Quote>;
}

export interface Payment {
  _id: number;
  date: Date;
  concept: string;
  hash: string;
  originalAmount: string;
  originalCurrency: string;
  paidAmount: string;
  paidCurrency: string;
  status: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Quote extends FunctionsQuote<QuoteRequestType> {}
export interface SetListenerRequest {
  listenerId: "payments";
  listener: any;
}
