
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { RankStatus } from "@/store/Auth/AuthInterfaces";
import { Rank } from "@/store/Ranks/RanksInterfaces";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "ranks-card",
  props: {
    displayedRank: {
      type: String,
      default: "current",
    },
    cardTitle: {
      type: String,
      default: "Plan de Carrera",
    },
    userRanksCarrerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ranksData: new Map<string, Rank>(),
      displayedRankId: "INITIAL",
    };
  },
  methods: {
    isDisplayedRank(rankID: string) {
      return rankID == this.displayedRankId;
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
    isRank(rankID: string, status: string): boolean {
      if (this.ranksCarrerData instanceof Map) {
        const r: RankStatus = this.ranksCarrerData.get(rankID) as RankStatus;
        return (r || false) && r.status == status;
      } else {
        return false;
      }
    },
    isCurrentRank(rankID: string) {
      return this.isRank(rankID, "current");
    },
    isNextRank(rankID: string) {
      const r = this.isRank(rankID, "next");
      return r;
    },
    isRankLocked(rankID: string) {
      return this.isRank(rankID, "locked");
    },
    isRankUnlocked(rankID: string) {
      return this.isRank(rankID, "unlocked");
    },
    rankDate(rankID: string) {
      if (this.ranksCarrerData instanceof Map) {
        const _rankCarrerData: RankStatus = this.ranksCarrerData.get(
          rankID
        ) as RankStatus;
        return _rankCarrerData?.date;
      }
      return undefined;
    },
    setRanks() {
      this.ranksData = this.allRanks();
    },
    clearRanks() {
      this.ranksData = new Map<string, Rank>();
    },
  },
  computed: {
    ...mapGetters("ranks", ["rankById", "allRanks"]),
    // displayedRankId() {

    // },
    ranksCarrerData() {
      // this.clearRanks();
      // this.setRanks();
      const ranks = this.ranksData; //this.allRanks() as Map<string, Rank>;
      const ranksCarrerData = new Map<string, RankStatus>();
      const carrerData = this.userRanksCarrerData;
      let previousStatus = "";
      ranks.forEach((r, k) => {
        let c = carrerData[k];
        if (c == undefined) {
          c = {
            _id: k,
            progress: 0,
            status: previousStatus == "current" ? "next" : "locked",
            priority: 0,
          };
        }
        const rankStatus: RankStatus = {
          _id: k,
          priority: r.priority,
          progress: c.progress,
          status: c.status,
          date: c.date ? c.date?.toDate() : undefined,
        };
        ranksCarrerData.set(k, rankStatus);
        previousStatus = rankStatus.status;
      });
      return ranksCarrerData;
    },
    currentRank(): RankStatus | undefined {
      if (this.ranksCarrerData instanceof Map) {
        for (const rank of this.ranksCarrerData.values()) {
          if (rank.status === "current") {
            return rank;
          }
        }
      }
      return undefined;
    },
    nextRank(): RankStatus | undefined {
      if (this.ranksCarrerData instanceof Map) {
        for (const rank of this.ranksCarrerData.values()) {
          if (rank.status === "next") {
            return rank;
          }
        }
      }
      return undefined;
    },
  },
  mounted() {
    this.setRanks();
    let rankObj;
    switch (this.displayedRank.toLowerCase()) {
      case "next":
        rankObj = this.nextRank;
        break;
      case "current":
        rankObj = this.currentRank;
        break;
      default:
        rankObj = this.rankById(this.displayedRank);
    }
    rankObj = rankObj != undefined ? rankObj : this.currentRank;
    this.displayedRankId = rankObj ? rankObj._id : "INITIAL";
  },
});
