
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { doGetActiveEvents } from "@/store/Events/EventsInterfaces";
import EventCard from "@/components/events/EventCard.vue";
import MultipleCardPlaceholders from "@/components/events/MultipleCardPlaceholders.vue";

export default defineComponent({
  name: "BuyTicketsAdmin",
  components: {
    EventCard,
    MultipleCardPlaceholders,
  },
  computed: {
    ...mapState("events", {
      events: "events",
      loading: "eventsLoading",
      loaded: "eventsLoaded",
    }),
  },
  methods: {
    getEvents() {
      this.$store.dispatch("events/" + doGetActiveEvents);
    },
    goNewEvent() {
      this.$router.push({
        name: "buy-tickets-admin-new-event",
      });
    },
    goArchiveEvents() {
      this.$router.push({
        name: "buy-tickets-admin-archive",
      });
    },
  },
  mounted() {
    this.getEvents();
  },
});
