/* eslint-disable @typescript-eslint/no-non-null-assertion */
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ActionContext } from "vuex";
import * as fb from "../../firebase";
import { DownlineUser } from "../Auth/AuthInterfaces";
//import router from "../../router";
import {
  ADD_MEMBER_TO_TEAM,
  CHANGE_TEAM_LEADER,
  doGetLeaderData,
  doGetTeam,
  setLeaderUsername,
  UnilevelMember,
  UnilevelState,
  UnilevelTeam,
} from "./UnilevelInterfaces";

const initialState: UnilevelState = {
  currentLeader: "",
  teamMap: new Map<string, UnilevelTeam>(),
};
const state: UnilevelState = { ...initialState };

const mutations = {
  ADD_MEMBER_TO_TEAM(
    state: UnilevelState,
    payload: {
      leader: UnilevelMember;
      side: "left" | "right";
      member: UnilevelMember;
    }
  ) {
    const teamLeaderNode = state.teamMap.get(payload.leader.usernamePK);
    if (teamLeaderNode != undefined) {
      const newTeam: UnilevelTeam = {
        leader: payload.member,
        leftTeam: new Array<UnilevelTeam>(),
        rightTeam: new Array<UnilevelTeam>(),
      };
      if (payload.side == "left") {
        if (
          !teamLeaderNode.leftTeam.find((u) => {
            return u.leader.usernamePK == payload.member.usernamePK;
          })
        ) {
          teamLeaderNode.leftTeam.push(newTeam);
        }
      } else if (payload.side == "right") {
        if (
          !teamLeaderNode.rightTeam.find((u) => {
            return u.leader.usernamePK == payload.member.usernamePK;
          })
        ) {
          teamLeaderNode.rightTeam.push(newTeam);
        }
      }
      if (
        state.teamMap.get(newTeam.leader.username.toLowerCase()) == undefined
      ) {
        state.teamMap.set(newTeam.leader.username.toLowerCase(), newTeam);
      }
    }
  },
  CHANGE_TEAM_LEADER(state: UnilevelState, newLeaderUsername: string) {
    state.currentLeader = newLeaderUsername;
  },
  CLEAR_STATE() {
    state.currentLeader = initialState.currentLeader;
    state.teamMap = new Map<string, UnilevelTeam>();
  },
};

const actions = {
  async initializeTeam(context: ActionContext<UnilevelState, any>) {
    const loggedUsername = context.rootState.auth.userProfileData._usernamePK;
    const team = context.getters.getTeam(loggedUsername);
    if (team == undefined) {
      await context.dispatch(doGetLeaderData, { username: loggedUsername });
    }
    context.commit(CHANGE_TEAM_LEADER, loggedUsername);
  },
  doUpdateLeaderData(
    context: ActionContext<UnilevelState, any>,
    username: string
  ) {
    return context.dispatch(doGetLeaderData, {
      username: username,
      forceUpdate: true,
    });
  },
  async doGetLeaderData(
    context: ActionContext<UnilevelState, any>,
    p: { username: string; forceUpdate: boolean } = {
      username: "",
      forceUpdate: false,
    }
  ) {
    const { username, forceUpdate } = p;
    const _usernamePK = username.toLowerCase();
    const team = context.getters.getTeam(_usernamePK);
    const members = team?.leftTeam.length + team?.rightTeam.length;
    if (!forceUpdate && team != undefined && members > 0) {
      context.commit(CHANGE_TEAM_LEADER, _usernamePK);
      return;
    }
    const getLeaderData = fb.func.httpsCallable("Unilevel-getLeaderData");
    const result: UnilevelMember | undefined = await getLeaderData({
      username: _usernamePK,
    }).then((result) => {
      if (result.data.OPCODE == "SUCCESS") {
        const memberData = result.data.data;
        for (const rankData in memberData.ranksCarrerData) {
          if (memberData.ranksCarrerData[rankData].date) {
            const timestamp = new firebase.firestore.Timestamp(
              memberData.ranksCarrerData[rankData].date._seconds,
              memberData.ranksCarrerData[rankData].date._nanoseconds
            );
            memberData.ranksCarrerData[rankData].date = timestamp;
          }
        }
        memberData.subscriptions = new Map(
          Object.entries(memberData.subscriptions)
        );

        if (memberData.scholarshipData != undefined) {
          memberData.scholarshipData.periodEndDate =
            new firebase.firestore.Timestamp(
              memberData.scholarshipData.periodEndDate._seconds,
              memberData.scholarshipData.periodEndDate._nanoseconds
            ).toDate();
        }

        return memberData as UnilevelMember;
      } else {
        return undefined;
      }
    });
    if (result != undefined) {
      if (forceUpdate || !state.teamMap.has(result.usernamePK)) {
        const teamLeaderNode = {
          leader: result,
          leftTeam: new Array<UnilevelTeam>(),
          rightTeam: new Array<UnilevelTeam>(),
        };
        state.teamMap.set(result.usernamePK, teamLeaderNode);
      }

      context.commit(CHANGE_TEAM_LEADER, result.usernamePK);
      await context.dispatch(doGetTeam, result);
    } else {
      context.commit(CHANGE_TEAM_LEADER, "@@USER_NOT_FOUND@@");
    }
  },
  async doGetTeam(
    context: ActionContext<UnilevelState, any>,
    leader: UnilevelMember
  ) {
    const _usernamePK = leader.usernamePK;

    const getEnrolledUsers = fb.func.httpsCallable("Users-getEnrolledUsers");
    const getLeaderData = fb.func.httpsCallable("Unilevel-getLeaderData");

    const downlineUsers: Array<DownlineUser> = await getEnrolledUsers({
      username: _usernamePK,
    }).then((result) => {
      if (result.data.OPCODE == "SUCCESS") {
        const users = result.data.data;
        return users;
      } else {
        return new Array<DownlineUser>();
      }
    });

    //downlineUsers.forEach((user) => {
    for (const user of downlineUsers) {
      getLeaderData({ username: user.username }).then((result) => {
        if (result.data.OPCODE == "SUCCESS") {
          const memberData = result.data.data;
          for (const rankData in memberData.ranksCarrerData) {
            if (memberData.ranksCarrerData[rankData].date) {
              const timestamp = new firebase.firestore.Timestamp(
                memberData.ranksCarrerData[rankData].date._seconds,
                memberData.ranksCarrerData[rankData].date._nanoseconds
              );
              memberData.ranksCarrerData[rankData].date = timestamp;
            }
          }
          memberData.subscriptions = new Map(
            Object.entries(memberData.subscriptions)
          );
          if (memberData.scholarshipData != undefined) {
            memberData.scholarshipData.periodEndDate =
              new firebase.firestore.Timestamp(
                memberData.scholarshipData.periodEndDate._seconds,
                memberData.scholarshipData.periodEndDate._nanoseconds
              ).toDate();
          }

          context.commit(ADD_MEMBER_TO_TEAM, {
            leader: leader,
            side: user.binarySide,
            member: memberData,
          });
        }
      });
    }
  },
  async setLeaderUsername(
    context: ActionContext<UnilevelState, any>,
    newLeaderUsername: string
  ) {
    const loggedUsername = context.rootState.auth.userProfileData._usernamePK;
    newLeaderUsername =
      newLeaderUsername == "" || newLeaderUsername == undefined
        ? loggedUsername
        : newLeaderUsername.toLocaleLowerCase();
    await context.dispatch(doGetLeaderData, { username: newLeaderUsername });
  },
  async searchUser(
    context: ActionContext<UnilevelState, any>,
    username: string
  ) {
    username = username.trim().toLowerCase();
    const team = context.getters.getTeam(username);
    if (team) {
      context.dispatch(setLeaderUsername, username);
    } else {
      await context.dispatch(doGetLeaderData, { username: username });
      // context.dispatch(setLeaderUsername, username);
    }
  },
};

const getters = {
  getTeam(state: UnilevelState, getters: any, rootState: any) {
    return (username?: string): UnilevelTeam | undefined => {
      username =
        username?.toLowerCase() || rootState.auth.userProfileData._usernamePK;
      if (username) {
        const team = state.teamMap.get(username);
        if (team) {
          return team;
        }
      }
    };
  },
  getCurrentTeam(state: UnilevelState): UnilevelTeam | undefined {
    return state.teamMap.get(state.currentLeader);
  },
  getCurrentLeader(state: UnilevelState): string | undefined {
    return state.currentLeader;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
