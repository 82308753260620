
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import { UnilevelMember } from "@/store/Unilevel/UnilevelInterfaces";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "carrer-summary",
  props: {
    userData: { required: true, type: Object as () => UnilevelMember },
    displayFooter: { required: false, type: Boolean, default: true },
  },
  computed: {
    ...mapGetters("ranks", ["rankName", "rankIconClass", "rankById"]),
    ...mapGetters("auth", ["nextRank", "currentRank"]),
    userRank(): Rank {
      return this.rankById(this.userData.currentRank);
    },
    userNextRank(): Rank {
      const nextRank = this.rankById(this.userData.nextRank);
      return nextRank;
    },
    invoicedTotalString(): string {
      return this.userData.invoicedTotal.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    nextRankPoints(): string {
      if (this.userNextRank?.requiredPoints) {
        return this.userNextRank?.requiredPoints.toLocaleString();
      } else {
        return "0";
      }
    },
    nextRankEarning(): string {
      if (this.userNextRank?.requiredEarning) {
        return this.userNextRank?.requiredEarning.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      } else {
        return "0.00";
      }
    },
    totalValidPoints(): number {
      return this.userData.points.valid.left + this.userData.points.valid.right;
    },
    maxTeamPoints(): string {
      return (this.userNextRank?.maxPointsPerTeam * 1).toLocaleString();
    },
    maxAffiliatePoints(): string {
      return (this.userNextRank?.maxPointsPerAffiliate * 1).toLocaleString();
    },
    maxTeamPercentage(): number {
      return (
        (this.userNextRank?.maxPointsPerTeam * 100) /
        (this.userNextRank?.requiredPoints * 1)
      );
    },
    maxAffiliatePercentage(): number {
      return (
        (this.userNextRank?.maxPointsPerAffiliate * 100) /
        (this.userNextRank?.requiredPoints * 1)
      );
    },
    academyDaysLeft(): number | undefined {
      const days = this.userData.subscriptions.get("trading-academy");
      return days;
    },
    distributorDaysLeft(): number | undefined {
      const days = this.userData.subscriptions.get("distributor-license");
      return days;
    },
    gtrLiteDaysLeft(): number | undefined {
      const days = this.userData.subscriptions.get("gtr-lite");
      return days;
    },
    liteDistributorDaysLeft(): number | undefined {
      const days = this.userData.subscriptions.get("lite-distributor-license");
      return days;
    },
    currentUserPackage(): string {
      return this.userData.subscriptionPackage.package.packageId;
    },
    hasMMIColombia(): boolean {
      const days = this.userData.subscriptions.get("mmi-colombia");
      return days ? true : false;
    },
    scholarshipEndDate(): string {
      return this.userData.scholarshipData?.periodEndDate &&
        this.userData.scholarshipData?.periodEndDate.getTime() > 0
        ? this.formatDateTimezone(
            this.userData.scholarshipData?.periodEndDate.getTime()
          )
        : "-";
    },
  },
  methods: {
    scholarshipPointsClass(points: number) {
      const classes = "fa-lg ms-2";
      if (
        this.userData.scholarshipData &&
        this.userData.scholarshipData.periodPoints >= points
      ) {
        return "fas fa-user text-orange " + classes;
      } else {
        return "far fa-user text-light-gray " + classes;
      }
    },
    daysLeftClass(daysLeft: number): string {
      if (daysLeft <= 5) {
        return "bg-danger";
      } else if (daysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
  },
});
