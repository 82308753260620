
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import * as fb from "../../firebase";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
  UpdateSubscriptionNoVolumeRequest,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { nombres } from "@/utils/nombres";
import { AccountSummary } from "@/store/Account/AccountInterface";
import {
  EmailValidationResponse,
  UsernameValidationResponse,
  validationTools,
} from "@/mixins/validationTools";
import { generateDateKey } from "@/mixins/tools";

export default defineComponent({
  name: "subscription-register-card",
  props: {
    // subscriptionId: { type: Object as () => subscriptionType, required: true },
    lastQuote: { type: Object, required: true },
    isPaid: { type: Boolean, required: true },
  },
  emits: ["clearQuote", "requestQuote", "paid"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", [
      "getSubscriptionConfig",
      "getUserSubscription",
      "durationUnitLocalized",
    ]),
    ...mapGetters("account", ["getPromotions", "getAccountSummary"]),
    ...mapGetters("sys", ["isOffline"]),
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    promotions() {
      return this.getPromotions;
    },
    SubscriptionDurationUnitLocalized(): string {
      return this.durationUnitLocalized(this.SubscriptionConfig.duration);
    },
    PeriodsStep(): number {
      return this.SubscriptionConfig?.duration || 0;
    },
    Total(): string {
      const total = this.SubscriptionConfig.cost;
      return total.toLocaleString();
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    daysLeftClass(): string {
      if (this.directAffiliateSubsPeriod <= 5) {
        return "bg-danger";
      } else if (this.directAffiliateSubsPeriod <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    ExpirationDurationUnitLocalized(): string {
      return this.durationUnitLocalized(this.directAffiliateSubsPeriod);
    },
    isRegistering(): boolean {
      return this.registering;
    },
    isPaying(): boolean {
      return this.paying;
    },
    innerLegUnlocked(): boolean {
      if (this.accountSummary?.innerLegUnlocked == undefined) {
        return true;
      }
      return this.accountSummary?.innerLegUnlocked
        ? this.accountSummary?.innerLegUnlocked
        : false;
    },
  },
  data() {
    return {
      devHelpersHidden: !(
        process.env.VUE_APP_DEV_TESTING_HELPERS_VISIBLE === "true"
      ),
      emailTimer: undefined as NodeJS.Timeout | undefined,
      usernameTimer: undefined as NodeJS.Timeout | undefined,
      emailValidation: new EmailValidationResponse(),
      usernameValidation: new UsernameValidationResponse(),
      registrationForm: {
        email: "",
        username: "",
        password: "a123456789",
        passwordConfirm: "a123456789",
        enroller: "",
        acceptTerms: true,
        picture: "",
      },
      subscriptionId: "gtr-vip" as subscriptionType,
      binarySide: "left",
      emailOK: false,
      usernameOK: false,
      usernameAvailable: true,
      invalidUsername: false,
      emailMismatch: false,
      isRegistered: false,
      periodsToAdd: 1,
      directAffiliateSubsPeriod: 0,
      registering: false,
      paying: false,
    };
  },
  methods: {
    doRegister() {
      this.registering = true;
      const userData = {
        email: this.registrationForm.email,
        username: this.registrationForm.username,
        password: this.registrationForm.password,
        passwordConfirm: this.registrationForm.passwordConfirm,
        enroller: this.getUsernamePK,
        binarySide: this.binarySide,
        pictureURL: "",
      };

      this.$store
        .dispatch("auth/register", userData)
        .then(() => {
          this.$store
            .dispatch("auth/validateEmail", this.registrationForm.email)
            .then((response) => {
              if (response.data.OPCODE == "SUCCESS") {
                this.isRegistered = true;
              }
              this.registering = false;
            })
            .catch((error) => {
              console.error(error);
              this.registering = false;
            });
        })
        .catch((error) => {
          alert("Error: " + error.message);
          this.registering = false;
        });
    },
    goToRegister() {
      this.isRegistered = false;
      this.periodsToAdd = 1;
      this.directAffiliateSubsPeriod = 0;
      this.registrationForm.email = "";
      this.registrationForm.username = "";
    },
    invalidFields(): boolean {
      return (
        this.invalidUsername ||
        !this.usernameAvailable ||
        !this.getUsernamePK ||
        !this.registrationForm.username ||
        !this.registrationForm.email ||
        this.emailValidation.hasError
      );
    },
    validateEmailInput() {
      this.emailOK = false;
      this.updateUsername();
      if (this.emailTimer !== undefined) {
        clearTimeout(this.emailTimer);
      }
      if (this.registrationForm.email.length === 0) {
        this.emailValidation = new EmailValidationResponse();
        this.emailValidation.hasError = true;
        this.emailValidation.userMessage = "El correo es requerido";
        return;
      }

      this.emailTimer = setTimeout(async () => {
        this.emailValidation = this.validateEmail(this.registrationForm.email);
        this.validateUsernameInput();
        if (
          this.emailValidation.status === "SUCCESS" ||
          this.emailValidation.status === "WARNING"
        ) {
          if (!(await this.isEmailAvailable())) {
            this.emailValidation.hasWarning = false;
            this.emailValidation.canChange = false;
            this.emailValidation.hasError = true;
            this.emailValidation.userMessage =
              "El correo electrónico ya ha sido registrado.";
          }
          this.emailOK = true;
        }
        clearTimeout(this.emailTimer as NodeJS.Timeout);
        this.emailTimer = undefined;
      }, 500);
    },
    updateUsername() {
      // const prevUsername = this.registrationForm.username;
      const newValue = this.registrationForm.email.split("@")[0];
      // if (
      // prevUsername.length === 0
      // ||
      // (newValue.startsWith(prevUsername) && prevUsername.length !== 0) ||
      // (prevUsername.startsWith(newValue) && (newValue.length !== 0 || prevUsername.length === 1))
      // ) {
      this.registrationForm.username = newValue;
      // }
    },
    async isEmailAvailable(): Promise<boolean> {
      return await this.$store
        .dispatch("auth/GetEmailAvailability", this.registrationForm.email)
        .then((response) => {
          if (response.OPCODE == "UNAVAILABLE") {
            return false;
          } else if (response.OPCODE == "SUCCESS") {
            return true;
          } else {
            throw Error("Can not validate email availability");
          }
        })
        .catch((error) => {
          alert("Error: " + error.message);
          throw error;
        });
    },
    validateUsernameInput() {
      this.invalidUsername = false;
      this.usernameOK = false;

      if (this.usernameTimer !== undefined) {
        clearTimeout(this.usernameTimer);
      }

      if (this.registrationForm.username.length === 0) {
        return;
      }

      this.usernameTimer = setTimeout(async () => {
        this.usernameValidation = await this.validateUsername(
          this.registrationForm.username
        );
        this.invalidUsername = this.usernameValidation.hasError;
        this.usernameAvailable =
          this.usernameValidation.isAvailable !== undefined
            ? this.usernameValidation.isAvailable
            : this.usernameAvailable;
        this.usernameOK = !this.invalidUsername && this.usernameAvailable;
        clearTimeout(this.usernameTimer as NodeJS.Timeout);
        this.usernameTimer = undefined;
      }, 500);
    },
    onChange(event: any) {
      const data = event.target.value;
      this.binarySide = data;
    },
    subscriptionQuote() {
      const affiliateUsername = this.registrationForm.username.toLowerCase();
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == affiliateUsername &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          duration: this.SubscriptionConfig.duration,
          durationUnit: this.SubscriptionConfig.durationUnit,
          username: affiliateUsername,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
    generarUsuario() {
      const randomUser =
        nombres[Math.floor(Math.random() * nombres.length) + 1].toLowerCase() +
        ("00" + (Math.floor(Math.random() * 99) + 1)).substr(-2);
      this.registrationForm.email = randomUser + "@email.gtr.academy";
      this.registrationForm.username = randomUser;
      this.registrationForm.password = "a123456789";
      this.registrationForm.passwordConfirm = "a123456789";
    },
    registerWithNoVolume() {
      this.paying = true;
      const subscriptionRequest: UpdateSubscriptionNoVolumeRequest = {
        subscriptionId: "both",
        duration: 0,
        durationUnit: "days",
        username: this.registrationForm.username.toLowerCase(),
        email: this.registrationForm.email,
        isDirectAffiliate: false,
        noVolume: true,
        noVolumeRef: "noVolume@" + generateDateKey(),
      };
      const UpdateSubscription = fb.func.httpsCallable(
        "Subscriptions-UpdateSubscriptionWithoutVolume"
      );
      UpdateSubscription(subscriptionRequest)
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.goToRegister();
            this.$emit("paid");
            alert("Activado correctamente");
          } else if (response.data.OPCODE == "ERROR") {
            alert(response.data.message);
          }
        })
        .finally(() => {
          this.paying = false;
        });
    },
    innerLegLocked(): boolean {
      if (this.innerLegUnlocked || this.innerLegUnlocked == undefined) {
        return false;
      }
      return true;
    },
    setSubscription(subscription: subscriptionType) {
      this.subscriptionId = subscription;
    },
  },
  watch: {
    isPaid: function (newValue) {
      if (newValue == true) {
        this.goToRegister();
        this.$emit("paid");
      }
    },
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    const validateUsername = validationTools.methods.validateUsername;
    return {
      validateEmail,
      validateUsername,
    };
  },
});
