
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { tools } from "@/mixins/tools";
import MyTeamUsersSort from "./my-team-users-sort.vue";
import MyTeamNumericSort from "./my-team-numeric-sort.vue";
import MyTeamAlphaSort from "./my-team-alpha-sort .vue";

export default defineComponent({
  name: "my-team-table",
  components: {
    MyTeamUsersSort,
    MyTeamNumericSort,
    MyTeamAlphaSort,
  },
  emits: ["updateSortConfiguration"],
  computed: {
    ...mapGetters("ranks", ["rankName", "rankIconClass"]),
    ...mapGetters("myTeam", {
      isLoading: "getIsLoading",
      teamMembers: "getDisplayedTeam",
      getColumnSortDirection: "getColumnSortDirection",
    }),
  },
  methods: {
    binarySideLabel(binarySide: "left" | "right" | string) {
      switch (binarySide) {
        case "left":
          return "Izquierdo";
        case "right":
          return "Derecho";
        default:
          return "";
      }
    },
    binarySideIconClass(binarySide: "left" | "right" | string) {
      switch (binarySide) {
        case "left":
          return "fas fa-arrow-alt-circle-left text-teal fa-lg";
        case "right":
          return "fas fa-arrow-alt-circle-right text-purple fa-lg";
        default:
          return "";
      }
    },
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
    daysLeftClass(daysLeft: number): string {
      if (daysLeft <= 5) {
        return "bg-danger";
      } else if (daysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    showUserTeam(username: string) {
      this.$store.dispatch("myTeam/doGetTeamForUser", {
        username: username,
        forceUpdate: false,
      });
    },
    updateSortConfiguration(column: string, direction: "asc" | "desc") {
      this.$emit("updateSortConfiguration", column, direction);
    },
  },
});
