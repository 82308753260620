
import {
  doGetCommissions,
  unsubscribeListener,
} from "@/store/Commissions/CommissionsInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  LeaderBoardCard,
  RanksCard,
  RegistrationLinkCard,
  ImagesCarousel,
  VideosCarousel,
} from "../components";
import { CommissionsSummary } from "../components/commissions";
import {
  // AccountSummary,
  BinaryCard,
  ScholarshipCard,
  SubscriptionsCard,
  UnilevelCard,
} from "../components/account";
import { ImageModal } from "@/components/modals";
import { tools } from "@/mixins/tools";
import * as bs from "bootstrap";
import TicketHeader from "@/components/tickets/header.vue";
import firebase from "firebase/compat/app";

export default defineComponent({
  components: {
    LeaderBoardCard,
    RanksCard,
    RegistrationLinkCard,
    CommissionsSummary,
    // AccountSummary,
    VideosCarousel,
    ImagesCarousel,
    ImageModal,
    BinaryCard,
    ScholarshipCard,
    SubscriptionsCard,
    UnilevelCard,
    TicketHeader,
  },
  name: "Home",
  data() {
    return {
      loading: false,
      modalHandler: {
        show() {
          return;
        },
        hide() {
          return;
        },
      },
      imageModal: {} as any,
      imageModalId: "",
      ticketsInfo: {},
      event: null as any,
      unwatchCommissionsSummary: () => {
        return;
      },
    };
  },
  async mounted() {
    //this.$store.dispatch("loading", false);

    if (!this.emailVerified) {
      const element: HTMLElement = document.getElementById(
        "resendEmail"
      ) as HTMLElement;
      this.modalHandler = new bs.Modal(element);
      this.openModal();
    }
    if (this.config.modal.active) {
      const imageModalElement = document.getElementById(this.imageModalId);
      this.imageModal = bs.Modal.getOrCreateInstance(imageModalElement);
      this.imageModal.show();
    }
  },
  computed: {
    ...mapGetters("commissions", {
      summary: "getSummary",
    }),
    ...mapGetters("auth", {
      emailVerified: "getUserVerifiedStatus",
      email: "getUseremail",
      ranksCarrerData: "getRanksCarrerData",
      getUsernamePK: "getUsernamePK",
    }),
    ...mapGetters("sys", {
      config: "getHomeConfiguration",
    }),
  },
  methods: {
    async getTickets() {
      const ticketsPerUser = firebase
        .functions()
        .httpsCallable("Tickets-TicketsPerUser");

      const _ticketsRef = await ticketsPerUser();
      this.ticketsInfo = _ticketsRef.data;
      this.event = _ticketsRef.data.event;
    },
    requestCommissions() {
      this.$store.dispatch("commissions/" + doGetCommissions);
    },
    initializeAccountSummary(username: string) {
      this.$store
        .dispatch("account/initializeAccountSummary", username)
        .catch((error) => {
          console.error(error);
        });
    },
    resendEmail() {
      this.loading = true;
      this.$store
        .dispatch("auth/validateEmail", this.email)
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.loading = false;
            this.close();
          }
        })
        .catch((error) => {
          alert("Error: " + error.message);
          this.loading = false;
        });
    },
    close() {
      this.modalHandler.hide();
    },
    openModal() {
      this.modalHandler.show();
    },
  },
  async created() {
    this.imageModalId = "image-modal-" + this.generateShortGUID();
    this.requestCommissions();
    this.initializeAccountSummary(this.getUsernamePK);
    this.unwatchCommissionsSummary = this.$store.watch(
      (state) => state.commissions.summary,
      () => {
        return;
      },
      { deep: true }
    );
    // this.getTickets();
  },
  beforeUnmount() {
    this.unwatchCommissionsSummary();
    this.$store.dispatch("commissions/" + unsubscribeListener);
  },
  setup() {
    const generateShortGUID = tools.methods.generateShortGUID;
    return {
      generateShortGUID,
    };
  },
});
