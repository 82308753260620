
import { defineComponent } from "vue";

export default defineComponent({
  name: "my-team-numeric-sort",
  props: {
    direction: {
      type: String,
    },
    lowLabel: {
      type: String,
      default: "Menor a Mayor",
    },
    highLabel: {
      type: String,
      default: "Mayor a Menor",
    },
  },
  emits: ["order"],
  methods: {
    emitSort(order: "asc" | "desc") {
      this.$emit("order", order);
    },
  },
});
