
import { Leaderboard } from "@/store/Leaderboard/LeaderboardInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { tools } from "@/mixins/tools";
import * as bootstrap from "bootstrap";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "leader-board-card",
  data() {
    return {
      activeSlide: 0,
      id: "",
    };
  },
  computed: {
    ...mapGetters("ranks", ["rankName", "rankIconClass"]),
    ...mapGetters("leaderboard", ["getLeaderboards"]),
    leaderboards(): Leaderboard[] {
      return this.getLeaderboards;
    },
  },
  methods: {
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
  },
  created() {
    this.$store.dispatch("leaderboard/doGetLeaderboard");
    this.id = tools.methods.generateShortGUID();
  },
  mounted() {
    const myCarousel = document.querySelector(
      "#leaderboardCarousel-" + this.id
    );
    const carousel = new bootstrap.Carousel(myCarousel);
    carousel.cycle();
    if (myCarousel) {
      myCarousel.addEventListener("slide.bs.carousel", (par: any) => {
        this.activeSlide = par.to;
      });
    }
  },
});
