export class EmailValidationResponse {
  status: "SUCCESS" | "WARNING" | "ERROR" | "PROCESSING" = "PROCESSING";
  code: string | undefined;
  systemMessage: string = "";
  userMessage: string = "";
  hasError: boolean = false;
  hasWarning: boolean = false;
  canChange: boolean = false;
}

export class UsernameValidationResponse {
  status: "SUCCESS" | "WARNING" | "ERROR" | "PROCESSING" = "PROCESSING";
  code: string | undefined;
  systemMessage: string = "";
  userMessage: string = "";
  hasError: boolean = false;
  isAvailable: boolean | undefined = undefined;
}

export class PasswordValidationResponse {
  status: "SUCCESS" | "WARNING" | "ERROR" | "PROCESSING" = "PROCESSING";
  code: string | undefined;
  systemMessage: string = "";
  userMessage: string = "";
  hasError: boolean = false;
}

export function validateIsEmail(email: string): boolean {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (!email) {
    return false;
  }

  if (email.length > 254) {
    return false;
  }

  const valid = emailRegex.test(email);

  if (!valid) {
    return false;
  }

  // checking fo some things  regex can't handle
  const parts = email.split("@");
  if (parts[0].length > 64) {
    return false;
  }

  const domainParts = parts[1].split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  ) {
    return false;
  }

  return true;
}

export function validateEmail(email: string): EmailValidationResponse {
  // const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; <- Original
  // Allows for Ips
  const mailformat =
    /^([^<>()[\]\\.,;:\s@"À-ü]+(\.[^<>()[\]\\.,;:\s@"À-ü]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // .comm
  // .commm
  // .con
  // .concon
  // .comon
  // .com.com
  // .comom
  const commonDomainMistakes =
    /((\.(((c|x)o(m){2,})|((c|x)(om){2,})|((c|x)o|(c|x)on|(c|x)omon)+)|(\.(c|x)om){2,})|(xom))$/;

  const misspelledError = [
    "gamail",
    "gmai.",
    "gmal",
    "gmali",
    "gmmail",
    "gmqil",
    "gnail",
  ];

  const response = new EmailValidationResponse();
  if (!email.match(mailformat)) {
    response.hasError = true;
    response.status = "ERROR";
    response.code = "INVALID_ADDRESS";
    response.systemMessage = "Invalid email address";
    response.userMessage = "La dirección de correo electrónico no es válida.";
    response.canChange = false;
    return response;
  } else {
    const emailParts = email.split("@");
    if (emailParts[1].indexOf(".") < 0) {
      response.hasError = true;
      response.status = "ERROR";
      response.code = "MISSING_DOMAIN_POINT";
      response.systemMessage = "Invalid domain";
      response.userMessage =
        "El dominio de la dirección de correo electrónico no es válido.";
      response.canChange = false;
      return response;
    } else if (emailParts[1].match(commonDomainMistakes)) {
      response.hasWarning = true;
      response.status = "WARNING";
      response.code = "COMMON_DOMAIN_MISTAKE";
      response.systemMessage = "Common domain mistake";
      response.userMessage =
        "Verifique que el dominio esté correcto: " + emailParts[1];
      response.canChange = true;
      return response;
    } else {
      for (const error of misspelledError) {
        if (emailParts[1].indexOf(error) > -1) {
          response.hasWarning = true;
          response.status = "WARNING";
          response.code = "MISSPELLED_DOMAIN";
          response.systemMessage = "Misspelled domain";
          response.userMessage =
            "Verifique que el dominio esté correcto: " + emailParts[1];
          response.canChange = true;
          return response;
        }
      }
      response.status = "SUCCESS";
      response.code = "SUCCESS";
      response.systemMessage = "Valid email address";
      response.userMessage = "Correo válido.";
      response.canChange = true;
      return response;
    }
  }
}

export function validateUsername(username: string): UsernameValidationResponse {
  const response = new UsernameValidationResponse();
  const startsWithAN = /^[a-zA-Z0-9]/;
  const usernameFormat = /^[a-zA-Z0-9][a-zA-Z0-9._-]*$/;

  if (username.length === 0) {
    response.hasError = true;
    response.status = "ERROR";
    response.code = "EMPTY_USERNAME";
    response.systemMessage = "Empty username";
    response.userMessage = "El nombre de usuario no puede estar vacío.";
  } else if (username.length <= 3) {
    response.hasError = true;
    response.status = "ERROR";
    response.code = "USERNAME_TOO_SHORT";
    response.systemMessage = "Username too short";
    response.userMessage = "El nombre de usuario es demasiado corto.";
  }

  if (response.hasError === false) {
    if (username.match(startsWithAN) === null) {
      response.hasError = true;
      response.status = "ERROR";
      response.code = "USERNAME_START_WITH_ALPHA_NUMERIC";
      response.systemMessage = "Username starts with an alphanumeric character";
      response.userMessage =
        "El nombre de usuario debe empezar con un carácter alfanumérico.";
    } else if (username.match(usernameFormat) === null) {
      response.hasError = true;
      response.status = "ERROR";
      response.code = "INVALID_USERNAME";
      response.systemMessage = "Invalid username";
      response.userMessage =
        "El nombre de usuario solo puede contener letras, números, '.', '-' y '_'.";
    }
  }
  response.status =
    response.status == "PROCESSING" ? "SUCCESS" : response.status;
  return response;
}

export function validPassword(password: string) {
  const response = new PasswordValidationResponse();

  if (password.length > 0 && password.length < 6) {
    response.hasError = true;
    response.status = "ERROR";
    response.code = "PASSWORD_TOO_SHORT";
    response.systemMessage = "Password too short";
    response.userMessage = "La contraseña es demasiado corta.";
    return response;
  }

  response.hasError = false;
  response.status = "SUCCESS";
  response.code = "SUCCESS";
  response.systemMessage = "Valid password";
  response.userMessage = "Contraseña válida.";
  return response;
}
