
import { defineComponent } from "vue";
import { ProfilePermissions } from "@/store/System/SystemConfigInterfaces";
import { mapState } from "vuex";

export default defineComponent({
  name: "ProfilePermissionsConfiguration",
  computed: {
    ...mapState("sys", ["UserMenu"]),
  },
  data() {
    return {
      addMode: false,
      loading: false,
      profiles: Array<ProfilePermissions>(),
      newProfile: {
        profileId: "",
        data: Array<string>() || undefined,
      },
      currentProfile: { profileId: "", data: [""] },
    };
  },
  created() {
    this.$store
      .dispatch("sys/doGetAllProfiles")
      .then((response) => {
        this.profiles = response;
        this.currentProfile.profileId = response[0].profileId;
        this.changeProfile(this.currentProfile.profileId);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    cleanList() {
      this.UserMenu.forEach((item: any) => {
        const element = document.getElementById(item.Key) as HTMLInputElement;
        element.checked = false;
      });
    },
    changeProfile(profileId: string) {
      this.currentProfile.data = [""];

      const data = this.profiles.find((x) => x.profileId == profileId)?.data;
      if (data != undefined) {
        this.currentProfile.data = data;
      }
      this.UserMenu.forEach((item: any) => {
        const element = document.getElementById(item.Key) as HTMLInputElement;
        element.checked = this.isChecked(item.Key);
      });
    },
    isChecked(key: string) {
      return this.currentProfile.data.includes(key);
    },
    updatePermissions() {
      this.loading = true;
      const checked = [...document.querySelectorAll("input:checked")].map(
        (e) => e.id
      );
      const backup = this.currentProfile;
      this.currentProfile.data = checked;

      this.$store
        .dispatch("sys/updateProfile", this.currentProfile)
        .then((response) => {
          const profile = this.profiles.find(
            (x) => x.profileId == this.currentProfile.profileId
          );
          if (profile != undefined) {
            profile.data = this.currentProfile.data;
          }
          alert(response.message);
        })
        .catch((error) => {
          this.currentProfile = backup;
          alert("Error: " + error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createProfile() {
      this.addMode = true;
      this.newProfile.profileId = "";
      this.newProfile.data = [];
      this.cleanList();
    },
    saveProfile() {
      this.loading = true;
      const checked = [...document.querySelectorAll("input:checked")].map(
        (e) => e.id
      );

      this.newProfile.data = checked;

      this.$store
        .dispatch("sys/addProfile", this.newProfile)
        .then((response) => {
          this.profiles.push({
            profileId: this.newProfile.profileId,
            data: this.newProfile.data,
          });
          this.currentProfile.profileId = this.newProfile.profileId;

          this.loading = false;
          this.addMode = false;

          this.changeProfile(this.currentProfile.profileId);
          alert(response.message);
        })
        .catch((error) => {
          this.loading = false;
          alert("Error: " + error.message);
        });
    },
    cancel() {
      this.addMode = false;
      this.currentProfile.profileId = this.profiles[0].profileId;
      this.changeProfile(this.currentProfile.profileId);
    },
  },
});
