
import router from "@/router";
import { MMIUser } from "@/store/MMI/MMIInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MmiUserCard from "./UserCard.vue";
import MmiCurrentUserCard from "./CurrentUserCard.vue";
import { Searching, UserNotFound } from "@/components/search-users/";
import Register from "./Register.vue";
import VideosPlaylist from "./VideosPlaylist.vue";
import { SubscriptionInfo } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { VideoData } from "functions/src/MMI/MMIInterfaces";
import { ClassModal } from "../schedule";

export default defineComponent({
  name: "mmi-colombia",
  components: {
    Register,
    UserNotFound,
    Searching,
    MmiUserCard,
    MmiCurrentUserCard,
    VideosPlaylist,
    ClassModal,
  },
  data() {
    return {
      isActive: false,
      chapter: "colombia",
      loading: true,
      refreshing: false,
      displayedUser: "",
      searchUsername: "",
      searching: false,
      userNotFound: false,
      currentVideo: {} as VideoData,
    };
  },
  computed: {
    ...mapGetters("mmi", {
      getUserStructure: "getUserStructure",
      getChapterVideosPlaylist: "getChapterVideosPlaylist",
      getWatchedVideos: "getWatchedVideos",
    }),
    ...mapGetters("auth", {
      getUsernamePK: "getUsernamePK",
      getUserRole: "getUserRole",
    }),
    ...mapGetters("subs", ["getSubscriptionConfig", "getUserSubscription"]),
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig("mmi-colombia");
    },
    currentStructure(): MMIUser {
      return this.getUserStructure(this.chapter, this.displayedUser);
    },
    breakRowAt(): { 2: number; 3: number; 5: number } {
      return {
        2: this.currentStructure.users.length % 2,
        3: this.currentStructure.users.length % 3,
        5: this.currentStructure.users.length % 5,
      };
    },
    isRootNode(): boolean {
      return this.getUsernamePK === this.displayedUser;
    },
    displayUser(): boolean {
      return this.currentStructure && !this.userNotFound && !this.searching;
    },
    playlist(): Array<VideoData> {
      return this.getChapterVideosPlaylist(this.chapter);
    },
    watchedVideos(): Array<string> {
      return this.getWatchedVideos(this.chapter);
    },
    editPermision() {
      return this.$store.getters["auth/getUserRole"].some((e) =>
        ["master", "root"].includes(e)
      );
    },
  },
  methods: {
    async searchUser() {
      this.userNotFound = false;
      if (
        this.getUserStructure(this.chapter, this.searchUsername) === undefined
      ) {
        this.searching = true;
        await this.$store
          .dispatch("mmi/doGetUserStructure", {
            chapter: this.chapter,
            username: this.searchUsername,
          })
          .then(() => {
            router.push({
              path: "/mastermind-intensive/" + this.chapter,
              query: { _u: this.searchUsername },
            });
          })
          .catch((error) => {
            if (error instanceof Error) {
              if (
                error.name === "USER_NOT_FOUND" ||
                error.name === "USER_NOT_AUTHORIZED"
              ) {
                this.userNotFound = true;
              } else {
                alert(error.message);
              }
            }
          })
          .finally(() => {
            this.searching = false;
          });
      } else {
        router.push({
          path: "/mastermind-intensive/" + this.chapter,
          query: { _u: this.searchUsername },
        });
      }
    },
    goRoot() {
      this.userNotFound = false;
      router.push({
        path: "/mastermind-intensive/" + this.chapter,
        query: { _u: this.getUsernamePK },
      });
    },
    goUp() {
      this.userNotFound = false;
      router.push({
        path: "/mastermind-intensive/" + this.chapter,
        query: { _u: this.currentStructure.parent },
      });
    },
    async refresh() {
      this.refreshing = true;
      await this.$store
        .dispatch("mmi/doGetUserStructure", {
          chapter: this.chapter,
          username: this.displayedUser,
        })
        .catch((err) => {
          console.error(err);
          alert(err);
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    changeVideo(videoData: VideoData) {
      this.currentVideo = videoData;
    },
    addWatchedVideo(videoId: string) {
      this.$store.dispatch("mmi/addWatchedVideo", {
        chapter: this.chapter,
        videoId: videoId,
      });
    },
    removeWatchedVideo(videoId: string) {
      this.$store.dispatch("mmi/removeWatchedVideo", {
        chapter: this.chapter,
        videoId: videoId,
      });
    },
  },
  async mounted() {
    this.loading = true;
    if (this.$route.query._u === undefined) {
      this.goRoot();
    }
    this.currentVideo.url = this.SubscriptionConfig.videos
      ? this.SubscriptionConfig.videos.content
      : "";
    await this.$store.dispatch("mmi/doGetChapterVideosPlaylist", this.chapter);
    this.$store.dispatch("mmi/doGetWatchedVideos", this.chapter);
  },
  unmounted() {
    this.$store.dispatch("mmi/doClearWatchedVideosListener", this.chapter);
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name !== "mmi-colombia") {
          return;
        }
        this.loading = true;
        this.displayedUser = this.$route.query._u
          ? this.$route.query._u
          : this.getUsernamePK;
        if (this.currentStructure === undefined) {
          this.$store
            .dispatch("mmi/doGetUserStructure", {
              chapter: this.chapter,
              username: this.displayedUser,
            })
            .then(() => {
              this.isActive = true;
            })
            .catch((error) => {
              if (
                !(
                  error instanceof Error &&
                  (error.name === "USER_NOT_FOUND" ||
                    error.name === "USER_NOT_AUTHORIZED")
                )
              ) {
                console.error("Error: ", error);
                alert(error);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.isActive = true;
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
});
