
import { defineComponent } from "vue";

export default defineComponent({
  name: "my-team-alpha-sort",
  props: {
    direction: {
      type: String,
    },
    lowLabel: {
      type: String,
      default: "Ordenar A-Z",
    },
    highLabel: {
      type: String,
      default: "Ordenar Z-A",
    },
  },
  emits: ["order"],
  methods: {
    emitSort(order: "asc" | "desc") {
      this.$emit("order", order);
    },
  },
});
