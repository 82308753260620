
import "dayjs/locale/es";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Event, doGetActiveEvents } from "@/store/Events/EventsInterfaces";
import { images } from "@/mixins/images";
import LoaderSpinner from "@/components/generals/Loader.vue";
import MarkdownViewer from "@/components/markdown/MarkdownViewer.vue";
import _ from "lodash";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    LoaderSpinner,
    MarkdownViewer,
  },
  name: "BuyTickets",
  data() {
    return {
      event: null as Event | null,
      maxDetails: 600,
      detailsExpanded: false,
      image: null as string | null,
      isLoadingEvents: false,
    };
  },
  computed: {
    ...mapGetters("events", ["getActiveEvents", "getEvent"]),
    eventActive() {
      return (
        !!this.event &&
        ((this.event?.payment_options &&
          this.event?.payment_options?.length > 0) ||
          this.event?.accept_bitcoin) &&
        this.event?.ticket_options &&
        this.event?.ticket_options?.length > 0
      );
    },
    date() {
      return (date, format: string | undefined = undefined) =>
        dayjs(new Date(date))
          .locale("es")
          .format(format ?? "DD MMMM, YYYY • hh:mm a");
    },
    endDate() {
      return (date) => {
        const end = dayjs(new Date(date)).format("DDMMYYYY");
        const start = dayjs(
          new Date((this.event?.date.start as any)._seconds)
        ).format("DDMMYYYY");

        return this.date(date, end == start ? "hh:mm a" : undefined);
      };
    },
    showDetails() {
      if (
        this.event?.details &&
        this.event?.details?.length > this.maxDetails &&
        this.detailsExpanded == false
      )
        return `${this.event?.details.substring(0, this.maxDetails)}...`;
      return this.event?.details;
    },
  },
  methods: {
    async getImageSrc() {
      if (!this.event || !this.event.image) return;
      const imageSrc = await images.methods.getImage(
        this.event.image as string
      );
      this.image = imageSrc;
    },
    async getEvents() {
      this.isLoadingEvents = true;
      const activeEvents = await this.$store.dispatch(
        `events/${doGetActiveEvents}`
      );
      const event = activeEvents[0];
      if (event) {
        await this.$store.dispatch("events/doGetEventTicketOptions", {
          event_id: event.id,
        });

        await this.$store.dispatch("events/doGetEventTicketPaymentOptions", {
          event_id: event.id,
        });

        this.event = _.cloneDeep(this.getEvent(event.id));
        this.getImageSrc();
      }
      this.isLoadingEvents = false;
    },
    expandDetails() {
      this.detailsExpanded = !this.detailsExpanded;
    },
    goCheckout() {
      this.$router.push({
        name: "buy-tickets-checkout",
        params: { id: this.event?.id },
      });
    },
  },
  async mounted() {
    this.getEvents();
  },
});
