<template>
  <router-view />
  <bootstrap-breakpoints
    :class="{ 'visually-hidden': devHelpersHidden }"
  ></bootstrap-breakpoints>
</template>
<script>
import { defineComponent } from "vue";
import { doGetRanksInfo } from "@/store/Ranks/RanksInterfaces";
import BootstrapBreakpoints from "./components/dev/BootstrapBreakpoints";

export default defineComponent({
  data() {
    return {
      devHelpersHidden: !(
        process.env.VUE_APP_DEV_TESTING_HELPERS_VISIBLE === "true"
      ),
    };
  },
  async created() {
    this.$store.dispatch("ranks/" + doGetRanksInfo);
    this.$store.dispatch("sys/doGetMenu");
    this.$store.dispatch("sys/doGetSystemConfig");
  },
  unmounted() {
    this.$store.dispatch("sys/clearConfigListeners");
  },
  components: { BootstrapBreakpoints },
});
</script>
<style lang="scss">
#app {
  min-height: 100vh;
  overflow: hidden;
}
</style>
