
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { sortableColumns } from "@/store/MyTeam/MyTeamInterfaces";

export default defineComponent({
  name: "my-team-sort-pills",
  emits: ["updateSortConfiguration"],
  computed: {
    ...mapGetters("myTeam", {
      getSortConfiguration: "getSortConfiguration",
    }),
  },
  methods: {
    sortOrderIconClass(
      column: sortableColumns,
      direction: "asc" | "desc"
    ): string {
      switch (column) {
        case "username":
        case "nickname":
        case "binary_side":
        case "cmmi_end_date":
          return direction === "asc"
            ? "fas fa-sort-alpha-down ms-2 me-1"
            : "fas fa-sort-alpha-up ms-2 me-1";
        case "rank_priority":
          return direction === "asc"
            ? "fas fa-sort-amount-down-alt ms-2 me-1"
            : "fas fa-sort-amount-up-alt ms-2 me-1";
        case "downlines":
        case "academy_days_left":
        case "scholarship_points":
        case "license_days_left":
        case "total_volume":
        case "qualified_volume_total":
        case "qualified_percentage_total":
        case "depth":
          return direction === "asc"
            ? "fas fa-sort-numeric-down ms-2 me-1"
            : "fas fa-sort-numeric-up ms-2 me-1";
        default:
          return "";
      }
    },
    getUserFriendlyColumnName(column: sortableColumns): string {
      switch (column) {
        case "username":
          return "Usuario";
        case "nickname":
          return "Nickname";
        case "rank_priority":
          return "Rango";
        case "downlines":
          return "Miembros de Equipo";
        case "academy_days_left":
          return "Academia";
        case "binary_side":
          return "Lado";
        case "scholarship_points":
          return "Beca";
        case "license_days_left":
          return "Licencia";
        case "cmmi_end_date":
          return "CMMI";
        case "total_volume":
          return "Volumen Total";
        case "qualified_volume_total":
          return "Volumen Calificado";
        case "qualified_percentage_total":
          return "Porcentaje Calificado";
        case "depth":
          return "Profundidad";
        default:
          return column;
      }
    },
    updateSortConfiguration(column: string, direction: "asc" | "desc") {
      this.$emit("updateSortConfiguration", column, direction);
    },
  },
});
