
/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineComponent } from "vue";
import ProfileMenu from "./ProfileMenu.vue";
import Notifications from "./Notifications.vue";
import { mapGetters } from "vuex";
import { MenuItem } from "@/store/System/SystemConfigInterfaces";

export default defineComponent({
  components: { ProfileMenu, Notifications },
  name: "dashboard-topbar",
  props: ["menuName"],
  data() {
    return {
      name: this.menuName,
      icon: "",
      label: "",
    };
  },
  computed: {
    ...mapGetters("sys", ["getUserMenuItemByRoute"]),
  },
  mounted() {
    const menu: MenuItem = this.getUserMenuItemByRoute(this.name) as MenuItem;
    this.icon = menu.Icon;
    this.label = menu.Label;
  },
  watch: {
    $route(to, from) {
      this.name = this.$route.name;
      const menu: MenuItem = this.getUserMenuItemByRoute(this.name) as MenuItem;
      this.icon = menu?.Icon;
      this.label = menu?.Label;
    },
  },
});
