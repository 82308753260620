import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authCompat } from "./firebase";

import AuthLayout from "./layout/AuthLayout.vue";
import DashboardLayout from "./layout/DashboardLayout.vue";
import * as Views from "./views";

import dev from "./components/dev/devComponent.vue";
import * as Components from "./components";
import { store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "frontpage",
    component: Components.FrontPage,
  },
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/home",
        name: "home",
        component: Views.Home, //() => import("Views.Home.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "home",
        },
      },
      {
        path: "tickets",
        name: "tickets",
        component: Views.TicketPacks, //() => import("Views.TicketPacks.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "tickets",
        },
      },
      {
        path: "subscriptions",
        name: "subscriptions",
        component: Views.Subscriptions, //() => import("Views.Subscriptions.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "subscriptions",
        },
      },
      {
        path: "academy",
        name: "academy",
        component: Views.Academy, //() => import("Views.Withdrawals.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "academy",
        },
      },
      {
        path: "recorded-classes",
        name: "rec-classes",
        component: Views.RecordedClasses, //() => import("Views.Withdrawals.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "rec-classes",
        },
      },
      {
        path: "gtr-system",
        name: "gtr-system",
        component: Views.GtrSystem, //() => import("Views.Withdrawals.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "gtr-system",
        },
      },
      {
        path: "mastermind-intensive",
        name: "mastermind-intensive",
        component: Views.MastermindIntensive, //() => import("Views.Withdrawals.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "mmi-colombia",
        },
        children: [
          {
            path: "colombia",
            name: "mmi-colombia",
            component: Components.MMI.Colombia,
            meta: {
              requiresAuth: true,
              accessKey: "mmi-colombia",
            },
          },
        ],
      },
      {
        path: "commissions",
        name: "commissions",
        component: Views.Commissions, //() => import("Views.Commissions.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "commissions",
        },
      },
      {
        path: "gtr-points",
        name: "gtr-points",
        component: Views.GtrPoints, //() => import("Views.GtrPoints.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "gtr-points",
        },
      },
      {
        path: "withdrawals",
        name: "withdrawals",
        component: Views.Withdrawals, //() => import("Views.Withdrawals.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "withdrawals",
        },
      },
      {
        path: "payments",
        name: "payments",
        component: Views.Payments, //() => import("Views.Payments.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "payments",
        },
      },
      {
        path: "tools",
        name: "tools",
        component: Views.Tools, //() => import("Views.Payments.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "tools",
        },
      },
      {
        path: "qualification-dashboard",
        name: "qualification-dashboard",
        component: Views.QualificationDashboard,
        meta: {
          requiresAuth: true,
          accessKey: "qualification-dashboard",
        },
      },
      {
        path: "my-team",
        name: "my-team",
        component: Views.MyTeam,
        meta: {
          requiresAuth: true,
          accessKey: "my-team",
        },
      },
      {
        path: "unilevel",
        name: "unilevel",
        component: Views.Unilevel, //() => import ("Views.Unilevel.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "unilevel",
        },
      },
      {
        path: "binary-network",
        name: "binary-network",
        component: Views.BinaryNetwork, //() => import ("Views.BinaryNetwork.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "binary-network",
        },
      },
      {
        path: "profile",
        name: "profile",
        component: Views.Profile, //() => import ("Views.Profile.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "profile",
        },
      },
      {
        path: "queries",
        name: "queries",
        component: Views.Queries, //() => import ("Views.Queries.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "queries",
        },
      },
      {
        path: "channels",
        name: "channels",
        component: Views.Channels, //() => import ("Views.Channels.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "channels",
        },
      },
      {
        path: "alerts",
        name: "alerts",
        component: Views.Alerts, //() => import ("Views.Alerts.vue"),
        meta: {
          requiresAuth: true,
          accessKey: "alerts",
        },
      },
      {
        path: "sys-config",
        name: "sys-config",
        component: Views.SysConfig,
        redirect: "/sys-config/users-tools",
        meta: {
          requiresAuth: true,
          accessKey: "sys-config",
        },
        children: [
          // {
          //   path: "payouts",
          //   name: "Payouts",
          //   component: Components.SysConfig.Payouts,
          //   meta: {}
          // },
          {
            path: "users-tools",
            name: "UserTools",
            component: Components.SysConfig.UsersTools,
            meta: {},
          },
          {
            path: "schedules-configuration",
            name: "SchedulesConfiguration",
            component: Components.SysConfig.SchedulesConfiguration,
            meta: {},
          },
          {
            path: "profile-permissions",
            name: "ProfilePermissionsConfiguration",
            component: Components.SysConfig.ProfilePermissionsConfiguration,
            meta: {},
          },
          {
            path: "user-profiles",
            name: "UserProfilesConfiguration",
            component: Components.SysConfig.UserProfilesConfiguration,
            meta: {},
          },
        ],
      },
      {
        path: "payouts",
        name: "payouts",
        component: Views.Payouts,
        meta: {
          requiresAuth: true,
          accessKey: "payouts",
        },
      },
      {
        path: "/buy-tickets",
        name: "buy-tickets",
        component: Views.BuyTickets,
        meta: {
          requiresAuth: false,
          accessKey: "buy-tickets",
        },
      },
      {
        path: "/buy-tickets/checkout/:id",
        name: "buy-tickets-checkout",
        component: Views.BuyTicketsCheckout,
        meta: {
          requiresAuth: false,
          accessKey: "buy-tickets-checkout",
        },
      },
      {
        path: "events/set",
        name: "set-tickets-admin",
        component: Views.SetTicketsAdmin,
        meta: {
          requiresAuth: true,
          accessKey: "set-tickets-admin",
        },
      },
      {
        path: "events",
        name: "buy-tickets-admin",
        component: Views.BuyTicketsAdmin,
        meta: {
          requiresAuth: true,
          accessKey: "buy-tickets-admin",
        },
      },
      {
        path: "events/new",
        name: "buy-tickets-admin-new-event",
        component: Views.BuyTicketsNewEvent,
        meta: {
          requiresAuth: true,
          accessKey: "buy-tickets-admin-new-event",
        },
      },
      {
        path: "events/archive",
        name: "buy-tickets-admin-archive",
        component: Views.BuyTicketsAdminArchive,
        meta: {
          requiresAuth: true,
          accessKey: "buy-tickets-admin-archive",
        },
      },
      {
        path: "events/edit/:id",
        name: "buy-tickets-admin-edit-event",
        props: true,
        component: Views.BuyTicketsEditEvent,
        meta: {
          requiresAuth: false,
          accessKey: "buy-tickets-admin-edit-event",
        },
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: Views.Login,
      },
      {
        path: "/registro",
        name: "register",
        component: Views.Register,
        props: true,
        children: [
          {
            path: ":username/:side",
            name: "registration",
            component: Views.Register,
            props: true,
          },
        ],
      },
      {
        path: "/reset-password/:token",
        name: "resetPassword",
        component: Views.ResetPassword,
        props: true,
      },
    ],
  },
  {
    path: "/ticket-code/:token",
    name: "",
    component: Views.QRCodeGenerate,
    props: true,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: About
  // },
  {
    path: "/offline",
    name: "offline",
    component: Components.UpdatingSystem,
  },
  {
    path: "/dev",
    name: "dev",
    component: dev,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Views.NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // linkExactActiveClass: "active",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const hasPermission = store.getters["auth/getUserPermissions"].find
    ? store.getters["auth/getUserPermissions"].find(
        (x: string) => x == to.meta.accessKey
      ) || false
    : false;
  const offline = store.getters["sys/isOffline"];
  // console.debug("-----------");
  // console.debug("auth.currentUser", auth.currentUser?.displayName);
  // console.debug("from", from);
  // console.debug("to", to);
  // console.debug(store.getters["auth/getUserPermissions"].length);
  // console.debug("requiresAuth", requiresAuth);
  // console.debug("hasPermission", hasPermission);
  // console.debug("to.meta.accessKey", to.meta.accessKey);

  if (offline && to.name != "offline") {
    next("/offline");
  } else if (!offline && to.name == "offline") {
    next("/login");
  } else if (requiresAuth && !authCompat.currentUser) {
    next("/login");
  } else if (
    requiresAuth &&
    store.getters["auth/getUserPermissions"].length <= 0
  ) {
    next("/login");
  } else if (hasPermission || to.meta.accessKey == undefined) {
    next();
  } else {
    next("/denied");
  }
});

export default router;
