import { VideoData } from "functions/src/MMI/MMIInterfaces";
// export const  = "";
// Actions
// export const action = "action";

// Mutations
// export const MUTATION_EXAMPLE = "MUTATION_EXAMPLE";
export const SET_USER_STRUCTURE = "SET_USER_STRUCTURE";
export const SET_CHAPTER_PLAYLIST = "SET_CHAPTER_PLAYLIST";
export const SET_CHAPTER_WATCHED_VIDEOS = "SET_CHAPTER_WATCHED_VIDEOS";
export const SET_CHAPTER_WATCHED_VIDEOS_LISTENER =
  "SET_CHAPTER_WATCHED_VIDEOS_LISTENER";
export const CLEAR_CHAPTER_WATCHED_VIDEOS_LISTENER =
  "CLEAR_CHAPTER_WATCHED_VIDEOS_LISTENER";

export type Chapters = "colombia";

export interface MMIState {
  colombia: ChapterData;
}

export interface ChapterData {
  users: Map<string, MMIUser>;
  videos: Array<VideoData>;
  watchedVideos: Array<string>;
}

export interface MMIUser {
  _usernamePK: string;
  username: string;
  nickname: string;
  currentRankId: string;
  rankIconClass: string;
  profilePicture: string;
  isPassUp: boolean;
  parent: string;
  initialDate: Date;
  users: Array<MMIUser>;
}
