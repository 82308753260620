
import { Address } from "@/store/Profile/ProfileInterfaces";
import { Country } from "@/store/System/SystemConfigInterfaces";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "profile-address",
  props: {
    addressInfo: {
      type: Object as () => Address,
      default: {} as Address,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      localAddress: {} as Address,
      displayedCountry: {} as Country,
      saving: false,
    };
  },
  computed: {
    ...mapGetters("sys", [
      "getEnabledCountries",
      "getStarredCountries",
      "getCountryByAlpha3Code",
    ]),
    readOnlyClasses(): any {
      return {
        "bg-light": this.editMode,
        "bg-body": !this.editMode,
        "bg-gradient": !this.editMode,
        "text-secondary": !this.editMode,
      };
    },
    starredCountries(): Array<Country> {
      const starred = this.getStarredCountries
        .filter((country: Country) => {
          return country.enabled === true;
        })
        .sort((a: Country, b: Country) => {
          if (a.priority === b.priority) {
            return a.spanish.localeCompare(b.spanish);
          } else {
            return a.priority - b.priority;
          }
        });
      return starred;
    },
    enabledCountries(): Array<Country> {
      const enabled = this.getEnabledCountries
        .filter((country: Country) => {
          return country.enabled === true;
        })
        .sort((a: Country, b: Country) => {
          return a.spanish.localeCompare(b.spanish);
        });
      return enabled;
    },
  },
  methods: {
    ...mapActions("profile", ["doUpdateUserAddressInfo"]),
    edit() {
      this.editMode = true;
      this.$nextTick(() => (this.$refs.street as HTMLInputElement).select());
    },
    save() {
      //call Save Address Info function
      this.saving = true;
      this.doUpdateUserAddressInfo(this.localAddress)
        .then(() => {
          this.editMode = false;
        })
        .catch((error: any) => {
          alert(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancelEdit() {
      this.editMode = false;
      this.localAddress = { ...this.addressInfo };
      this.displayedCountry = {
        ...this.getCountryByAlpha3Code(this.localAddress.country),
      };
    },
    setDisplayedCountry(country: Country) {
      this.localAddress.country = country.alpha3Code;
      this.displayedCountry = { ...country };
    },
  },
  mounted() {
    this.localAddress = { ...this.addressInfo };
    this.displayedCountry = {
      ...this.getCountryByAlpha3Code(this.localAddress.country),
    };
  },
  watch: {
    addressInfo: {
      handler(newValue: Address) {
        this.localAddress = { ...newValue };
        this.displayedCountry = {
          ...this.getCountryByAlpha3Code(this.localAddress.country),
        };
      },
      immediate: true,
    },
  },
});
