
import "dayjs/locale/es";
import { defineComponent } from "vue";
import {
  PaymentOptions as IPaymentOptions,
  TicketPaymentOptions as ITicketPaymentOptions,
} from "@/store/Events/EventsInterfaces";
import PaymentOptionModal from "@/components/events/admin/ticket-options/PaymentOptionModal.vue";
import _ from "lodash";
import dayjs from "dayjs";

export default defineComponent({
  name: "ticket-option-payment-options",
  props: ["option", "paymentOptions", "id", "newEvent", "eventId"],
  emits: ["removePaymentOption", "setPaymentOptions"],
  components: {
    PaymentOptionModal,
  },
  data() {
    return {
      option_types_format: {
        day_next_month: "El día %% del siguiente mes después de la compra",
        days_after_purchase: "%% días despúes de la compra",
        specific_date: "En la fecha %%",
        immediately: "Inmediatamente despúes de la compra",
      },
      currentOptions: [] as ITicketPaymentOptions[],
      currentEventId: "" as string,
    };
  },
  computed: {
    option_type_html() {
      return (opt) => {
        return this.option_types_format[opt.type].replace(
          "%%",
          `<strong>${
            opt.type == "specific_date"
              ? this.date(dayjs(opt.value))
              : opt.value ?? ""
          }</strong>`
        );
      };
    },
    date() {
      return (date) =>
        dayjs(new Date(date)).locale("ess").format("DD MMMM, YYYY, hh:mm a");
    },
    payment_discounts() {
      return (options: IPaymentOptions[]) =>
        _.orderBy(options, ["position"], ["asc"]);
    },
  },
  watch: {
    paymentOptions: function () {
      this.processData();
    },
    eventId: function (eventId) {
      this.currentEventId = eventId;
    },
  },
  methods: {
    remove(ind) {
      const parent: any = this.$parent;
      parent.removePaymentOption(ind);
    },
    savePaymentOptions(payment_options, modal, component) {
      if (this.newEvent) this.$emit("setPaymentOptions", payment_options);
      const save = this.$store.dispatch("events/createPaymentOption", {
        eventId: this.eventId,
        data: payment_options,
      });

      save
        .then(() => {
          this.$parent?.$emit("getData");
          component.resetData();
          if (modal) {
            const element = document.querySelector(
              `#${modal.uniqueId} .btn-close`
            );
            if (element instanceof HTMLElement) element.click();
          }
        })
        .catch((err) => console.error(err));
    },
    processData() {
      this.currentOptions = _.cloneDeep(this.paymentOptions);
    },
  },
  mounted() {
    this.processData();
  },
});
