// Actions

//Mutations
export const UPDATE_DISPLAYED_TEAM = "UPDATE_DISPLAYED_TEAM";
export const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_DISPLAYED_TEAM = "CLEAR_DISPLAYED_TEAM";
export const CLEAR_BREADCRUMBS = "CLEAR_BREADCRUMBS";
export const UPDATE_CURRENT_DISPLAYED_USER = "UPDATE_CURRENT_DISPLAYED_USER";
export const CLEAR_FULL_TEAM = "CLEAR_FULL_TEAM";
export const CLEAR_CURRENT_DISPLAYED_USER = "CLEAR_CURRENT_DISPLAYED_USER";
export const SET_LEADER_TEAM_CACHE = "SET_LEADER_TEAM_CACHE";
export const UPDATE_SORT_CONFIGURATION = "UPDATE_SORT_CONFIGURATION";

export type sortableColumns =
  | "username"
  | "nickname"
  | "rank_priority"
  | "downlines"
  | "binary_side"
  | "academy_days_left"
  | "scholarship_points"
  | "license_days_left"
  | "cmmi_end_date"
  | "total_volume"
  | "qualified_volume_total"
  | "qualified_percentage_total"
  | "depth";

export interface MyTeamState {
  fullTeamCache: Map<
    string,
    { uplines: Array<string>; leaderTeam: Array<MyTeamMember> }
  >;
  displayedTeam: Array<MyTeamMember>;
  breadcrumbs: Array<string>;
  isLoading: boolean;
  currentDisplayedUser: string;
  sortConfiguration: [Map<sortableColumns, "asc" | "desc">] | [];
}

export interface MyTeamMember {
  _active_from_date: string;
  _team_leader: string;
  _updated_date: string;
  _usernamePK: string;
  username: string;
  nickname: string;
  current_rank: string;
  profile_picture: string;
  pictureUrl: string;
  binary_side: string;
  rank_priority: number;
  academy_end_date: string;
  academy_days_left: number;
  cmmi_end_date: string;
  license_end_date: string | null;
  license_days_left: number | null;
  scholarship_points: number;
  scholarship_end_date: string;
  total_volume_left: number;
  total_volume_right: number;
  total_volume: number;
  total_volume_left_flag: string;
  total_volume_right_flag: string;
  qualified_volume_right: number;
  qualified_volume_left: number;
  qualified_volume_total: number;
  qualified_percentage_right: number;
  qualified_percentage_left: number;
  qualified_percentage_total: number;
  qualified_volume_left_flag: string;
  qualified_volume_right_flag: string;
  next_rank_id: string;
  required_earning: number;
  required_points: number;
  depth: number;
  uplines: Array<string>;
  downlines: number;
}
