
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { RankStatus } from "@/store/RankQualification/RankQualificationInterfaces";
import { mapGetters } from "vuex";
import { Rank } from "@/store/Ranks/RanksInterfaces";

export default defineComponent({
  name: "rank-requalification",
  props: {
    rankRequalificationRecords: {
      type: Map as PropType<Map<string, Array<RankStatus>>>,
      required: true,
    },
    currentRankPriority: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("ranks", ["allRanks"]),
    ...mapGetters("rankQualification", ["getGtrLifeBonusTypeConfig"]),
    periods() {
      const p = new Array<dayjs.Dayjs>();
      for (let i = 3; i >= 0; i--) {
        p.push(dayjs().tz("America/Mexico_city").subtract(i, "month"));
      }
      return p;
    },
    ranksWithBonus() {
      const ranksValues = Array.from(
        (this.allRanks() as Map<string, Rank>).values()
      );
      return ranksValues
        .filter((r) => r.gtrLifeBonus != undefined)
        .sort((a, b) => {
          return a.priority - b.priority;
        });
    },
  },
  methods: {
    getRankStatusIcon(rank: string, period: string) {
      const rankStatus = this.rankRequalificationRecords.get(rank);
      if (rankStatus) {
        const periodStatus = rankStatus.find((rs) => rs.period === period);
        if (periodStatus) {
          const status = periodStatus.status;
          if (status) {
            return "fas fa-check-circle fa-fw text-success";
          } else {
            if (
              period === dayjs().tz("America/Mexico_city").format("YYYY-MM")
            ) {
              return "fas fa-question-circle fa-fw text-warning";
            }
            return "fas fa-times-circle fa-fw text-danger";
          }
        }
      }
      return "";
    },
    typeIconClass(type?: string): string {
      let t = type;
      switch (type) {
        case "car":
          t = "gtrCar";
          break;
        case "department":
          t = "gtrDep";
          break;
        case "gtrlife":
          t = "gtrLife";
          break;
      }
      return this.getGtrLifeBonusTypeConfig(t)?.icon;
    },
  },
});
