import { UserPackageInfo } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { RankStatus } from "../Auth/AuthInterfaces";

//export const  = "";
//Actions
//export const action = "action";
export const initializeTeam = "initializeTeam";
export const doGetLeaderData = "doGetLeaderData";
export const doGetTeam = "doGetTeam";
export const setLeaderUsername = "setLeaderUsername";
export const searchUser = "searchUser";
export const doUpdateLeaderData = "doUpdateLeaderData";

//Mutations
//export const MUTATION_EXAMPLE = "MUTATION_EXAMPLE";
export const INIT = "INIT";
export const ADD_TEAM_DATA = "ADD_TEAM_DATA";
export const ADD_MEMBER_TO_TEAM = "ADD_MEMBER_TO_TEAM";
export const CHANGE_TEAM_LEADER = "CHANGE_TEAM_LEADER";
export const CLEAR_STATE = "CLEAR_STATE";

export interface UnilevelState {
  currentLeader: string;
  teamMap: Map<string, UnilevelTeam>;
}

export interface UnilevelPoints {
  valid: {
    right: number;
    left: number;
    total: number;
  };
  current: number;
  qualifying: number;
  left: number;
  right: number;
}

export interface UnilevelMember extends AffiliatesData {
  usernamePK: string;
  nickname: string;
  username: string;
  currentRank: string;
  currentRankPriority: number;
  pictureUrl: string;
  enroller: string;
  // Subscription Name -> remaining days
  subscriptions: Map<string, number>;
  ranksCarrerData: Map<string, RankStatus>;
  nextRank: string;
  nextPointsRank: string;
  nextRankRequiredPoints: number;
  nextRankMaxPoints: number;
  nextRankMaxTeamPoints: number;
  scholarshipData?: ScholarshipData;
  subscriptionPackage: UserPackageInfo;
}

export interface AffiliatesData {
  points: UnilevelPoints;
  invoicedTotal: number;
  directAffiliates: number;
}

export interface ScholarshipData {
  periodPoints: number;
  periodEndDate: Date;
}

export interface UnilevelTeam {
  leader: UnilevelMember;
  leftTeam: Array<UnilevelTeam>;
  rightTeam: Array<UnilevelTeam>;
}

export class UnilevelMember implements UnilevelMember {
  constructor(
    nickname: string,
    username: string,
    rank: string,
    pictureUrl: string,
    enroller: string,
    ranksCarrerData: Map<string, RankStatus>
  ) {
    this.nickname = nickname;
    this.usernamePK = username.toLowerCase();
    this.username = username;
    this.currentRank = rank;
    this.pictureUrl = pictureUrl;
    this.enroller = enroller;
    this.points = {
      valid: {
        right: 0,
        left: 0,
        total: 0,
      },
      current: 0,
      left: 0,
      right: 0,
      qualifying: 0,
    };
    this.invoicedTotal = 0;
    this.subscriptions = new Map<string, number>();
    this.directAffiliates = 0;
    this.ranksCarrerData = ranksCarrerData;
  }
}
