
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "cody-ai",
  computed: {
    ...mapGetters("sys", {
      config: "getSystemConfig",
    }),
  },
  created() {
    window.codySettings = { widget_id: this.config["cody-widget_id"] };

    !(function () {
      const t = window,
        e = document,
        a = function () {
          const t = e.createElement("script");
          (t.type = "text/javascript"),
            (t.async = !0),
            (t.src = "https://trinketsofcody.com/cody-widget.js");
          const a = e.getElementsByTagName("script")[0];
          if (a.parentNode) {
            a.parentNode.insertBefore(t, a);
          }
        };
      return "complete" === document.readyState
        ? a()
        : t.attachEvent
        ? t.attachEvent("onload", a)
        : t.addEventListener("load", a, !1);
    })();
  },
});
