
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import * as bs from "bootstrap";
import Cropper from "cropperjs";
import { mapGetters } from "vuex";
import { UpdateProfilePictureRequest } from "@/store/Profile/ProfileInterfaces";
import { generateDateKey } from "@/mixins/tools";

interface ImageData {
  url: string;
  name: string;
  fullPath: string;
}

export default defineComponent({
  name: "profile-picture-gallery-modal",
  data() {
    return {
      modalObject: {} as any,
      cropper: new Cropper(document.createElement("img")),
      modeCrop: false,
      defaultImages: Array<ImageData>(),
      saving: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUserUid", "getUserPicture"]),
    sortedDefaultImages(): Array<ImageData> {
      const sorted = [...this.defaultImages];
      return sorted.sort((a, b) => {
        if (a.fullPath < b.fullPath) {
          return -1;
        }
        if (a.fullPath > b.fullPath) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    selectPictureFile() {
      const input = this.$refs.uploadedImage as HTMLInputElement;
      input.click();
    },
    cancelCrop() {
      this.modeCrop = false;
    },
    uploadImage(event: Event) {
      // console.log(event.target);
      if (event && event.target) {
        const target = event.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
          if (target.files[0].size > 5000000) {
            alert("Seleccione una imagen de máximo 5 MB");
            return;
          }
          this.modeCrop = true;

          const result = this.$refs.imageWorkArea as Element;
          result.innerHTML = "";
          this.cropper.clear();

          const reader = new FileReader();
          reader.onload = (e) => {
            if (e?.target?.result) {
              const img = document.createElement("img");
              img.id = "image";
              img.src = e.target.result as string;
              img.style.maxHeight = "350px";
              img.style.display = "block";
              img.style.maxWidth = "100%";
              result.appendChild(img);

              this.cropper = new Cropper(img, {
                aspectRatio: 1,
                viewMode: 1,
                background: false,
                zoomable: false,
              });
            }
          };
          reader.readAsDataURL(target.files[0]);
        } else {
          // const result = this.$refs.imageWorkArea as Element;
          // console.log(result.innerHTML);
          alert("Seleccione una imagen");
        }
      }
    },
    closeModal() {
      this.modalObject.hide();
      setTimeout(() => {
        this.modeCrop = false;
        this.cropper.clear();
      }, 1000);
    },
    saveProfilePicture(isDefault: boolean, imgUrl?: string) {
      this.saving = true;
      let pictureUrl: string;
      let imgSrc: string | undefined;

      if (isDefault) {
        if (imgUrl) {
          pictureUrl = imgUrl;
        } else {
          alert("Seleccione una imagen");
          return;
        }
      } else {
        imgSrc = this.cropper.getCroppedCanvas({ width: 500 }).toDataURL();
        pictureUrl =
          "/profile-pictures/" +
          this.getUsernamePK +
          "/" +
          this.getUsernamePK +
          "_" +
          generateDateKey() +
          ".png";
      }

      const request: UpdateProfilePictureRequest = {
        usernamePK: this.getUsernamePK,
        originalUrl: this.getUserPicture,
        pictureUrl: pictureUrl,
        dataBase64Url: imgSrc,
        isDefaultImage: isDefault,
      };

      this.$store
        .dispatch("profile/updateProfilePicture", request)
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          alert("Error: " + error.message);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
  mounted() {
    this.modalObject = bs.Modal.getOrCreateInstance(
      document.getElementById("profile-picture-gallery-modal")
    );
  },
  created() {
    firebase
      .storage()
      .ref("img/gtr-images/neon")
      .listAll()
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          folderRef.listAll().then((res) => {
            res.items.forEach((itemRef) => {
              itemRef.getDownloadURL().then((url) => {
                this.defaultImages.push({
                  url: url,
                  name: itemRef.name,
                  fullPath: itemRef.fullPath,
                });
              });
            });
          });
        });
        res.items.forEach((itemRef) => {
          itemRef.getDownloadURL().then((url) => {
            this.defaultImages.push({
              url: url,
              name: itemRef.name,
              fullPath: itemRef.fullPath,
            });
          });
        });
      });
  },
});
