import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_downgrade_to_lite = _resolveComponent("downgrade-to-lite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_downgrade_to_lite, {
      "lost-benefits": _ctx.lostBenefits,
      "subscription-id": _ctx.subscriptionId,
      "last-quote": _ctx.lastQuote,
      "is-quoting": _ctx.isQuoting,
      onClearQuote: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clearQuote'))),
      onRequestQuote: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('requestQuote', $event)))
    }, null, 8, ["lost-benefits", "subscription-id", "last-quote", "is-quoting"]),
    _createTextVNode(" > ")
  ], 64))
}