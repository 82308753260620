import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mb-4" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_payouts_list = _resolveComponent("payouts-list")!
  const _component_payout_detail = _resolveComponent("payout-detail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_payouts_list, {
        class: "",
        payoutsList: _ctx.getPayoutsList,
        onPayoutSelected: _ctx.updateDetails,
        onYearMonthSelected: _ctx.updatePayoutsList
      }, null, 8, ["payoutsList", "onPayoutSelected", "onYearMonthSelected"]),
      _createVNode(_component_payout_detail, {
        class: "flex-grow-1 table-responsive",
        payoutDetails: _ctx.getPayoutDetails,
        payoutId: _ctx.currentPayoutId
      }, null, 8, ["payoutDetails", "payoutId"])
    ])
  ]))
}