
import router from "@/router";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "profile-menu",
  computed: {
    ...mapGetters("auth", ["getUserNickname"]),
    ...mapGetters("auth", ["getUserPicture"]),
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("auth", ["getUserPermissions"]),
    ...mapGetters("subs", ["isVIP"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      router.push({ name: "login" });
    },
    canAccess(screenKey: string) {
      return (
        (this.getUserPermissions.includes &&
          this.getUserPermissions.includes(screenKey)) ||
        false
      );
    },
  },
});
