
import { defineComponent } from "vue";

import * as bs from "bootstrap";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

import transferGtrPoints from "./transferGtrPoints.vue";

export default defineComponent({
  name: "gtr-points-summary",
  components: { transferGtrPoints },
  props: {
    summary: { type: Object, required: true },
  },
  data() {
    return {
      modalObj: {} as any,
    };
  },
  computed: {
    total(): string {
      const num = this.summary?.total || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    earned(): string {
      const num = this.summary?.earned || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    expended(): string {
      const num = this.summary?.expended || 0;
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    totalDate(): string {
      return this.summary?.totalUpdatedDate
        ? this.formatDateTimezone(this.summary.totalUpdatedDate)
        : "-";
    },
    earnedDate(): string {
      return this.summary?.earnedUpdatedDate
        ? this.formatDateTimezone(this.summary.pendingUpdatedDate)
        : "-";
    },
    expendedDate(): string {
      return this.summary?.expendedUpdatedDate
        ? this.formatDateTimezone(this.summary.paidUpdatedDate)
        : "-";
    },
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    openTransferModal() {
      this.modalObj.show();
    },
  },
  mounted() {
    this.modalObj = new bs.Modal(
      document.getElementById("transferGtrPointsModal")
    );
  },
});
