
import { PersonalInfo } from "@/store/Profile/ProfileInterfaces";
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "peronsal-info",
  props: {
    personalInfo: {
      type: Object as () => PersonalInfo,
      default: {} as PersonalInfo,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      localPersonalInfo: {},
      saving: false,
    };
  },
  computed: {
    readOnlyClasses(): any {
      return {
        "bg-light": this.editMode,
        "bg-body": !this.editMode,
        "bg-gradient": !this.editMode,
        "text-secondary": !this.editMode,
      };
    },
  },
  methods: {
    ...mapActions("profile", ["doUpdateUserPersonalInfo"]),
    edit() {
      this.editMode = true;
      this.$nextTick(() => (this.$refs.names as HTMLInputElement).select());
    },
    save() {
      //call Save Personal Info function
      this.saving = true;
      const dob = (this.localPersonalInfo as PersonalInfo).dateOfBirth || "";
      if (dob != "") {
        const date = new Date(dob);
        (this.localPersonalInfo as PersonalInfo).dateOfBirth = date
          .toISOString()
          .substring(0, 10);
      }

      this.doUpdateUserPersonalInfo(this.localPersonalInfo)
        .then(() => {
          this.editMode = false;
        })
        .catch((error: any) => {
          alert(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancelEdit() {
      this.editMode = false;
      this.localPersonalInfo = { ...this.personalInfo };
    },
  },
  mounted() {
    this.localPersonalInfo = { ...this.personalInfo };
  },
  watch: {
    personalInfo: {
      handler(newValue: PersonalInfo) {
        this.localPersonalInfo = { ...newValue };
      },
      immediate: true,
    },
  },
});
