import { ActionContext } from "vuex";
import * as fb from "../../firebase";
// import router from "../../router";

import {
  ExpiringPoints,
  GtrBonusStatusConfig,
  GtrBonusTypesConfig,
  GtrLifestyleBonus,
  PointsHistory,
  RankQualificationState,
  RankStatus,
  UPDATE_USER_EXPIRING_POINTS,
  UPDATE_USER_GTR_LIFESTYLE_BONUS,
  UPDATE_USER_POINTS_HISTORY,
  UPDATE_USER_RANK_REQUALIF,
} from "./RankQualificationInterfaces";
import {
  GtrBonusStatusMap,
  GtrBonusTypesMap,
} from "./RankQualificationLocalData";

const initialState: RankQualificationState = {
  bonusList: new Array<GtrLifestyleBonus>(),
  expiringPoints: new Array<ExpiringPoints>(),
  pointsHistory: new Array<PointsHistory>(),
  rankRequalification: new Map<string, Array<RankStatus>>(),
  gtrLifeBonusConfig: {
    status: GtrBonusStatusMap,
    type: GtrBonusTypesMap,
  },
};

const state: RankQualificationState = { ...initialState };
const mutations = {
  UPDATE_USER_GTR_LIFESTYLE_BONUS(
    state: RankQualificationState,
    bonusList: Array<GtrLifestyleBonus>
  ) {
    state.bonusList = bonusList;
  },
  UPDATE_USER_EXPIRING_POINTS(
    state: RankQualificationState,
    expiringPoints: Array<ExpiringPoints>
  ) {
    state.expiringPoints = expiringPoints;
  },
  UPDATE_USER_POINTS_HISTORY(
    state: RankQualificationState,
    pointsHistory: Array<PointsHistory>
  ) {
    state.pointsHistory = pointsHistory;
  },
  UPDATE_USER_RANK_REQUALIF(
    state: RankQualificationState,
    rankRequalification: Map<string, Array<RankStatus>>
  ) {
    state.rankRequalification = rankRequalification;
  },
  CLEAR_RANK_QUALIF_DATA(state: RankQualificationState) {
    state.bonusList = new Array<GtrLifestyleBonus>();
    state.expiringPoints = new Array<ExpiringPoints>();
    state.pointsHistory = new Array<PointsHistory>();
    state.rankRequalification = new Map<string, Array<RankStatus>>();
  },
};
const actions = {
  async doGetExpiringPoints(
    context: ActionContext<RankQualificationState, any>
  ) {
    const getExpiringPoints = fb.func.httpsCallable(
      "RankPoints-getExpiringPoints"
    );
    const response = await getExpiringPoints().then((response) => {
      if (response.data.OPCODE == "SUCCESS") {
        return response.data.data;
      } else {
        throw response.data.error;
      }
    });
    const expiringPoints = response.map((points: any) => {
      return {
        date: new Date(points.date),
        left: points.left,
        right: points.right,
        total: points.total,
      };
    });
    context.commit(UPDATE_USER_EXPIRING_POINTS, expiringPoints);
  },
  async doGetGtrLifeBonus(context: ActionContext<RankQualificationState, any>) {
    const getGtrLifeBonuses = fb.func.httpsCallable(
      "RankPoints-getGtrLifeBonuses"
    );
    const bonusList = await getGtrLifeBonuses().then((response) => {
      if (response.data.OPCODE == "SUCCESS") {
        return response.data.data;
      } else {
        throw response.data.error;
      }
    });
    context.commit(UPDATE_USER_GTR_LIFESTYLE_BONUS, bonusList);
  },
  async doGetPointsHistory(
    context: ActionContext<RankQualificationState, any>
  ) {
    // const username = await context.rootGetters["auth/getUsernamePK"];

    // CALL API
    const getPointsHistory = fb.func.httpsCallable(
      "RankPoints-getPointsHistory"
    );
    const response = await getPointsHistory().then((response) => {
      if (response.data.OPCODE == "SUCCESS") {
        return response.data.data;
      } else {
        throw response.data.error;
      }
    });

    const rankPointsHistory = new Map<string, Array<RankStatus>>();
    Object.keys(response).forEach((key: string) => {
      const rankStatus = response[key].map((rank: any) => {
        return {
          period: rank._period,
          status: rank.requalified,
        } as RankStatus;
      });
      rankPointsHistory.set(key, rankStatus);
    });

    const pointsHistory =
      (response["INFLUENCER"]?.map((data: any) => {
        return {
          period: data._period,
          points: data.maxPeriodPoints,
        } as PointsHistory;
      }) as Array<PointsHistory>) || new Array<PointsHistory>();

    context.commit(UPDATE_USER_POINTS_HISTORY, pointsHistory);
    context.commit(UPDATE_USER_RANK_REQUALIF, rankPointsHistory);
  },
};
const getters = {
  getExpiringPoints(state: RankQualificationState) {
    return state.expiringPoints;
  },
  getGtrLifeBonus(state: RankQualificationState) {
    return state.bonusList;
  },
  getGtrLifeBonusTypeConfig(state: RankQualificationState) {
    return (type: string): GtrBonusTypesConfig | undefined => {
      return state.gtrLifeBonusConfig.type.get(type);
    };
  },
  getGtrLifeBonusStatusConfig(state: RankQualificationState) {
    return (status: string): GtrBonusStatusConfig | undefined => {
      return state.gtrLifeBonusConfig.status.get(status);
    };
  },
  getPointsHistory(state: RankQualificationState) {
    return state.pointsHistory;
  },
  getRankRequalification(state: RankQualificationState) {
    return state.rankRequalification;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
