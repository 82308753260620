
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import * as fb from "../../firebase";
import * as bs from "bootstrap";

export default defineComponent({
  name: "virtual-classroom",
  props: {
    showLiveClasses: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("profile", { weknowData: "getUserWeknowData" }),
    isClassroomActive(): boolean {
      return this.weknowData ? this.weknowData.status === "active" : false;
    },
  },
  methods: {
    ...mapActions("profile", ["doGetUserWeknowData"]),
    classroomLogin() {
      const classroomInfoRequest = {
        username: this.getUsernamePK,
      };

      const GetClassroomInfo = fb.func.httpsCallable("Users-getClassroomInfo");
      GetClassroomInfo(classroomInfoRequest).then((response) => {
        const info = response.data;
        if (info.OPCODE == "SUCCESS") {
          (this.$refs.username as HTMLInputElement).value = info.data.username;
          (this.$refs.password as HTMLInputElement).value = info.data.password;
          (this.$refs.formClassroom as HTMLFormElement).action =
            info.data.loginUrl;
          (this.$refs.btnSubmit as HTMLButtonElement).click();
          (this.$refs.username as HTMLInputElement).value = "";
          (this.$refs.password as HTMLInputElement).value = "";
        } else {
          alert(
            "No se pudo obtener la información para acceder a las clases grabadas."
          );
        }
      });
    },
  },
  mounted() {
    new bs.Popover(this.$refs.popups);
    new bs.Popover(this.$refs.activando);

    this.doGetUserWeknowData(this.getUsernamePK);
  },
});
