import { GtrPointsTypeConfig } from "./GtrPointsInterfaces";

export const GtrPointsTypesMap = new Map<string, GtrPointsTypeConfig>();

//#region Commission Types
GtrPointsTypesMap.set("earned", {
  code: "earned",
  icon: "fas fa-sign-in-alt fa-fw text-green",
  label: "Ganado",
});
GtrPointsTypesMap.set("expended", {
  code: "expended",
  icon: "fas fa-sign-out-alt fa-fw text-red",
  label: "Gastado",
});
