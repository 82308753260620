/* eslint-disable @typescript-eslint/no-unused-vars */
import { confettiKit } from "@/assets/js/confettiKit";

const base = {
  confettiCount: 300,
  position: "bottomLeftRight",
  angle: 0,
  decay: 0.9,
  spread: 60,
  startVelocity: 110,
  elements: {
    confetti: {
      direction: "down",
      rotation: true,
    },
    star: {
      count: 0,
      direction: "down",
      rotation: true,
    },
    ribbon: {
      count: 0,
      direction: "down",
      rotation: true,
    },
    custom: [
      {
        count: 15,
        width: 30,
        textSize: 165,
        contentType: "image",
        direction: "down",
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        count: 15,
        width: 30,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 30,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 20,
        width: 30,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 20,
        width: 25,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 15,
        width: 30,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 45,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 20,
        width: 12,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 18,
        textSize: 165,
        contentType: "image",
        direction: "down",
        rotation: true,
      },
    ],
  },
};

const defaultConfetti = {
  confettiCount: 250,
  position: "bottomLeftRight",
  angle: 0,
  decay: 0.9,
  spread: 60,
  startVelocity: 120,
  elements: {
    confetti: {
      direction: "down",
      rotation: true,
    },
    star: {
      count: 0,
      direction: "down",
      rotation: true,
    },
    ribbon: {
      count: 0,
      direction: "down",
      rotation: true,
    },
    custom: [
      {
        count: 10,
        width: 20,
        textSize: 165,
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 30,
        textSize: 165,
        content: require("@/assets/img/confetti/Bitcoin.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 25,
        textSize: 165,
        content: require("@/assets/img/confetti/Bitcoin-coin-2.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 15,
        textSize: 165,
        content: require("@/assets/img/confetti/Bitcoin-coin-2.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 20,
        textSize: 165,
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 25,
        textSize: 165,
        content: require("@/assets/img/confetti/GTR-Logo-Confetti.png"),
        contentType: "image",
        direction: "up",
        rotation: true,
      },
      {
        count: 10,
        width: 20,
        textSize: 165,
        content: require("@/assets/img/confetti/GTR-Logo-Confetti.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
      {
        count: 10,
        width: 40,
        textSize: 165,
        content: require("@/assets/img/confetti/GTR-Logo-Confetti.png"),
        contentType: "image",
        direction: "down",
        rotation: true,
      },
    ],
  },
};

const newYear = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/newYear/image-1.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/newYear/image-2.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/newYear/image-3.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/newYear/image-4.png"),
        rotation: true,
      },
    ],
  },
};

const reyes = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/reyes/image-1.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/reyes/image-2.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/reyes/image-3.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/reyes/image-4.png"),
        rotation: true,
      },
    ],
  },
};

const valentines = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/valentines/image-1.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/valentines/image-2.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/valentines/image-3.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/valentines/image-4.png"),
        rotation: true,
      },
    ],
  },
};

const spring = { ...defaultConfetti };
//   elements: {
//     custom: [
//       {
//         content: require("@/assets/img/confetti/Bitcoin-vector.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-1.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-2.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-3.png"),
//         rotation: false
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-4.png"),
//         rotation: true
//       }
//     ]
//   }
// };

const children = { ...defaultConfetti };
//   elements: {
//     custom: [
//       {
//         content: require("@/assets/img/confetti/Bitcoin-vector.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-1.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-2.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-3.png"),
//         rotation: false
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-4.png"),
//         rotation: true
//       }
//     ]
//   }
// };

const mothers = { ...defaultConfetti };
//   elements: {
//     custom: [
//       {
//         content: require("@/assets/img/confetti/Bitcoin-vector.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-1.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-2.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-3.png"),
//         rotation: false
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-4.png"),
//         rotation: true
//       }
//     ]
//   }
// };

const fathers = { ...defaultConfetti };
//   elements: {
//     custom: [
//       {
//         content: require("@/assets/img/confetti/Bitcoin-vector.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-1.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-2.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-3.png"),
//         rotation: false
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-4.png"),
//         rotation: true
//       }
//     ]
//   }
// };

const summer = { ...defaultConfetti };
//   elements: {
//     custom: [
//       {
//         content: require("@/assets/img/confetti/Bitcoin-vector.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-1.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-2.png"),
//         rotation: true
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-3.png"),
//         rotation: false
//       },
//       {
//         content: require("@/assets/img/confetti/[replace]/image-4.png"),
//         rotation: true
//       }
//     ]
//   }
// };

const mexico = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/mexico/image-1.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/mexico/image-2.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/mexico/image-3.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/mexico/image-4.png"),
        rotation: true,
      },
    ],
  },
};

const halloween = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/halloween/halloween.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/halloween/halloween-pumpkin_1.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/halloween/ghost-halloween.png"),
        rotation: false,
        direction: "up",
      },
      {
        content: require("@/assets/img/confetti/halloween/bat-halloween.png"),
        rotation: false,
        direction: "up",
      },
    ],
  },
};

const muertos = {
  elements: {
    custom: [
      {
        content: require("@/assets/img/confetti/Bitcoin-vector.png"),
        rotation: true,
      },
      {
        content: require("@/assets/img/confetti/dia_de_muertos/catrina-1.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/dia_de_muertos/altar-de-muertos-2.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/dia_de_muertos/catrina-3.png"),
        rotation: false,
      },
      {
        content: require("@/assets/img/confetti/dia_de_muertos/calaverita-1.png"),
        rotation: false,
      },
    ],
  },
};

const xmas = {
  position: "topLeftRight",
  elements: {
    custom: [
      { content: require("@/assets/img/confetti/Bitcoin-vector.png") },
      { content: require("@/assets/img/confetti/xmas/cane.png") },
      { content: require("@/assets/img/confetti/xmas/hat.png") },
      { content: require("@/assets/img/confetti/xmas/snowflake-1.png") },
      { content: require("@/assets/img/confetti/xmas/snowflake-2.png") },
    ],
  },
};

export function createConfetti() {
  let params: any = { ...base };

  let conf;
  const now = new Date();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  if ((month === 12 && date >= 30) || (month === 1 && date < 4)) {
    // 30 de diciembre al 3 de enero => año nuevo OK
    conf = { ...newYear };
  } else if (month === 1 && date >= 4 && date <= 6) {
    // 4 al 6 de enero => reyes magos OK
    conf = { ...reyes };
  } else if (month === 2 && date > 11 && date <= 16) {
    // 12 al 16 de febrero => san valentin
    conf = { ...valentines };
  } else if (month === 2 && date == 24) {
    // 24 de febrero => dia de la bandera
    conf = { ...mexico };
  } else if (month === 3 && date > 15 && date <= 22) {
    // 16 al 22 de marzo => primavera
    conf = { ...spring };
  } else if (month === 4 && date > 27 && date <= 30) {
    // 28 al 30 de abril => dia del niño
    conf = { ...children };
  } else if (month === 5 && date > 5 && date <= 12) {
    // 6 al 12 de mayo => dia de las madres
    conf = { ...mothers };
  } else if (month === 6 && date > 9 && date <= 20) {
    // 10 al 20 de junio => dia del padre
    conf = { ...fathers };
  } else if (month === 7 && date > 15 && date <= 31) {
    // 13 al 31 de julio => verano
    conf = { ...summer };
  } else if (month === 9 && date > 9 && date <= 25) {
    // 10 al 25 de septiembre => independencia de mexico
    conf = { ...mexico };
  } else if (month === 10 && date > 24 && date <= 31) {
    // 25 al 31 de octubre => halloween
    conf = { ...halloween };
  } else if (month === 11 && date >= 1 && date <= 4) {
    // 1 al 2 de noviembre => dia de muertos
    conf = { ...muertos };
  } else if (month === 12 && date > 9 && date < 30) {
    // 10 al 29 de diciembre => navidad OK
    conf = { ...xmas };
  } else {
    conf = { ...defaultConfetti };
    // conf = { ...halloween };
  }
  params = { ...params, ...conf };
  params.elements = { ...base.elements };
  params.elements.custom[0] = {
    ...params.elements.custom[0],
    ...conf.elements.custom[0],
  };
  params.elements.custom[1] = {
    ...params.elements.custom[1],
    ...conf.elements.custom[1],
  };
  params.elements.custom[2] = {
    ...params.elements.custom[2],
    ...conf.elements.custom[2],
  };
  params.elements.custom[3] = {
    ...params.elements.custom[3],
    ...conf.elements.custom[3],
  };
  params.elements.custom[4] = {
    ...params.elements.custom[4],
    ...conf.elements.custom[4],
  };
  params.elements.custom[5] = {
    ...params.elements.custom[5],
    ...conf.elements.custom[3],
  };
  params.elements.custom[6] = {
    ...params.elements.custom[6],
    ...conf.elements.custom[3],
  };
  params.elements.custom[7] = {
    ...params.elements.custom[7],
    ...conf.elements.custom[4],
  };
  params.elements.custom[8] = {
    ...params.elements.custom[8],
    ...conf.elements.custom[4],
  };

  return new confettiKit({ ...params });
}
