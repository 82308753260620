import * as fb from "../../firebase";
// import router from "../../router";

import { BasicResponse, DataResponse } from "functions/src/responseTypes";
import {
  ADD_NODES_TO_USER,
  CHANGE_ROOT_USERNAME,
  doGetUserTree,
  GetUserTreeRequest,
  INIT,
  UserNetworkState,
} from "./UserNetworkInterfaces";
import { ActionContext } from "vuex";
import {
  BinaryTreeNodeUser,
  SearchUserResponse,
} from "functions/src/Users/UsersInterfaces";

const initialState: UserNetworkState = {
  rootNodeUsername: "",
  binaryTreeNodes: new Array<BinaryTreeNodeUser>(),
  binaryTreeMap: new Map<string, BinaryTreeNodeUser>(),
};

const state: UserNetworkState = { ...initialState };
const mutations = {
  INIT(state: UserNetworkState, currentUser: string) {
    state.rootNodeUsername = currentUser;
    state.binaryTreeNodes = new Array<BinaryTreeNodeUser>();
    state.binaryTreeMap = new Map<string, BinaryTreeNodeUser>();
  },
  ADD_NODES_TO_USER(state: UserNetworkState, tree: BinaryTreeNodeUser[]) {
    if (state.binaryTreeNodes.length == 0 || state.binaryTreeMap.size == 0) {
      //Assign tree and Create tree Map
      state.binaryTreeNodes = tree;
      tree.forEach((n) => {
        state.binaryTreeMap.set(n.username, n);
      });
    } else {
      //Find user to update
      // asign left and right childs
      tree.forEach((n) => {
        const mapNode = state.binaryTreeMap.get(n.username);
        if (mapNode) {
          //If there is a node, update
          mapNode.loadedLevels = n.loadedLevels;
          mapNode.childLeft = n.childLeft;
          mapNode.childRight = n.childRight;
        } else {
          state.binaryTreeNodes.push(n);
          state.binaryTreeMap.set(n.username, n);
        }
      });
    }
  },
  CHANGE_ROOT_USERNAME(state: UserNetworkState, newUsername: string) {
    state.rootNodeUsername = newUsername;
  },
  CLEAR_STATE() {
    Object.assign(state, initialState);
  },
};

const actions = {
  async initializeTree(context: ActionContext<UserNetworkState, any>) {
    const loggedUsername = context.rootState.auth.userProfileData._usernamePK;
    const tree = context.getters.getTreeForUser(
      loggedUsername
    ) as BinaryTreeNodeUser;
    if (tree.username == undefined || tree.username == "") {
      context.commit(INIT, loggedUsername);
      await context.dispatch(doGetUserTree, {
        username: loggedUsername,
        levels: 4,
      });
    }
  },
  async doGetUserTree(
    context: ActionContext<UserNetworkState, any>,
    request: GetUserTreeRequest
  ) {
    const getBinaryTree = fb.func.httpsCallable("Users-getBinaryTree");
    const r = await getBinaryTree(request);
    const response: DataResponse<BinaryTreeNodeUser[]> | BasicResponse = r.data;
    if (response.OPCODE == "SUCCESS") {
      const tree: BinaryTreeNodeUser[] = (
        response as DataResponse<BinaryTreeNodeUser[]>
      ).data;
      context.commit(ADD_NODES_TO_USER, tree);
      // context.commit(CHANGE_ROOT_USERNAME, newUsername);
    } else if (response.OPCODE == "ERROR") {
      throw response.error;
    }
  },
  async setRootUsername(
    context: ActionContext<UserNetworkState, any>,
    newUsername: string
  ) {
    const loggedUsername = context.rootState.auth.userProfileData._usernamePK;
    newUsername =
      newUsername == "" || newUsername == undefined
        ? loggedUsername
        : newUsername.toLowerCase();
    const tree = context.getters.getTreeForUser(
      newUsername
    ) as BinaryTreeNodeUser;
    if (
      tree.username == undefined ||
      tree.username == "" ||
      tree.loadedLevels < 4
    ) {
      // context.commit(INIT, newUsername);
      await context.dispatch(doGetUserTree, {
        username: newUsername,
        levels: 4,
      });
    }
    context.commit(CHANGE_ROOT_USERNAME, newUsername);
  },
  async searchUser(
    context: ActionContext<UserNetworkState, any>,
    username: string
  ) {
    username = username.trim().toLowerCase();
    if (username.includes("@")) {
      const searchUser = fb.func.httpsCallable("Users-searchUser");
      const r = await searchUser({ username: username });
      const response: DataResponse<SearchUserResponse> | BasicResponse = r.data;
      if (response.OPCODE == "SUCCESS") {
        username = (response as DataResponse<SearchUserResponse>).data
          .usernamePK;
      } else if (response.OPCODE == "ERROR") {
        throw response.error;
      }
    }
    const tree = context.getters.getTreeForUser(username) as BinaryTreeNodeUser;
    if (
      tree.username == undefined ||
      tree.username == "" ||
      tree.loadedLevels < 4
    ) {
      // context.commit(INIT, newUsername);
      await context.dispatch(doGetUserTree, {
        username: username,
        levels: 4,
      });
    }
    context.commit(CHANGE_ROOT_USERNAME, username);
  },
};

const getters = {
  getTreeForUser(state: UserNetworkState, getters: any, rootState: any) {
    return (username?: string): BinaryTreeNodeUser => {
      username =
        username?.toLowerCase() || rootState.auth.userProfileData._usernamePK;
      // console.log(username);
      // console.log("treeMap", state.binaryTreeMap);
      // console.log("treeNodes", state.binaryTreeNodes);
      if (username && state.binaryTreeMap.get) {
        const node = state.binaryTreeMap.get(username);
        if (node) {
          return node;
        }
      }
      return new BinaryTreeNodeUser();
    };
  },
  getRootUserNode(state: UserNetworkState): BinaryTreeNodeUser | undefined {
    if (state.binaryTreeMap.get) {
      return state.binaryTreeMap.get(state.rootNodeUsername);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
