
import { defineComponent } from "vue";
import DowngradeToLite from "./DowngradeToLite.vue";
import { subscriptionType } from "functions/src/Subscriptions/SubscriptionsInterfaces";

export default defineComponent({
  name: "gtr-basic-to-lite",
  components: {
    DowngradeToLite,
  },
  data() {
    return {
      lostBenefits: [
        "Plan de Compensación GTR",
        "Acceso a Beca GTR",
        "GTR Market-King",
        "GTR English",
        "GTR Ai Money",
        "GTR Social Impact",
        "GTR Travel",
        "GTR Points",
      ],
    };
  },
  props: {
    subscriptionName: { type: String, required: false },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["clearQuote", "requestQuote"],
});
