import { App as vueApp, createApp } from "vue";
import App from "./App.vue";
import router /* , { offlineRouter } */ from "./router";
import { store } from "./store";

import "@popperjs/core";
import "bootstrap";
import "./assets/styles/flags.scss";
import "./assets/styles/custom-bootstrap.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../node_modules/@fortawesome/fontawesome-free/css/solid.css";
import "../node_modules/@fortawesome/fontawesome-free/css/regular.css";
import "../node_modules/@fortawesome/fontawesome-free/css/brands.css";
import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
import "../node_modules/cropperjs/dist/cropper.min.css";

const app: vueApp<Element> = createApp(App);
app
  .use(store)
  .use(router)
  //.use(offlineRouter)
  .mount("#app");
