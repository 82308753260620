
import { defineComponent } from "vue";
import {
  ExpiringPoints,
  GtrLifestyleBonus,
  PointsHistory,
  RankRequalification,
} from "@/components/qualification-dashboard";
import { mapGetters } from "vuex";
import {
  doGetExpiringPoints,
  doGetGtrLifeBonus,
  doGetPointsHistory,
} from "@/store/RankQualification/RankQualificationInterfaces";

export default defineComponent({
  name: "qualification-dashboard",
  components: {
    ExpiringPoints,
    GtrLifestyleBonus,
    PointsHistory,
    RankRequalification,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters("rankQualification", {
      gtrLifeBonusList: "getGtrLifeBonus",
      expiringPoints: "getExpiringPoints",
      pointsHistory: "getPointsHistory",
      rankRequalification: "getRankRequalification",
    }),
    ...mapGetters("auth", {
      userProfile: "getUserProfile",
    }),
    ...mapGetters("ranks", {
      getRankById: "rankById",
    }),
    currentRankPriority(): number {
      return this.getRankById(this.userProfile.currentRank).priority;
    },
    hasGtrLifeBonus(): boolean {
      return (
        this.getRankById(this.userProfile.currentRank).gtrLifeBonus && true
      );
    },
  },
  methods: {
    async requestGtrLifeBonus() {
      await this.$store.dispatch("rankQualification/" + doGetGtrLifeBonus);
    },
    async requestExpiringPoints() {
      await this.$store.dispatch("rankQualification/" + doGetExpiringPoints);
    },
    async requestPointsHistory() {
      await this.$store.dispatch("rankQualification/" + doGetPointsHistory);
    },
  },
  async created() {
    await Promise.all([
      this.requestGtrLifeBonus(),
      this.requestExpiringPoints(),
      this.requestPointsHistory(),
    ]);
    this.loaded = true;
  },
});
