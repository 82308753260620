
import { PointsHistory } from "@/store/RankQualification/RankQualificationInterfaces";
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
import { Line as LineChart, ChartComponentRef } from "vue-chartjs";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  Legend,
  CategoryScale,
  Tooltip,
  ChartDataset,
  ChartData,
} from "chart.js";
import { mapGetters } from "vuex";
import { Rank } from "@/store/Ranks/RanksInterfaces";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Legend,
  Colors,
  Tooltip
);
export default defineComponent({
  name: "points-history",
  components: {
    LineChart,
  },
  props: {
    pointsHistory: {
      type: Array as PropType<Array<PointsHistory>>,
      required: true,
    },
  },
  data() {
    return {
      chartOptionsObj: {
        responsive: true,
        elements: {
          line: {
            tension: 0,
            borderWidth: 2,
            borderDash: [2],
            borderDashOffset: 1,
          },
          point: {
            radius: 1,
            borderWidth: 2,
            pointStyle: "line",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          colors: {
            enabled: true,
            forceOverride: true,
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              padding: 15,
            },
          },
        },
      },
      chartDataObj: {
        labels: [],
        datasets: [],
      } as ChartData<"line", number[], string>,
    };
  },
  computed: {
    ...mapGetters("ranks", ["allRanks"]),
    pointsHistorySorted() {
      const newPointsArray = [...this.pointsHistory];
      return newPointsArray.sort((a, b) =>
        dayjs(a.period).isBefore(dayjs(b.period)) ? -1 : 1
      );
    },
    periods() {
      return this.pointsHistorySorted.map((p) =>
        dayjs(p.period).tz("America/Mexico_city")
      );
    },
    userPointsdata() {
      return this.pointsHistorySorted.map((p) => p.points);
    },
    maxPoints() {
      return Math.max(...this.userPointsdata);
    },
    chartData() {
      return this.generateChartData();
    },
    chartOptions() {
      return this.chartOptionsObj;
    },
  },
  methods: {
    generateChartData() {
      const records = this.userPointsdata.length;

      const ranksValues = Array.from(
        (this.allRanks() as Map<string, Rank>).values()
      );
      const ranksOrdered = ranksValues
        .filter((r) => r.requiredPoints > 0)
        .sort((a, b) => {
          return a.priority - b.priority;
        });

      const datasets = new Array<ChartDataset<"line", number[]>>();
      for (let i = 0; i < ranksOrdered.length; i++) {
        const rank = ranksOrdered[i];
        const rankPoints = rank.requiredPoints;
        const rankName = rank.name;

        const rankData = new Array(records).fill(rankPoints);
        datasets.push({
          data: rankData,
          label: rankName,
        });
        if (rankPoints > this.maxPoints) {
          break;
        }
      }
      datasets.push({
        data: this.userPointsdata,
        label: "Puntos",
        pointStyle: "circle",
        borderDash: [],
        borderDashOffset: 0,
        pointRadius: 4,
        borderWidth: 3,
      });
      return {
        labels: this.periods.map((p) => p.format("MMM YY")),
        datasets: datasets.reverse(),
      };
    },
    updateChart() {
      const chartRef = this.$refs.chartRef as ChartComponentRef;
      chartRef.chart?.update();
    },
  },
  mounted() {
    this.chartDataObj = this.generateChartData();
    setTimeout(this.updateChart, 500);
  },
  watch: {
    pointsHistory() {
      this.chartDataObj = this.generateChartData();
      this.updateChart();
    },
  },
});
