import { ThunkBase } from "@/@types/common/thunk.types";
import { DocumentReference, Timestamp } from "firebase/firestore";

// Actions
export const doGetActiveEvents = "doGetActiveEvents";
export const doGetArchivedEvents = "doGetArchivedEvents";
export const doGetEventTicketOptions = "doGetEventTicketOptions";
export const doGetEventTicketPaymentOptions = "doGetEventTicketPaymentOptions";
export const doHoldTickets = "doHoldTickets";

// Mutations
export const SET_CLASSES = "SET_CLASSES";
export const SET_SCHEDULE_ACADEMY = "SET_SCHEDULE_ACADEMY";
export const SET_SCHEDULE_GTR = "SET_SCHEDULE_GTR";

export interface EventsState {
  events: Event[];
  eventsLoaded: boolean;
  eventsLoading: boolean;
  archivedEvents: Event[];
  archivedEventsLoaded: boolean;
  archivedEventsLoading: boolean;
  currentEvent: ThunkBase<Event> | null;
  ticketOptions: Record<string, ThunkBase<OptionEvent[]> | null>;
}

export interface Event {
  id?: string;
  tickets?: object;
  organizer: string;
  contact: EventContact;
  location: EventLocation;
  title: string;
  date: EventDate;
  details: string;
  ticket_options?: OptionEvent[];
  payment_options?: TicketPaymentOptions[];
  image?: string | IEventImage;
  logo?: string | IEventImage;
  accept_bitcoin?: boolean | string;
}

export interface EventContact {
  email: string;
  phone: string;
}

export interface EventLocation {
  type: string;
  address?: EventAddress;
  online_url?: string;
}

export interface EventAddress {
  name: string;
  address_1: string;
  address_2: string;
  state: string;
  country: string;
  zip: string;
  city: string;
}

export interface EventDate {
  display_end_date_time?: boolean;
  display_start_date_time?: boolean;
  end: Timestamp;
  language?: string;
  start: Timestamp;
  timezone?: string;
  type?: string;
}

export interface EventIdContext {
  event_id: string;
  ticket_option_id?: string;
}

export interface OptionEvent {
  id: string;
  quantity?: number;
  name: string;
  price: OptionEventPrice;
  total: number;
  available_quantity: number | string;
  description: string;
  type: string;
  tickets_per_order: OptionTicketPerOrder;

  tickets_per_order_maximum_quantity?: null | number;
  tickets_per_order_maximum?: boolean | string;
  tickets_per_order_minimum_quantity?: null | number;
  tickets_per_order_minimum?: boolean | string;
  price_value?: number;
  price_currency?: string;
  ticket_sales_starts?: TicketSales;
  ticket_sales_ends?: TicketSales;

  is_pack?: string;
  pack?: OptionEventPack;
  pack_option?: string;
  pack_quantity?: number;

  ticket_sales_starts_type: string;
  ticket_sales_starts_unit: string;
  ticket_sales_starts_value: number | Timestamp | Date;
  ticket_sales_starts_hide: boolean | string;
  ticket_sales_ends_type: string;
  ticket_sales_ends_unit: string;
  ticket_sales_ends_value: number | Timestamp | Date;
  ticket_sales_ends_hide: boolean | string;
}

export interface OptionEventPack {
  option_id?: DocumentReference;
  option?: OptionEvent;
  quantity?: number;
}

export interface TicketSales {
  type: string;
  unit?: string;
  value: number | Timestamp;
  hide?: boolean;
}

export interface OptionTicketPerOrder {
  maximum_quantity: null | number;
  minimum_quantity: null | number;
}

export interface OptionEventPrice {
  value: number;
  currency: string;
  total: number;
}

export interface AdminEventData extends Event {
  email: string;
  phone: string;
  eventDateStart: Date;
  eventDateEnd: Date;
  location_type: string;
  addr_name: string;
  addr_address_1: string;
  addr_address_2: string;
  addr_city: string;
  addr_country: string;
  addr_state: string;
  addr_zip: string;
  online_url: string;
  eventId?: string;
  currency?: string;
  accept_bitcoin?: boolean | string;
}

export interface ICreatTicketOption {
  eventId: string;
  data: TicketPaymentOptions;
}

export interface TicketOptionFormInterface {
  saveData: () => void;
  resetData: () => void;
  savePaymentOptions: () => void;
}

export interface TicketPaymentOptions {
  id?: string;
  name: string;
  description: string;
  options: PaymentOptions[];
  start?: Date | Timestamp | any;
  end?: Date | Timestamp | any;
}

export interface PaymentOptions {
  percentage: number;
  type: string;
  value: number | Timestamp;
  position: number;
}

export interface IEventImage {
  src: string;
  base64: string | null;
  mimeType: MimeTypes;
}

export type MimeTypes =
  | "audio/x-mpeg"
  | "application/postscript"
  | "audio/x-aiff"
  | "application/x-aim"
  | "image/x-jg"
  | "video/x-ms-asf"
  | "audio/basic"
  | "video/x-msvideo"
  | "video/x-rad-screenplay"
  | "application/x-bcpio"
  | "application/octet-stream"
  | "image/bmp"
  | "text/html"
  | "application/x-cdf"
  | "application/pkix-cert"
  | "application/java"
  | "application/x-cpio"
  | "application/x-csh"
  | "text/css"
  | "application/msword"
  | "application/xml-dtd"
  | "video/x-dv"
  | "application/x-dvi"
  | "application/vnd.ms-fontobject"
  | "text/x-setext"
  | "image/gif"
  | "application/x-gtar"
  | "application/x-gzip"
  | "application/x-hdf"
  | "application/mac-binhex40"
  | "text/x-component"
  | "image/ief"
  | "text/vnd.sun.j2me.app-descriptor"
  | "application/java-archive"
  | "text/x-java-source"
  | "application/x-java-jnlp-file"
  | "image/jpeg"
  | "application/javascript"
  | "text/plain"
  | "application/json"
  | "audio/midi"
  | "application/x-latex"
  | "audio/x-mpegurl"
  | "image/x-macpaint"
  | "text/troff"
  | "application/mathml+xml"
  | "application/x-mif"
  | "video/quicktime"
  | "video/x-sgi-movie"
  | "audio/mpeg"
  | "video/mp4"
  | "video/mpeg"
  | "video/mpeg2"
  | "application/x-wais-source"
  | "application/x-netcdf"
  | "application/oda"
  | "application/vnd.oasis.opendocument.database"
  | "application/vnd.oasis.opendocument.chart"
  | "application/vnd.oasis.opendocument.formula"
  | "application/vnd.oasis.opendocument.graphics"
  | "application/vnd.oasis.opendocument.image"
  | "application/vnd.oasis.opendocument.text-master"
  | "application/vnd.oasis.opendocument.presentation"
  | "application/vnd.oasis.opendocument.spreadsheet"
  | "application/vnd.oasis.opendocument.text"
  | "application/vnd.oasis.opendocument.graphics-template"
  | "application/vnd.oasis.opendocument.text-web"
  | "application/vnd.oasis.opendocument.presentation-template"
  | "application/vnd.oasis.opendocument.spreadsheet-template"
  | "application/vnd.oasis.opendocument.text-template"
  | "application/ogg"
  | "video/ogg"
  | "audio/ogg"
  | "application/x-font-opentype"
  | "audio/flac"
  | "application/annodex"
  | "audio/annodex"
  | "video/annodex"
  | "application/xspf+xml"
  | "image/x-portable-bitmap"
  | "image/pict"
  | "application/pdf"
  | "image/x-portable-graymap"
  | "audio/x-scpls"
  | "image/png"
  | "image/x-portable-anymap"
  | "image/x-portable-pixmap"
  | "application/vnd.ms-powerpoint"
  | "image/vnd.adobe.photoshop"
  | "image/x-quicktime"
  | "image/x-cmu-raster"
  | "application/rdf+xml"
  | "image/x-rgb"
  | "application/vnd.rn-realmedia"
  | "application/rtf"
  | "text/richtext"
  | "application/font-sfnt"
  | "application/x-sh"
  | "application/x-shar"
  | "application/x-stuffit"
  | "application/x-sv4cpio"
  | "application/x-sv4crc"
  | "image/svg+xml"
  | "application/x-shockwave-flash"
  | "application/x-tar"
  | "application/x-tcl"
  | "application/x-tex"
  | "application/x-texinfo"
  | "image/tiff"
  | "text/tab-separated-values"
  | "application/x-font-ttf"
  | "application/x-ustar"
  | "application/voicexml+xml"
  | "image/x-xbitmap"
  | "application/xhtml+xml"
  | "application/vnd.ms-excel"
  | "application/xml"
  | "image/x-xpixmap"
  | "application/xslt+xml"
  | "application/vnd.mozilla.xul+xml"
  | "image/x-xwindowdump"
  | "application/vnd.visio"
  | "audio/x-wav"
  | "image/vnd.wap.wbmp"
  | "text/vnd.wap.wml"
  | "application/vnd.wap.wmlc"
  | "text/vnd.wap.wmlsc"
  | "application/vnd.wap.wmlscriptc"
  | "video/x-ms-wmv"
  | "application/font-woff"
  | "application/font-woff2"
  | "model/vrml"
  | "application/wspolicy+xml"
  | "application/x-compress"
  | "application/zip";
