
import { defineComponent } from "vue";
import {
  setLeaderUsername,
  UnilevelMember,
} from "@/store/Unilevel/UnilevelInterfaces";
import { CarrerSummaryTable } from "./";
import { images } from "@/mixins/images";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "carrer-summary-card",
  components: { CarrerSummaryTable },
  props: {
    leader: { required: false, type: Object as () => UnilevelMember },
    searching: { required: true, type: Boolean, default: false },
  },
  emits: ["searchUser", "reloadUser"],
  data() {
    return {
      leaderPicture: "",
      searchUsernameString: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserProfile", "getUsernamePK"]),
    loggedUserEnroller(): string {
      return this.getUserProfile.enroller;
    },
    userNotFound(): boolean {
      return this.leader?.username == "@@USER_NOT_FOUND@@";
    },
    isVIP(): boolean {
      const isVIP = this.leader?.subscriptions.has("mmi-colombia") ?? false;
      return isVIP;
    },
  },
  methods: {
    changeTeamLeader(newLeaderUsername?: string) {
      this.$store.dispatch("unilevel/" + setLeaderUsername, newLeaderUsername);
    },
    searchUser() {
      this.$emit("searchUser", this.searchUsernameString.toLowerCase());
    },
    refresh() {
      this.$emit("reloadUser", this.leader?.username);
    },
  },
  created() {
    if (this.leader) {
      this.getProfilePicture(this.leader.pictureUrl).then((url) => {
        this.leaderPicture = url;
      });
    }
  },
  setup() {
    const getProfilePicture = images.methods.getProfilePicture;
    return {
      getProfilePicture,
    };
  },
  watch: {
    "leader.pictureUrl": function (newValue) {
      if (newValue != undefined) {
        this.getProfilePicture(newValue).then((url) => {
          this.leaderPicture = url;
        });
      }
    },
  },
});
