
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import { errorModal, informationModal } from "../modals";
import UserNotFound from "@/components/search-users/UserNotFound.vue";
import Searching from "@/components/search-users/Searching.vue";
import ProfileCard from "../profile/ProfileCard.vue";
import { CarrerSummaryTable } from "../unilevel";
import ProfilePictureGalleryModal from "../profile/ProfilePictureGalleryModal.vue";
import { SearchUserResponse } from "../../store/User/UserInterfaces";
import ActivateNoVolume from "./users-tools/ActivateNoVolume.vue";
import AddCommissionRecord from "./users-tools/AddCommissionRecord.vue";
import ActivateRafflePrize from "./users-tools/ActivateRafflePrize.vue";
import ChangeUserEmail from "./users-tools/ChangeUserEmail.vue";
import CreateQuote from "./users-tools/CreateQuote.vue";
import ActivateQuote from "./users-tools/ActivateQuote.vue";
import * as bs from "bootstrap";

export default defineComponent({
  name: "users-tools",
  components: {
    errorModal,
    informationModal,
    ActivateNoVolume,
    AddCommissionRecord,
    ActivateRafflePrize,
    CarrerSummaryTable,
    ChangeUserEmail,
    ProfileCard,
    UserNotFound,
    Searching,
    ProfilePictureGalleryModal,
    CreateQuote,
    ActivateQuote,
  },
  data() {
    return {
      informationModal: {} as any,
      errorModal: {} as any,
      searching: false,
      notFound: false,
      username: "",
      userData: {} as SearchUserResponse,
      errorTitle: "",
      errorMessage: "",
      informationTitle: "",
      informationMessage: "",
    };
  },
  methods: {
    searchUser() {
      this.searching = true;
      this.notFound = false;
      this.userData = {} as SearchUserResponse;

      const searchUserFunction = firebase
        .functions()
        .httpsCallable("Admin-SearchUser");
      searchUserFunction({ username: this.username })
        .then((result) => {
          const response = result.data;

          if (response.OPCODE == "SUCCESS") {
            const user: SearchUserResponse = response.data;
            user.subscriptions = new Map(Object.entries(user.subscriptions));

            if (user.metadata != undefined) {
              user.metadata.creationTime = new Date(user.metadata.creationTime);
              user.metadata.lastSignInTime = new Date(
                user.metadata.lastSignInTime
              );
              user.metadata.lastRefreshTime = new Date(
                user.metadata.lastRefreshTime
              );
            }

            if (user.scholarshipData != undefined) {
              const endDateTimeStamp: any = user.scholarshipData.periodEndDate;
              user.scholarshipData.periodEndDate =
                new firebase.firestore.Timestamp(
                  endDateTimeStamp._seconds,
                  endDateTimeStamp._nanoseconds
                ).toDate();
            }
            this.userData = user;
          } else if (response.OPCODE == "ERROR") {
            const error = response.error;
            if (error.name == "USER_NOT_FOUND") {
              this.notFound = true;
            } else {
              alert(error.message);
              console.error(error);
            }
          }
          this.searching = false;
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Error al llamar la función de búsqueda de usuarios: " +
              error.message
          );
          this.searching = false;
        });
    },
    updateEmail(newEmail: string) {
      this.userData.contact.email.data = newEmail;
      this.informationTitle = "Correco electrónico cambiado exitosamente";
      this.informationMessage =
        "El correo electrónico del usuario se ha cambiado exitosamente.";
      this.informationModal.show();
    },
    quoteActivated() {
      this.informationTitle = "Ficha de Pago activada";
      this.informationMessage = "La ficha de pago sido activada exitosamente.";
      this.informationModal.show();
    },
    quoteCreated() {
      this.informationTitle = "Usuario activado";
      this.informationMessage = "El usuario ha sido activado exitosamente.";
      this.informationModal.show();
    },
    userActivated() {
      this.informationTitle = "Usuario activado";
      this.informationMessage = "El usuario ha sido activado exitosamente.";
      this.informationModal.show();
    },
    commissionAdded() {
      this.informationTitle = "Comisión Agregada";
      this.informationMessage = "La comisión ha sido agregada exitosamente.";
      this.informationModal.show();
    },
    showError(title: string, message: string) {
      this.errorTitle = title;
      this.errorMessage = message;
      this.errorModal.show();
    },
  },
  mounted() {
    const infModalElement = document.getElementById("information-modal");
    this.informationModal = bs.Modal.getOrCreateInstance(infModalElement);
    infModalElement?.addEventListener("hidden.bs.modal", () => {
      this.informationTitle = "";
      this.informationMessage = "";
    });

    const errorModalElement = document.getElementById("error-modal");
    this.errorModal = bs.Modal.getOrCreateInstance(errorModalElement);
    errorModalElement?.addEventListener("hidden.bs.modal", () => {
      this.errorTitle = "";
      this.errorMessage = "";
    });
  },
});
