import { httpsCallable } from "firebase/functions";
import * as fb from "../../firebase";
import {
  CLEAR_CURRENT_DISPLAYED_USER,
  CLEAR_DATA,
  CLEAR_DISPLAYED_TEAM,
  MyTeamMember,
  MyTeamState,
  SET_IS_LOADING,
  SET_LEADER_TEAM_CACHE,
  sortableColumns,
  UPDATE_BREADCRUMBS,
  UPDATE_CURRENT_DISPLAYED_USER,
  UPDATE_DISPLAYED_TEAM,
  UPDATE_SORT_CONFIGURATION,
} from "./MyTeamInterfaces";
import { BasicResponse, DataResponse } from "functions/src/responseTypes";
import { ActionContext } from "vuex";
import { images } from "@/mixins/images";

const initialState: MyTeamState = {
  displayedTeam: [],
  fullTeamCache: new Map(),
  currentDisplayedUser: "",
  breadcrumbs: [],
  isLoading: false,
  sortConfiguration: [],
};

const state: MyTeamState = { ...initialState };

const mutations = {
  UPDATE_DISPLAYED_TEAM(
    state: MyTeamState,
    displayedTeam: Array<MyTeamMember>
  ) {
    state.displayedTeam = displayedTeam;
  },
  SET_LEADER_TEAM_CACHE(
    state: MyTeamState,
    teamInfo: {
      teamLeader: string;
      teamData: { uplines: Array<string>; leaderTeam: Array<MyTeamMember> };
    }
  ) {
    state.fullTeamCache.set(teamInfo.teamLeader, teamInfo.teamData);
  },
  UPDATE_CURRENT_DISPLAYED_USER(state: MyTeamState, username: string) {
    state.currentDisplayedUser = username;
  },
  UPDATE_BREADCRUMBS(state: MyTeamState, breadcrumbs: Array<string>) {
    state.breadcrumbs = breadcrumbs;
  },
  SET_IS_LOADING(state: MyTeamState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  CLEAR_DATA(state: MyTeamState) {
    initialState.fullTeamCache = new Map();
    state.fullTeamCache = new Map();
    state.breadcrumbs = [];
    state.displayedTeam = [];
    state.currentDisplayedUser = "";
    state.isLoading = false;
    Object.assign(state, initialState);
  },
  CLEAR_DISPLAYED_TEAM(state: MyTeamState) {
    state.displayedTeam = [];
  },
  CLEAR_FULL_TEAM_CACHE(state: MyTeamState) {
    state.fullTeamCache = new Map();
  },
  CLEAR_CURRENT_DISPLAYED_USER(state: MyTeamState) {
    state.currentDisplayedUser = "";
  },
  CLEAR_BREADCRUMBS(state: MyTeamState) {
    state.breadcrumbs = [];
  },
  UPDATE_SORT_CONFIGURATION(
    state: MyTeamState,
    sortConfiguration: [Map<sortableColumns, "asc" | "desc">] | []
  ) {
    state.sortConfiguration = sortConfiguration;
  },
};

const actions = {
  async doGetTeamForUser(
    context: ActionContext<MyTeamState, any>,
    params: { username: string; forceUpdate: boolean } = {
      username: "",
      forceUpdate: false,
    }
  ) {
    const { commit, dispatch, getters } = context;
    commit(SET_IS_LOADING, true);
    commit(CLEAR_DISPLAYED_TEAM);
    commit(CLEAR_CURRENT_DISPLAYED_USER);
    dispatch("locallyAdjustBreadcrumbs", params.username);

    let teamToDisplay:
      | { uplines: Array<string>; leaderTeam: Array<MyTeamMember> }
      | undefined = undefined;
    teamToDisplay = await getters.getCachedTeam(params.username);
    if (!teamToDisplay || params.forceUpdate === true) {
      // Here we will call the API to get the team for the given
      const getFullTeamForUser = httpsCallable(
        fb.functions,
        "MyTeam-GetFullTeamForUser"
      );
      await getFullTeamForUser({
        username: params.username,
        forceUpdate: params.forceUpdate,
      })
        .then(async (result) => {
          const response = result.data as BasicResponse;
          // console.log(response);
          if (response.OPCODE === "SUCCESS") {
            const data = (response as DataResponse<any>).data as {
              teamMembers: Array<MyTeamMember>;
              userUplines: Array<string>;
            };

            const profilePictures = data.teamMembers.map(async (member) => {
              member.profile_picture = await images.methods.getProfilePicture(
                member.pictureUrl
              );

              // Count how many downlines the user has
              // by counting how many times the user appears in the uplines array
              member.downlines = data.teamMembers.filter((m) =>
                m.uplines.includes(member._usernamePK)
              ).length;
            });

            await Promise.all(profilePictures).then(() => {
              teamToDisplay = {
                uplines: data.userUplines,
                leaderTeam: data.teamMembers,
              };
              commit(SET_LEADER_TEAM_CACHE, {
                teamLeader: params.username,
                teamData: teamToDisplay,
              });
            });
          } else {
            if (response.OPCODE === "ERROR") {
              throw new Error(response.message);
            }
          }
        })
        .catch(async (error) => {
          console.error(error);
          teamToDisplay = {
            uplines: await getters.getBreadcrumbs,
            leaderTeam: [],
          };
        });
    }
    commit(UPDATE_DISPLAYED_TEAM, teamToDisplay?.leaderTeam);
    commit(UPDATE_BREADCRUMBS, teamToDisplay?.uplines);
    commit(UPDATE_CURRENT_DISPLAYED_USER, params.username);
    dispatch("sortDisplayedTeam");
    commit(SET_IS_LOADING, false);
  },
  doRefreshTeamForUser({ dispatch }: ActionContext<MyTeamState, any>) {
    dispatch("doGetTeamForUser", {
      username: state.currentDisplayedUser,
      forceUpdate: true,
    });
  },
  locallyAdjustBreadcrumbs(
    { commit }: ActionContext<MyTeamState, any>,
    username: string
  ) {
    const index = state.breadcrumbs.indexOf(username);
    if (index > -1) {
      commit(UPDATE_BREADCRUMBS, state.breadcrumbs.slice(0, index + 1));
    } else {
      commit(UPDATE_BREADCRUMBS, [...state.breadcrumbs, username]);
    }
  },
  clearData({ commit }: any) {
    commit(CLEAR_DATA);
  },
  updateSortConfiguration(
    { commit, dispatch }: ActionContext<MyTeamState, any>,
    sortColumn: { column: sortableColumns; direction: "asc" | "desc" },
    executeSort: boolean = true
  ) {
    // Get the current sort configuration for the column
    const currentSortConfiguration = state.sortConfiguration.find((c) =>
      c.get(sortColumn.column)
    );

    if (!currentSortConfiguration) {
      // If there is no sort configuration, add it to the configuration preserving the rest
      const newSortConfiguration = new Map<sortableColumns, "asc" | "desc">();
      newSortConfiguration.set(sortColumn.column, sortColumn.direction);
      commit(UPDATE_SORT_CONFIGURATION, [
        ...state.sortConfiguration,
        newSortConfiguration,
      ]);
    } else if (
      // If the column is already in the configuration and the direction is the same, remove it preserving the rest
      currentSortConfiguration.get(sortColumn.column) === sortColumn.direction
    ) {
      const newSortConfiguration = state.sortConfiguration.filter(
        (c) => !c.get(sortColumn.column)
      );
      commit(UPDATE_SORT_CONFIGURATION, newSortConfiguration);
    } else {
      // If the column is already in the configuration but the direction is different, update it
      const newSortConfiguration = state.sortConfiguration.map((c) => {
        if (c.get(sortColumn.column)) {
          c.set(sortColumn.column, sortColumn.direction);
        }
        return c;
      });
      commit(UPDATE_SORT_CONFIGURATION, newSortConfiguration);
    }
    if (executeSort) {
      dispatch("sortDisplayedTeam");
    }
  },
  sortDisplayedTeam({ commit, state }: ActionContext<MyTeamState, any>) {
    const sortConfiguration = state.sortConfiguration;
    if (sortConfiguration.length > 0) {
      const sortedTeam = [...state.displayedTeam];
      sortedTeam.sort((a: MyTeamMember, b: MyTeamMember) => {
        for (let i = 0; i < sortConfiguration.length; i++) {
          const sortColumn = Array.from(
            sortConfiguration[i].keys()
          )[0] as sortableColumns;
          const sortDirection = Array.from(sortConfiguration[i].values())[0] as
            | "asc"
            | "desc";
          let a_value = a[sortColumn];
          let b_value = b[sortColumn];

          if (sortColumn === "cmmi_end_date") {
            a_value = a_value ? "1" : "0";
            b_value = b_value ? "1" : "0";
          } else if (sortColumn === "scholarship_points") {
            a_value =
              a_value !== null && (a_value as number) < 300 ? a_value : 400;
            b_value =
              b_value !== null && (b_value as number) < 300 ? b_value : 400;
          }

          if (typeof a_value === "string" && typeof b_value === "string") {
            const a_str = a_value.toLowerCase();
            const b_str = b_value.toLowerCase();
            if (a_str < b_str) {
              return sortDirection === "asc" ? -1 : 1;
            }
            if (a_str > b_str) {
              return sortDirection === "asc" ? 1 : -1;
            }
          } else if (
            typeof a_value === "number" &&
            typeof b_value === "number"
          ) {
            if (a_value < b_value) {
              return sortDirection === "asc" ? -1 : 1;
            }
            if (a_value > b_value) {
              return sortDirection === "asc" ? 1 : -1;
            }
          }
        }
        return 0;
      });
      commit(UPDATE_DISPLAYED_TEAM, sortedTeam);
    }
  },
};

const getters = {
  getDisplayedTeam: (state: MyTeamState) => state.displayedTeam,
  getIsLoading: (state: MyTeamState) => state.isLoading,
  getBreadcrumbs: (state: MyTeamState) => state.breadcrumbs,
  getCurrentDisplayedUser: (state: MyTeamState) => state.currentDisplayedUser,
  getCachedTeam: (state: MyTeamState) => (username: string) =>
    state.fullTeamCache.get(username),
  getSortConfiguration: (state: MyTeamState) => state.sortConfiguration,
  isSortedBy:
    (state: MyTeamState) =>
    (column: sortableColumns, direction: "asc" | "desc") =>
      state.sortConfiguration.some((c) => c.get(column) === direction),
  getColumnSortDirection: (state: MyTeamState) => (column: sortableColumns) => {
    const sortConfiguration = state.sortConfiguration.find((c) =>
      c.get(column)
    );
    return sortConfiguration ? sortConfiguration.get(column) : "";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
