import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-column justify-content-between"
}
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 1,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_6 = {
  key: 2,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_7 = {
  key: 3,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_8 = {
  key: 4,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_9 = {
  key: 5,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_10 = {
  key: 6,
  class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4"
}
const _hoisted_11 = { class: "col-12 col-lg-6 col-xxl-4 d-flex flex-row mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_card = _resolveComponent("status-card")!
  const _component_gtr_vip = _resolveComponent("gtr-vip")!
  const _component_gtr_basic = _resolveComponent("gtr-basic")!
  const _component_gtr_lite = _resolveComponent("gtr-lite")!
  const _component_gtr_basic_to_lite = _resolveComponent("gtr-basic-to-lite")!
  const _component_gtr_vip_to_lite = _resolveComponent("gtr-vip-to-lite")!
  const _component_mmi_colombia = _resolveComponent("mmi-colombia")!
  const _component_checkout_modal = _resolveComponent("checkout-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.getUserCurrentPackage !== 'none')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_status_card, {
                "card-type": "package",
                "subscription-id": _ctx.academySubscriptionId,
                "package-id": _ctx.getUserCurrentPackage,
                lastQuote: _ctx.quote,
                isQuoting: _ctx.isQuoting,
                onClearQuote: _ctx.clearQuote,
                onRequestQuote: _ctx.createQuote
              }, null, 8, ["subscription-id", "package-id", "lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_status_card, {
                "card-type": "license",
                "subscription-id": _ctx.licenseSubscriptionId,
                "package-id": _ctx.getUserCurrentPackage,
                lastQuote: _ctx.quote,
                isQuoting: _ctx.isQuoting,
                onClearQuote: _ctx.clearQuote,
                onRequestQuote: _ctx.createQuote
              }, null, 8, ["subscription-id", "package-id", "lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'none')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_gtr_vip, {
              "subscription-id": "gtr-vip",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'none')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_gtr_basic, {
              "subscription-id": "trading-academy",
              "subscription-name": "GTR Básico",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'none')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_gtr_lite, {
              "subscription-id": "gtr-lite",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'gtr-basic' && _ctx.isUserActive === false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_gtr_basic_to_lite, {
              "subscription-id": "gtr-lite",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'gtr-vip' && _ctx.isUserActive === false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_gtr_vip_to_lite, {
              "subscription-id": "gtr-lite",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.getUserCurrentPackage === 'gtr-lite')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_gtr_basic, {
              "subscription-name": "GTR Básico",
              "subscription-id": "trading-academy",
              "promotion-type": "upgrade",
              "promotion-id": "lite-to-basic-upgrade",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_mmi_colombia, {
          "subscription-name": "Colombia<br \\>Mastermind Intensive",
          "subscription-id": "mmi-colombia",
          lastQuote: _ctx.quote,
          isQuoting: _ctx.isQuoting,
          onClearQuote: _ctx.clearQuote,
          onRequestQuote: _ctx.createQuote
        }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
      ])
    ]),
    _createVNode(_component_checkout_modal, {
      quote: _ctx.quote,
      onClearQuote: _ctx.clearQuote,
      onRenewQuote: _ctx.createQuote,
      onPaid: _ctx.paid
    }, null, 8, ["quote", "onClearQuote", "onRenewQuote", "onPaid"])
  ], 64))
}