// export const  = "";
// Actions
export const doGetGtrLifeBonus = "doGetGtrLifeBonus";
export const doGetExpiringPoints = "doGetExpiringPoints";
export const doGetPointsHistory = "doGetPointsHistory";

// Mutations
export const UPDATE_USER_GTR_LIFESTYLE_BONUS =
  "UPDATE_USER_GTR_LIFESTYLE_BONUS";
export const UPDATE_USER_EXPIRING_POINTS = "UPDATE_USER_EXPIRING_POINTS";
export const UPDATE_USER_POINTS_HISTORY = "UPDATE_USER_POINTS_HISTORY";
export const UPDATE_USER_RANK_REQUALIF = "UPDATE_USER_RANK_REQUALIF";

export interface RankQualificationState {
  bonusList: Array<GtrLifestyleBonus>;
  expiringPoints: Array<ExpiringPoints>;
  pointsHistory: Array<PointsHistory>;
  rankRequalification: Map<string, Array<RankStatus>>;
  gtrLifeBonusConfig: {
    status: Map<string, GtrBonusStatusConfig>;
    type: Map<string, GtrBonusTypesConfig>;
  };
}

export interface server__GtrLifestyleBonus {
  _id: number;
  _period: string;
  _rankId: string;
  _usernamePK: string;
  amount: number;
  dateEarned: Date;
  dateExpires: Date;
  description: string;
  status: "new" | "approved" | "rejected" | "expired" | "paid" | "review";
  type: "car" | "gtrlife" | "department";
}

export interface GtrLifestyleBonus {
  id: number;
  period: string;
  amount: number;
  status: "new" | "approved" | "rejected" | "expired" | "paid" | "review";
  type: "gtrCar" | "gtrLife" | "gtrDep";
}

export interface ExpiringPoints {
  date: Date;
  left: number;
  right: number;
  total: number;
}

export interface PointsHistory {
  period: Date;
  points: number;
}

export interface RankStatus {
  period: string;
  status: boolean;
}

export interface GtrBonusTypesConfig {
  code: string;
  label: string;
  icon: string;
}

export interface GtrBonusStatusConfig {
  code: string;
  label: string;
  icon: string;
  colorClass: string;
  amountClass: string;
}
