
import "dayjs/locale/es";
import { ComponentPublicInstance, defineComponent } from "vue";
import { OptionEvent } from "@/store/Events/EventsInterfaces";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import _ from "lodash";
import dayjs from "dayjs";

export default defineComponent({
  name: "new-ticket-option-form",
  props: ["option", "options", "id", "event"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      currentData: {} as OptionEvent,
      eventData: {} as OptionEvent,
      textareaOpacity: 0.65 as number,
      currency: null,
      ticketSales: {
        never: "Nunca",
        immediately: "Inmediatamente",
        after_starts: "Después de comenzar",
        before_starts: "Antes de comenzar",
        after_ends: "Después de finalizar",
        before_ends: "Antes de finalizar",
        fix_date: "Fecha y hora fija",
      },
      units: {
        minutes: "Minuto(s)",
        hours: "Hora(s)",
        days: "Día(s)",
        weeks: "Semana(s)",
        months: "Mes(es)",
      },
      is_pack: "is_not_pack" as string,
      eventDataOriginal: {
        ticket_sales_starts_type: "immediately",
        ticket_sales_ends_type: "never",
        ticket_sales_starts_hide: "false",
        ticket_sales_ends_hide: "false",
        tickets_per_order_minimum: "false",
        tickets_per_order_maximum: "false",
        type: "paid",
      } as OptionEvent,
    };
  },
  methods: {
    formatNumber(val: number | string, currency: null) {
      if (typeof val == "string") val = val.replaceAll(",", "");
      if (Number.isNaN(val)) return "";
      let formatted = Number(val).toLocaleString();
      if (currency) formatted = `$ ${formatted} ${currency}`;
      return formatted;
    },
    formatDate(date) {
      return dayjs(date)
        .locale("es-mx")
        .format("DD \\d\\e MMMM \\d\\e YYYY • hh:mm a");
    },
    resetData() {
      this.currentData = _.cloneDeep({
        ...this.eventDataOriginal,
      }) as OptionEvent;
      this.is_pack = "is_not_pack";
    },
    saveData() {
      const parent: any = this.$parent?.$parent?.$parent;
      const data = {
        ...this.currentData,
        price_currency: this.event?.currency ?? "USD",
      };

      this.$store
        .dispatch("events/createTicketOption", {
          data,
          event_id: parent.event.id,
        })
        .then(() => {
          const modal = this.$parent as ComponentPublicInstance<{
            dismiss: () => void;
          }>;
          const parent = this.$parent?.$parent?.$parent
            ?.$parent as ComponentPublicInstance<{
            getData: () => void;
          }>;

          if (modal) modal.dismiss();
          if (parent) parent.getData();
          this.resetData();
        })
        .catch((err) => console.error(err));
    },
    modifyValue(evt) {
      const {
        target: { name, value: $value },
      } = evt;
      let value = $value;
      if (value === "is_pack") value = true;
      if (value === "is_not_pack") value = false;

      if (name == "is_pack") this.is_pack = value ? "is_pack" : "is_not_pack";
      let val =
        value.length > 0 ||
        typeof value == "boolean" ||
        value.constructor.name === "Date"
          ? value
          : " ";
      if (
        [
          "price_value",
          "tickets_per_order_minimum_quantity",
          "tickets_per_order_maximum_quantity",
          "available_quantity",
          "pack_quantity",
        ].includes(name)
      )
        val = this.formatNumber(val, this.currency ?? null);

      this.currentData = {
        ...this.currentData,
        [name]: val,
      };
    },
    modifyDateStart(value) {
      this.modifyValue({
        target: { name: "ticket_sales_starts_value", value },
      });
    },
    modifyDateEnd(value) {
      // console.log("modifyDateEnd", value);
      this.modifyValue({ target: { name: "ticket_sales_ends_value", value } });
    },
    scrolled(evt) {
      const element = evt.target;
      const max = 40;
      let scroll = element.scrollTop / max;
      const top = 0.65;

      if (scroll >= top) scroll = top;
      if (scroll <= 0) scroll = 0;

      scroll = Math.abs(scroll - top);

      this.textareaOpacity = scroll;
    },
  },
  computed: {
    canBePack() {
      return this.options.filter((ele) => !ele.pack).length > 0;
    },
    editing() {
      let modifying = false;
      Object.entries(this.currentData).map(([key, val]) => {
        if (modifying) return;
        modifying = this.eventDataOriginal[key] != val;
      });

      return modifying;
    },
    // ticketSalesEndsValue(): Date {
    //   const ticket_sales_ends_value =
    //     this.eventData.ticket_sales_ends_value ||
    //     this.currentData.ticket_sales_ends_value;
    //   if (
    //     typeof ticket_sales_ends_value == "number" ||
    //     typeof ticket_sales_ends_value == "undefined"
    //   )
    //     return new Date();

    //   if (ticket_sales_ends_value.constructor.name === "Date")
    //     return ticket_sales_ends_value;

    //   return new Date(
    //     parseInt(
    //       ticket_sales_ends_value.toMillis
    //         ? ticket_sales_ends_value.toMillis().toString()
    //         : ticket_sales_ends_value.toString()
    //     )
    //   );
    // },
    // ticketSalesStartsValue(): Date {
    //   const ticket_sales_starts_value =
    //     this.currentData.ticket_sales_starts_value ||
    //     this.eventData.ticket_sales_starts_value;

    //   if (
    //     typeof ticket_sales_starts_value == "number" ||
    //     typeof ticket_sales_starts_value == "undefined"
    //   )
    //     return new Date();

    //   if (ticket_sales_starts_value.constructor.name === "Date")
    //     return ticket_sales_starts_value as Date;

    //   return new Date(
    //     parseInt(
    //       ticket_sales_starts_value.toMillis
    //         ? ticket_sales_starts_value.toMillis().toString()
    //         : ticket_sales_starts_value.toString()
    //     )
    //   );
    // },
    allOptions() {
      return this.currentData.is_pack || this.eventData.is_pack
        ? this.options.filter((ele) => !ele.pack)
        : [];
    },
  },
  watch: {
    editing() {
      this.$emit("modifying", this.editing);
    },
  },
  mounted() {
    this.eventDataOriginal = {
      ...this.eventDataOriginal,
      price_currency: this.event?.currency ?? "USD",
      available_quantity: "1,000",
    };

    // console.log(
    //   typeof this.eventDataOriginal.ticket_sales_starts?.value,
    //   this.eventDataOriginal.ticket_sales_starts?.value
    // );

    // const ticket_sales_starts_value =
    //   this.eventDataOriginal.ticket_sales_starts?.value.toMillis()
    //     ? new Date(this.eventDataOriginal.ticket_sales_starts?.value.toMillis())
    //     : this.eventDataOriginal.ticket_sales_starts?.value;

    this.currentData = _.cloneDeep({
      ...this.eventDataOriginal,
    }) as OptionEvent;
  },
});
