import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = {
  key: 0,
  class: "row",
  id: "schedule"
}
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_week_schedule = _resolveComponent("week-schedule")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isUserActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_week_schedule, {
              scheduleId: "trading-academy",
              title: "Clases en vivo",
              titleIcon: "fas fa-broadcast-tower",
              scheduleType: "academy"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}