
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import VirtualClassroom from "../components/subscriptions/VirtualClassroom.vue";

export default defineComponent({
  name: "recorded-classes",
  components: {
    VirtualClassroom,
  },
  computed: {
    ...mapGetters("subs", ["isUserActive"]),
  },
});
