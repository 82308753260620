
import { defineComponent } from "vue";
import uid from "lodash.uniqueid";
import { Modal as BSModal } from "bootstrap";
type Modal = typeof BSModal;

export default defineComponent({
  name: "modal-component",
  props: [
    "buttonClass",
    "buttonStyle",
    "buttonDisabled",
    "onClickBtn",
    "title",
    "button",
    "modalSize",
    "id",
    "modalBodyStyle",
    "modalStyle",
    "modalContentStyle",
    "modalDialogStyle",
    "showCloseButton",
    "onOpen",
    "eventId",
    "open",
    "noButton",
  ],
  data() {
    return {
      styles: {
        primary: "btn-primary",
        secondary: "btn-secondary",
        success: "btn-success",
        danger: "btn-danger",
        warning: "btn-warning",
        info: "btn-info",
        light: "btn-light",
        dark: "btn-dark",
      },
      show: false as boolean,
      modal: {} as Modal,
      modalData: this,
    };
  },
  computed: {
    size() {
      if (!this.modalSize) return "modal-xl";
      return this.modalSize;
    },
    uniqueId() {
      return this.id || `modal-${uid()}`;
    },
    currentButtonStyle() {
      let style = this.buttonStyle;

      if (style && style.search("btn") != -1) {
        const $style = Object.entries(this.styles).find(
          (obj) => obj[1] == style
        );
        if ($style && $style.length > 0) style = $style[0];
      }
      if (!this.styles[style]) style = "primary";
      return this.styles[style];
    },
    show_close_btn() {
      return this.showCloseButton ?? true;
    },
  },
  methods: {
    onClick(evt) {
      evt.preventDefault();
      this.show = true;

      if (this.onClickBtn) this.onClickBtn();
      if (this.onOpen) this.onOpen();
    },
    dismiss(evt) {
      if (evt) evt.preventDefault();
      this.show = false;
    },
  },
  beforeUnmount() {
    const element = document.querySelector(".btn-close");
    if (element instanceof HTMLElement) element.click();
  },
  watch: {},
});
