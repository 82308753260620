
import { defineComponent } from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { images } from "@/mixins/images";
import dayjs from "dayjs";
export default defineComponent({
  name: "TicketTemplate",
  props: ["ticket", "event"],
  components: {
    VueQrcode,
  },
  data() {
    return {
      logo: null as any,
      options: {
        maskPattern: 7,
        scale: 10,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
        margin: 0,
      },
    };
  },
  computed: {
    date() {
      return (date) => {
        return dayjs(date._seconds * 1000).format("MMMM DD, YYYY|HH:mm A");
      };
    },
  },
  methods: {
    async getLogo() {
      if (this.ticket.event.logo)
        this.logo = await images.methods.getImage(this.ticket.event.logo);
    },
  },
  mounted() {
    this.getLogo();
  },
});
