
import { defineComponent } from "vue";

import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "commissions-detail",
  props: {
    commissions: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  computed: {
    ...mapGetters("commissions", [
      "getCommissionTypeConfig",
      "getCommissionStatusConfig",
    ]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    translateType(type: string): string {
      return this.getCommissionTypeConfig(type)?.label || type;
    },
    typeIconClass(type: string): string {
      return this.getCommissionTypeConfig(type)?.icon || "";
    },
    translateStatus(status: string): string {
      return this.getCommissionStatusConfig(status)?.label || status;
    },
    statusIconClass(status: string): string {
      return this.getCommissionStatusConfig(status)?.icon || "";
    },
    statusColorClass(status: string): string {
      return this.getCommissionStatusConfig(status)?.colorClass || "";
    },
    amountClass(status: string): string {
      return this.getCommissionStatusConfig(status)?.amountClass || "";
    },
  },
});
