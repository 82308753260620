import {
  GtrBonusStatusConfig,
  GtrBonusTypesConfig,
} from "./RankQualificationInterfaces";

export const GtrBonusTypesMap = new Map<string, GtrBonusTypesConfig>();

//#region Gtr Bonus Types
GtrBonusTypesMap.set("gtrCar", {
  code: "gtrCar",
  icon: "fas fa-fw fa-car text-red",
  label: "Bono GTR Car",
});
GtrBonusTypesMap.set("gtrDep", {
  code: "gtrDep",
  icon: "fas fa-fw fa-building text-blue",
  label: "Bono GTR Apartment",
});
GtrBonusTypesMap.set("gtrLife", {
  code: "gtrLife",
  icon: "fas fa-fw fa-passport text-success",
  label: "Bono GTR Lifestyle",
});
GtrBonusTypesMap.set("gtrLifeBonus[INFLUENCER]", {
  code: "gtrLifeBonus[INFLUENCER]",
  icon: "far fa-fw fa-thumbs-up text-pink",
  label: "Recalificación: Influencer",
});
GtrBonusTypesMap.set("gtrLifeBonus[FINANCIAL-INF]", {
  code: "gtrLifeBonus[FINANCIAL-INF]",
  icon: "fas fa-fw fa-comments-dollar text-green",
  label: "Recalificación: Financial Influencer",
});
GtrBonusTypesMap.set("gtrLifeBonus[SIX-FIGURES]", {
  code: "gtrLifeBonus[SIX-FIGURES]",
  icon: "far fa-fw fa-gem text-cyan",
  label: "Recalificación: Six Figures",
});
GtrBonusTypesMap.set("gtrLifeBonus[GLOBAL-AMBASSADOR]", {
  code: "gtrLifeBonus[GLOBAL-AMBASSADOR]",
  icon: "fas fa-fw fa-globe text-purple",
  label: "Recalificación: Global Ambassador",
});
GtrBonusTypesMap.set("gtrLifeBonus[GLOBAL-CROWN]", {
  code: "gtrLifeBonus[GLOBAL-CROWN]",
  icon: "fas fa-fw fa-crown text-red",
  label: "Recalificación: Global Crown",
});
GtrBonusTypesMap.set("gtrLifeBonus[LEYEND]", {
  code: "gtrLifeBonus[LEYEND]",
  icon: "fas fa-fw fa-space-shuttle text-black",
  label: "Recalificación: Leyend",
});
//#endregion

export const GtrBonusStatusMap = new Map<string, GtrBonusStatusConfig>();

//#region Gtr Bonus Status
GtrBonusStatusMap.set("new", {
  code: "new",
  icon: "fa fa-star fa-fw",
  label: "Ganado",
  colorClass: "text-info",
  amountClass: "muted",
});

GtrBonusStatusMap.set("expired", {
  code: "expired",
  icon: "far fa-calendar-times fa-fw",
  label: "Expirado",
  colorClass: "muted",
  amountClass: "text-decoration-line-through",
});

GtrBonusStatusMap.set("review", {
  code: "review",
  icon: "fas fa-tasks fa-fw",
  label: "En Revisión",
  colorClass: "text-danger",
  amountClass: "",
});

GtrBonusStatusMap.set("rejected", {
  code: "rejected",
  icon: "fas fa-times fa-fw",
  label: "Rechazado",
  colorClass: "text-danger",
  amountClass: "text-decoration-line-through",
});

GtrBonusStatusMap.set("approved", {
  code: "approved",
  icon: "fas fa-check fa-fw",
  label: "Aprobado",
  colorClass: "text-success",
  amountClass: "",
});

GtrBonusStatusMap.set("paid", {
  code: "paid",
  icon: "fab fa-fw fa-bitcoin text-yellow",
  label: "Pagado",
  colorClass: "text-success",
  amountClass: "text-dark fw-bold",
});
//#endregion
