
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { tools } from "@/mixins/tools";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
import {
  AccountSummary,
  BinaryExpiration,
} from "@/store/Account/AccountInterface";

export default defineComponent({
  name: "binary-card",
  computed: {
    ...mapGetters("account", ["getAccountSummary"]),
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    binaryExpiration(): BinaryExpiration | undefined {
      return this.accountSummary?.binaryExpiration;
    },
    isBinaryBonusExpired(): boolean {
      return this.binaryExpiration
        ? this.binaryExpiration?.left.daysLeft < 0 &&
            this.binaryExpiration?.right.daysLeft < 0
        : true;
    },
  },
  methods: {
    binaryBonusIconClass(
      innerLegUnlocked: boolean,
      hasBonus: boolean,
      days: number
    ) {
      if (innerLegUnlocked == false) {
        return "fa fas fa-lock fa-fw";
      } else if (hasBonus) {
        if (days < 10) {
          return "fas fa-exclamation-circle fa-fw text-red";
        } else if (days >= 10 && days < 15) {
          return "fas fa-exclamation-circle fa-fw text-warning";
        } else {
          return "fas fa-check-circle fa-fw text-green";
        }
      } else {
        return "fas fa-times-circle fa-fw text-red";
      }
    },
    binaryBonusDaysColorClass(days: number = 0) {
      if (days < 10) {
        return "danger";
      } else if (days >= 10 && days < 15) {
        return "warning";
      } else {
        return "green";
      }
    },
    binaryBonusSideIconClass(days: number = 0) {
      if (days < 0) {
        return "fa-times";
      } else if (days >= 0 && days < 15) {
        return "fa-exclamation";
      } else {
        return "fa-check";
      }
    },
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
  },
  setup() {
    const durationUnitLocalized = tools.methods.durationUnitLocalized;
    return {
      durationUnitLocalized,
    };
  },
});
