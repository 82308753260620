
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { tools } from "@/mixins/tools";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "subscriptions-card",
  computed: {
    ...mapGetters("subs", ["getUserSubscription", "getUserCurrentPackage"]),
    packageSubscription(): UserSubscription {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return this.getUserSubscription("gtr-lite");
        case "gtr-basic":
        case "gtr-vip":
        default:
          return this.getUserSubscription("trading-academy");
      }
    },
    distributorLicenseSubscription(): UserSubscription {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return this.getUserSubscription("lite-distributor-license");
        case "gtr-basic":
        case "gtr-vip":
        default:
          return this.getUserSubscription("distributor-license");
      }
    },
    packageIconClasses(): string {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return "fa-satellite-dish text-cyan";
        case "gtr-basic":
        case "gtr-vip":
        default:
          return "fa-user-graduate text-indigo";
      }
    },
    packageName(): string {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return "GTR Lite";
        case "gtr-basic":
        case "gtr-vip":
        default:
          return "Academia GTR";
      }
    },
    packageFormattedEndDate(): string {
      return this.packageSubscription.endDate
        ? this.formatDateTimezone(
            (this.packageSubscription.endDate as Date).getTime()
          )
        : "-";
    },
    packageDurationUnitLocalized(): string {
      return tools.methods.durationUnitLocalized(
        this.packageSubscription.daysLeft || 0,
        "days"
      );
    },
    licenseIconClasses(): string {
      switch (this.getUserCurrentPackage) {
        case "gtr-lite":
          return " fa-briefcase text-teal";
        case "gtr-basic":
        case "gtr-vip":
        default:
          return " fa-briefcase text-info";
      }
    },
    licenseFormattedEndDate(): string {
      return this.distributorLicenseSubscription.endDate
        ? this.formatDateTimezone(
            (this.distributorLicenseSubscription.endDate as Date).getTime()
          )
        : "-";
    },
    licenseDurationUnitLocalized(): string {
      return tools.methods.durationUnitLocalized(
        this.distributorLicenseSubscription.daysLeft || 0,
        "days"
      );
    },
  },
  methods: {
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
    daysLeftClass(daysLeft: number): string {
      if (daysLeft <= 5) {
        return "bg-danger";
      } else if (daysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
  },
});
