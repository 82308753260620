
import { defineComponent, PropType } from "vue";
import { images } from "@/mixins/images";
import { tools } from "@/mixins/tools";
import * as bootstrap from "bootstrap";
import { NoticeParams } from "@/store/System/SystemConfigInterfaces";

export default defineComponent({
  name: "images-carousel",
  props: {
    imagesArray: {
      type: Array as PropType<Array<NoticeParams>>,
      default: () => [],
    },
    imgClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imagesList: new Array<NoticeParams>(),
      activeSlide: 0,
      id: "",
    };
  },
  methods: {
    getImages() {
      this.imagesArray.forEach((img: NoticeParams, index: number) => {
        this.imagesList[index] = { ...img };
        this.getImage(img.src).then((r) => {
          this.imagesList[index].src = r;
        });
      });
    },
  },
  created() {
    this.id = this.generateShortGUID();
    this.getImages();
  },
  watch: {
    imagesArray() {
      this.getImages();
    },
  },
  mounted() {
    const myCarousel = document.querySelector("#imageCarousel-" + this.id);
    const carousel = new bootstrap.Carousel(myCarousel);
    carousel.cycle();
    if (myCarousel) {
      myCarousel.addEventListener("slide.bs.carousel", (par: any) => {
        this.activeSlide = par.to;
      });
    }
  },
  setup() {
    const getImage = images.methods.getImage;
    const generateShortGUID = tools.methods.generateShortGUID;
    return {
      getImage,
      generateShortGUID,
    };
  },
});
