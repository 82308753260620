import { TicketsState } from "./TicketsInterfases";
import firebase from "firebase/compat/app";

const initialState: TicketsState = {};

const state = initialState;

const mutations = {};

const actions = {
  getAllTicketsByTypes(_, data) {
    const { eventId } = data;
    const tickets = firebase
      .functions()
      .httpsCallable("Tickets-GetAllTicketsByTypes");

    return tickets({ eventId }).then((tickets) => tickets.data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
