
import { ProcessSecuredDataRequest } from "@/store/Profile/ProfileInterfaces";
import { defineComponent } from "vue";
const numbers = /^[0-9]$/;

export default defineComponent({
  name: "code-confirmation-modal",
  data() {
    return {
      code: ["", "", "", "", ""],
      isSaving: false,
    };
  },
  props: {
    requestTag: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    updated: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["processSecuredData", "closed"],
  computed: {
    canSave(): boolean {
      return this.code.join("").trim().length == 5;
    },
    hasError(): boolean {
      return this.errorMessage != "";
    },
  },
  methods: {
    save() {
      const req: ProcessSecuredDataRequest = {
        requestTag: this.requestTag,
        confirmationCode: this.code.join(""),
      };
      this.isSaving = true;
      this.$emit("processSecuredData", req);
    },
    closeModal(): void {
      this.$emit("closed");
    },
    autoTab(e: KeyboardEvent) {
      const input = e.target as HTMLInputElement;
      const inputIndex = Number(input.id.split("-")[1]);
      if (!input.value.match(numbers)) {
        input.value = "";
        this.code[inputIndex - 1] = "";
        return;
      } else if (e.key.match(numbers)) {
        if (inputIndex !== 5 && input.value.length === input.maxLength) {
          (this.$refs[`code-${inputIndex + 1}`] as HTMLInputElement).select();
        }
      }
    },
    autoBackspace(e: KeyboardEvent) {
      const input = e.target as HTMLInputElement;
      const inputIndex = Number(input.id.split("-")[1]);
      switch (e.key) {
        case "Backspace":
          if (inputIndex !== 1 && input.value.length === 0) {
            (this.$refs[`code-${inputIndex - 1}`] as HTMLInputElement).select();
          }
          break;
      }
    },
    pasteCode(e: ClipboardEvent) {
      const value = e.clipboardData?.getData("text").trim();
      if (value?.length === 5 && value.match(/^[0-9]{5}$/)) {
        this.code = value.trim().split("");
      }
    },
  },
  watch: {
    errorMessage(value) {
      if (value) {
        this.code = ["", "", "", "", ""];
        this.isSaving = false;
      }
    },
    updated(newValue: boolean) {
      if (newValue) {
        this.code = ["", "", "", "", ""];
        this.isSaving = false;
      }
    },
  },
});
