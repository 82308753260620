
import { defineComponent } from "vue";
import { MdEditor } from "md-editor-v3";
import { debounce } from "lodash";
import "md-editor-v3/lib/style.css";

export default defineComponent({
  name: "markdown-editor",
  components: {
    MdEditor,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: "",
      defaultConfig: [
        "bold",
        "underline",
        "italic",
        "-",
        "title",
        "strikeThrough",
        "sub",
        "sup",
        "quote",
        "unorderedList",
        "orderedList",
        "task",
        "-",
        "codeRow",
        "code",
        "link",
        "image",
        "table",
        "mermaid",
        "katex",
        "-",
        "=",
        "pageFullscreen",
        "fullscreen",
        "preview",
        "htmlPreview",
        "catalog",
      ] as any[],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    initialValue: {
      type: String,
    },
  },
  onMounted() {
    this.value = this.initialValue ?? "";
  },
  watch: {
    value: {
      immediate: true,
      handler(value: string) {
        this.debouncedUpdateValue(value);
      },
    },
    initialValue: {
      immediate: true,
      handler(value: string) {
        if (typeof value !== "undefined") {
          this.value = value;
        }
      },
    },
  },
  setup(props, { emit }) {
    const updateValue = (value: string) => {
      emit("update:modelValue", value);
    };
    const debouncedUpdateValue = debounce(updateValue, 300);
    return {
      debouncedUpdateValue,
    };
  },
});
