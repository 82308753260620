
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

import { GtrLifestyleBonus } from "@/store/RankQualification/RankQualificationInterfaces";

export default defineComponent({
  name: "gtr-lifestyle-bonus",
  props: {
    bonusList: {
      type: Array as PropType<Array<GtrLifestyleBonus>>,
      required: true,
    },
  },
  computed: {
    ...mapGetters("rankQualification", [
      "getGtrLifeBonusTypeConfig",
      "getGtrLifeBonusStatusConfig",
    ]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    translateType(type: string): string {
      return this.getGtrLifeBonusTypeConfig(type)?.label || type;
    },
    typeIconClass(type: string): string {
      return this.getGtrLifeBonusTypeConfig(type)?.icon || "";
    },
    translateStatus(status: string): string {
      return this.getGtrLifeBonusStatusConfig(status)?.label || status;
    },
    statusIconClass(status: string): string {
      return this.getGtrLifeBonusStatusConfig(status)?.icon || "";
    },
    statusColorClass(status: string): string {
      return this.getGtrLifeBonusStatusConfig(status)?.colorClass || "";
    },
    amountClass(status: string): string {
      return this.getGtrLifeBonusStatusConfig(status)?.amountClass || "";
    },
  },
});
