
import { defineComponent } from "vue";
export default defineComponent({
  name: "video-card",
  props: [
    "video",
    "selectVideo",
    "toggleWatchedVideo",
    "current",
    "watched",
    "index",
  ],
});
