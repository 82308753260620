
import { defineComponent } from "vue";

export default defineComponent({
  name: "event-count-selector",
  props: [
    "max",
    "min",
    "value",
    "id",
    "class",
    "sales_started",
    "sales_ended",
    "disabled",
  ],
  emits: ["onChange"],
  data() {
    return {
      defaults: {
        max: this.max ?? null,
        min: this.min ?? 0,
      },
      currentValue: this.value ?? 0,
      error: "",
      classInput: this.class,
    };
  },
  computed: {
    isDisabled() {
      if (this.disabled) return this.disabled;
      return this.sales_ended || !this.sales_started;
    },
    errorMessage() {
      switch (this.error) {
        case "maxError":
          return `No se puede agregar más de ${this.defaults.max} boleto(s)`;
        case "minError":
          return `No se puede agregar menos de ${this.defaults.min} boleto(s)`;
        default:
          return "";
      }
    },
  },
  watch: {
    currentValue() {
      this.$emit("onChange", this.currentValue);
      if (this.currentValue == "") return;
      if (this.defaults.max && this.currentValue >= this.defaults.max) {
        if (this.currentValue > this.defaults.max) this.error = "maxError";
        this.currentValue = this.defaults.max;
        return;
      }
      if (this.defaults.min >= 0 && this.currentValue <= this.defaults.min) {
        if (this.currentValue < this.defaults.min) this.error = "minError";
        this.currentValue = this.defaults.min;
        return;
      }
      this.error = "";
    },
    max: function (max) {
      if (max == 0) return null;
    },
  },
  methods: {
    change(evt) {
      const {
        target: { value },
      } = evt;
      this.currentValue = value;
    },
    add() {
      this.currentValue += 1;
    },
    substract() {
      this.currentValue -= 1;
    },
  },
});
