
import "dayjs/locale/es";
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import {
  OptionEvent,
  Event,
  AdminEventData,
  TicketPaymentOptions as ITicketPaymentOptions,
} from "../../../store/Events/EventsInterfaces";
import VueDatePicker from "@vuepic/vue-datepicker";
import MarkdownEditor from "@/components/markdown/MarkdownEditor.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import _ from "lodash";
import dayjs from "dayjs";

export default defineComponent({
  name: "new-event-form",
  props: ["event", "buttonClass", "buttonStyle"],
  data() {
    return {
      optionSelected: [] as OptionEvent[],
      currentEvent: null as null | Event,
      currentNewData: {} as AdminEventData,
      eventData: {} as AdminEventData,
      textareaOpacity: 0.65 as number,
      currencies: {
        MXN: "Pesos méxicanos (MXN)",
        USD: "Dolares (USD)",
        BTC: "Bitcoins (BTC)",
      },
      image: {
        src: null as null | string,
        base64: null as null | string,
        mimeType: null as null | string,
      },
      logo: {
        src: null as null | string,
        base64: null as null | string,
        mimeType: null as null | string,
      },
      loading: false,
    };
  },
  components: {
    VueDatePicker,
    MarkdownEditor,
  },
  computed: {
    ...mapGetters("events", ["getEvent", "getTicketPaymentOptions"]),
    ticket_options() {
      return this.currentEvent?.ticket_options ?? [];
    },
    editing() {
      return Object.entries(this.currentNewData).length > 0;
    },
  },
  methods: {
    setPaymentOptions(payment_options) {
      this.currentNewData = {
        ...this.currentNewData,
        payment_options,
      };
    },
    formatDate(date) {
      return dayjs(date).format("DD MMMM, YYYY • hh:mm a");
    },
    scrolled(evt) {
      const element = evt.target;
      const max = 40;
      let scroll = element.scrollTop / max;
      const top = 0.65;

      if (scroll >= top) scroll = top;
      if (scroll <= 0) scroll = 0;

      scroll = Math.abs(scroll - top);

      this.textareaOpacity = scroll;
    },
    modifyValue(evt) {
      const {
        target: { name, value },
      } = evt;

      const _value =
        (typeof value == "string" && value.length > 0) ||
        (typeof value == "object" && value.constructor.name == "Date")
          ? value
          : " ";

      this.currentNewData = {
        ...this.currentNewData,
        [name]: _value,
      };
    },
    modifyDateStart(value) {
      this.modifyValue({ target: { name: "eventDateStart", value } });
    },
    modifyDateEnd(value) {
      this.modifyValue({ target: { name: "eventDateEnd", value } });
    },
    modifyDescription(value) {
      this.modifyValue({ target: { name: "details", value } });
    },
    saveData() {
      try {
        this.validateRequiredData();
        this.loading = true;
        this.$store
          .dispatch("events/createEvent", this.currentNewData)
          .then((res) => {
            setTimeout(() => {
              this.resetData();
              return this.$router.push({
                name: "buy-tickets-admin-edit-event",
                params: { id: res.data },
              });
            }, 1000);
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        }
      }
    },
    validateRequiredData() {
      if (!this.currentNewData.title) {
        (this.$refs.title as any).focus();
        throw new Error("Un titulo es requerido para poder continuar.");
      }

      if (!this.currentNewData?.location_type) {
        throw new Error(
          "Es necesario que selecciones un tipo de ubicación para poder continuar."
        );
      }

      if (!this.currentNewData.eventDateStart) {
        throw new Error("Selecciona una fecha de inicio para poder continuar.");
      }

      if (
        this.currentNewData.email &&
        !this.validateEmail(this.currentNewData.email)
      ) {
        (this.$refs.email as any).focus();
        throw new Error(
          "El formato de email no esta correcto. (name@domain.com)"
        );
      }
    },
    validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    resetData() {
      this.currentNewData = {} as AdminEventData;
    },
    removePaymentOption() {
      const payment_options = (this.currentNewData?.payment_options ||
        this.eventData?.payment_options) as ITicketPaymentOptions[];
      this.currentNewData = {
        ...this.currentNewData,
        payment_options,
      };
    },
    processData() {
      let dateStart = this.event?.date?.start._seconds;
      dateStart = parseInt(dateStart?.toString() ?? "");

      // console.log(dateStart);

      let dateEnd = this.event?.date?.end._seconds;
      dateEnd = parseInt(dateEnd?.toString() ?? "");

      this.eventData = _.cloneDeep({
        ...this.event,
        title: this.event?.title ?? "",
        organizer: this.event?.organizer ?? "",
        email: this.event?.contact?.email ?? "",
        phone: this.event?.contact?.phone ?? "",
        details: this.event?.details ?? "",
        eventDateStart: new Date(dateStart),
        eventDateEnd: new Date(dateEnd),

        // LOCATION INFO
        location_type: this.event?.location?.type ?? "",
        addr_name: this.event?.location?.address?.name ?? "",
        addr_address_1: this.event?.location?.address?.address_1 ?? "",
        addr_address_2: this.event?.location?.address?.address_2 ?? "",
        addr_city: this.event?.location?.address?.city ?? "",
        addr_country: this.event?.location?.address?.country ?? "",
        addr_state: this.event?.location?.address?.state ?? "",
        addr_zip: this.event?.location?.address?.zip ?? "",

        online_url: "",

        payment_options: this.getTicketPaymentOptions(this.event.id),
        currency: "USD",
        accept_bitcoin: this.event?.accept_bitcoin
          ? this.event?.accept_bitcoin === true
            ? "accept_bitcoin"
            : "not_accept_bitcoin"
          : "accept_bitcoin",
      });
    },
    async fileSelected(evt) {
      const [file] = evt.target.files;
      if (file) {
        const image = {
          src: URL.createObjectURL(file),
          base64: await this.getBase64(file),
          mimeType: file.type,
        };

        this.image = image;

        this.currentNewData = {
          ...this.currentNewData,
          image,
        };
      }
    },
    async logoSelected(evt) {
      const [file] = evt.target.files;
      if (file) {
        const logo = {
          src: URL.createObjectURL(file),
          base64: await this.getBase64(file),
          mimeType: file.type,
        };

        this.logo = logo;

        this.currentNewData = {
          ...this.currentNewData,
          logo,
        };
      }
    },
    getBase64(file) {
      return new Promise<string | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          return resolve(
            typeof reader.result == "string" ? reader.result : null
          );
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
          return reject(error);
        };
      });
    },
    arrayBufferToBase64(buffer) {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    deleteCover() {
      const image = {
        src: null,
        base64: null,
        mimeType: null,
      };

      this.image = image;

      this.currentNewData = {
        ...this.currentNewData,
        image: undefined,
      };

      (this.$refs.image as any).value = "";
    },
    deleteLogo() {
      const logo = {
        src: null,
        base64: null,
        mimeType: null,
      };

      this.logo = logo;

      this.currentNewData = {
        ...this.currentNewData,
        logo: undefined,
      };

      (this.$refs.logo as any).value = "";
    },
  },
  mounted() {
    this.resetData();
    this.currentNewData.currency = "USD";
    this.currentNewData.accept_bitcoin = "accept_bitcoin";
  },
});
