
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import {
  CommissionRecord,
  CommissionStatusConfig,
  CommissionTypeConfig,
} from "@/store/Commissions/CommissionsInterfaces";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import dayjs from "dayjs";

export default defineComponent({
  name: "activate-no-volume",
  components: {
    VueDatePicker,
  },
  props: {
    username: { type: String, required: true },
    email: { type: String, required: true },
  },
  emits: ["commissionAdded", "error"],
  data() {
    return {
      update: false,
      adding: false,
      commissionRecord: {
        source: "",
        amount: undefined,
        status: {} as CommissionStatusConfig,
        type: {} as CommissionTypeConfig,
        date: null,
      } as CommissionRecord,
      types: [
        "penalty",
        "prize",
        "loan",
        "loanPayment",
        "cancel",
        "tickets",
        "direct",
        "binary",
        "carrer",
        "scholarship",
        "scholarshipCredit",
        "novolume",
        "mmi-colombia",
        "gtrLifeBonus[INFLUENCER]",
        "gtrLifeBonus[FINANCIAL-INF]",
        "gtrLifeBonus[SIX-FIGURES]",
        "gtrLifeBonus[GLOBAL-AMBASSADOR]",
        "gtrLifeBonus[GLOBAL-CROWN]",
        "gtrLifeBonus[LEYEND]",
      ],
      status: ["pending", "processing", "lost", "paid"],
    };
  },
  methods: {
    addCommission() {
      this.adding = true;

      const AddCommission = firebase
        .functions()
        .httpsCallable("Admin-AddCommission");
      AddCommission({
        username: this.username,
        commission: {
          source: this.commissionRecord.source,
          amount: this.commissionRecord.amount || 0,
          status: this.commissionRecord.status.code,
          type: this.commissionRecord.type.code,
          date: this.commissionRecord.date,
        },
      })
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.$emit("commissionAdded");

            this.commissionRecord.source = "";
            this.commissionRecord.amount = undefined;
            this.commissionRecord.status = {} as CommissionStatusConfig;
            this.commissionRecord.type = {} as CommissionTypeConfig;
          } else if (response.data.OPCODE == "ERROR") {
            this.$emit(
              "error",
              "Error al Agregar la Comisión",
              response.data.messageDetails || ""
            );
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit(
            "error",
            "Error al Agregar la Comisión",
            error.message || error
          );
        })
        .finally(() => {
          this.adding = false;
        });
    },
    setCommissionDate(date: Date) {
      this.commissionRecord.date = date;
    },
    formatDate(date) {
      return dayjs(date).format("DD / MMM / YYYY • hh:mm a");
    },
  },
  computed: {
    ...mapGetters("commissions", [
      "getCommissionTypeConfig",
      "getCommissionStatusConfig",
    ]),
    readOnlyClassesObject(): any {
      return {
        "bg-light": this.update,
        "text-muted": !this.update,
        "bg-body": !this.update,
        "bg-gradient": !this.update,
        "text-secondary": this.update,
      };
    },
    readOnlyClassesString(): string {
      return Object.keys(this.readOnlyClassesObject)
        .map((key) => {
          if (this.readOnlyClassesObject[key]) {
            return key;
          }
        })
        .join(" ");
    },
    amountWarning(): boolean {
      const negativeCommissions = ["penalty", "loanPayment", "cancel"];
      if (this.commissionRecord.amount) {
        if (this.commissionRecord.amount >= 0) {
          return negativeCommissions.includes(this.commissionRecord.type.code);
        } else {
          return negativeCommissions.includes(this.commissionRecord.type.code)
            ? false
            : true;
        }
      }
      return false;
    },
    getTypesConfig(): Array<CommissionTypeConfig> {
      const typesConfigArray = new Array<CommissionTypeConfig>();
      for (const t of this.types) {
        const _t = this.getCommissionTypeConfig(t);
        if (_t) {
          typesConfigArray.push(_t);
        }
      }
      return typesConfigArray;
    },
    getStatusesConfig(): Array<CommissionStatusConfig> {
      const statusesConfigArray = new Array<CommissionStatusConfig>();
      for (const s of this.status) {
        const _s = this.getCommissionStatusConfig(s);
        if (_s) {
          statusesConfigArray.push(_s);
        }
      }
      return statusesConfigArray;
    },
  },
});
