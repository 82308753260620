import firebase from "firebase/compat/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
};

const app = firebase.initializeApp(firebaseConfig);

// utils
const fbfs = app.firestore();
const firestore = getFirestore(app);
const authCompat = firebase.auth();
const auth = getAuth(app);
const func = firebase.functions();
const functions = getFunctions(app);
const storage = firebase.storage();
let reCaptchaSiteKey = process.env.VUE_APP_ReCaptchaV3Provider || "";
if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  reCaptchaSiteKey = process.env.VUE_APP_ReCaptcha_Debug_Token || "";

  const firebaseHost = process.env.VUE_APP_FIREBASE_HOST || "localhost";

  fbfs.useEmulator(firebaseHost, 8090);
  connectFirestoreEmulator(firestore, firebaseHost, 8090);
  authCompat.useEmulator(`http://${firebaseHost}:9099`);
  connectAuthEmulator(auth, `http://${firebaseHost}:9099`);
  func.useEmulator(firebaseHost, 5001);
  connectFunctionsEmulator(functions, firebaseHost, 5001);
}
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(reCaptchaSiteKey),
  isTokenAutoRefreshEnabled: true,
});

// collection references
const ranksCollection = fbfs.collection("ranksConfiguration");
const usersCollection = fbfs.collection("users");
const binaryTreeCollection = fbfs.collection("binary-tree");

// export utils/refs
export {
  auth,
  firestore,
  functions,
  fbfs,
  authCompat as authCompat,
  func,
  storage,
  binaryTreeCollection,
  ranksCollection,
  usersCollection,
};
