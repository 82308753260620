import dayjs from "dayjs";

// TODO: This is a weird timestamp, investigate why is using and remove it, use the native from firebase instead
interface WeirdTimestamp {
  _seconds: number;
  _nanoseconds: number;
}

export function getDateFromFirebaseTimestamp(timestamp: WeirdTimestamp) {
  const date = timestamp._seconds * 1000;
  return dayjs(new Date(date));
}

export function getFormattedTimestamp(timestamp: WeirdTimestamp) {
  return getDateFromFirebaseTimestamp(timestamp).format("MMM DD, YYYY|hh:mm A");
}
