
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { myTeamTable, myTeamBreadcrumbs } from "@/components/my-team";
import { tools } from "@/mixins/tools";
import MyTeamSortPills from "@/components/my-team/my-team-sort-pills.vue";

export default defineComponent({
  name: "my-team",
  components: {
    myTeamTable,
    myTeamBreadcrumbs,
    MyTeamSortPills,
  },
  computed: {
    ...mapGetters("auth", { usernamePK: "getUsernamePK" }),
    ...mapGetters("myTeam", {
      teamMembers: "getDisplayedTeam",
      isLoading: "getIsLoading",
      currentDisplayedUser: "getCurrentDisplayedUser",
      getSortConfiguration: "getSortConfiguration",
      isSortedBy: "isSortedBy",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("myTeam/doRefreshTeamForUser");
    },
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
    updateSortConfiguration(column: string, direction: "asc" | "desc") {
      this.$store.dispatch("myTeam/updateSortConfiguration", {
        column: column,
        direction: direction,
      });
    },
  },
  created() {
    if (!this.teamMembers || this.teamMembers?.length === 0) {
      this.$store.dispatch("myTeam/doGetTeamForUser", {
        username: this.usernamePK,
        forceUpdate: false,
      });
    }
  },
});
