
import { sortableColumns } from "@/store/MyTeam/MyTeamInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "my-team-users-sort",
  emits: ["columnOrder"],
  computed: {
    ...mapGetters("myTeam", {
      isSortedBy: "isSortedBy",
    }),
  },
  methods: {
    emitSort(column: sortableColumns, direction: "asc" | "desc") {
      this.$emit("columnOrder", column, direction);
    },
  },
});
