import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a11fc34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-header shadow" }
const _hoisted_2 = { class: "navbar navbar-top navbar-dark shadow ps-3 pe-2 pe-md-3 p-0" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "navbar-brand d-flex flex-row flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_profile_menu = _resolveComponent("profile-menu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("i", {
            class: _normalizeClass(["me-2", _ctx.icon])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
        ]),
        _createVNode(_component_notifications),
        _createVNode(_component_profile_menu)
      ])
    ])
  ]))
}