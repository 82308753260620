
import "dayjs/locale/es";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Event, OptionEvent } from "@/store/Events/EventsInterfaces";
import TicketOptionCard from "@/components/events/TicketOptionCard.vue";
import EventTicketResume from "@/components/events/EventTicketResume.vue";
import TicketsCheckout from "@/components/events/TicketsCheckout.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "buy-ticket-checkout",
  components: {
    TicketOptionCard,
    EventTicketResume,
    TicketsCheckout,
  },
  data() {
    return {
      maxDetails: 600,
      detailsExpanded: false,
      optionSelected: [] as OptionEvent[],
      event: null as Event | null,
      checkout: false as boolean,
    };
  },
  watch: {
    events: function () {
      if (this.getActiveEvents.length > 0) this.event = this.getActiveEvents[0];
    },
  },
  computed: {
    ...mapGetters("events", ["getActiveEvents", "getEvent"]),
    date() {
      return (date, format: string | undefined = undefined) => {
        return date
          ? dayjs(new Date(date._seconds * 1000))
              .locale("es")
              .format(format ?? "DD MMMM, YYYY • hh:mm a")
          : undefined;
      };
    },
    endDate() {
      return (date) => {
        const end = dayjs(new Date(date)).format("DDMMYYYY");
        const start = dayjs((this.event?.date.start as any)._seconds).format(
          "DDMMYYYY"
        );
        return this.date(date, end == start ? "hh:mm a" : undefined);
      };
    },
    showDetails() {
      if (
        this.event?.details &&
        this.event?.details?.length > this.maxDetails &&
        this.detailsExpanded == false
      )
        return `${this.event?.details.substring(0, this.maxDetails)}...`;
      return this.event?.details;
    },
    ticket_options() {
      return this.getEvent(this.$route.params.id).ticket_options ?? [];
    },
  },
  methods: {
    async getEvents() {
      this.$store.dispatch("events/doGetActiveEvents");
    },
    expandDetails() {
      this.detailsExpanded = !this.detailsExpanded;
    },
    async getData() {
      this.event = this.getEvent(this.$route.params.id);
      this.getTicketOptions();
      this.getPaymentOptions();
    },
    getTicketOptions() {
      this.$store.dispatch("events/doGetEventTicketOptions", {
        event_id: this.$route.params.id,
      });
    },
    getPaymentOptions() {
      this.$store.dispatch("events/doGetEventTicketPaymentOptions", {
        event_id: this.$route.params.id,
      });
    },
    _changeSelector(option: OptionEvent, quantity: number) {
      const ind = this.optionSelected.findIndex(
        (ele: OptionEvent) => ele.id == option.id
      );
      const total = option.price.value * quantity;

      if (ind >= 0) {
        if (quantity == 0) this.optionSelected.splice(ind, 1);
        else {
          this.optionSelected[ind].quantity = quantity;
          this.optionSelected[ind].total = total;
        }
      } else {
        this.optionSelected.push({ ...option, quantity, total });
      }
    },
    openCheckout() {
      this.checkout = true;
    },
    closeCheckout() {
      this.checkout = false;
    },
  },
  async mounted() {
    this.getData();
  },
});
