
import { defineComponent } from "vue";
import * as fb from "../../firebase";
import { generateDateKey } from "@/mixins/tools";
import { UpdateSubscriptionNoVolumeRequest } from "functions/src/Subscriptions/SubscriptionsInterfaces";

export default defineComponent({
  name: "payouts",
  data() {
    return {
      isCalculating: {
        binary: false,
        payout: false,
        quote: false,
        activating: false,
      },
      registrationForm: {
        email: "",
        username: "",
      },
    };
  },
  methods: {
    calculateBinary() {
      this.isCalculating.binary = true;
      const calculateBinaryPoints = fb.func.httpsCallable(
        "System-calculateBinaryPoints"
      );
      calculateBinaryPoints()
        .then((/*response*/) => {
          // console.log(response);
        })
        .finally(() => {
          this.isCalculating.binary = false;
        });
    },
    calculatePayouts() {
      this.isCalculating.payout = true;
      const calculatePayouts = fb.func.httpsCallable("System-calculatePayouts");
      calculatePayouts()
        .then((/*response*/) => {
          // console.log(response);
        })
        .finally(() => {
          this.isCalculating.payout = false;
        });
    },
    registerWithNoVolume() {
      this.isCalculating.activating = true;
      const subscriptionRequest: UpdateSubscriptionNoVolumeRequest = {
        subscriptionId: "both",
        duration: 0,
        durationUnit: "days",
        username: this.registrationForm.username.toLowerCase(),
        email: this.registrationForm.email,
        isDirectAffiliate: false,
        noVolume: true,
        noVolumeRef: "noVolumeActivation@" + generateDateKey(),
      };
      const UpdateSubscription = fb.func.httpsCallable(
        "Subscriptions-UpdateSubscriptionWithoutVolume"
      );
      UpdateSubscription(subscriptionRequest)
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            alert("Activado correctamente");
          } else if (response.data.OPCODE == "ERROR") {
            alert(response.data.message);
          }
        })
        .finally(() => {
          this.isCalculating.activating = false;
        });
    },
  },
});
