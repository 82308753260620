import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.id,
    role: "dialog"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["btn-close btn-sm m-2", _ctx.closeButtonClass]),
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, 2),
          _createElementVNode("img", {
            class: "img-fluid",
            src: _ctx.imgSrc,
            alt: _ctx.image.alt
          }, null, 8, _hoisted_5)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}