
import { defineComponent } from "vue";
import EventCardPlaceholder from "@/components/events/EventCardPlaceholder.vue";
export default defineComponent({
  components: {
    EventCardPlaceholder,
  },
  name: "multiple-card-placeholders",
  props: ["admin", "total", "hasTitle"],
  computed: {
    events() {
      return Array(this.total).fill({});
    },
  },
  methods: {},
});
