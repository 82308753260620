// Actions
export const doGetCommissions = "doGetCommissions";
export const unsubscribeListener = "unsubscribeListener";
export const getSummary = "getSummary";

// Mutations
export const UPDATE_USER_COMMISSION_SUMMARY = "UPDATE_USER_COMMISSION_SUMMARY";
export const UPDATE_USER_TO_BE_PROCESSED = "UPDATE_USER_TO_BE_PROCESSED";
export const UPDATE_USER_TOTALS_WITH_FUTURE_COMMISSIONS =
  "UPDATE_USER_TOTALS_WITH_FUTURE_COMMISSIONS";
export const ADD_USER_COMMISSION = "ADD_USER_COMMISSION";
export const UPDATE_USER_COMMISSION = "UPDATE_USER_COMMISSION";
export const DELETE_USER_COMMISSION = "DELETE_USER_COMMISSION";
export const CLEAR_USER_COMMISSIONS = "CLEAR_USER_COMMISSIONS";
export const SET_LISTENER = "SET_LISTENER";
export const CLEAR_LISTENER = "CLEAR_LISTENER";

export interface CommissionTypeConfig {
  code: string;
  label: string;
  icon: string;
}

export interface CommissionStatusConfig {
  code: string;
  label: string;
  icon: string;
  colorClass: string;
  amountClass: string;
}

export interface CommissionsState {
  commissionsTypes: Map<string, CommissionTypeConfig>;
  commissionsStatuses: Map<string, CommissionStatusConfig>;
  commissions: Array<Commission>;
  summary: CommissionsSummary;
}

export interface Commission {
  _id: number; // => this.date.getTime()
  date?: Date;
  // concept: string;
  source: string;
  amount: number;
  status: string; // | "pending" | "paid" | "loading" | "error"
  type: string;
}

export interface CommissionRecord {
  date: null | Date;
  // concept: string;
  source: string;
  amount: number | undefined;
  status: CommissionStatusConfig;
  type: CommissionTypeConfig;
}

export interface CommissionsSummary {
  _total: number;
  total: number;
  totalUpdatedDate: Date | undefined;
  _pending: number;
  pending: number;
  pendingUpdatedDate: Date | undefined;
  paid: number;
  paidUpdatedDate: Date | undefined;
  toBeProcessed: number | undefined;
  toBeProcessedUpdatedDate: Date | undefined;
  futureAmount: number;
}

export class CommissionsSummary {
  constructor() {
    this.total = 0;
    this.totalUpdatedDate = undefined;
    this.pending = 0;
    this.pendingUpdatedDate = undefined;
    this.paid = 0;
    this.paidUpdatedDate = undefined;
  }
}

export interface SetListenerRequest {
  listenerId:
    | "commissions"
    | "summary"
    | "toBeProcessed"
    | "futureCommissions"
    | undefined;
  commissions(): void;
  summary(): void;
  toBeProcessed(): void;
  futureCommissions(): void;
}
