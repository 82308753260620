
import { defineComponent } from "vue";
import { Contact, SecuredDataRequest } from "@/store/Profile/ProfileInterfaces";
import { mapActions, mapGetters } from "vuex";
import { Country } from "@/store/System/SystemConfigInterfaces";
import {
  EmailValidationResponse,
  validationTools,
} from "@/mixins/validationTools";

export default defineComponent({
  name: "contact",
  props: {
    contactInfo: {
      type: Object as () => Contact,
      default: {} as Contact,
      required: false,
    },
    registrationDate: {
      type: Date,
      default: new Date(0),
      required: true,
    },
    updated: {
      type: Boolean,
      default: false,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
      required: true,
    },
    ccModalStatus: {
      type: String,
      default: "hidden",
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      localContactInfo: {
        email: {
          data: "",
          visible: true,
        },
        mobile: {
          data: {
            dialCode: "",
            number: "",
          },
          whatsapp: false,
          telegram: false,
          visible: false,
        },
        telegram: {
          data: {
            dialCode: "",
            number: "",
          },
          visible: false,
        },
        whatsapp: {
          data: {
            dialCode: "",
            number: "",
          },
          visible: false,
        },
        instagram: {
          data: "",
          visible: false,
        },
        tiktok: {
          data: "",
          visible: false,
        },
        facebook: {
          data: "",
          visible: false,
        },
      } as Contact,
      saving: false,
      emailTimer: undefined as NodeJS.Timeout | undefined,
      emailOK: false,
      emailValidation: new EmailValidationResponse(),
    };
  },
  emits: ["saveSecuredData", "error"],
  computed: {
    ...mapGetters("sys", ["getStarredCountries"]),
    countriesWithDialCodes(): Array<Country> {
      const starred = this.getStarredCountries
        .filter((country: Country) => {
          return (
            country.enabled === true &&
            country.dialCode &&
            country.dialCode !== ""
          );
        })
        .sort((a: Country, b: Country) => {
          if (a.priority === b.priority) {
            return a.spanish.localeCompare(b.spanish);
          } else {
            return a.priority - b.priority;
          }
        });
      return starred;
    },
    canSave(): boolean {
      return (
        this.localContactInfo.email.data !== this.contactInfo.email.data ||
        this.localContactInfo.email.visible !==
          this.contactInfo.email.visible ||
        this.localContactInfo.mobile?.data.dialCode !==
          this.contactInfo.mobile?.data.dialCode ||
        this.localContactInfo.mobile?.data.number !==
          this.contactInfo.mobile?.data.number ||
        this.localContactInfo.mobile?.whatsapp !==
          this.contactInfo.mobile?.whatsapp ||
        this.localContactInfo.mobile?.telegram !==
          this.contactInfo.mobile?.telegram ||
        this.localContactInfo.mobile?.visible !==
          this.contactInfo.mobile?.visible ||
        this.localContactInfo.whatsapp?.data.dialCode !==
          this.contactInfo.whatsapp?.data.dialCode ||
        this.localContactInfo.whatsapp?.data.number !==
          this.contactInfo.whatsapp?.data.number ||
        this.localContactInfo.whatsapp?.visible !==
          this.contactInfo.whatsapp?.visible ||
        this.localContactInfo.telegram?.data.dialCode !==
          this.contactInfo.telegram?.data.dialCode ||
        this.localContactInfo.telegram?.data.number !==
          this.contactInfo.telegram?.data.number ||
        this.localContactInfo.telegram?.visible !==
          this.contactInfo.telegram?.visible ||
        this.localContactInfo.instagram?.data !==
          this.contactInfo.instagram?.data ||
        this.localContactInfo.tiktok?.data !== this.contactInfo.tiktok?.data ||
        this.localContactInfo.facebook?.data !==
          this.contactInfo.facebook?.data ||
        this.localContactInfo.instagram?.visible !==
          this.contactInfo.instagram?.visible ||
        this.localContactInfo.tiktok?.visible !==
          this.contactInfo.tiktok?.visible ||
        this.localContactInfo.facebook?.visible !==
          this.contactInfo.facebook?.visible
      );
    },
    readOnlyClasses(): any {
      return {
        "bg-light": this.editMode,
        "bg-body": !this.editMode,
        "bg-gradient": !this.editMode,
        "text-secondary": !this.editMode,
      };
    },
    readOnlyClassesExtra(): any {
      return {
        "bg-light": this.editMode,
        "bg-body": !this.editMode || this.localContactInfo.mobile?.whatsapp,
        "bg-gradient": !this.editMode || this.localContactInfo.mobile?.whatsapp,
        "text-secondary":
          !this.editMode || this.localContactInfo.mobile?.whatsapp,
      };
    },
  },
  methods: {
    ...mapActions("profile", ["doUpdateUserContactInfo"]),
    edit() {
      this.editMode = true;
      // Patch because this.$nextTick() doesn't work when emits are used
      setTimeout((): void => {
        (this.$refs.email as HTMLInputElement).select();
      }, 200);
    },
    validateEmailInput() {
      this.emailOK = false;
      if (this.emailTimer !== undefined) {
        clearTimeout(this.emailTimer);
      }
      if (this.localContactInfo.email.data.length === 0) {
        this.emailValidation = new EmailValidationResponse();
        this.emailValidation.hasError = true;
        this.emailValidation.userMessage = "El correo es requerido";
        return;
      }

      this.emailTimer = setTimeout(async () => {
        this.emailValidation = this.validateEmail(
          this.localContactInfo.email.data
        );
        if (
          this.emailValidation.status === "SUCCESS" ||
          this.emailValidation.status === "WARNING"
        ) {
          if (
            this.localContactInfo.email.data.toLowerCase() !==
              this.contactInfo.email.data.toLowerCase() &&
            !(await this.isEmailAvailable())
          ) {
            this.emailValidation.hasWarning = false;
            this.emailValidation.canChange = false;
            this.emailValidation.hasError = true;
            this.emailValidation.userMessage =
              "El correo electrónico ya ha sido registrado.";
          }
          this.emailOK = true;
        }
        clearTimeout(this.emailTimer as NodeJS.Timeout);
        this.emailTimer = undefined;
      }, 500);
    },
    async isEmailAvailable(): Promise<boolean> {
      return await this.$store
        .dispatch("auth/GetEmailAvailability", this.localContactInfo.email.data)
        .then((response) => {
          if (response.OPCODE == "UNAVAILABLE") {
            return false;
          } else if (response.OPCODE == "SUCCESS") {
            return true;
          } else {
            throw Error("Can not validate email availability");
          }
        })
        .catch((error) => {
          alert("Error: " + error.message);
          throw error;
        });
    },
    save() {
      this.saving = true;
      const hoursSinceRegistration =
        Math.abs(
          new Date(this.registrationDate).getTime() - new Date().getTime()
        ) / 36e5;

      // if email is the same or the
      // change is less than 26 hours
      // then update without confirmation code
      // eslint-disable-next-line no-constant-condition
      if (
        this.localContactInfo.email.data.toLowerCase() ===
          this.contactInfo.email.data.toLowerCase() ||
        (this.localContactInfo.email.data.toLowerCase() !==
          this.contactInfo.email.data.toLowerCase() &&
          hoursSinceRegistration < 26)
      ) {
        this.doUpdateUserContactInfo(this.localContactInfo)
          .then(() => {
            this.editMode = false;
          })
          .catch((error: any) => {
            if (error.name && error.name != "") {
              if (error.name === "CONFIRMATION_CODE_REQUIRED") {
                this.saveSecuredData();
              } else {
                this.$emit("error", error);
              }
            } else {
              this.$emit("error", error);
            }
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.saveSecuredData();
      }
    },
    saveSecuredData() {
      this.$emit("saveSecuredData", {
        type: "email",
        data: this.localContactInfo,
        requestId: "",
        requestTag: "",
      } as SecuredDataRequest);
    },
    cancelEdit() {
      this.setLocalContactInfo(this.contactInfo);
      this.editMode = false;
    },
    setLocalContactInfo(newValue: Contact) {
      this.localContactInfo = {
        ...this.localContactInfo,
        ...(JSON.parse(JSON.stringify(newValue)) as Contact),
      };
      if (this.localContactInfo.mobile?.whatsapp) {
        this.localContactInfo.whatsapp = {
          data: this.localContactInfo.mobile.data,
          visible: this.localContactInfo.whatsapp
            ? this.localContactInfo.whatsapp.visible
            : false,
        };
      }

      if (this.localContactInfo.mobile?.telegram) {
        this.localContactInfo.telegram = {
          data: this.localContactInfo.mobile.data,
          visible: this.localContactInfo.telegram
            ? this.localContactInfo.telegram.visible
            : false,
        };
      }
    },
    sameForWhatsapp() {
      if (
        !this.localContactInfo.mobile?.whatsapp &&
        this.localContactInfo.mobile?.data.dialCode
      ) {
        this.localContactInfo.whatsapp = {
          data: this.localContactInfo.mobile.data,
          visible: this.localContactInfo.whatsapp
            ? this.localContactInfo.whatsapp.visible
            : this.localContactInfo.mobile.visible,
        };
      } else {
        this.localContactInfo.whatsapp = {
          ...this.contactInfo.whatsapp,
          data: {
            ...(this.contactInfo.whatsapp?.data || {
              dialCode: "",
              number: "",
            }),
          },
          visible: this.contactInfo.whatsapp
            ? this.contactInfo.whatsapp.visible
            : false,
        };
      }
    },
    sameForTelegram() {
      if (
        !this.localContactInfo.mobile?.telegram &&
        this.localContactInfo.mobile?.data.dialCode
      ) {
        this.localContactInfo.telegram = {
          data: this.localContactInfo.mobile.data,
          visible: this.localContactInfo.telegram
            ? this.localContactInfo.telegram.visible
            : this.localContactInfo.mobile.visible,
        };
      } else {
        this.localContactInfo.telegram = {
          ...this.contactInfo.telegram,
          data: {
            ...(this.contactInfo.telegram?.data || {
              dialCode: "",
              number: "",
            }),
          },
          visible: this.contactInfo.telegram
            ? this.contactInfo.telegram.visible
            : false,
        };
      }
    },
  },
  mounted() {
    this.setLocalContactInfo(this.contactInfo);
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    return {
      validateEmail,
    };
  },
  watch: {
    contactInfo: {
      handler(newValue: Contact) {
        this.setLocalContactInfo(newValue);
      },
      immediate: true,
    },
    updated: {
      handler(newValue: boolean) {
        if (newValue) {
          this.editMode = false;
          this.saving = false;
        }
      },
    },
    error: {
      handler(newValue: boolean) {
        if (newValue) {
          this.saving = false;
        }
      },
    },
    ccModalStatus: {
      handler(newValue: "hidden" | "shown") {
        switch (newValue) {
          case "shown":
            this.saving = true;
            break;
          case "hidden":
            this.saving = false;
            break;
        }
      },
    },
  },
});
