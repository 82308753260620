
import { mapGetters } from "vuex";
import {
  doGetEventTicketPaymentOptions,
  doHoldTickets,
  PaymentOptions,
} from "../../store/Events/EventsInterfaces";
import { defineComponent } from "vue";
import orderBy from "lodash.orderby";
import { CheckoutModal } from "../modals";
import * as bs from "bootstrap";
import dayjs, { Dayjs } from "dayjs";

export default defineComponent({
  name: "tickets-checkout",
  props: ["optionSelected", "disabled", "event"],
  data() {
    return {
      date_format: "DD \\d\\e MMMM \\d\\e YYYY",
      option_types_format: "En la fecha %%",
      loading: false,
      quote: {} as any,
      modalObj: {} as any,
      isQuoting: false,
      affiliateIsPaid: false,
      lastQuote: {} as any,
    };
  },
  components: {
    CheckoutModal,
  },
  computed: {
    ...mapGetters("sys", ["isOffline"]),
    ...mapGetters("events", ["getEvent"]),
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    is_disabled() {
      if (typeof this.disabled == "boolean" && this.disabled == true)
        return this.disabled;
      if (typeof this.disabled == "boolean" && this.disabled == false)
        return this.event?.payment_options?.length == 0;
      return false;
    },
    payment_discounts() {
      return (options: PaymentOptions[]) =>
        orderBy(options, ["position"], ["asc"]);
    },
    option_type_html() {
      return (opt) =>
        this.option_types_format.replace(
          "%%",
          `<strong>${this.get_date(opt)}</strong>`
        );
    },
    get_date() {
      return (opt) => {
        let date: Dayjs | string = dayjs();
        switch (opt.type) {
          case "days_after_purchase":
            date = date.add(opt.value, "days");
            break;
          case "day_next_month":
            date = date.date(opt.value).add(1, "month");
            break;
          case "immediately":
            date = "inmediatamente";
            break;
          default:
            date = dayjs(opt.value);
            break;
        }
        return typeof date !== "string" ? date.format(this.date_format) : date;
      };
    },
    grand_total() {
      return this.optionSelected.reduce((a, b) => a + b.total, 0);
    },
    format_currency() {
      return (price: any = { value: 0 }, total = null) =>
        `${this.event.currency} ` +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: this.event.currency,
        }).format(total != null ? total : price.value);
    },
  },
  methods: {
    doGetEventTicketPaymentOptions() {
      if (!this.event) return;
      this.$store.dispatch("events/" + doGetEventTicketPaymentOptions, {
        event_id: this.event?.id,
      });
    },
    selectOption(paymentOption) {
      this.loading = true;
      const ticketOptions = this.optionSelected.map((ticket) => ({
        id: ticket.id,
        quantity: ticket.quantity,
        total: ticket.total,
      }));

      this.$store
        .dispatch("events/buyTickets", {
          eventId: this.event?.id,
          eventName: this.event?.title,
          paymentOption: paymentOption.id,
          ticketOptions,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$router.push({ name: "tickets" });
          }, 1000);
        })
        .catch((err) => alert(err));
    },
    clearQuote() {
      this.quote = {} as any;
    },
    paid() {
      this.affiliateIsPaid = true;
      this.modalObj.hide();
      this.$router.push("/tickets");
    },
    async openCheckoutModal() {
      // generate Hold
      if (!this.isOffline) {
        this.isQuoting = true;
        const ticketOptions = this.optionSelected.map((ticket) => ({
          id: ticket.id,
          quantity: ticket.quantity,
          total: ticket.total,
        }));
        this.$store
          .dispatch(`events/${doHoldTickets}`, {
            eventId: this.event?.id,
            eventName: this.event?.title,
            ticketOptions,
          })
          .then((q) => {
            if (q.OPCODE == "ERROR") {
              alert(q.message);
            } else {
              this.quote = {
                ...q.data,
                expirationTime: new Date(
                  (q.data.expirationTime as any)._seconds * 1000
                ),
              };
              if (!this.modalObj._isShown) {
                this.modalObj.show();
              }
            }
            this.isQuoting = false;
          });
      }
    },
    initialQuote() {
      this.quote = {
        event: this.event.title,
        eventId: this.event.id,
        username: this.getUsernamePK,
        email: this.getUseremail,
        fromAmount: this.grand_total,
        fromCurrency: this.event.currency,
      };
    },
  },
  mounted() {
    this.doGetEventTicketPaymentOptions();
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
  },
});
