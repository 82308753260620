import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_qrcode = _resolveComponent("vue-qrcode")!

  return (_openBlock(), _createBlock(_component_vue_qrcode, {
    ref: "qrcode",
    value: _ctx.data,
    options: _ctx.options
  }, null, 8, ["value", "options"]))
}