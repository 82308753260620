
import { defineComponent } from "vue";

import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "gtr-points-detail",
  props: {
    gtrpoints: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("gtrPoints", ["getGtrPointsTypeConfig"]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    translateType(type: string): string {
      return this.getGtrPointsTypeConfig(type)?.label || type;
    },
    typeIconClass(type: string): string {
      return this.getGtrPointsTypeConfig(type)?.icon || "";
    },
  },
});
