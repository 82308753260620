
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import {
  CLEAR_USER_WITHDRAWALS,
  doGetWithdrawals,
  unsubscribeListener,
} from "@/store/Withdrawals/WithdrawalsInterfaces";

import dayjs from "dayjs";
import { Commission } from "@/store/Commissions/CommissionsInterfaces";
import { getCommission } from "../payouts/PayoutDetail.vue";

export default defineComponent({
  name: "withdrawals-detail",
  data() {
    return {
      commissionsDetailsMap: new Map<number, Array<Commission>>(),
    };
  },
  computed: {
    ...mapGetters("commissions", [
      "getCommissionTypeConfig",
      "getCommissionStatusConfig",
    ]),
    ...mapGetters("withdrawals", ["getWithdrawals"]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    statusIconClass(status: string): string {
      switch (status) {
        case "new":
          return "far fa-clock";
        case "processing":
          return "fas fa-cogs";
        case "paid":
          return "fas fa-flag-checkered";
        case "confirmed":
          return "fas fa-cogs";
        case "complete":
          return "far fa-check-circle";
        case "expired":
          return "fas fa-ban";
        case "invalid":
          return "fas fa-exclamation-triangle";
        default:
          return "";
      }
    },
    statusColorClass(status: string): string {
      switch (status) {
        case "new":
          return "text-warning";
        case "processing":
          return "text-cyan";
        case "paid":
          return "text-teal";
        case "confirmed":
          return "text-cyan";
        case "complete":
          return "text-success";
        case "expired":
          return "text-warning";
        case "invalid":
          return "text-danger";
        default:
          return "";
      }
    },
    translateStatus(status: string): string {
      switch (status) {
        case "new":
          return "Iniciado";
        case "processing":
          return "En proceso";
        case "paid":
          return "Iniciado";
        case "confirmed":
          return "Procesando";
        case "complete":
          return "Pagado";
        case "expired":
          return "Expirado";
        case "invalid":
          return "Inválido";
        default:
          return status;
      }
    },
    hashIconClass() {
      return "fas fa-hourglass-half";
    },
    loadCommissionDetails(
      username: string,
      widrawalId: number,
      commissionsIds: Array<number>
    ) {
      if (!this.commissionsDetailsMap.has(widrawalId)) {
        const commissions = new Array<Commission>();
        for (const c of commissionsIds) {
          const commission: Commission = {
            _id: c,
            amount: 0,
            status: "loading",
            source: "",
            date: new Date(c),
            // concept: "",
            type: "",
          };
          commissions.push(commission);
          getCommission(username, c)
            .then((data) => {
              const arrayRef = this.commissionsDetailsMap.get(widrawalId);
              if (!arrayRef) {
                return;
              }
              const index = arrayRef.findIndex((c) => c._id == data._id);
              if (index > -1) {
                arrayRef[index].amount = data.amount;
                arrayRef[index].status = data.status;
                arrayRef[index].source = data.source;
                arrayRef[index].type = data.type;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        this.commissionsDetailsMap.set(widrawalId, commissions);
      }
    },
    translateType(type: string): string {
      return this.getCommissionTypeConfig(type)?.label || type;
    },
    typeIconClass(type: string): string {
      return this.getCommissionTypeConfig(type)?.icon || "";
    },
    amountClass(status: string): string {
      return this.getCommissionStatusConfig(status)?.amountClass || "";
    },
  },
  created() {
    if (this.getWithdrawals.length > 0) {
      this.$store.commit("withdrawals/" + CLEAR_USER_WITHDRAWALS);
    }
    this.$store.dispatch("withdrawals/" + doGetWithdrawals);
  },
  beforeUnmount() {
    this.$store.dispatch("withdrawals/" + unsubscribeListener);
  },
});
