
import { AccountSummary } from "@/store/Account/AccountInterface";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
//import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "AccountSummary",
  data() {
    return {
      account: {
        academyDaysLeft: 0,
        distributorDaysLeft: 0,
        directAffiliates: 0,
        hasBinaryNetworkBonus: false,
        bonusRemainingDays: 0,
        configuredWallet: false,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("account", ["getAccountSummary"]),
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    usernamePK(): string {
      if (this.getUsernamePK) {
        this.getAccount(this.getUsernamePK);
      }
      return this.getUsernamePK;
    },
    expiringSide(): string {
      switch (this.accountSummary.binaryBonusExpiringSide) {
        case "left":
          return "Izquierdo";
        case "right":
          return "Derecho";
        default:
          return "";
      }
    },
    scholarshipStartDate(): string {
      return this.accountSummary?.scholarshipStartDate
        ? this.formatDateTimezone(
            this.accountSummary.scholarshipStartDate.getTime()
          )
        : "-";
    },
    scholarshipEndDate(): string {
      return this.accountSummary?.scholarshipEndDate
        ? this.formatDateTimezone(
            this.accountSummary.scholarshipEndDate.getTime()
          )
        : "-";
    },
  },
  methods: {
    getAccount(username: string) {
      this.$store
        .dispatch("account/initializeAccountSummary", username)
        .catch((error) => {
          console.error(error);
        });
    },
    daysLeftClass(daysLeft: number): string {
      if (daysLeft <= 5) {
        return "bg-danger";
      } else if (daysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    bonusClass(innerLegUnlocked: boolean, hasBonus: boolean, days: number) {
      if (innerLegUnlocked == false) {
        return "fa fas fa-lock";
      } else if (hasBonus) {
        if (days < 10) {
          return "bi bi-exclamation-circle-fill text-red";
        } else if (days >= 10 && days < 15) {
          return "bi bi-exclamation-circle-fill text-warning";
        } else {
          return "bi bi-check-circle-fill text-green";
        }
      } else {
        return "bi bi-x-circle-fill text-red";
      }
    },
    scholarshipPointsClass(points: number) {
      const classes = "fa-lg ms-2";
      if (this.accountSummary.scholarshipPoints >= points) {
        return "fas fa-user text-orange " + classes;
      } else {
        return "far fa-user text-light-gray " + classes;
      }
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
  },
});
