
import { defineComponent } from "vue";
import { images } from "../../mixins/images";
import { getFormattedTimestamp } from "@/mixins/dateTools";

export default defineComponent({
  name: "event-card",
  props: ["event", "admin", "getEvents"],
  data() {
    return {
      image: "" as string,
      logo: "" as string,
    };
  },
  components: {},
  computed: {
    date() {
      return (date) => {
        return getFormattedTimestamp(date);
      };
    },
  },
  methods: {
    async getImageSrc() {
      const imageSrc = await images.methods.getImage(this.event.image);
      this.image = imageSrc;
    },
    async getLogoSrc() {
      const imageSrc = await images.methods.getImage(this.event.logo);
      this.logo = imageSrc;
    },
    goEdit(id) {
      this.$router.push({
        name: "buy-tickets-admin-edit-event",
        params: {
          id,
        },
      });
    },
    archiveEvent(eventId) {
      if (confirm("¿Estás seguro de querer eliminar este registro?")) {
        this.$store
          .dispatch("events/archiveEvent", { eventId })
          .then(() => (this.$parent as any).getEvents());
      }
    },
    unarchiveEvent(eventId) {
      if (confirm("¿Estás seguro de querer restaurar este registro?")) {
        this.$store
          .dispatch("events/unarchiveEvent", { eventId })
          .then(() => (this.$parent as any).getEvents());
      }
    },
  },
  mounted() {
    if (this.event.image) this.getImageSrc();
    if (this.event.logo) this.getLogoSrc();
  },
});
