
import * as fb from "@/firebase";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import QRCode from "qrcode";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import * as bs from "bootstrap";
import { createConfetti } from "@/assets/js/confettiKit/confettiConstructor";

export default defineComponent({
  name: "checkout-modal",
  props: {
    quote: {
      type: Object as () => Quote<QuoteRequestType>,
      required: true,
    },
  },
  emits: ["clearQuote", "renewQuote", "paid"],
  data() {
    return {
      unsubscribe: () => {
        return;
      },
      expiration: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
        difference: 0,
        string: "",
      },
      timer: setInterval(() => {
        return;
      }, 10000),
      paymentRef: "bitcoin:?amount=",
      refreshFlag: 0,
      refreshTimer: setInterval(() => {
        return;
      }, 10000),
      bitcoinPartyTimeout: setTimeout(() => {
        return;
      }, 10000),
      replayTimeout: setTimeout(() => {
        return;
      }, 10000),
      paymentReceived: false,
      countdownBP: 3,
      replay: false,
      isPaid: false,
      paidQuoteData: {
        type: "",
        username: "",
        subscriptionName: "",
        concept: "",
      },
      modalObject: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("subs", ["getSubscriptionConfig", "durationUnitLocalized"]),
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.quote.subscriptionId);
    },
    durationUnitString(): string {
      return this.durationUnitLocalized(
        this.quote.duration,
        this.quote.durationUnit
      );
    },
  },
  methods: {
    startCountdown(toDate: Date) {
      this.timer = setInterval(() => this.countdown(toDate), 1000);
    },
    countdown(toDate: Date) {
      const dateEntered = toDate;
      const now = new Date();
      const difference = dateEntered.getTime() - now.getTime();

      if (difference <= 0) {
        // Timer done
        clearInterval(this.timer);
        //this.$emit("clearQuote");
      } else {
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        hours %= 24;
        minutes %= 60;
        seconds %= 60;

        const t = {
          difference: difference,
          days: days.toString(),
          hours: hours.toString(),
          minutes: ("00" + minutes.toString()).slice(-2),
          seconds: ("00" + seconds.toString()).slice(-2),
          string: "",
        };

        let exp = "";
        exp +=
          days > 0
            ? days == 1
              ? days.toString() + " día "
              : days.toString() + " días "
            : "";
        exp += t.hours + ":" + t.minutes + ":" + t.seconds;
        t.string = exp;
        this.expiration = t;
      }
    },
    renderQr() {
      QRCode.toCanvas(
        document.getElementById("qrCanvas"),
        this.paymentRef,
        {
          width: 246,
          height: 246,
        },
        (error: any) => {
          if (error) alert(error);
        }
      );
    },
    renewQuote() {
      clearInterval(this.timer);
      this.expiration.string = "";
      const canvas = document.getElementById("qrCanvas") as HTMLCanvasElement;
      const context = canvas.getContext("2d");
      context?.clearRect(0, 0, canvas.width, canvas.height);

      this.paidQuoteData = {
        type: "",
        username: "",
        subscriptionName: "",
        concept: "",
      };

      this.$emit("clearQuote");
      const request: QuoteSubscriptionRequest = {
        subscriptionId: this.quote.subscriptionId,
        duration: this.quote.duration,
        durationUnit: this.quote.durationUnit,
        username: this.quote.username,
        email: this.quote.email,
        isDirectAffiliate: false,
        forceUpdate: true,
      };
      this.$emit("renewQuote", request);
    },
    copyToClipboard(inputId: string) {
      const input = document.getElementById(inputId) as HTMLInputElement;
      if (input) {
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand("copy");
      }
    },
    close() {
      setTimeout(() => {
        this.paidQuoteData = {
          type: "",
          username: "",
          subscriptionName: "",
          concept: "",
        };
        this.$emit("clearQuote");
        this.isPaid = false;
        this.replay = false;
        this.paymentReceived = false;
        clearTimeout(this.replayTimeout);
        clearTimeout(this.bitcoinPartyTimeout);
        this.unsubscribe();
      }, 1000);
    },
    confetti() {
      createConfetti();
    },
    bitcoinParty() {
      this.paymentReceived = true;
      this.replay = false;
      this.countdownBP = 3;
      setTimeout(() => {
        this.countdownBP--;
        setTimeout(() => {
          this.countdownBP--;
          setTimeout(() => {
            this.paymentReceived = false;
          }, 1500);
        }, 1500);
      }, 1500);
      this.bitcoinPartyTimeout = setTimeout(() => {
        this.confetti();
        this.replayTimeout = setTimeout(() => {
          this.replay = true;
        }, 8000);
      }, 5000);
    },
  },
  watch: {
    "quote.expirationTime": function (newValue) {
      if (this.quote.expirationTime) {
        clearInterval(this.timer);
        this.countdown(newValue);
        this.startCountdown(newValue);
      } else if (this.quote.expirationTime == undefined) {
        clearInterval(this.timer);
        this.expiration.string = "";
        //this.isPayed = false;
      }
    },
    "quote.address": function (newValue) {
      if (newValue != undefined) {
        let coin: string;
        switch (this.quote.paymentCurrency) {
          case "BTC":
            coin = "bitcoin";
            break;
          case "LTCT":
            coin = "litecoin";
            break;
          default:
            coin = "bitcoin";
        }
        this.paymentRef =
          coin +
          ":" +
          this.quote.address +
          "?amount=" +
          this.quote.paymentAmount;
        this.renderQr();
      }
    },
    "quote.tx_id": function (newValue) {
      if (newValue != undefined) {
        const _quoteRef = fb.fbfs
          .collection("users/" + this.quote.username + "/quotes")
          .doc(newValue);
        this.unsubscribe();
        this.unsubscribe = _quoteRef.onSnapshot((docSnapshot) => {
          if (docSnapshot.exists) {
            //console.log(docSnapshot.data()?.status);
            if (
              docSnapshot.data()?.status == "paid" ||
              docSnapshot.data()?.status == "confirmed" ||
              docSnapshot.data()?.status == "complete" ||
              docSnapshot.data()?.status == "paidOver"
            ) {
              //console.log("inside if", docSnapshot.data()?.status);
              this.paidQuoteData = {
                type: this.quote.type,
                username: this.quote.username,
                subscriptionName: this.SubscriptionConfig?.displayName,
                concept: this.quote.concept,
              };
              this.unsubscribe();
              this.paymentReceived = true;
              this.countdownBP = 3;
              setTimeout(() => {
                this.countdownBP--;
                setTimeout(() => {
                  this.countdownBP--;
                  setTimeout(() => {
                    this.paymentReceived = false;
                  }, 1500);
                }, 1500);
              }, 1500);
              this.bitcoinPartyTimeout = setTimeout(() => {
                this.$emit("clearQuote");
                this.isPaid = true;
                this.$emit("paid");
                this.refreshTimer = setInterval(() => {
                  if (this.isPaid) {
                    this.refreshFlag = Math.random() * 100;
                  } else {
                    clearInterval(this.refreshTimer);
                  }
                }, 6000);
                this.confetti();
                this.replayTimeout = setTimeout(() => {
                  this.replay = true;
                }, 8000);
              }, 5000);
            }
          }
        });
      }
    },
  },
  mounted() {
    if (!this.isPaid) {
      const btnCopyWallet = document.getElementById("buttonCopyWallet");
      const btnCopyTotal = document.getElementById("buttonCopyTotal");
      /*const ttWallet = */ new bs.Tooltip(btnCopyWallet);
      /*const ttTotal  = */ new bs.Tooltip(btnCopyTotal);
      //console.log(ttWallet, ttTotal);
    }
  },
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.refreshTimer);
    clearTimeout(this.replayTimeout);
    clearTimeout(this.bitcoinPartyTimeout);
    this.unsubscribe();
  },
});
