
import { defineComponent } from "vue";
import { images } from "@/mixins/images";
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as bs from "bootstrap";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { UserProfileCardData } from "@/store/Profile/ProfileInterfaces";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import { Country } from "@/store/System/SystemConfigInterfaces";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "profile-card",
  props: {
    user: {
      type: Object as () => UserProfileCardData,
      required: true,
    },
  },
  data() {
    return {
      userPicture: "",
      coverImage: "",
      displayedCountry: {} as Country,
      editMode: false,
      localNickname: "",
      galleryModal: {} as any,
      saving: false,
    };
  },
  computed: {
    //...mapGetters("auth", ["getUsernamePK", "getUserPicture"]),
    ...mapGetters("ranks", ["rankById"]),
    ...mapGetters("sys", ["getCountryByAlpha3Code"]),
    userRank(): Rank {
      return this.rankById(this.user.currentRank);
    },
    location() {
      let l = "";
      if (
        this.user.city &&
        this.user.state &&
        this.user.city != "" &&
        this.user.state != ""
      ) {
        l = this.user.city + ", " + this.user.state;
      } else if (this.user.city && this.user.city != "") {
        l = this.user.city;
      } else if (this.user.state && this.user.state != "") {
        l = this.user.state;
      }
      return l;
    },
    tiktokUsername(): string {
      return this.user.contact.tiktok?.data.startsWith("@")
        ? this.user.contact.tiktok.data
        : "@" + this.user.contact.tiktok?.data;
    },
    canEdit(): boolean {
      // if ()
      return (
        firebase.auth().currentUser?.email?.toString() ===
          this.user.contact.email?.data.toString() &&
        firebase.auth().currentUser?.displayName?.toString() ===
          this.user.username.toString().toLowerCase()
      );
    },
  },
  methods: {
    formatDateTimezone(milliseconds: number): string {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
    setDisplayedCountry() {
      this.displayedCountry = {
        ...this.getCountryByAlpha3Code(this.user.country),
      };
    },
    editNickname() {
      this.editMode = true;
      this.$nextTick(() => {
        (this.$refs.nickname as HTMLInputElement).select();
      });
    },
    save() {
      if (this.localNickname != this.user.nickname) {
        this.saving = true;
        this.$store
          .dispatch("profile/doUpdateNickname", this.localNickname.trim())
          .then(() => {
            this.editMode = false;
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.editMode = false;
      }
    },
    cancelEdit() {
      this.editMode = false;
      this.localNickname = this.user.nickname;
    },
    openGalleryModal() {
      this.galleryModal.show();
    },
  },
  mounted() {
    const coverImage =
      this.user.coverPictureUrl && this.user.coverPictureUrl != ""
        ? this.user.coverPictureUrl
        : "img/backgrounds/triangles-3d-1.png";
    this.getImage(coverImage).then((r) => {
      this.coverImage = r;
    });

    if (this.user.pictureUrl && this.user.pictureUrl != "") {
      if (!this.user.pictureUrl?.startsWith("http")) {
        this.getProfilePicture(this.user.pictureUrl).then((r) => {
          this.userPicture = r;
        });
      } else {
        this.userPicture = this.user.pictureUrl;
      }
    }
    this.localNickname = this.user.nickname;
    this.setDisplayedCountry();
    // if (this.canEdit) {
    this.galleryModal = bs.Modal.getOrCreateInstance(
      document.getElementById("profile-picture-gallery-modal")
    );
    // }
  },
  watch: {
    "user.pictureUrl": function (newValue: string) {
      if (newValue != "") {
        if (!newValue?.startsWith("http")) {
          this.getProfilePicture(newValue).then((r) => {
            this.userPicture = r;
          });
        } else {
          this.userPicture = newValue;
        }
      }
    },
    "user.coverPictureUrl": function (newValue: string) {
      const coverImage =
        this.user.coverPictureUrl && this.user.coverPictureUrl != ""
          ? newValue
          : "img/backgrounds/triangles-3d-1.png";
      this.getImage(coverImage).then((r) => {
        this.coverImage = r;
      });
    },
    "user.country": function () {
      this.setDisplayedCountry();
    },
    "user.nickname": function (newValue: string) {
      if (newValue != "") {
        this.localNickname = newValue;
      }
    },
  },
  setup() {
    const getImage = images.methods.getImage;
    const getProfilePicture = images.methods.getProfilePicture;
    return {
      getImage,
      getProfilePicture,
    };
  },
});
