
import { defineComponent } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "ClassEditModal",
  data() {
    return {
      classInfo: {
        imageUrl: "",
        link: "",
        registrationOpen: false,
        title: "",
      },
      edit: false,
      hasImage: false,
      time: "",
      dayOfWeek: "",
    };
  },
  props: {
    timeKey: Number,
    day: String,
    index: Number,
    info: Object,
    mode: String,
    program: String,
  },
  mounted() {
    this.edit = this.mode == "edit" ? true : false;

    if (this.edit) {
      this.classInfo.imageUrl = this.info?.imageUrl;
      this.classInfo.link = this.info?.link;
      this.classInfo.registrationOpen = this.info?.registrationOpen;
      this.classInfo.title = this.info?.title;
    }
  },
  methods: {
    invalidFields() {
      if (this.edit) {
        if (!this.classInfo.link || !this.classInfo.title) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          !this.classInfo.link ||
          !this.classInfo.title ||
          !this.dayOfWeek ||
          !this.time
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    close() {
      this.$emit("close");
    },
    deleteClass() {
      this.$store.dispatch("loading", true);
      const week = dayjs().format("WW");
      const year = dayjs().format("YYYY");
      this.$store
        .dispatch("classes/deleteClass", {
          week: week,
          year: year,
          program: this.program,
          class: {
            hour: this.timeKey,
            day: this.day,
            index: this.index,
          },
        })
        .then((response) => {
          alert(response.message);
          this.$store
            .dispatch("classes/doGetSchedules", {
              year: year,
              week: week,
              program: this.program,
            })
            .catch((error) => {
              alert(error);
            });
          this.close();
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
    save() {
      this.$store.dispatch("loading", true);
      const week = dayjs().format("WW");
      const year = dayjs().format("YYYY");

      if (this.edit) {
        this.$store
          .dispatch("classes/updateClass", {
            week: week,
            year: year,
            program: this.program,
            class: {
              hour: this.timeKey,
              day: this.day,
              index: this.index,
              info: this.classInfo,
            },
          })
          .then((response) => {
            alert(response.message);
            this.$store
              .dispatch("classes/doGetSchedules", {
                year: year,
                week: week,
                program: this.program,
              })
              .catch((error) => {
                alert(error);
              });
            this.close();
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      } else {
        this.$store
          .dispatch("classes/addClass", {
            week: week,
            year: year,
            program: this.program,
            class: {
              hour: dayjs(this.time, "HH:mm").format("hh:mma"),
              day: this.dayOfWeek,
              index: 0,
              info: this.classInfo,
            },
          })
          .then((response) => {
            alert(response.message);
            this.$store
              .dispatch("classes/doGetSchedules", {
                year: year,
                week: week,
                program: this.program,
              })
              .catch((error) => {
                alert(error);
              });
            this.close();
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    },
    selectImage() {
      const inputImage = document.getElementById(
        "inUploadPicture"
      ) as HTMLInputElement;
      inputImage.click();
    },
    uploadImage(e: any) {
      if (e.target.files.length) {
        if (e.target.files[0].size > 5000000) {
          alert("Seleccione una imagen de máximo 5 MB");
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          if (e?.target?.result) {
            this.classInfo.imageUrl = e.target.result as string;
            this.hasImage = true;
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    },
  },
});
