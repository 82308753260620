
import { defineComponent } from "vue";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";
import { ClassData } from "functions/src/Classes/ClassesInterfaces";

dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);

export default defineComponent({
  name: "confirm-delete-class-modal",
  props: {
    classInfo: {
      type: Object as () => ClassData,
      required: true,
    },
  },
  data() {
    return {
      weekDays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      weekDaysEs: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
    };
  },
  methods: {
    deleteClass() {
      this.$emit("delete-class", this.classInfo);
    },
    translateDay(day: string) {
      return this.weekDaysEs[this.weekDays.indexOf(day)];
    },
  },
});
