
import { defineComponent, PropType } from "vue";
import * as bootstrap from "bootstrap";
import { VideoParams } from "@/store/System/SystemConfigInterfaces";
import VimeoPlayer from "@vimeo/player";

export default defineComponent({
  name: "videos-carousel",
  props: {
    playlist: {
      type: Array as PropType<Array<VideoParams>>,
      required: true,
    },
  },
  data() {
    return {
      activeSlide: 0,
    };
  },
  mounted() {
    const myCarousel = document.querySelector("#carouselVideos");
    const carousel = new bootstrap.Carousel(myCarousel);
    carousel.cycle();
    if (myCarousel) {
      myCarousel.addEventListener("slide.bs.carousel", (par: any) => {
        this.activeSlide = par.to;
      });
    }
    // Create a new Vimeo player instance and add an event listener for the "play" event
    const iframes = document.querySelectorAll("#vimeoPlayer");
    for (let i = 0; i < iframes.length; i++) {
      const currentiframe = iframes[i];
      if (currentiframe instanceof HTMLIFrameElement) {
        const player = new VimeoPlayer(currentiframe);
        player.on("play", () => {
          carousel.pause(); // Pause the carousel when the video starts playing
        });
        player.on("pause", () => {
          carousel.cycle(); // Resume the carousel when the video stops playing
        });
        player.on("ended", () => {
          setTimeout(() => {
            player.unload(); // Reset the video player to show cover and rewind
          }, 3000);
          carousel.cycle(); // Resume the carousel when the video ends
        });
      }
    }
  },
});
