
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { images } from "@/mixins/images";
import { MMIUser } from "@/store/MMI/MMIInterfaces";

export default defineComponent({
  name: "mmi-user-card",
  props: {
    userData: {
      type: Object as () => MMIUser,
      required: true,
    },
    chapter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      profilePictureUrl: "",
    };
  },
  computed: {
    userInitialDate(): string {
      const d: Date =
        (this.userData.initialDate as any)._seconds !== undefined
          ? new Date((this.userData.initialDate as any)._seconds * 1000)
          : this.userData.initialDate;
      return d.getTime() === 0 ? "-" : dayjs(d).format("YYYY/MM/DD HH:mm:ss z");
    },
  },
  created() {
    this.getProfilePicture(this.userData.profilePicture).then((response) => {
      this.profilePictureUrl = response;
    });
  },
  setup() {
    const getProfilePicture = images.methods.getProfilePicture;
    return {
      getProfilePicture,
    };
  },
});
