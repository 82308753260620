
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  CLEAR_USER_GTR_POINTS,
  GtrPointsRecord,
  doGetGtrPoints,
  unsubscribeListener,
} from "../store/GtrPoints/GtrPointsInterfaces";
import { gtrPointsDetail, gtrPointsSummary } from "../components/gtrPoints";

export default defineComponent({
  name: "gtr-points",
  components: { gtrPointsDetail, gtrPointsSummary },
  data() {
    return {
      loading: true,
      unwatch: () => {
        return;
      },
      loaded: false,
    };
  },
  computed: {
    ...mapGetters("gtrPoints", {
      getGtrPoints: "getGtrPoints",
      summary: "getSummary",
    }),
    gtrPoints(): GtrPointsRecord[] {
      const ordered = [...this.getGtrPoints];
      return ordered.sort((a: GtrPointsRecord, b: GtrPointsRecord) => {
        return b._id - a._id;
      });
    },
  },
  methods: {
    async requestGtrPoints() {
      this.loading = true;
      this.$store.dispatch("gtrPoints/" + doGetGtrPoints).then(() => {
        this.loading = false;
      });
      this.loaded = true;
    },
  },
  async created() {
    if (this.gtrPoints.length > 0) {
      this.$store.commit("gtrPoints/" + CLEAR_USER_GTR_POINTS);
    }
    this.requestGtrPoints();
    this.unwatch = this.$store.watch(
      (state) => state.gtrPoints,
      () => {
        return;
      },
      { deep: true }
    );
  },
  beforeUnmount() {
    this.unwatch();
    this.$store.dispatch("gtrPoints/" + unsubscribeListener);
  },
});
