
import { mapGetters } from "vuex";
import { defineComponent, ref } from "vue";
import {
  CLEAR_USER_QUOTES,
  doGetQuotes,
  unsubscribeListener,
  Quote,
} from "@/store/Payments/PaymentsInterfaces";
import * as bs from "bootstrap";
import { CheckoutModal } from "../modals";

import dayjs from "dayjs";
import { QuoteSubscriptionRequest } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { quoteSubscription } from "@/store/Subscriptions/SubscriptionsConsts";

export default defineComponent({
  name: "quotes-detail",
  components: {
    CheckoutModal,
  },
  data() {
    return {
      quote: {} as Quote,
      modalObj: {} as any,
      isQuoting: false,
    };
  },
  computed: {
    ...mapGetters("sys", ["isOffline"]),
    ...mapGetters("payments", ["getQuotes"]),
    ...mapGetters("subs", ["getUserSubscription"]),
    quotes(): Array<Quote> {
      const ordered = [...this.getQuotes];
      return ordered.sort((a: Quote, b: Quote) => {
        return (
          (a.createdDate as Date).getTime() - (b.createdDate as Date).getTime()
        );
      });
    },
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    isTimeToHide(quote: Quote) {
      const hideTime = new Date(quote.expirationTime as Date);
      return (
        this.currentTime >= hideTime || !["new", "paid"].includes(quote.status)
      );
    },
    showModal(quote: Quote) {
      const q = { ...quote };
      q.expirationTime = new Date(q.expirationTime as Date);
      this.quote = q;
      if (!this.modalObj._isShown) {
        this.modalObj.show();
      }
    },
    clearQuote() {
      this.quote = {} as Quote;
    },
    createQuote(request: QuoteSubscriptionRequest) {
      if (!this.isOffline) {
        this.isQuoting = true;
        this.$store.dispatch("subs/" + quoteSubscription, request).then((q) => {
          if (q.OPCODE == "ERROR") {
            alert(q.message);
            if (!this.modalObj._isShown) {
              this.modalObj.hide();
            }
          } else {
            this.quote = q;

            if (!this.modalObj._isShown) {
              this.modalObj.show();
            }
          }
          this.isQuoting = false;
        });
      }
    },
  },
  created() {
    if (this.getQuotes.length > 0) {
      this.$store.commit("payments/" + CLEAR_USER_QUOTES);
    }
    this.$store.dispatch("payments/" + doGetQuotes);
  },
  mounted() {
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
  },
  beforeUnmount() {
    this.$store.dispatch("payments/" + unsubscribeListener, "quotes");
  },
  setup() {
    const currentTime = ref(new Date());

    setInterval(() => {
      currentTime.value = new Date();
    }, 1000);

    return {
      currentTime,
    };
  },
});
