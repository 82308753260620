import { CommitInterface } from "..";
import * as fb from "../../firebase";
import {
  BuildSchedule,
  ClassesState,
  SET_ACADEMY_LAST_UPDATE,
  SET_GTR_SYSTEM_LAST_UPDATE,
  SET_LAST_UPDATED_LISTENER,
  sortTime,
  TimeWeekDefinition,
} from "./ClassesInterfases";
import { images } from "@/mixins/images";
import { httpsCallable } from "firebase/functions";
import { DataResponse } from "functions/src/responseTypes";
import { ClassData } from "functions/src/Classes/ClassesInterfaces";

const initialState: ClassesState = {
  academy: new Map<string, TimeWeekDefinition>(),
  gtrSystem: new Map<string, TimeWeekDefinition>(),
  academyLastUpdated: new Date(0),
  gtrSystemLastUpdated: new Date(0),
  lastUpdatedListener: () => {
    return;
  },
};

const state = { ...initialState };

const mutations = {
  SET_CLASSES(
    state: ClassesState,
    data: { scheduleId: string; schedule: Map<string, TimeWeekDefinition> }
  ) {
    switch (data.scheduleId) {
      case "academy":
        state.academy = data.schedule;
        break;
      case "gtrSystem":
        state.gtrSystem = data.schedule;
        break;
    }
  },
  SET_SCHEDULE_ACADEMY(
    state: ClassesState,
    schedule: Map<string, TimeWeekDefinition>
  ) {
    state.academy = schedule;
  },
  SET_SCHEDULE_GTR(
    state: ClassesState,
    schedule: Map<string, TimeWeekDefinition>
  ) {
    state.gtrSystem = schedule;
  },
  SET_ACADEMY_LAST_UPDATE(state: ClassesState, date: Date) {
    state.academyLastUpdated = date;
  },
  SET_GTR_SYSTEM_LAST_UPDATE(state: ClassesState, date: Date) {
    state.gtrSystemLastUpdated = date;
  },
  SET_LAST_UPDATED_LISTENER(state: ClassesState, listener: () => void): void {
    state.lastUpdatedListener = listener;
  },
  CLEAR_LAST_UPDATED_LISTENER(state: ClassesState): void {
    state.lastUpdatedListener = () => {
      return;
    };
  },
  CLEAR_CLASSES_STATE(state: ClassesState) {
    Object.assign(state, initialState);
  },
};

const actions = {
  createUpdatesListener({ commit, dispatch }: CommitInterface<ClassesState>) {
    const scheduleUpdateListener = fb.fbfs
      .collection("schedule")
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          if (data) {
            const lastUpdated = data.lastUpdated.toDate();
            if (change.doc.id === "academy") {
              if (lastUpdated > state.academyLastUpdated) {
                dispatch("doGetAcademySchedule");
              }
              commit(SET_ACADEMY_LAST_UPDATE, lastUpdated);
            } else if (change.doc.id === "gtrSystem") {
              if (lastUpdated > state.gtrSystemLastUpdated) {
                dispatch("doGetGtrSystemSchedule");
              }
              commit(SET_GTR_SYSTEM_LAST_UPDATE, lastUpdated);
            }
          }
        });
      });
    commit(SET_LAST_UPDATED_LISTENER, scheduleUpdateListener);
  },
  doGetSchedules({ dispatch }: CommitInterface<ClassesState>) {
    // Fetch both academy and GTR system schedules
    dispatch("doGetAcademySchedule");
    dispatch("doGetGtrSystemSchedule");
  },
  doGetAcademySchedule({ commit }: CommitInterface<ClassesState>) {
    const GetWeekScheduleByHours = httpsCallable(
      fb.functions,
      "Classes-GetWeekScheduleByHours"
    );
    GetWeekScheduleByHours({ scheduleType: "academy" }).then((response) => {
      const data = response.data as DataResponse<
        Map<string, TimeWeekDefinition>
      >;
      if (data.OPCODE === "SUCCESS") {
        let schedule = new Map(Object.entries(data.data));
        BuildSchedule(schedule);
        schedule = new Map([...schedule].sort(sortTime));
        commit("SET_SCHEDULE_ACADEMY", schedule);
      }
    });
  },
  doGetGtrSystemSchedule({ commit }: CommitInterface<ClassesState>) {
    const GetWeekScheduleByHours = httpsCallable(
      fb.functions,
      "Classes-GetWeekScheduleByHours"
    );
    GetWeekScheduleByHours({ scheduleType: "gtrSystem" }).then((response) => {
      const data = response.data as DataResponse<
        Map<string, TimeWeekDefinition>
      >;
      if (data.OPCODE === "SUCCESS") {
        let schedule = new Map(Object.entries(data.data));
        BuildSchedule(schedule);
        schedule = new Map([...schedule].sort(sortTime));
        commit("SET_SCHEDULE_GTR", schedule);
      }
    });
  },
  async doChangePosition(_, data) {
    const response = httpsCallable(fb.functions, "Classes-ChangePosition");
    return response(data);
  },
  async doUpdateClass(
    _context: any,
    params: { newClassData: ClassData; oldClassData: ClassData }
  ) {
    const updateClass = httpsCallable(fb.functions, "Classes-UpdateClass");
    const response = await updateClass(params);
    const responseData = response.data as DataResponse<string>;
    if (responseData.OPCODE === "SUCCESS") {
      return responseData.data;
    } else if (responseData.OPCODE === "ERROR") {
      console.error(responseData);
      throw responseData.messageDetails;
    } else {
      throw new Error("Unknown error");
    }
  },
  async doDeleteClass(_context: any, params: { classInfo: ClassData }) {
    const deleteClass = httpsCallable(fb.functions, "Classes-DeleteClass");
    const response = await deleteClass(params);
    const responseData = response.data as DataResponse<string>;
    if (responseData.OPCODE === "SUCCESS") {
      return responseData.data;
    } else if (responseData.OPCODE === "ERROR") {
      console.error(responseData);
      throw responseData.messageDetails;
    } else {
      throw new Error("Unknown error");
    }
  },
  doSaveImage(_, request) {
    return images.methods.setImage(request);
  },
};

const getters = {
  getAcademySchedule(state: ClassesState): Map<string, TimeWeekDefinition> {
    return state.academy;
  },
  getGtrSystemSchedule(state: ClassesState): Map<string, TimeWeekDefinition> {
    return state.gtrSystem;
  },
  getSchedule(state: ClassesState) {
    return (scheduleId: string): Map<string, TimeWeekDefinition> => {
      switch (scheduleId) {
        case "trading-academy":
          return state.academy;
        case "gtr-system":
          return state.gtrSystem;
        default:
          return state.academy;
      }
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
