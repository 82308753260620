
import { defineComponent } from "vue";
import { MdPreview } from "md-editor-v3";
import "md-editor-v3/lib/preview.css";

export default defineComponent({
  name: "markdown-viewer",
  components: {
    MdPreview,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
});
