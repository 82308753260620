
import { defineComponent } from "vue";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import { mapGetters } from "vuex";
import { images } from "@/mixins/images";

import {
  setLeaderUsername,
  UnilevelMember,
} from "@/store/Unilevel/UnilevelInterfaces";

export default defineComponent({
  name: "team-member",
  props: {
    memberData: { required: true, type: Object as () => UnilevelMember },
    teamSide: { type: String, required: true },
    maxPointsPerUser: { type: Number, required: true },
  },
  data() {
    return {
      rankBackground: "",
      profilePic: "",
    };
  },
  computed: {
    ...mapGetters("ranks", ["rankById"]),
    userRank(): Rank {
      return this.rankById(this.memberData.currentRank);
    },
    picSideClass(): string {
      return this.teamSide == "left" ? "order-2" : "order-1";
    },
    dataSideClass(): string {
      return this.teamSide == "left" ? "order-1" : "order-2";
    },
    bgSideClass(): string {
      return this.teamSide == "left" ? "order-2" : "order-1";
    },
    photoSideClass(): string {
      return this.teamSide == "left" ? "order-1" : "order-2";
    },
    memberShortcutsClass(): string {
      return this.teamSide == "left" ? "order-2" : "order-1";
    },
    memberBriefClass(): string {
      return this.teamSide == "left" ? "order-1" : "order-2";
    },
    remainigDaysTradingAcademy(): number {
      return this.memberData.subscriptions?.get
        ? this.memberData.subscriptions?.get("trading-academy") || 0
        : 0;
    },
    remainigDaysDistributor(): number {
      return this.memberData.subscriptions?.get
        ? this.memberData.subscriptions?.get("distributor-license") || 0
        : 0;
    },
    isVIP(): boolean {
      let isVIP = false;
      try {
        // If subscriptions is a Map
        isVIP = this.memberData?.subscriptions.has("mmi-colombia") ?? false;
      } catch {
        // If subscriptions is an object
        isVIP = this.memberData?.subscriptions["mmi-colombia"] ?? false;
      }
      return isVIP;
    },
    gtrLiteDaysLeft(): number | undefined {
      const days = this.memberData.subscriptions.get("gtr-lite");
      return days;
    },
    liteDistributorDaysLeft(): number | undefined {
      const days = this.memberData.subscriptions.get(
        "lite-distributor-license"
      );
      return days;
    },
    currentUserPackage(): string {
      return this.memberData.subscriptionPackage.package.packageId;
    },
    isLite(): boolean {
      return this.currentUserPackage == "gtr-lite";
    },
    backgroundColor(): string {
      return this.isVIP
        ? "vip-background-color"
        : this.isLite
        ? "lite-background-color"
        : "bg-light";
    },
  },
  methods: {
    changeTeamLeader(newLeaderUsername: string) {
      if (newLeaderUsername != undefined) {
        this.$store.dispatch(
          "unilevel/" + setLeaderUsername,
          newLeaderUsername
        );
      }
    },
  },
  created() {
    this.getImage(this.userRank.backgroundImage).then((response) => {
      this.rankBackground = response;
    });
    this.getProfilePicture(this.memberData.pictureUrl).then((response) => {
      this.profilePic = response;
    });
  },
  setup() {
    const getProfilePicture = images.methods.getProfilePicture;
    const getImage = images.methods.getImage;
    return {
      getProfilePicture,
      getImage,
    };
  },
});
