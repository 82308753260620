
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import { Rank } from "@/store/Ranks/RanksInterfaces";
import TeamMember from "./TeamMember.vue";
import { UnilevelTeam } from "@/store/Unilevel/UnilevelInterfaces";

export default defineComponent({
  name: "team",
  components: {
    TeamMember,
  },
  props: {
    team: { required: true, type: Object as () => UnilevelTeam },
    loading: { required: true, type: Boolean },
  },
  data() {
    return {
      nextRankReqPoints: 0,
      showAcademy: "academyAll",
      showDistributor: "distributorAll",
      sortOrder: "ALF",
      displayedTeam: {
        leftTeam: new Array<UnilevelTeam>(),
        rightTeam: new Array<UnilevelTeam>(),
      },
    };
  },
  computed: {
    ...mapGetters("ranks", ["rankById"]),
    UserRank(): Rank {
      return this.rankById(this.team?.leader.currentRank);
    },
    currentPoints(): number {
      return this.team?.leader.points.left + this.team?.leader.points.right;
    },
    leftValidPoints(): number {
      return this.team?.leader.points.valid.left;
    },
    rightValidPoints(): number {
      return this.team?.leader.points.valid.right;
    },
    currentValidPoints(): number {
      return this.leftValidPoints + this.rightValidPoints;
    },
    leftPercentaje(): string {
      const p =
        (this.leftValidPoints * 100) / this.team?.leader.nextRankRequiredPoints;
      return p.toFixed(2);
    },
    rightPercentaje(): string {
      const p =
        (this.rightValidPoints * 100) /
        this.team?.leader.nextRankRequiredPoints;
      return p.toFixed(2);
    },
    displayedLeftTeam(): UnilevelTeam[] {
      return this.team ? [...this.team.leftTeam] : [];
    },
    displayedRightTeam(): UnilevelTeam[] {
      return this.team ? [...this.team.rightTeam] : [];
    },
    hasTeamMembers(): boolean {
      return this.team?.rightTeam.length > 0 || this.team?.leftTeam.length > 0;
    },
    totalTeamMembers(): number {
      return this.team?.rightTeam.length + this.team?.leftTeam.length;
    },
  },
  methods: {
    filterTeam() {
      this.displayedTeam.leftTeam = [...this.displayedLeftTeam].filter(
        (m: UnilevelTeam) => {
          return this.filterTeamFunction(m);
        }
      );
      this.displayedTeam.rightTeam = [...this.displayedRightTeam].filter(
        (m: UnilevelTeam) => {
          return this.filterTeamFunction(m);
        }
      );
    },
    orderTeam() {
      this.displayedTeam.leftTeam.sort((a, b) => {
        return this.sortTeamFunction(a, b);
      });
      this.displayedTeam.rightTeam.sort((a, b) => {
        return this.sortTeamFunction(a, b);
      });
    },
    sortTeamFunction(a: UnilevelTeam, b: UnilevelTeam): number {
      if (this.sortOrder === "ALF") {
        return a.leader.username.localeCompare(b.leader.username);
      } else if (this.sortOrder.startsWith("RANK")) {
        const aRank = a.leader.currentRankPriority;
        const bRank = b.leader.currentRankPriority;

        if (this.sortOrder === "RANK_TOP") {
          return bRank - aRank;
        } else if (this.sortOrder === "RANK_LOW") {
          return aRank - bRank;
        }
      } else if (this.sortOrder === "POINTS_TOP") {
        return b.leader.points.qualifying - a.leader.points.qualifying;
      } else if (this.sortOrder === "POINTS_LOW") {
        return a.leader.points.qualifying - b.leader.points.qualifying;
      } else if (this.sortOrder.startsWith("ACADEMY")) {
        let aDays = a.leader.subscriptions.get("trading-academy");
        aDays = aDays ? aDays : 0;
        let bDays = b.leader.subscriptions.get("trading-academy");
        bDays = bDays ? bDays : 0;
        if (this.sortOrder === "ACADEMY_CLOSE") {
          return aDays - bDays;
        } else if (this.sortOrder === "ACADEMY_DISTANT") {
          return bDays - aDays;
        }
      }
      return 0;
    },
    filterTeamFunction(m: UnilevelTeam): boolean {
      let responseAcademy = false;
      let responseDistributor = false;

      if (this.showAcademy === "academyAll") {
        responseAcademy = true;
      } else if (this.showAcademy === "academyOn") {
        const days = m.leader.subscriptions.get("trading-academy");
        responseAcademy = days && days > 0 ? true : false;
      } else if (this.showAcademy === "academyOff") {
        let days = m.leader.subscriptions.get("trading-academy");
        days = days ? days : 0;
        responseAcademy = days <= 0 ? true : false;
      }

      if (this.showDistributor === "distributorAll") {
        responseDistributor = true;
      } else if (this.showDistributor === "distributorOn") {
        const days = m.leader.subscriptions.get("distributor-license");
        responseDistributor = days && days > 0 ? true : false;
      } else if (this.showDistributor === "distributorOff") {
        let days = m.leader.subscriptions.get("distributor-license");
        days = days ? days : 0;
        responseDistributor = days <= 0 ? true : false;
      }
      return responseAcademy && responseDistributor;
    },
  },
  watch: {
    showAcademy: function () {
      this.filterTeam();
      this.orderTeam();
    },
    showDistributor: function () {
      this.filterTeam();
      this.orderTeam();
    },
    sortOrder: function () {
      this.filterTeam();
      this.orderTeam();
    },
    displayedLeftTeam: function () {
      this.filterTeam();
      this.orderTeam();
    },
    displayedRightTeam: function () {
      this.filterTeam();
      this.orderTeam();
    },
  },
  mounted() {
    this.filterTeam();
  },
});
