
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import {
  CLEAR_USER_PAYMENTS,
  doGetPayments,
  unsubscribeListener,
} from "@/store/Payments/PaymentsInterfaces";

import dayjs from "dayjs";

export default defineComponent({
  name: "payments-detail",
  computed: {
    ...mapGetters("payments", ["getPayments"]),
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    toTwoDecimals(num: number): string {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    statusIconClass(status: string): string {
      switch (status) {
        case "new":
          return "far fa-play-circle";
        case "paid":
          return "fas fa-flag-checkered";
        case "confirmed":
          return "fas fa-cogs";
        case "complete":
          return "far fa-check-circle";
        case "paidOver":
          return "fas fa-exclamation-triangle";
        case "paidPartial":
          return "fas fa-exclamation-circle";
        case "expired":
          return "fas fa-ban";
        case "invalid":
          return "fas fa-exclamation-triangle";
        default:
          return "";
      }
    },
    statusColorClass(status: string): string {
      switch (status) {
        case "new":
          return "text-muted";
        case "paid":
          return "text-teal";
        case "confirmed":
          return "text-cyan";
        case "complete":
          return "text-success";
        case "paidOver":
          return "text-warning";
        case "paidPartial":
          return "text-danger";
        case "expired":
          return "text-warning";
        case "invalid":
          return "text-danger";
        default:
          return "";
      }
    },
    translateStatus(status: string): string {
      switch (status) {
        case "new":
          return "Nuevo";
        case "paid":
          return "Iniciado";
        case "confirmed":
          return "Procesando";
        case "complete":
          return "Pagado";
        case "paidOver":
          return "Pago excedido";
        case "paidPartial":
          return "Pago incompleto";
        case "expired":
          return "Expirado";
        case "invalid":
          return "Inválido";
        default:
          return status;
      }
    },
  },
  created() {
    if (this.getPayments.length > 0) {
      this.$store.commit("payments/" + CLEAR_USER_PAYMENTS);
    }
    this.$store.dispatch("payments/" + doGetPayments);
  },
  beforeUnmount() {
    this.$store.dispatch("payments/" + unsubscribeListener, "payments");
  },
});
