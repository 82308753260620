
import { defineComponent } from "vue";
import {
  Financial,
  WalletInfo,
  SecuredDataRequest,
} from "@/store/Profile/ProfileInterfaces";
import { mapActions } from "vuex";

export default defineComponent({
  name: "btc-wallet",
  props: {
    financialInfo: {
      type: Object as () => Financial,
      default: {} as Financial,
      required: true,
    },
    updated: {
      type: Boolean,
      default: false,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
      required: true,
    },
    ccModalStatus: {
      type: String,
      default: "hidden",
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      localFinancialInfo: {
        btcWallet: {
          address: "",
          label: "",
        },
      } as Financial,
      saving: false,
    };
  },
  emits: ["saveSecuredData"],
  computed: {
    canSave(): boolean {
      return (
        this.localFinancialInfo.btcWallet?.address !==
          this.financialInfo.btcWallet?.address ||
        this.localFinancialInfo.btcWallet?.label !==
          this.financialInfo.btcWallet?.label
      );
    },
    readOnlyClasses(): any {
      return {
        "bg-light": this.editMode,
        "bg-body": !this.editMode,
        "bg-gradient": !this.editMode,
        "text-secondary": !this.editMode,
      };
    },
  },
  methods: {
    ...mapActions("profile", ["doUpdateUserFinancialInfo"]),
    edit() {
      this.editMode = true;
      // Patch because this.$nextTick() doesn't work when emits are used
      setTimeout((): void => {
        (this.$refs.btcWallet as HTMLInputElement).select();
      }, 200);
    },
    save() {
      this.saving = true;
      this.$emit("saveSecuredData", {
        type: "btcWallet",
        data: this.localFinancialInfo.btcWallet,
        requestId: "",
        requestTag: "",
      } as SecuredDataRequest);
    },
    cancelEdit() {
      this.setLocalFinancialInfo(this.financialInfo);
      this.editMode = false;
      this.saving = false;
    },
    setLocalFinancialInfo(newValue: Financial) {
      if (newValue.btcWallet) {
        // if (typeof newValue.btcWallet === typeof "") {
        //   this.localFinancialInfo.btcWallet = {
        //     address: newValue.btcWallet.toString() + ""
        //   } as WalletInfo;
        // } else {
        this.localFinancialInfo.btcWallet = {
          ...(newValue.btcWallet as WalletInfo),
        };
        this.saving = false;
        // }
      }
    },
  },
  mounted() {
    this.setLocalFinancialInfo(this.financialInfo);
  },
  watch: {
    financialInfo: {
      handler(newValue: Financial) {
        this.setLocalFinancialInfo(newValue);
      },
      deep: true,
    },
    updated: {
      handler(newValue: boolean) {
        if (newValue) {
          this.editMode = false;
          this.saving = false;
        }
      },
    },
    error: {
      handler(newValue: boolean) {
        if (newValue) {
          this.saving = false;
        }
      },
    },
    ccModalStatus: {
      handler(newValue: "hidden" | "shown") {
        switch (newValue) {
          case "shown":
            this.saving = true;
            break;
          case "hidden":
            this.saving = false;
            break;
        }
      },
    },
  },
});
