export const nombres = [
  "Hugo",
  "Lucia",
  "Martin",
  "Sofia",
  "Lucas",
  "Martina",
  "Mateo",
  "Maria",
  "Leo",
  "Julia",
  "Daniel",
  "Paula",
  "Alejandro",
  "Emma",
  "Pablo",
  "Daniela",
  "Manuel",
  "Valeria",
  "Alvaro",
  "Alba",
  "Adrian",
  "Sara",
  "David",
  "Noa",
  "Mario",
  "Carmen",
  "Enzo",
  "Carla",
  "Diego",
  "Alma",
  "Marcos",
  "Claudia",
  "Izan",
  "Valentina",
  "Javier",
  "Vega",
  "Marco",
  "Lara",
  "Alex",
  "Olivia",
  "Bruno",
  "Mia",
  "Oliver",
  "Aitana",
  "Miguel",
  "Lola",
  "Thiago",
  "Chloe",
  "Antonio",
  "Ana",
  "Marc",
  "Alejandra",
  "Carlos",
  "Abril",
  "Angel",
  "Jimena",
  "Juan",
  "Elena",
  "Gonzalo",
  "Laia",
  "Gael",
  "Adriana",
  "Sergio",
  "Candela",
  "Nicolas",
  "Marta",
  "Dylan",
  "Triana",
  "Gabriel",
  "Irene",
  "Jorge",
  "Vera",
  "Jose",
  "Ines",
  "Adam",
  "Carlota",
  "Liam",
  "Laura",
  "Eric",
  "Manuela",
  "Samuel",
  "Ariadna",
  "Dario",
  "Victoria",
  "Hector",
  "Blanca",
  "Luca",
  "Marina",
  "Iker",
  "Rocio",
  "Amir",
  "Clara",
  "Rodrigo",
  "Nora",
  "Saul",
  "Alicia",
  "Victor",
  "Lia",
  "Francisco",
  "Leire",
  "Ivan",
  "Celia",
  "Jesus",
  "Eva",
  "Jaime",
  "Gala",
  "Aaron",
  "Elsa",
  "Ruben",
  "Angela",
  "Ian",
  "Amira",
  "Guillermo",
  "Zoe",
  "Erik",
  "Andrea",
  "Mohamed",
  "Ainhoa",
  "Julen",
  "Leyre",
  "Luis",
  "Isabel",
  "Pau",
  "Nerea",
  "Unai",
  "Africa",
  "Rafael",
  "Ainara",
  "Joel",
  "Ona",
  "Alberto",
  "Cayetana",
  "Pedro",
  "Nour",
  "Raul",
  "Luna",
  "Aitor",
  "Aina",
  "Santiago",
  "Iria",
  "Rayan",
  "Mar",
  "Pol",
  "Gabriela",
  "Nil",
  "Mara",
  "Noah",
  "Jana",
  "Jan",
  "Natalia",
  "Asier",
  "Julieta",
  "Fernando",
  "Aroa",
  "Alonso",
  "Cloe",
  "Matias",
  "India",
  "Biel",
  "Helena",
  "Andres",
  "Cristina",
  "Axel",
  "Fatima",
  "Ismael",
  "Lina",
  "Marti",
  "Diana",
  "Arnau",
  "Naia",
  "Imran",
  "Carolina",
  "Luka",
  "Iris",
  "Ignacio",
  "Arlet",
  "Aleix",
  "Alexia",
  "Alan",
  "Elia",
  "Elias",
  "Greta",
  "Omar",
  "Nahia",
  "Isaac",
  "Yasmin",
  "Youssef",
  "Malak",
  "Jon",
  "Berta",
  "Teo",
  "Amaia",
  "Mauro",
  "Aya",
  "Oscar",
  "Salma",
  "Cristian",
  "Isabella",
  "Leonardo",
  "Elisa",
];
