import { ActionContext } from "vuex";
import * as fb from "../../firebase";

import {
  ADD_USER_GTR_POINTS,
  GtrPointsRecord,
  DELETE_USER_GTR_POINTS,
  SetListenerRequest,
  SET_LISTENER,
  UPDATE_USER_GTR_POINTS,
  GtrPointsState,
  GtrPointsSummary,
  UPDATE_USER_GTR_POINTS_SUMMARY,
  CLEAR_USER_GTR_POINTS,
  CLEAR_LISTENER,
  GtrPointsTypeConfig,
} from "./GtrPointsInterfaces";
import { GtrPointsTypesMap } from "./GtrPointsLocalData";

const initialState: GtrPointsState = {
  GtrPointsTypes: GtrPointsTypesMap,
  gtrPointsRecords: [],
  summary: {
    _id: 0,
    _usernamePK: "",
    total: 0,
    earned: 0,
    expended: 0,
    totalUpdatedDate: undefined,
    earnedUpdatedDate: undefined,
    expendedUpdatedDate: undefined,
  },
};
const snapshotListeners = {
  gtrPoints: () => {
    return;
  },
  summary: () => {
    return;
  },
};

const state: GtrPointsState = { ...initialState };

const mutations = {
  UPDATE_USER_GTR_POINTS_SUMMARY(
    state: GtrPointsState,
    summary: GtrPointsSummary
  ) {
    state.summary = summary;
  },
  ADD_USER_GTR_POINTS(state: GtrPointsState, record: GtrPointsRecord) {
    // Find the index where the new record should be inserted
    const index = state.gtrPointsRecords.findIndex(
      (item: any) => item._id < record._id
    );
    // If no such index is found, push the record to the end of the array
    if (index === -1) {
      state.gtrPointsRecords.push(record);
    } else {
      // Otherwise, insert the record at the correct index to maintain order
      state.gtrPointsRecords.splice(index, 0, record);
    }
  },
  DELETE_USER_GTR_POINTS(state: GtrPointsState, recordId: number) {
    state.gtrPointsRecords = [
      ...state.gtrPointsRecords.filter((item) => {
        return item._id != recordId;
      }),
    ];
  },
  UPDATE_USER_GTR_POINTS(state: GtrPointsState, record: GtrPointsRecord) {
    const index = state.gtrPointsRecords.findIndex((item: any) => {
      return item._id == record._id;
    });
    if (index !== -1) {
      state.gtrPointsRecords.splice(index, 1, record);
    }
  },
  CLEAR_USER_GTR_POINTS() {
    if (state) {
      state.summary = {
        _id: 0,
        _usernamePK: "",
        total: 0,
        earned: 0,
        expended: 0,
        totalUpdatedDate: undefined,
        earnedUpdatedDate: undefined,
        expendedUpdatedDate: undefined,
      };
      state.gtrPointsRecords.splice(0, state.gtrPointsRecords.length);
    }
  },
  SET_LISTENER(state: GtrPointsState, request: SetListenerRequest) {
    if (request.listenerId == undefined) {
      snapshotListeners.gtrPoints = request.gtrPoints;
      snapshotListeners.summary = request.summary;
    } else if (request.listenerId == "gtrPoints") {
      snapshotListeners.gtrPoints = request.gtrPoints;
    } else if (request.listenerId == "summary") {
      snapshotListeners.summary = request.summary;
    }
  },
  CLEAR_LISTENER(state: GtrPointsState, listenerId?: "gtrPoints" | "summary") {
    if (listenerId == undefined) {
      snapshotListeners.gtrPoints();
      snapshotListeners.gtrPoints = function () {
        return;
      };
      snapshotListeners.summary();
      snapshotListeners.summary = function () {
        return;
      };
    } else {
      snapshotListeners[listenerId]();
      snapshotListeners[listenerId] = function () {
        return;
      };
    }
  },
};

const actions = {
  async doGetGtrPoints(context: ActionContext<GtrPointsState, any>) {
    context.commit(CLEAR_LISTENER);
    context.commit(CLEAR_USER_GTR_POINTS);

    //console.log(context.rootGetters["auth/getUsernamePK"]);
    const username = await context.rootGetters["auth/getUsernamePK"];
    //console.log("getCommissions: " + username);
    const _userGtrPoints = fb.fbfs.collection(
      "users/" + username + "/gtrPoints/"
    );
    const _summaryRef = _userGtrPoints.where("_id", "==", 0);
    const summaryListener = _summaryRef.onSnapshot((querySnapshot) => {
      //console.log("snapshot", querySnapshot);
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        if (doc.data()) {
          const summaryData = doc.data();
          //GtrPoints summary
          const summary = summaryData as GtrPointsSummary;
          summary.totalUpdatedDate =
            summaryData.totalUpdatedDate.toDate() || undefined;
          summary.earnedUpdatedDate =
            summaryData.earnedUpdatedDate.toDate() || undefined;
          summary.expendedUpdatedDate =
            summaryData.expendedUpdateDate?.toDate() || undefined;

          //console.log("commit summary", summary);
          context.commit(UPDATE_USER_GTR_POINTS_SUMMARY, summary);
        }
      });
    });

    const _detailRef = _userGtrPoints.orderBy("_id", "desc").limit(100);
    const l = _detailRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        if (doc.data()) {
          const recordData = doc.data();
          const record = recordData as GtrPointsRecord;
          if (recordData._id != 0) {
            if (change.type == "added") {
              context.commit(ADD_USER_GTR_POINTS, record);
            } else if (change.type == "modified") {
              context.commit(UPDATE_USER_GTR_POINTS, record);
            }
          }
        } else if (change.type == "removed") {
          context.commit(DELETE_USER_GTR_POINTS, doc.id);
        }
      });
    });
    context.commit(SET_LISTENER, { gtrPoints: l, summary: summaryListener });
  },
  unsubscribeListener(context: ActionContext<GtrPointsState, any>) {
    context.commit(CLEAR_LISTENER);
  },
};
const getters = {
  getGtrPoints(state: GtrPointsState): Array<GtrPointsRecord> {
    return state ? state.gtrPointsRecords : new Array<GtrPointsRecord>();
  },
  getSummary(state: GtrPointsState): GtrPointsSummary | undefined {
    return state ? state.summary : undefined;
  },
  getGtrPointsTypeConfig(state: GtrPointsState) {
    return (type: string): GtrPointsTypeConfig | undefined => {
      return state.GtrPointsTypes.get(type);
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
