
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Cropper from "cropperjs";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import { generateDateKey } from "@/mixins/tools";
import { UpdateProfilePictureRequest } from "@/store/Profile/ProfileInterfaces";

interface IFolder {
  fullPath: string;
  name: string;
  bucket: string;
  fullFolderPath: string;
}

interface ImageData {
  url: string;
  name: string;
  fullPath: string;
}

export default defineComponent({
  emits: ["updateImage"],
  name: "class-image-modal",
  props: ["classId", "imageWidth", "imageHeight", "aspectRatio"],
  data() {
    return {
      modalObject: {} as any,
      cropper: new Cropper(document.createElement("img")),
      modeCrop: false,
      defaultImages: Array<ImageData>(),
      saving: false,
      folders: [] as Array<IFolder>,
      breadcrumb: ["Inicio"] as Array<string>,
      breadcrumbAvoid: ["img", "classes"] as Array<string>,
      path: "img/classes" as string,
      currentPath: "img/classes" as string,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUserUid", "getUserPicture"]),
    getPath() {
      return (page) => {
        if (page == "Inicio") return this.path;
        const ind = this.breadcrumb.findIndex((ele) => ele == page);
        if (ind >= 0) return this.breadcrumb.slice(1, ind + 1).join("/");
      };
    },
    sortedDefaultImages(): Array<ImageData> {
      const sorted = [...this.defaultImages];
      return sorted.sort((a, b) => {
        if (a.fullPath < b.fullPath) {
          return -1;
        }
        if (a.fullPath > b.fullPath) {
          return 1;
        }
        return 0;
      });
    },
    modal() {
      return this.$refs.classModalImageUpload
        ? new Modal(this.$refs.classModalImageUpload)
        : null;
    },
  },
  methods: {
    closeModal() {
      if (this.modal) this.modal.hide();
    },
    openModal() {
      if (this.modal) this.modal.show();
    },
    selectPictureFile() {
      const input = this.$refs.uploadedImage as HTMLInputElement;
      input.click();
    },
    cancelCrop() {
      this.modeCrop = false;
    },
    uploadImage(event: Event) {
      if (event && event.target) {
        const target = event.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
          if (target.files[0].size > 5000000) {
            alert("Seleccione una imagen de máximo 5 MB");
            return;
          }
          this.modeCrop = true;

          const result = this.$refs.imageWorkArea as Element;
          result.innerHTML = "";
          this.cropper.clear();

          const reader = new FileReader();
          reader.onload = (e) => {
            if (e?.target?.result) {
              const img = document.createElement("img");
              img.id = "image";
              img.src = e.target.result as string;
              img.style.maxHeight = "350px";
              img.style.display = "block";
              img.style.maxWidth = "100%";
              result.appendChild(img);

              this.cropper = new Cropper(img, {
                aspectRatio: this.aspectRatio ?? 1,
                viewMode: 0,
                background: false,
                zoomable: false,
              });
            }
          };
          reader.readAsDataURL(target.files[0]);
        } else {
          // const result = this.$refs.imageWorkArea as Element;
          alert("Seleccione una imagen");
        }
      }
    },
    openFolder(folder) {
      this.breadcrumb = ["Inicio", ...folder.split("/")];
      this.getStorage(folder);
    },
    saveProfilePicture(imgUrl?: string) {
      if (!imgUrl) {
        const imgSrc = this.cropper
          .getCroppedCanvas({ width: 500 })
          .toDataURL();
        const pictureUrl = `${this.path}/${this.getUsernamePK}/${
          this.getUsernamePK
        }-${generateDateKey()}.png`;
        imgUrl = imgSrc;

        const request: UpdateProfilePictureRequest = {
          usernamePK: this.getUsernamePK,
          originalUrl: this.getUserPicture,
          pictureUrl: pictureUrl,
          dataBase64Url: imgSrc,
          isDefaultImage: false,
        };

        this.$store
          .dispatch("classes/doSaveImage", request)
          .catch((error) => {
            alert("Error: " + error.message);
          })
          .finally(() => {
            this.saving = false;
          });
      }
      this.$emit("updateImage", imgUrl);
      if (this.modal) this.modal.hide();
    },
    getStorage(path = null) {
      this.folders = [] as Array<IFolder>;
      this.defaultImages = [] as Array<ImageData>;

      firebase
        .storage()
        .ref(path ?? this.path)
        .listAll()
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            const folder = {
              fullPath: folderRef.fullPath,
              name: folderRef.name,
              bucket: folderRef.bucket,
              fullFolderPath: folderRef.toString(),
            };

            // Save folder on Data()
            this.folders.push(folder);
          });

          // Save files in Data()
          res.items.forEach((itemRef) => {
            itemRef.getDownloadURL().then((url) => {
              this.defaultImages.push({
                url: url,
                name: itemRef.name,
                fullPath: itemRef.fullPath,
              });
            });
          });
        });
    },
  },
  // mounted() {
  //   this.modalObject = bs.Modal.getOrCreateInstance(
  //     document.getElementById("profile-picture-gallery-modal")
  //   );
  // },
  created() {
    this.getStorage();
  },
  unmounted() {
    if (this.modal) this.modal.hide();
  },
});
