import { ActionContext } from "vuex";
import {
  ADD_PAYOUT,
  CLEAR_PAYOUTS,
  CLEAR_PAYOUTS_LIST,
  DELETE_PAYOUT,
  Payout,
  PayoutsStoreState,
  SetListenerRequest,
  SET_LISTENER,
  SET_PAYOUTS_LIST,
  unsubscribeListener,
  UPDATE_PAYOUT,
} from "./PayoutsInterfaces";
import { firestore } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const initialState: PayoutsStoreState = {
  payouts: new Array<Payout>(),
  payoutsList: new Array<string>(),
};

let snapshotListeners: any[] = [];

const state: PayoutsStoreState = { ...initialState };

const mutations = {
  SET_PAYOUTS_LIST(state: PayoutsStoreState, payoutsList: Array<string>) {
    state.payoutsList = payoutsList;
  },
  CLEAR_PAYOUTS_LIST(state: PayoutsStoreState) {
    if (state) {
      state.payoutsList = new Array<string>();
    }
  },
  CLEAR_PAYOUTS(state: PayoutsStoreState) {
    if (state) {
      state.payouts = new Array<Payout>();
    }
  },
  ADD_PAYOUT(state: PayoutsStoreState, payouts: Payout) {
    state.payouts?.push(payouts);
  },
  UPDATE_PAYOUT(state: PayoutsStoreState, updatedPayout: Payout) {
    const index = state.payouts.findIndex((p) => {
      return p._id == updatedPayout._id;
    });
    state.payouts[index] = updatedPayout;
  },
  DELETE_PAYOUT(state: PayoutsStoreState, payoutId: string) {
    state.payouts = state.payouts?.filter((p) => {
      return p._id != payoutId;
    });
  },
  SET_LISTENER(state: PayoutsStoreState, request: SetListenerRequest) {
    snapshotListeners = request.listener;
  },
};

const actions = {
  async doGetPayoutsList(
    context: ActionContext<PayoutsStoreState, any>,
    yearMonth: string
  ) {
    context.commit(CLEAR_PAYOUTS);
    context.commit(CLEAR_PAYOUTS_LIST);

    // If yearMonth is not provided, use the current month
    if (!yearMonth) {
      yearMonth = dayjs().tz("America/Mexico_city").format("YYYY-MM");
    }
    // Get the first day of the month
    const firstDay = dayjs(yearMonth + "-01")
      .tz("America/Mexico_city", true)
      .startOf("day")
      .valueOf();

    // Get the last day of the month
    const lastDay = dayjs(yearMonth)
      .tz("America/Mexico_city", true)
      .endOf("month")
      .endOf("day")
      .valueOf();

    const _withdrawalsRef = collection(firestore, "systemWithdrawals");

    const _currentPageListQuery = query(
      _withdrawalsRef,
      where("_id", ">=", firstDay),
      where("_id", "<=", lastDay),
      orderBy("_id", "desc")
    );

    const payoutsList = new Array<string>();
    await getDocs(_currentPageListQuery).then((payouts) => {
      payouts.forEach((doc) => {
        payoutsList.push(doc.id);
      });
    });
    context.commit(SET_PAYOUTS_LIST, payoutsList);
  },
  async doGetPayoutDetails(
    context: ActionContext<PayoutsStoreState, any>,
    payoutId: string
  ) {
    context.dispatch(unsubscribeListener, "payouts");
    context.commit(CLEAR_PAYOUTS);

    if (!payoutId) {
      return;
    }
    const _withdrawalsRef = collection(firestore, "systemWithdrawals");
    const withdrawalDetailRef = doc(_withdrawalsRef, payoutId);

    const payoutData = await getDoc(withdrawalDetailRef).then((doc) => {
      if (doc.exists()) {
        return doc.data();
      } else {
        return {} as any;
      }
    });
    const payoutRecipientsRef = payoutData.recipientsWithdrawalsRefs;
    // Create an object to track the current state of each document
    const currentDocs: { [id: string]: any } = {};

    // Create an array to hold the unsubscribe functions for the listeners
    const unsubscribeFunctions: any[] = [];
    if (payoutRecipientsRef) {
      // Set up a listener for each recipient
      for (const recipientRef of payoutRecipientsRef) {
        const unsubscribe = onSnapshot(recipientRef, (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            const previousDoc = currentDocs[doc.id];

            if (!previousDoc) {
              // This is a new document
              context.commit(ADD_PAYOUT, docData);
            } else if (
              JSON.stringify(previousDoc) !== JSON.stringify(docData)
            ) {
              // This document has been modified
              context.commit(UPDATE_PAYOUT, docData);
            }

            // Update the current state of the document
            currentDocs[doc.id] = docData;
          } else if (currentDocs[doc.id]) {
            // This document has been removed
            context.commit(DELETE_PAYOUT, doc.id);
            delete currentDocs[doc.id];
          }
        });

        // Save the unsubscribe function so you can remove the listener later
        unsubscribeFunctions.push(unsubscribe);
      }

      context.commit(SET_LISTENER, {
        listenerId: "payouts",
        listener: unsubscribeFunctions,
      });
    }
  },
  unsubscribeListener() {
    for (const unsubscribe of snapshotListeners) {
      unsubscribe();
    }
  },
};
const getters = {
  getPayoutsList(state: PayoutsStoreState): Array<string> {
    return state ? state.payoutsList : new Array<string>();
  },
  getPayoutDetails(state: PayoutsStoreState): Array<Payout> {
    return state ? state.payouts : new Array<Payout>();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
