
import { ExpiringPoints } from "@/store/RankQualification/RankQualificationInterfaces";
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "expiring-points",
  props: {
    expiringPoints: {
      type: Array as PropType<Array<ExpiringPoints>>,
      required: true,
    },
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD");
    },
  },
});
