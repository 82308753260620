
import { defineComponent } from "vue";
import * as fb from "../../../firebase";
import { generateDateKey } from "@/mixins/tools";
import { UpdateSubscriptionNoVolumeRequest } from "functions/src/Subscriptions/SubscriptionsInterfaces";

export default defineComponent({
  name: "activate-no-volume",
  props: {
    username: { type: String, required: true },
    email: { type: String, required: true },
  },
  emits: ["userActivated", "error"],
  data() {
    return {
      update: false,
      quote: false,
      activating: false,
    };
  },
  methods: {
    registerWithNoVolumePrize() {
      this.activating = true;
      const subscriptionRequest: UpdateSubscriptionNoVolumeRequest = {
        subscriptionId: "prize",
        duration: 0,
        durationUnit: "days",
        username: this.username.toLowerCase(),
        email: this.email,
        isDirectAffiliate: false,
        noVolume: true,
        noVolumeRef: "noVolumeActivationPrize@" + generateDateKey(),
      };
      const UpdateSubscription = fb.func.httpsCallable(
        "Admin-ActivateSubscriptionWithoutVolume"
      );
      UpdateSubscription(subscriptionRequest)
        .then((response) => {
          if (response.data.OPCODE == "SUCCESS") {
            this.$emit("userActivated");
          } else if (response.data.OPCODE == "ERROR") {
            this.$emit(
              "error",
              "Error al Activar el Usuario",
              response.data.message +
                "\n" +
                (response.data.messageDetails || "")
            );
          }
        })
        .finally(() => {
          this.activating = false;
        });
    },
  },
});
