import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "d-flex justify-content-end my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control w-25 me-3",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.registerUsername) = $event))
      }, null, 512), [
        [_vModelText, _ctx.registerUsername]
      ]),
      _createElementVNode("button", {
        class: "btn btn-outline-secondary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.register(_ctx.registerUsername)))
      }, " Registrar ")
    ])
  ]))
}